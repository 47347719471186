/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
// eslint-disable-next-line no-multiple-empty-lines

export const FUND_CODE = {
  functionalArea: 'R1',
  dataElementName: 'R_FUND_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const TXN_CODE = {
  functionalArea: 'F1',
  dataElementName: 'F_TXN_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};
export const COS = {
  functionalArea: 'C1',
  dataElementName: 'C_COS_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};
export const RECOUPMENT = {
  functionalArea: 'F1',
  dataElementName: 'F_RECOUP_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const CATEGORY_OF_SERVICE_VALIDVALUE = {
  functionalArea: 'Claims',
  dataElementName: 'C_COS_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const F_RSN_CD = {
  functionalArea: 'F1',
  dataElementName: 'F_RSN_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const AGNCY_CODE = {
  functionalArea: 'F1',
  dataElementName: 'F_AGCY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const F_PAYMENT_REQ_TY_CD = {
  functionalArea: "F1",
  dataElementName: "F_PAYMENT_REQ_TY_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const PAYEE_TYPE = {
  functionalArea: 'G1',
  dataElementName: 'G_CMN_ENTY_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const STATUS_TYPE = {
  functionalArea: 'F1',
  dataElementName: 'G_BATCH_TXN_STAT_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_STATUS_TYPE = {
  functionalArea: 'F1',
  dataElementName: 'G_PYE_PYR_STAT_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const BNKRPTCY_ID = {
  functionalArea: 'G1',
  dataElementName: 'F_BNKRPTCY_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const EFT_STAT_CODE = {
  functionalArea: 'F1',
  dataElementName: 'F_EFT_STAT_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const BANK_ACCT_TYPE = {
  functionalArea: 'F1',
  dataElementName: 'F_BANK_ACCT_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const ADR_USG_STAT_CD = {
  functionalArea: 'G1',
  dataElementName: 'G_ADR_USG_STAT_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const R_FUNC_AREA_CD = {
  functionalArea: 'Reference',
  dataElementName: 'R_FUNC_AREA_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const DATE_FORMAT = {
  functionalArea: 'Reference',
  dataElementName: 'G_PARAM_TYPE_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const NOTES_USG_CODE = {
  functionalArea: 'G1',
  dataElementName: 'G_NOTE_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const MAP_TYP_CODE = {
  functionalArea: 'G1',
  dataElementName: 'R_MAP_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_TYPE = {
  functionalArea: 'P1',
  dataElementName: 'P_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_SPECL_CODE = {
  functionalArea: 'P1',
  dataElementName: 'P_SPECL_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CONTACT_TYPE = {
  functionalArea: 'G1',
  dataElementName: 'G_REP_XREF_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const ACTIVITY_TYPE = {
  functionalArea: 'F1',
  dataElementName: 'F_ACTV_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const NAME_PREFIX = {
  functionalArea: 'G1',
  dataElementName: 'G_NAM_PREFX_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const STATE_CODE = {
  functionalArea: 'G1',
  dataElementName: 'G_US_STATE_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_ID_TYPE = {
  functionalArea: 'P1',
  dataElementName: 'P_ALT_ID_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PROVIDER_TYPE_CODE = {
  functionalArea: 'P1',
  dataElementName: 'P_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const CLAIM_TYPE = {
  functionalArea: 'C1',
  dataElementName: 'C_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const ADDRESS_USAGE_TYPE = {
  functionalArea: 'G1',
  dataElementName: 'G_ADR_USG_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_ID_TYPE_CD_OTHER = {
  functionalArea: 'F1',
  dataElementName: 'G_ENTY_EXTL_ID_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_ID_TYPE_CD_PROVIDER = {
  functionalArea: 'F1',
  dataElementName: 'P_ALT_ID_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_ID_TYPE_CD_MEMBER = {
  functionalArea: 'F1',
  dataElementName: 'B_ALT_ID_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_ID_TYPE_CD_TPL = {
  functionalArea: 'F1',
  dataElementName: 'DM_G_CARR_ENTY_ID_TY_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYEE_RESN_CD = {
  functionalArea: 'P1',
  dataElementName: 'P_ENRL_STAT_RSN_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const GL_CO_CD = {
  functionalArea: 'F1',
  dataElementName: 'F_GL_CO_CD',
  code: null,
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
};

export const MEMBER_TYPE_DD = {
  functionalArea: "Member",
  dataElementName: "B_ALT_ID_TY_CD",
  code: null,
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null
};

export const F_FSCL_YR_NUM = {
  functionalArea: 'F1',
  dataElementName: 'DM_YR_NUM',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const F_ORG_CD = {
  functionalArea: 'F1',
  dataElementName: 'F_ORG_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAY_STATUS = {
  functionalArea: 'F1',
  dataElementName: 'C_STAT_CD',
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null
};

export const PAYMENT_METHOD = {
  functionalArea: "C5",
  dataElementName: "C_WARR_MEDM_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const WARR_STAT_CD = {
  functionalArea: "F1",
  dataElementName: "C_WARR_STAT_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
};

export const PAYOUT_F_RSN_CD = {
  functionalArea: "F1",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 6006,
};

export const PAYMENT_CRITERIA_STS_CD = {
  functionalArea: "G1",
  dataElementName: "DM_IND",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 1093,
};

export const AR_BULK_RN9023_CD = {
  listNumber: 9023,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const RSN_CD_3001 = {
  listNumber: 3001,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const RSN_CD_3002 = {
  listNumber: 3002,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const REASON_CODES = {
  functionalArea: "F1",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 3102,
};

export const REASON_CODES_9301 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9301
};

export const REASON_CODE_LIST_9400 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9400
};

export const REASON_CODE_LIST_9401 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9401
};

export const REASON_CODE_LIST_9402 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9402
};

export const REASON_CODE_LIST_9403 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9403
};

export const REASON_CODE_LIST_9404 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9404
};

export const REASON_CODE_LIST_9405 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9405
};

export const REASON_CODE_LIST_9406 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9406
};

export const REASON_CODE_LIST_9407 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9407
};

export const REASON_CODE_LIST_9408 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9408
};

export const REASON_CODE_LIST_9409 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9409
};

export const REASON_CODE_LIST_9410 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9410
};

export const REASON_CODE_LIST_9411 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9411
};

export const REASON_CODE_LIST_9412 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9412
};

export const REASON_CODE_LIST_9413 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9413
};

export const REASON_CODE_LIST_9414 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9414
};

export const REASON_CODE_LIST_9415 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9415
};

export const REASON_CODE_LIST_9416 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9416
};

export const REASON_CODE_LIST_9417 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9417
};

export const REASON_CODE_LIST_9418 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9418
};

export const REASON_CODE_LIST_9419 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9419
};

export const REASON_CODE_LIST_9420 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9420
};

export const REASON_CODE_LIST_9421 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9421
};

export const REASON_CODE_LIST_9422 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9422
};

export const REASON_CODES_9302 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9302
};

export const REASON_CODES_9303 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9303
};

export const REASON_CODES_9304 = {
  functionalArea: 'FS',
  dataElementName: 'F_RSN_CD',
  listNumber: 9304
};


export const REASON_CODES_9026 = {
  functionalArea: "F1",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 9026,
};
export const AR_BULK_RN5_CD = {
  listNumber: 5,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN6_CD = {
  listNumber: 6,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN7_CD = {
  listNumber: 7,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN8_CD = {
  listNumber: 8,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN10_CD = {
  listNumber: 10,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN11_CD = {
  listNumber: 11,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN24_CD = {
  listNumber: 24,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN25_CD = {
  listNumber: 25,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const AR_BULK_RN3004_CD = {
  listNumber: 3004,
  businessName: null,
  functionalArea: "F1",
  dataElementName: null,
  description: "",
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false
};

export const AR_BULK_FRN_CD = {
  functionalArea: "F1",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 6
};

export const AR_BULK_RN16_CD = {
  listNumber: 16,
  businessName: null,
  functionalArea: 'F1',
  dataElementName: null,
  description: '',
  value: null,
  listType: null,
  descriptionStartsOrContains: null,
  businessNameStartsOrContains: null,
  listNumberStartsWith: false,
};

export const REASON_CODES_9027 = {
  functionalArea: "F1",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 9027
};
export const ADJST_RSN_CD = {
  functionalArea: "C1",
  dataElementName: "C_VOID_REPLCMT_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 1005
};
export const MANUAL_F_RSN_CD = {
  functionalArea: "F1",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null,
  code: null,
  listNumber: 3002
};

export const PAYEE_ID_TYPE_CODE = [{
  functionalArea: "Financial",
  dataElementName: "F_RSN_CD",
  businessName: null,
  valueShortDescription: null,
  crossReferenceColumnName: null,
  crossReferenceTableName: null,
  dataEleNameStartsOrContains: null,
  busNameStartsOrContains: null
}];
