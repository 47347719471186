/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { Component, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../ReceiptReissue.scss';
import { withRouter } from 'react-router';
import * as AppConstants from '../../../SharedModules/AppConstants';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../SharedModules/services/service';
import { LinkOfFcn, LinkOfTcn } from '../../../SharedModules/TabRedirections/customInputLink';
import { redirectPayoutFCN, redirectReceiptFCN } from '../../../SharedModules/TabRedirections/TabRedirections';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import * as ReceiptReissueConstant from './ReceiptReissueConstant';
import { getLongDescription } from '../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';
import { TRANSACTION_NUMBER_FORMAT } from '../../../SharedModules/Errors/ErrorMsgConstant';

/* CheckReplacementTabDetail()
* This component is used to display Check Replacement details (inside first tab in check replacement screen)
* Inside this component we are getting values using props sent by ReissueUpdate.js
*/
function CheckReplacementTabDetail(props) {
    const fndRsnCdInd = props.fundRsnCode17 && props.values?.finanReasonCode &&
          props.fundRsnCode17.filter(x => props.values?.finanReasonCode === x).toString();
  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
              <fieldset className="custom-fieldset">
                <legend>{ReceiptReissueConstant.ORIGINAL_CHECK}</legend>
                <div className="form-wrapper">
                  <div className="mui-custom-form with-select override-width-45 has-link">
                    <TextField
                      id="fcn"
                      disabled={true}
                      label="FCN"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Link to={redirectReceiptFCN(props.values.fcn)}>
                              {props.values.fcn}
                            </Link>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div className="mui-custom-form with-select override-width-45 ">
                    <TextField
                      id="originalCheckNumber"
                      disabled={true}
                      label="Original Check Number"
                      value={props.values.originalCheckNumber}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form with-select override-width-45 has-link">
                    <TextField
                      id="ftTcn"
                      disabled={true}
                      label="Related FT TCN"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <a href={CLAIMS_INQUIRY_TCN_LINK + props.values.ftTcn} target={'_blank'}>
                              {props.values.ftTcn}
                            </a>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div className="mui-custom-form  with-icon override-width-45">
                    <FormControl fullWidth>
                      <label className="MuiFormLabel-root small-label" for="originalCheckAmount">Original Check Amount</label>
                      <Input
                        disabled={true}
                        id="originalCheckAmount"
                        value={props.values.originalCheckAmount}
                        InputLabelProps={{
                          shrink: true
                        }}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="form-wrapper">
                    <NativeDropDown
                      id="reasonCode"
                      disabled={true}
                      className="override-width-45"
                      label="Original Financial Reason Code"
                      value={props.values.reasonCode}
                      InputLabelProps={{
                        shrink: true
                      }}
                      dropdownList={props.dropdownData ? props.dropdownData['F1#F_RSN_CD']
                      && props.dropdownData['F1#F_RSN_CD'].map(option => (
                          <option key={option.code} value={option.code}>
                            {option.description}
                          </option>
                        ))
                        : null}
                    />
                                  <div className="mui-custom-form with-select override-width-45">
                                  <TextField
                                    id='ATRnumber-Original'
                                    fullWidth
                                    label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                                    placeholder=""
                                    value={props.atrNumber}
                                    disabled={true}
                                    inputProps={{ maxlength: 18 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    
                                  ></TextField>
                                </div>
                </div>
  
              </fieldset>
            </div>
            <div className="col-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
              <fieldset className="custom-fieldset">
                <legend>{ReceiptReissueConstant.REPLACEMENT_CHECK}</legend>
                <div className="form-wrapper">
                  <div className={props.saveFlag ? "mui-custom-form with-select override-width-45 has-fcn-link" : "mui-custom-form with-select override-width-45"}>
                    <TextField
                      id="fcnCheck"
                      disabled={true}
                      label="FCN"
                      value={props.saveFlag ? null : props.values.fcnCheck}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: LinkOfFcn,
                        inputProps: { value: props.values.fcnCheck, redirection: redirectPayoutFCN, saveFlag: props.saveFlag }
                      }}
                    />
                  </div>
                  <div className="mui-custom-form with-select override-width-45">
                    <TextField
                      id="checkNumber"
                      disabled={props.fieldsDisabled || props.values.finanReasonCode !== fndRsnCdInd || props.fieldsDisabledSave}
                      label="Check Number"
                      required={props.values.finanReasonCode == fndRsnCdInd}
                      value={props.values.checkNumber}
                      inputProps={{ maxLength: 15 }}
                      onChange={props.handleChange('checkNumber')}
                      onInput={(e) => { e.target.value = e.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, ''); }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      helperText={props.errors[2] ? props.errorText[2] : props.errors[3] ? props.errorText[3] : null}
                      error={props.errors[2] ? props.errorText[2] : props.errors[3] ? props.errorText[3] :null}
                    />
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form with-select override-width-45 has-link">
                    <TextField
                      disabled={true}
                      id="ftTcnCheck"
                      label="Related FT TCN"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: LinkOfTcn,
                        inputProps: { value: props.values.ftTcnCheck, saveFlag: props.saveFlag }
                      }}
                    />
                  </div>
                  <div className="mui-custom-form  with-icon override-width-45">
                    <FormControl fullWidth>
                      <label className="MuiFormLabel-root small-label" for="checkAmount">Check Amount</label>
                      <Input
                        disabled={true}
                        id="checkAmount"
                        value={props.values.checkAmount}
                        InputLabelProps={{
                          shrink: true
                        }}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="form-wrapper">
                    <NativeDropDown
                      isRequired
                      id="finanReasonCode"
                      disabled={props.fieldsDisabled || props.fieldsDisabledSave}
                      className="override-width-45"
                      label="Financial Reason Code"
                      value={props.values.finanReasonCode}
                      onChange={props.handleChange('finanReasonCode')}
                      errTxt={props.errors[0] ? props.errorText[0] : props.errors[4] ? props.errorText[4] : null}
                      InputLabelProps={{
                        shrink: true
                      }}
                      dropdownList={props.reasonCodeList.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.description}
                        </option>
                      ))}

                    />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="mui-custom-form input-date with-date override-width-45">
                      <KeyboardDatePicker
                        id="checkDate"
                        disabled={props.fieldsDisabled || props.values.finanReasonCode !== fndRsnCdInd || props.fieldsDisabledSave}
                        required={props.values.finanReasonCode == fndRsnCdInd}
                        label="Check Date"
                        format="MM/dd/yyyy"
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder="mm/dd/yyyy"
                        value={props.selectedCheckDate}
                        onChange={props.handleCheckDateChange}
                        onKeyUp={props.handleCheckDateText}
                        helperText={props.errors[1] ? props.errorText[1] : null}
                        error={props.errors[1] ? props.errorText[1] : null}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <div className="mui-custom-form with-select override-width-45">
                                  <TextField
                                    id='ATRnumber'
                                    fullWidth
                                    label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                                    placeholder=""
                                    value={props.values.atrNumber||''}
                                    onChange={props.handleChange('atrNumber')}
                                    inputProps={{ maxlength: 18 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    helperText={props.errors[3]?`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`:null}
                                    error= {props.errors[3]?`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`:null}
                                  ></TextField>
                                </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withRouter((CheckReplacementTabDetail));
