/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './ARTransferActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';
export const validatePayeeID = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARTRANSFER_VALIDATE}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setValidatePayeeID([]));
        } else {
          dispatch(setValidatePayeeID(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setValidatePayeeID(sendingResponse));
      });
  };
};
export const setValidatePayeeID = (data) => {
  return {
    type: actionTypes.PAYEE_ID_VALIDATE,
    validatePayeeIDvalues: data
  };
};
export const saveARTransfer = (value, file) => {
  const formInfo = new FormData();
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARTRANSFER_TRANSFER}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setSaveResults([]));
        } else {
          dispatch(setSaveResults(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse  = commonAPIHandler(error);
        dispatch(setInternalError(sendingResponse));
      });
  };
};
export const setSaveResults = (data) => {
  return {
    type: actionTypes.AR_TRANSFER,
    saveResults: data
  };
};
export const resetTransfer = () => {
  return {
    type: actionTypes.RESET_TRANSFER,
    resetData: []
  };
};
export const setInternalError = (data) => {
  return {
    type: actionTypes.ARTRANSFER_ERROR,
    transferError: data
  };
};
