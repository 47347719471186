/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Bootstrap, { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import Radio from '@material-ui/core/Radio';
import { Link } from 'react-router-dom';
import { redirectGrossLevelFCN } from '../../../../../../../SharedModules/TabRedirections/TabRedirections';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../../../../SharedModules/services/service';
import { getLongDescription } from '../../../../../../../SharedModules/CommonUtilities/commonUtilities';
import * as commonMethods from '../../../../../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../../../../../SharedModules/Dropdowns/NativeDropDown'
import * as AppConstants from '../../../../../../../SharedModules/AppConstants';

export default function LienLevyDetails(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const disableValue = props.isDisabled;
  const disableIfF16007 = commonMethods.checkValueExists(props.dropDownCheckList, props.values.reasonCode);
  let lienHolderCode = props.lienHolderTypeData.find(value => value.code === props.values.lienHolderType);
  let lienHolderIDCode = props.lienHolderIdTypeData.find(value => value.code === props.values.lienHolderIdType);
  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsFCN"
              fullWidth
              label="FCN"
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 14 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.fcn}
            ></TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsOriginalA/R"
              fullWidth
              required
              label="Original A/R Amount"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: true,
                className: 'Mui-disabled'
              }}
              inputProps={{ maxlength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.originalARAmount).format('0,0.00')}
            ></TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsAdjustedA/R"
              fullWidth
              label="Adjusted A/R Amount"
              inputProps={{ maxlength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: true,
                className: 'Mui-disabled'
              }}
              value={numeral(props.values.adjustedARAmount).format('0,0.00')}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsBalanceAmount"
              fullWidth
              label="Balance Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.balanceAmount).format('0,0.00')}
            ></TextField>
          </div>
          <div
            className="mui-custom-form  override-m-1 override-width-10"
            style={{ lineHeight: '100%' }}>
            <label className="MuiFormLabel-root small-label">
              Approval<span>*</span>
            </label>
            <div className="sub-radio no-margin">
              <Radio
                value="Y"
                id="approvalIdLienLevyDetailsYes"
                disabled={!userInquiryPrivileges ? !userInquiryPrivileges : disableValue}
                checked={props.values.approval === 'Y'}
                onChange={props.handleChange('approval')}
              /><label className="text-black" for="approvalIdLienLevyDetailsYes">Yes</label>
              <Radio
                value="N"
                id="approvalIdLienLevyDetailsNo"
                checked={props.values.approval === 'N'}
                disabled={!userInquiryPrivileges ? !userInquiryPrivileges : disableValue}
                onChange={props.handleChange('approval')}
                className="ml-2"
              />
              <label className="text-black" for="approvalIdLienLevyDetailsNo">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form has-link input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsRelatedFT"
              fullWidth
              label="Related FT TCN"
              InputProps={{
                readOnly: true, className: 'Mui-disabled', startAdornment: (
                  <InputAdornment position="start">
                    <a href={CLAIMS_INQUIRY_TCN_LINK + props.values.relatedFTTCN} target={'_blank'}>
                      {props.values.relatedFTTCN}
                    </a>
                  </InputAdornment>
                )
              }}
              placeholder=""
              inputProps={{ maxlength: 17 }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsEstablishedDate"
              fullWidth
              label="Established Date"
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.establishedDate}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsLastDate"
              fullWidth
              label="Last Date Applied"
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lastDateApplied}
            ></TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsA/RAge"
              fullWidth
              label="A/R Age in Days"
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              value={props.values.arAgeInDays}
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-sm override-m-1 override-width-10">
            <TextField
              id="standard-LienLevyDetailsA/RLetter"
              fullWidth
              label="A/R Letter Sent "
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.arLettersent}
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <NativeDropDown
            className='override-m-1'
            isRequired
              id="standard-LienLevyDetailsLienHolder"
              label="Lien Holder Type"
              disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.balanceAmount === '0.00')}
            value={lienHolderCode !== undefined ? props.values.lienHolderType : DEFAULT_DD_VALUE}
            onChange={props.handleChange('lienHolderType')}
              inputProps={{ maxlength: 3 }}
            errTxt={props.errors[13] ? props.errorText[13] : null}
            dropdownList={props.lienHolderTypeData
                ? props.lienHolderTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
          />
          <NativeDropDown
            className='override-m-1'
            isRequired
              id="standard-LienLevyDetailsLienHolderIDType"
              label="Lien Holder ID Type Code"
              disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.balanceAmount === '0.00')}
            value={lienHolderIDCode !== undefined ? props.values.lienHolderIdType : DEFAULT_DD_VALUE}
            onChange={props.handleChange('lienHolderIdType')}
              inputProps={{ maxlength: 3 ,"data-testid":"test_lienHolderIdType"}}
            errTxt={props.errors[14] ? props.errorText[14] : null}
            dropdownList={props.lienHolderIdTypeData
                ? props.lienHolderIdTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
          />
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsLienHolderID"
              fullWidth
              required
              disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.balanceAmount === '0.00')}
              label="Lien Holder ID"
              placeholder=""
              inputProps={{ maxlength: 15 }}
              inputRef={props.inputRefCursor}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lienHolderId}
              onChange={props.handleChange('lienHolderId')}
              onBlur={props.validateLienHolderSystemDetails}
              helperText={props.errors[15] ? props.errorText[15] : null}
              error={props.errors[15] ? props.errorText[15] : null}
            ></TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-LienLevyDetailsLienHolderName"
              fullWidth
              label="Lien Holder Name"
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lienHolderName}
              onChange={props.handleChange('lienHolderName')}
            ></TextField>
          </div>
          <NativeDropDown
            className='override-m-1'
            id="payeeStatus"
              label="Payee Status"
            value={props.values.payeeStatus || DEFAULT_DD_VALUE}
            onChange={props.handleChange('payeeStatus')}
              title={getLongDescription(props.payeePaymentStatusFrmState, props.values.payeeStatus)}
            dropdownList={props.payeePaymentStatusFrmState
                ? props.payeePaymentStatusFrmState.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.description}
                  </option>
                ))
                : null}
          />
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={props.values.atrNumber}
              onChange={props.handleChange('atrNumber')}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={props.errors[16] ? props.errorText[16] : null}
              error={props.errors[16] ?props.errorText[16] : null}
            ></TextField>
          </div>
        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-5" style={{ maxWidth: '398px' }}>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper vertical-wrapper">
                  <div className="mui-custom-form min-md">
                    <TextField
                      required
                      fullWidth
                      id="standard-LienLevyDetailsFiscalYear"
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={{ maxlength: 4 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : disableValue, className: !userInquiryPrivileges ? !userInquiryPrivileges : disableValue ? 'Mui-disabled' : '' }}
                      value={props.values.fiscalYear}
                      onChange={props.handleChange('fiscalYear')}
                      helperText={props.errors[1] ? props.errorText[1] : null}
                      error={props.errors[1] ? props.errorText[1] : null}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                      onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                    ></TextField>
                  </div>
                  <NativeDropDown
                    className='min-md'
                      id="standard-LienLevyDetailsCategoryofService"
                      label="Category of Service"
                      InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : disableValue, className: !userInquiryPrivileges ? !userInquiryPrivileges : disableValue ? 'Mui-disabled' : '' }}
                      value={props.values.categoryOfService}
                    onChange={props.handleChange('categoryOfService')}
                    inputProps={{ maxlength: 3 }}
                      title={getLongDescription(props.COSData, props.values.categoryOfService)}
                    dropdownList={props.COSData &&
                        props.COSData !== 'undefined' &&
                        typeof props.COSData !== 'string'
                        ? props.COSData.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.description}
                          </option>
                        ))
                        : null}
                  />
                  <NativeDropDown
                    className='min-md'
                    isRequired
                      id="standard-LienLevyDetailsFundCode"
                      label="Fund Code"
                      InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : disableValue, className: !userInquiryPrivileges ? !userInquiryPrivileges : disableValue ? 'Mui-disabled' : '' }}
                      value={props.values.collocationCode}
                    onChange={props.handleChange('collocationCode')}
                    inputProps={{ maxlength: 10 }}
                      title={getLongDescription(props.fundCodeData, props.values.collocationCode)}
                    errTxt={props.errors[2] ? props.errorText[2] : null}
                    dropdownList={props.fundCodeData && props.fundCodeData !== undefined
                        ? props.fundCodeData.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.description}
                          </option>
                        ))
                        : null}
                  />
                </div>
              </fieldset>
            </div>
            <div className="col-7" style={{ paddingLeft: '62px' }}>
              <fieldset className="custom-fieldset">
                <legend>Recoupment:</legend>
                <div className="form-wrapper">
                  <NativeDropDown
                    className='min-md'
                      id="standard-LienLevyDetailsRecoupmentType"
                      label="Recoupment Type Code"
                      value={props.values.recoupmentTypeCode}
                    onChange={props.handleChange('recoupmentTypeCode')}
                      inputProps={{ maxlength: 1, "data-testid": "recoupment_type_code_test" }}
                    errTxt={props.errors[3] ? props.errorText[3] : null}
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.balanceAmount === '0.00')}
                    dropdownList={props.recoupmentData &&
                        props.recoupmentData !== 'undefined' &&
                        typeof props.recoupmentData !== 'string'
                        ? props.recoupmentData.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.description}
                          </option>
                        ))
                        : null}
                  />
                  <div className="mui-custom-form min-md">
                    <TextField
                      required={props.values.recoupmentTypeCode === 'A'}
                      fullWidth
                      id="standard-LienLevyDetailsInstallmentValue"
                      label="Installment Value"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                      value={props.values.installmentValue}
                      onBlur={props.formatDecimalField('installmentValue')}
                      onFocus={props.removeFormatDecimalFieldInstallment('installmentValue')}
                      onChange={props.handleDecimalValues('installmentValue')}
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.recoupmentTypeCode === 'S' || (disableIfF16007 && props.values.recoupmentTypeCode === 'A') || props.values.balanceAmount === '0.00')}
                      helperText={props.errors[10] ? props.errorText[10] : null}
                      error={props.errors[10] ? props.errorText[10] : null}
                    ></TextField>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form  min-md">
                    <TextField
                      id="standard-LienLevyDetailsPercentValue"
                      type='number'
                      fullWidth
                      required={props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'D'}
                      label="Percent Value"
                      placeholder=""
                      inputProps={{ maxlength: 13 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                      value={props.values.percentValue}
                      onBlur={props.formatPercent('percentValue')}
                      onChange={props.handleChange('percentValue')}
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.recoupmentTypeCode === 'S' || (disableIfF16007 && props.values.recoupmentTypeCode === 'B') || props.values.balanceAmount === '0.00')}
                      helperText={props.errors[9] ? props.errorText[9] : null}
                      error={props.errors[9] ? props.errorText[9] : null}
                    ></TextField>
                  </div>
                  <div className="mui-custom-form min-md">
                    <TextField
                      fullWidth
                      id="standard-LienLevyMaxWithholdAmount"
                      label="Max Withhold Amount"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                      value={props.values.maxWithholdAmount}
                      onBlur={props.formatDecimalField('maxWithholdAmount')}
                      onFocus={props.removeFormatDecimalFieldWHAmount('maxWithholdAmount')}
                      onChange={props.handleChange('maxWithholdAmount')}
                      disabled={true}
                    ></TextField>
                  </div>
                </div>
                <div className="form-wrapper v-align-end">
                  {
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="mui-custom-form with-date min-md">
                        <KeyboardDatePicker
                          maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                          id="date-picker-dialog-LienLevyDetailsStartDate"
                          label="Start Date"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          minDate={new Date()}
                          value={props.selectedRecoupmentDate}
                          onChange={props.handleRecoupmentDateChange}
                          InputLabelProps={{
                            shrink: true
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          disabled={!userInquiryPrivileges ? !userInquiryPrivileges
                            : props.values.recoupmentTypeCode === 'S' ||
                            props.values.balanceAmount === '0.00'
                          }
                          helperText={
                            props.errors[11]
                              ? props.errorText[11]
                              : null
                          }
                          error={
                            props.errors[11]
                              ? props.errorText[11]
                              : null
                          }
                          required={
                            props.values.recoupmentTypeCode === 'B' ||
                            props.values.recoupmentTypeCode === 'A' || props.values.recoupmentTypeCode === 'D'
                          }
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  }
                  <NativeDropDown
                    isRequired={props.values.recoupmentTypeCode ===AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || props.values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT}
                    className='min-md mb-auto'
                      id="standard-LienLevyDetailsFrequency"
                      label="Frequency"
                      value={
                        props.values.frequency
                          ? props.values.frequency
                          : DEFAULT_DD_VALUE
                      }
                    onChange={props.handleChange('frequency')}
                      inputProps={{ maxlength: 2,"data-testid":"test_frequency" }}
                    errTxt={props.errors[12] ? props.errorText[12] : null}
                      disabled={!userInquiryPrivileges ? !userInquiryPrivileges : (props.values.recoupmentTypeCode === 'S' || props.values.balanceAmount === '0.00')}
                    dropdownList={props.frequencyDataDropdown &&
                        props.frequencyDataDropdown !== 'undefined' &&
                        typeof props.frequencyDataDropdown !== 'string'
                        ? props.frequencyDataDropdown.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.description}
                          </option>
                        ))
                        : null}
                  />
                  <div className="mui-custom-form override-width-95 override-m-2">
                    {userInquiryPrivileges && !disableIfF16007 ? <a disabled='disabled' onClick={props.defaultRecoupment} className="has-link" style={{ color: 'blue', cursor: 'pointer' }}>
                      Use Payee Default Recoupment
                    </a> : <span>Use Payee Default Recoupment</span>}
                  </div>
              
                  {
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="mui-custom-form with-date min-md">
                        <KeyboardDatePicker
                          disabled={true}
                          maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                          id="date-picker-dialogLienLevyDetailInitialPaymentDate"
                          label="Initial Payment Date"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          minDate={new Date()}
                          disabled={!userInquiryPrivileges ? !userInquiryPrivileges : props.values.balanceAmount === '0.00'}
                          value={props.selectedInitialPaymentDate}
                          onChange={props.handleInitialPaymentDate}
                          InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  }
                  {
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="mui-custom-form with-date min-md">
                        <KeyboardDatePicker
                          disabled={true}
                          maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                          id="date-picker-dialogLienLevyDetailSatisfiedDate"
                          label="Satisfied Date"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          disabled={props.values.balanceAmount === '0.00'}
                          minDate={new Date()}
                          value={props.selectedSatisfiedDate}
                          onChange={props.handleSatisfiedDate}
                          InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  }

                </div>

              </fieldset>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ paddingRight: '22px' }}>
          <fieldset className="custom-fieldset">
            <legend>Funding Source Allocation:</legend>
            <div className="form-wrapper">

              <div className="mui-custom-form">
                <TextField
                  id="standard-federal"
                  fullWidth
                  label="Federal"
                  placeholder=""
                  value={props.values.federal}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-state"
                  fullWidth
                  label="State"
                  placeholder=""
                  value={props.values.state}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-county"
                  fullWidth
                  label="County"
                  placeholder=""
                  value={props.values.county}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-other"
                  fullWidth
                  label="Other"
                  placeholder=""
                  value={props.values.other}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  );
}
