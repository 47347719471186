/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/CorrectionSearchActionTypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

export const search1099CorrectionSearchAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SEARCH_CORRECTION_1099}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse) {

          dispatch(displayCorrectionSearchResults(sendingResponse));
        } else {
          dispatch(displayCorrectionSearchResults([]));
        };
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(displayCorrectionSearchResults(sendingResponse));
      });
  };
};

export const displayCorrectionSearchResults = (searchData) => {
  return {
    type: actionTypes.SEARCH_CORRECTION_1099,
    searchData: searchData
  };
};

export const searchCorrectionApprovalAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SEARCH_CORRECTION_APPROVAL_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse) {
          dispatch(setSearchCorrectionApprovalResults(sendingResponse));
        } else {
          dispatch(setSearchCorrectionApprovalResults([]));
        };
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setSearchCorrectionApprovalResults(sendingResponse));
      });
  };
};

export const setSearchCorrectionApprovalResults = (searchCorrectionApprovalData) => {
  return {
    type: actionTypes.SEARCH_CORRECTION_APPROVAL,
    searchCorrectionApprovalData: searchCorrectionApprovalData
  };
};

export const resetState = () => {
  return {
    type: actionTypes.CORRECTION_1099_SEARCH_RESULTS_RESET,
    resetData: []
  };
};

export const getCorrectionDetailList = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.CORRECTION_DETAIL_LIST_ENDPOINT}`, value)
      .then(response => {

        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setCorrectionDetailList([]));
        } else {
          dispatch(setCorrectionDetailList(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setCorrectionDetailList(sendingResponse));
      });
  };
};

export const setCorrectionDetailList = (correctionDeatilListData) => {
  return {
    type: actionTypes.CORRECTION_DETAIL_LIST_ACTION,
    correctionDeatilListData: correctionDeatilListData
  };
};

export const getCorrectionDetailView = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.CORRECTION_DETAIL_VIEW_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setCorrectionDetailViewResponse([]));
        } else {
          dispatch(setCorrectionDetailViewResponse(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setCorrectionDetailViewResponse(sendingResponse));
      });
  };
};

export const setCorrectionDetailViewResponse = (correctionDeatilViewData) => {
  return {
    type: actionTypes.CORRECTION_DETAIL_VIEW_ACTION,
    correctionDeatilViewData: correctionDeatilViewData
  };
};

export const get1099CorrectionDropdowns = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        if (response.data === undefined) {
          dispatch(setCorrectionDropdownsResponse([]));
        } else {
          dispatch(setCorrectionDropdownsResponse(response.data));
        }
      })
      .catch(error => {
        dispatch(setCorrectionDropdownsResponse(''));
      });
  };
};

export const setCorrectionDropdownsResponse = (dropdowns) => {
  return {
    type: actionTypes.CORRECTION_DROPDOWNS_ACTION,
    dropdowns: dropdowns
  };
};

export const correctionDetailUpdate = (value,file) => {
  const formInfo = new FormData();
  
  formInfo.append('correctionVO',JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.CORRECTION_DETAIL_UPDATE_ENDPOINT}`, formInfo
    , {
      headers: {
        'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setCorrectionDetailUpdate(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setCorrectionDetailUpdate(sendingResponse));
      });
  };
};

export const setCorrectionDetailUpdate = (correctionDetailUpdateResponse) => {
  return {
    type: actionTypes.CORRECTION_DETAIL_UPDATE_ACTION,
    correctionDetailUpdateResponse: correctionDetailUpdateResponse
  };
};

export const updateCorrectionApprovalAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.CORRECTION_APPROVAL_UPDATE_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setCorrectionApprovalUpdate(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setCorrectionApprovalUpdate(sendingResponse));
      });
  };
};

export const validateCorrectionApprovalAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.CORRECTION_APPROVAL_VALIDATE}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setCorrectionApprovalValidate(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setCorrectionApprovalValidate(sendingResponse));
      });
  };
};


export const setCorrectionApprovalUpdate = (correctionApprovalUpdateResponse) => {
  return {
    type: actionTypes.CORRECTION_APPROVAL_UPDATE_ACTION,
    correctionApprovalUpdateResponse: correctionApprovalUpdateResponse
  };
};

export const setCorrectionApprovalValidate = (correctionApprovalValidateResponse) => {
  return {
    type: actionTypes.CORRECTION_APPROVAL_VALIDATE_ACTION,
    correctionApprovalValidateResponse: correctionApprovalValidateResponse
  };
};

export const modifyStartWithOrContains = (selectedValue) => {
  if (selectedValue === 'Starts With') {
    return "0"; }
  if (selectedValue === 'Contains') {
    return "1"; }
  return null;
};


export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": {...keyValue}
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};