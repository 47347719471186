/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import * as FinancialReceiptConstants from './FinancialReceiptConstants';
import * as DateUtilities from '../../../SharedModules/DateUtilities/DateUtilities';
import * as AppConstants from '../../../SharedModules/AppConstants';
import * as moment from 'moment';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { object } from 'prop-types';
import { TRANSACTION_NUMBER_FORMAT } from '../../../SharedModules/Errors/ErrorMsgConstant';
// validate Member Tab Details
var DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
export const memberIDValidate = (errormessages, payeeTab, setShowPayeeError, setPayeeErrMsg, seterrorMessages) => {
  const tempErrMsgArray = errormessages;
  let _isIPvalid = true;
  let _showPTypeErr, _showPIDTypeErr, _showPIDErr, _showMIDTypeErr, _showMIDErr;
  _showPTypeErr = _showPIDTypeErr = _showPIDErr = _showMIDTypeErr = _showMIDErr = false;
  let _pTypeErrMsg, _pIDTypeErrMsg, _pIDErrMsg, _mIDTypeErrMsg, _mIDErrMsg;
  _pTypeErrMsg = _pIDTypeErrMsg = _pIDErrMsg = _mIDTypeErrMsg = _mIDErrMsg = '';
  if (payeeTab.memberIDType !== DEFAULT_DD_VALUE) {
    if (payeeTab.memberID === undefined || payeeTab.memberID === null || payeeTab.memberID === '') {
      _isIPvalid = false;
      _showMIDErr = true;
      _mIDErrMsg = FinancialReceiptConstants.MEMBER_ID_REQUIRED;
      tempErrMsgArray.push(_mIDErrMsg);
    }
  }
  if (payeeTab.memberID !== '' && payeeTab.memberIDType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showMIDTypeErr = true;
    _mIDTypeErrMsg = FinancialReceiptConstants.MEMBER_TYPE_REQUIRED;
    tempErrMsgArray.push(_mIDTypeErrMsg);
  }
  setShowPayeeError({
    showPTypeErr: _showPTypeErr,
    showPIDTypeErr: _showPIDTypeErr,
    showPIDErr: _showPIDErr,
    showMIDTypeErr: _showMIDTypeErr,
    showMIDErr: _showMIDErr
  });
  setPayeeErrMsg({
    pTypeErrMsg: _pTypeErrMsg,
    pIDTypeErrMsg: _pIDTypeErrMsg,
    pIDErrMsg: _pIDErrMsg,
    mIDTypeErrMsg: _mIDTypeErrMsg,
    mIDErrMsg: _mIDErrMsg
  });
  seterrorMessages(tempErrMsgArray);
  return _isIPvalid;
};
// Validate Payee Tab Details
export const validatePayeeDetails = (errormessages, payeeTab, setShowPayeeError, setPayeeErrMsg, seterrorMessages,originalTab,memLiabReasoncode) => {
  const tempErrMsgArray = errormessages;
  let memberIdReq = memLiabReasoncode.includes(originalTab.financialReason) ? true:false
  let _isIPvalid = true;
  let _showPTypeErr, _showPIDTypeErr, _showPIDErr, _showMIDTypeErr, _showMIDErr;
  _showPTypeErr = _showPIDTypeErr = _showPIDErr = _showMIDTypeErr = _showMIDErr = false;
  let _pTypeErrMsg, _pIDTypeErrMsg, _pIDErrMsg, _mIDTypeErrMsg, _mIDErrMsg;
  _pTypeErrMsg = _pIDTypeErrMsg = _pIDErrMsg = _mIDTypeErrMsg = _mIDErrMsg = '';
  if (payeeTab.entityType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showPTypeErr = true;
    _pTypeErrMsg = FinancialReceiptConstants.PAYEETYPE_REQUIRED;
    tempErrMsgArray.push(_pTypeErrMsg);
  }
  if (payeeTab.entityIDTypeCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showPIDTypeErr = true;
    _pIDTypeErrMsg = FinancialReceiptConstants.PAYEEIDTYPECODE_REQUIRED;
    tempErrMsgArray.push(_pIDTypeErrMsg);
  }
  if (payeeTab.entityID === '' || payeeTab.entityID === null || payeeTab.entityID === undefined) {
    _isIPvalid = false;
    _showPIDErr = true;
    _pIDErrMsg = FinancialReceiptConstants.PAYEEID_REQUIRED;
    tempErrMsgArray.push(_pIDErrMsg);
  }
  if (payeeTab.memberIDType !== DEFAULT_DD_VALUE) {
    if (payeeTab.memberID === undefined || payeeTab.memberID === null || payeeTab.memberID === '') {
      _isIPvalid = false;
      _showMIDErr = true;
      _mIDErrMsg = FinancialReceiptConstants.MEMBER_ID_REQUIRED;
      tempErrMsgArray.push(_mIDErrMsg);
    }
  }
  if (payeeTab.memberID !== '' && payeeTab.memberIDType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showMIDTypeErr = true;
    _mIDTypeErrMsg = FinancialReceiptConstants.MEMBER_TYPE_REQUIRED;
    tempErrMsgArray.push(_mIDTypeErrMsg);
  }
  if (memberIdReq && payeeTab.memberID == '' && payeeTab.memberIDType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showMIDTypeErr = true;
    _showMIDErr = true
    _mIDErrMsg = FinancialReceiptConstants.MEMBER_ID_REQUIRED_FOR_PATIENT_LIAB
    _mIDTypeErrMsg = FinancialReceiptConstants.MEMBER_ID_TYPE_REQUIRED_FOR_PATIENT_LIAB;
    tempErrMsgArray.push(_mIDErrMsg);
    tempErrMsgArray.push(_mIDTypeErrMsg);
  }
  setShowPayeeError({
    showPTypeErr: _showPTypeErr,
    showPIDTypeErr: _showPIDTypeErr,
    showPIDErr: _showPIDErr,
    showMIDTypeErr: _showMIDTypeErr,
    showMIDErr: _showMIDErr
  });
  setPayeeErrMsg({
    pTypeErrMsg: _pTypeErrMsg,
    pIDTypeErrMsg: _pIDTypeErrMsg,
    pIDErrMsg: _pIDErrMsg,
    mIDTypeErrMsg: _mIDTypeErrMsg,
    mIDErrMsg: _mIDErrMsg
  });
  seterrorMessages(tempErrMsgArray);
  return _isIPvalid;
};
// Validate Original Tab Details
export const validateOriginalDetails = (errorMessagesArray, originalTab, selectedDepositDate, setShowOriginalError, setOriginalErrMsg, payeeTabRef, seterrorMessages) => {
  const tempErrMsgArray = errorMessagesArray;
  let _isIPvalid = true;
  let _showATCodeErr, _showFRCodeErr, _showFCodeErr, _showOAmtErr, _showATRErr,_showCheckNumErr, _showIntTrackNumErr, _showItemNumErr, _showDipositDateErr;
  _showATCodeErr = _showFRCodeErr = _showFCodeErr = _showOAmtErr = _showCheckNumErr = _showIntTrackNumErr = _showItemNumErr = _showDipositDateErr = false;
  let _aTCodeErrMsg, _fRCodeErrMsg, _fCodeErrMsg, _oAmtErrMsg, _checkATRerr,_checkNumErrMsg, _intTrackNumErrMSg, _itemNumErrMsg, _depositDateErrMsg;
  _aTCodeErrMsg = _fRCodeErrMsg = _fCodeErrMsg = _oAmtErrMsg = _checkNumErrMsg = _intTrackNumErrMSg = _itemNumErrMsg = _depositDateErrMsg = '';
  if (originalTab.activityTypeCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showATCodeErr = true;
    _aTCodeErrMsg = FinancialReceiptConstants.ACTIVITY_TYPE_CODE_REQUIRED;
    tempErrMsgArray.push(_aTCodeErrMsg);
  }
  if (originalTab.financialReason === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showFRCodeErr = true;
    _fRCodeErrMsg = FinancialReceiptConstants.FINANCIAL_REASON_CODE_REQUIRED;
    tempErrMsgArray.push(_fRCodeErrMsg);
  }
  if (originalTab.fundCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showFCodeErr = true;
    _fCodeErrMsg = FinancialReceiptConstants.FUND_CODE_REQUIRED;
    tempErrMsgArray.push(_fCodeErrMsg);
  }
  if (originalTab.originalAmount === '') {
    _isIPvalid = false;
    _showOAmtErr = true;
    _oAmtErrMsg = FinancialReceiptConstants.ORIGINAL_REQUIRED;
    tempErrMsgArray.push(_oAmtErrMsg);
  } else {
    if (originalTab.originalAmount.toString().replace(/,/g, '') <= 0) {
      _isIPvalid = false;
      _showOAmtErr = true;
      _oAmtErrMsg = FinancialReceiptConstants.ORIGINAL_AMOUNT_GREATERTHAN_ZERO;
      tempErrMsgArray.push(_oAmtErrMsg);
    } else {
      if (originalTab.originalAmount) {
        if (originalTab.originalAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null) {
          _isIPvalid = false;
          _showOAmtErr = true;
          _oAmtErrMsg = FinancialReceiptConstants.INVALID_FORMAT;
          tempErrMsgArray.push(_oAmtErrMsg);
        } else {
          if (!(originalTab.originalAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null)) {
            _isIPvalid = false;
            _showOAmtErr = true;
            _oAmtErrMsg = FinancialReceiptConstants.INVALID_FORMAT;
            tempErrMsgArray.push(_oAmtErrMsg);
          }
        }
      }
    }
  }

  if (originalTab.checkNumber === '') {
    _isIPvalid = false;
    _showCheckNumErr = true;
    _checkNumErrMsg = FinancialReceiptConstants.CHECKNUMBER_REQUIRED;
    tempErrMsgArray.push(_checkNumErrMsg);
  }
  if(originalTab.atrNumber) {
    const regex = /[^A-Za-z0-9]/
     if (regex.test(originalTab.atrNumber)){
      _isIPvalid = false;
      _showATRErr = true;
      _checkATRerr = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`
      tempErrMsgArray.push(_checkATRerr);
     } else {
      _showATRErr=false
    }
  }
  
  if (originalTab.financialReason === '236') {
    if (payeeTabRef.payeeTab.memberIDType === DEFAULT_DD_VALUE && payeeTabRef.payeeTab.memberID === '') {
      _isIPvalid = false;
      payeeTabRef.setShowPayeeError({ ...payeeTabRef.showPayeeError, showMIDTypeErr: true, showMIDErr: true });
      payeeTabRef.setPayeeErrMsg({
        ...payeeTabRef.payeeErrorMsg,
      }); 
    } else if (payeeTabRef.payeeTab.memberIDType !== DEFAULT_DD_VALUE && payeeTabRef.payeeTab.memberID === '') {
      _isIPvalid = false;
      payeeTabRef.setShowPayeeError({ ...payeeTabRef.showPayeeError, showMIDTypeErr: false, showMIDErr: true });
      payeeTabRef.setPayeeErrMsg({ ...payeeTabRef.payeeErrorMsg, mIDTypeErrMsg: ''});
    } else if (payeeTabRef.payeeTab.memberIDType === DEFAULT_DD_VALUE && payeeTabRef.payeeTab.memberID !== '') {
      _isIPvalid = false;
      payeeTabRef.setShowPayeeError({ ...payeeTabRef.showPayeeError, showMIDTypeErr: true, showMIDErr: false });
      payeeTabRef.setPayeeErrMsg({ ...payeeTabRef.payeeErrorMsg,mIDErrMsg: '' });
    } else if (payeeTabRef.payeeTab.memberIDType !== DEFAULT_DD_VALUE && payeeTabRef.payeeTab.memberID !== '') {
      payeeTabRef.setShowPayeeError({ ...payeeTabRef.showPayeeError, showMIDTypeErr: false, showMIDErr: false });
      payeeTabRef.setPayeeErrMsg({
        ...payeeTabRef.payeeErrorMsg,
        mIDTypeErrMsg: '',
        mIDErrMsg: ''
      });
    }
  }
  if (selectedDepositDate && selectedDepositDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showDipositDateErr = true;
    _depositDateErrMsg = FinancialReceiptConstants.INVALID_DATE_FORMAT;
    tempErrMsgArray.push(_depositDateErrMsg);
  }
  if (selectedDepositDate && selectedDepositDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(selectedDepositDate)) {
      _isIPvalid = false;
      _showDipositDateErr = true;
      _depositDateErrMsg = AppConstants.DATE_ERROR_1964;
      tempErrMsgArray.push(_depositDateErrMsg);
    }
  }

  setShowOriginalError({
    showATRErr: _showATRErr,
    showATCodeErr: _showATCodeErr,
    showFRCodeErr: _showFRCodeErr,
    showFCodeErr: _showFCodeErr,
    showOAmtErr: _showOAmtErr,
    showCheckNumErr: _showCheckNumErr,
    showIntTrackNumErr: _showIntTrackNumErr,
    showItemNumErr: _showItemNumErr,
    showDipositDateErr: _showDipositDateErr
  });
  setOriginalErrMsg({
    atrErrMsg:_checkATRerr,
    aTCodeErrMsg: _aTCodeErrMsg,
    fRCodeErrMsg: _fRCodeErrMsg,
    fCodeErrMsg: _fCodeErrMsg,
    oAmtErrMsg: _oAmtErrMsg,
    checkNumErrMsg: _checkNumErrMsg,
    intTrackNumErrMSg: _intTrackNumErrMSg,
    itemNumErrMsg: _itemNumErrMsg,
    depositDateErrMsg: _depositDateErrMsg
  });
  seterrorMessages(tempErrMsgArray);
  return tempErrMsgArray;
};
// Validaate Add Refund PopUp Details
export const validateAddRefundPopUpDetails = (addRefndValues, selectedFFPCalculateDate, setShowAddRefundPopUpErr, setAddRefundPopUpErrMsg,
  originalTab, seterrorMessages) => {
  let _isIPvalid = true;
  let _showDispositonCodeErr, _showFCodeErr,_showATRErr, _showPaymentAmtErr, _showFinancialYearErr, _showFfpDateErr;
  _showDispositonCodeErr = _showFCodeErr = _showPaymentAmtErr = _showFinancialYearErr = _showFfpDateErr = false;
  let _dispositonCodeErrMsg, _fCodeErrMsg, _checkATRerr,_paymentAmtErrMsg, _financialYearErrMsg, _fFPDateErrMsg;
  _dispositonCodeErrMsg = _fCodeErrMsg = _paymentAmtErrMsg = _financialYearErrMsg = _fFPDateErrMsg = '';
  let errorMessagesArray = [];
  if (addRefndValues.reasonCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showDispositonCodeErr = true;
    _dispositonCodeErrMsg = FinancialReceiptConstants.ADDREFUND_DISPOSITION_REASON_CODE_REQUIRED;
    errorMessagesArray.push(_dispositonCodeErrMsg);
  }
  if(addRefndValues.atrNumber) {
    const regex = /[^A-Za-z0-9]/
     if (regex.test(addRefndValues.atrNumber)){
      _showATRErr = true;
    _isIPvalid = false;
      _checkATRerr = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`
      errorMessagesArray.push(_checkATRerr);
     } else {
      _showATRErr=false
    }
  }
  if (addRefndValues.fundCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showFCodeErr = true;
    _fCodeErrMsg = FinancialReceiptConstants.FUND_CODE_REQUIRED;
    errorMessagesArray.push(_fCodeErrMsg)

  }
  if (addRefndValues.payerAmount === '') {
    _isIPvalid = false;
    _showPaymentAmtErr = true;
    _paymentAmtErrMsg = FinancialReceiptConstants.PAYER_AMOUNT_REQUIRED;
    errorMessagesArray.push(_paymentAmtErrMsg)
    
  }
  else if (Number(addRefndValues.payerAmount.toString().replace(/,/g, '')) <= 0) {
    _isIPvalid = false;
    _showPaymentAmtErr = true;
    _paymentAmtErrMsg = FinancialReceiptConstants.PAYMENT_AMOUNT_GREATERTHAN_ZERO;
    errorMessagesArray.push(_paymentAmtErrMsg)

  } else {
    if (addRefndValues.payerAmount) {
      if (addRefndValues.payerAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null) {
        _isIPvalid = false;
        _showPaymentAmtErr = true;
        _paymentAmtErrMsg = FinancialReceiptConstants.INVALID_FORMAT;
        errorMessagesArray.push(_paymentAmtErrMsg)

      } else {
        if (!(addRefndValues.payerAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null)) {
          _isIPvalid = false;
          _showPaymentAmtErr = true;
          _paymentAmtErrMsg = FinancialReceiptConstants.INVALID_FORMAT;
          errorMessagesArray.push(_paymentAmtErrMsg)

        }
      }
    }
  }
  if ((Number(addRefndValues.payerAmount.toString().replace(/,/g, '')) !== 0 && addRefndValues.payerAmount !== '') && (Number(originalTab.originalAmount.toString().replace(/,/g, '')) === 0 || originalTab.originalAmount === '')) {
    _isIPvalid = false;
    _showPaymentAmtErr = true;
    _paymentAmtErrMsg = FinancialReceiptConstants.PAYMENT_AMOUNT_SHOULDNOTGREATERTHAN_ORIGINALAMOUNT;
    errorMessagesArray.push(_paymentAmtErrMsg)
  } else if (addRefndValues.payerAmount !== '' && originalTab.balanceAmount !== '' &&
    parseFloat(addRefndValues.payerAmount.toString().replace(/,/g, '')) > parseFloat(originalTab.balanceAmount.toString().replace(/,/g, ''))) {
    _isIPvalid = false;
    _showPaymentAmtErr = true;
    _paymentAmtErrMsg = FinancialReceiptConstants.PAYMENT_AMOUNT_BALANCE_AMOUNT;
    errorMessagesArray.push(_paymentAmtErrMsg)

  }
  if (addRefndValues.fiscalYear === null || addRefndValues.fiscalYear === undefined || addRefndValues.fiscalYear === '') {
    _isIPvalid = false;
    _showFinancialYearErr = true;
    _financialYearErrMsg = FinancialReceiptConstants.FISCAL_YEAR_REQUIRED;
    errorMessagesArray.push(_financialYearErrMsg)

  }
  if (selectedFFPCalculateDate && selectedFFPCalculateDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showFfpDateErr = true;
    _fFPDateErrMsg = FinancialReceiptConstants.INVALID_DATE_FORMAT;
    errorMessagesArray.push(_fFPDateErrMsg)

  }
  if (addRefndValues.reasonCode === '343' && selectedFFPCalculateDate === null) {
    _isIPvalid = false;
    _showFfpDateErr = true;
    _fFPDateErrMsg = FinancialReceiptConstants.FFP_CALCULATE_DATE_REQUIRED;
    errorMessagesArray.push(_fFPDateErrMsg)

  }
  if (selectedFFPCalculateDate && selectedFFPCalculateDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(selectedFFPCalculateDate)) {
      _isIPvalid = false;
      _showFfpDateErr = true;
      _fFPDateErrMsg = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(_fFPDateErrMsg)
      
    }
  }
  seterrorMessages(errorMessagesArray);
  setShowAddRefundPopUpErr({
    showDispositonCodeErr: _showDispositonCodeErr,
    showATRErr:_showATRErr,
    showFCodeErr: _showFCodeErr,
    showPaymentAmtErr: _showPaymentAmtErr,
    showFinancialYearErr: _showFinancialYearErr,
    showFfpDateErr: _showFfpDateErr
  });
  setAddRefundPopUpErrMsg({
    atrErrMsg: _checkATRerr,
    dispositonCodeErrMsg: _dispositonCodeErrMsg,
    fCodeErrMsg: _fCodeErrMsg,
    paymentAmtErrMsg: _paymentAmtErrMsg,
    financialYearErrMsg: _financialYearErrMsg,
    fFPDateErrMsg: _fFPDateErrMsg
  });
  return _isIPvalid;
};
export const addFundPopUpErrMsgReset = (setShowAddRefundPopUpErr, setAddRefundPopUpErrMsg) => {
  setShowAddRefundPopUpErr({
    showDispositonCodeErr: false,
    showFCodeErr: false,
    showPaymentAmtErr: false,
    showFinancialYearErr: false,
    showFfpDateErr: false
  });
  setAddRefundPopUpErrMsg({
    dispositonCodeErrMsg: '',
    fCodeErrMsg: '',
    paymentAmtErrMsg: '',
    financialYearErrMsg: '',
    fFPDateErrMsg: ''
  });
};
// Add Refund PopUp Reset
export const addFundPopUpReset = (payeeTab, addRefndValues, setAddRefundValues, addRefundNewFCN, selectedAddRefundTableRow) => {
  if (selectedAddRefundTableRow && selectedAddRefundTableRow !== null) {
    setAddRefundValues({
      fcn: selectedAddRefundTableRow.fcn,
      reasonCode: selectedAddRefundTableRow.reasonCode,
      fiscalYear: selectedAddRefundTableRow.fiscalYear,
      fundCode: selectedAddRefundTableRow.fundCode,
      cos: selectedAddRefundTableRow.cos === null ? DEFAULT_DD_VALUE : selectedAddRefundTableRow.cos,
      ffpCalculateDate: selectedAddRefundTableRow.ffpCalculateDate,
      payerAmount: selectedAddRefundTableRow.payerAmount,
      entityType: selectedAddRefundTableRow.entityType,
      entityIDTypeCode: selectedAddRefundTableRow.entityIDTypeCode,
      entityID: selectedAddRefundTableRow.entityID,
      _reasonCodeDescr: selectedAddRefundTableRow._reasonCodeDescr,
      _cosDescr: selectedAddRefundTableRow._cosDescr,
      atrNumber:selectedAddRefundTableRow?.atrNumber?.toUpperCase()
    });
  } else {
    setAddRefundValues({
      fcn: addRefundNewFCN || 0,
      reasonCode: DEFAULT_DD_VALUE,
      fiscalYear: (new Date()).getFullYear(),
      fundCode: DEFAULT_DD_VALUE,
      cos: DEFAULT_DD_VALUE,
      ffpCalculateDate: '',
      payerAmount: '0.00',
      entityType: payeeTab.entityType,
      entityIDTypeCode: payeeTab.entityIDTypeCode,
      entityID: payeeTab.entityID,
      _reasonCodeDescr: '',
      _cosDescr: '',
      atrNumber:''
    });
  }
};
// Validate Other/Billing Receivable Disposition Tab Details
export const validateOtherTabDetails = (otherBillingTab, originalTab, setShowOtherTabError, setOtherTabErrMsg, seterrorMessages) => {
  const tempErrMsgArray = [];
  let dispositionError = false;
  let _isIPvalid = true;
  let _showDispositionRCodeErr, _shoeFundCodeErr,_showATRErr, _showCosErr, _showPaymentAmtErr;
  let _dispositionRCodeErrMsg, _fundCodeErrMsg, _cosErrMsg, _paymentAmtErrMsg,_checkATRerr;

  _showDispositionRCodeErr = _shoeFundCodeErr = _showCosErr = _showPaymentAmtErr = false;
  _dispositionRCodeErrMsg = _fundCodeErrMsg = _cosErrMsg = _paymentAmtErrMsg=_checkATRerr = '';

  if (otherBillingTab.billingRsnCode !== DEFAULT_DD_VALUE &&
    otherBillingTab.otherBillingRecvDispfundCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _shoeFundCodeErr = true;
    _fundCodeErrMsg = FinancialReceiptConstants.FUND_CODE_REQUIRED;
    tempErrMsgArray.push(_fundCodeErrMsg);
  }
  if(otherBillingTab.atrNumber) {
    const regex = /[^A-Za-z0-9]/
     if (regex.test(otherBillingTab.atrNumber)){
      _showATRErr = true;
    _isIPvalid = false;

      _checkATRerr = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`
      tempErrMsgArray.push(_checkATRerr);
     } else {
      _showATRErr=false
    }
  }
  if (otherBillingTab.otherBillingRecvDispfundCode !== DEFAULT_DD_VALUE &&
    otherBillingTab.billingRsnCode === DEFAULT_DD_VALUE) {
      dispositionError = true
  }
  if (otherBillingTab.otherBillingCosCode !== DEFAULT_DD_VALUE &&
    otherBillingTab.billingRsnCode === DEFAULT_DD_VALUE) {
      dispositionError = true;
  }
  if (otherBillingTab.billingRsnCode !== DEFAULT_DD_VALUE) {
    if (Number(otherBillingTab.billingAmount.toString().replace(/,/g, '')) === 0 || otherBillingTab.billingAmount === null || otherBillingTab.billingAmount === '') {
      _isIPvalid = false;
      _showPaymentAmtErr = true;
      _paymentAmtErrMsg = FinancialReceiptConstants.PAYMENT_AMOUNT_GREATERTHAN_ZERO;
      tempErrMsgArray.push(_paymentAmtErrMsg);
    } else if (Number(otherBillingTab.billingAmount.toString().replace(/,/g, '')) !== 0 && (Number(originalTab.originalAmount.toString().replace(/,/g, '')) === 0 || originalTab.originalAmount === '')) {
      _isIPvalid = false;
      _showPaymentAmtErr = true;
      _paymentAmtErrMsg = FinancialReceiptConstants.PAYMENT_AMOUNT_SHOULDNOTGREATERTHAN_ORIGINALAMOUNT;
      tempErrMsgArray.push(_paymentAmtErrMsg);
    } else if (Number(otherBillingTab.billingAmount.toString().replace(/,/g, '')) !== 0 && Number(originalTab.originalAmount.toString().replace(/,/g, '')) !== 0 &&
      parseFloat(otherBillingTab.billingAmount.toString().replace(/,/g, '')) > parseFloat(originalTab.originalAmount.toString().replace(/,/g, ''))) {
      _isIPvalid = false;
      _showPaymentAmtErr = true;
      _paymentAmtErrMsg = FinancialReceiptConstants.PAYMENT_AMOUNT_SHOULDNOTGREATERTHAN_ORIGINALAMOUNT;
      tempErrMsgArray.push(_paymentAmtErrMsg);
    } else {
      if (otherBillingTab.billingAmount) {
        if (otherBillingTab.billingAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null) {
          _isIPvalid = false;
          _showPaymentAmtErr = true;
          _paymentAmtErrMsg = FinancialReceiptConstants.INVALID_FORMAT;
          tempErrMsgArray.push(_paymentAmtErrMsg);
        } else {
          if (!(otherBillingTab.billingAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null)) {
            _isIPvalid = false;
            _showPaymentAmtErr = true;
            _paymentAmtErrMsg = FinancialReceiptConstants.INVALID_FORMAT;
            tempErrMsgArray.push(_paymentAmtErrMsg);
          }
        }
      }
    }
  }
  if (otherBillingTab.billingAmount !== 0 && otherBillingTab.billingAmount !== '') {
    if (otherBillingTab.billingRsnCode === DEFAULT_DD_VALUE) {
      dispositionError = true;
    }
  }
  if  (dispositionError) {
    _isIPvalid = false;
    _showDispositionRCodeErr = true;
    _dispositionRCodeErrMsg = FinancialReceiptConstants.ADDREFUND_DISPOSITION_REASON_CODE_REQUIRED;
    tempErrMsgArray.push(_dispositionRCodeErrMsg);
  }
  setShowOtherTabError({
    showDispositionRCodeErr: _showDispositionRCodeErr,
    shoeFundCodeErr: _shoeFundCodeErr,
    showCosErr: _showCosErr,
    showPaymentAmtErr: _showPaymentAmtErr,
    showATRErr:_showATRErr,
  });

  setOtherTabErrMsg({
    dispositionRCodeErrMsg: _dispositionRCodeErrMsg,
    fundCodeErrMsg: _fundCodeErrMsg,
    cosErrMsg: _cosErrMsg,
    paymentAmtErrMsg: _paymentAmtErrMsg,
    atrErrMsg:_checkATRerr,
  });
  seterrorMessages(tempErrMsgArray);
  return _isIPvalid;
};
// Map function to get the Description for corresponding Code
export const mapCodeDescription = (_code, _mapArray) => {
  if (_mapArray && _mapArray.length > 0) {
    let _description = [];
    _description = _mapArray.filter((value, index) => value.code === _code);
    if (_description.length > 0) {
      return _description[0].description;
    } else {
      return _code;
    };
  };
  return _code;
};
export const buildAddRefundRowObject = (addRefndValues, payeeTab, originalTab) => {
  return {
    auditUserID: 'MDEAKINS',
    auditTimeStamp: null,
    addedAuditUserID: 'DM_User',
    addedAuditTimeStamp: null,
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    otherBillingRecvDispfundCode: null,
    otherBillingCosCode: null,
    memberSystemID: null,
    financialReason: null,
    dispReasonCode: null,
    establishedDate: originalTab.establishedDate,
    appliedAmount: null,
    dispAmount: null,
    additionalInfoReqIndicator: null,
    tempOtherdispositionReasonCode: null,
    tempOtherBillingAmount: null,
    originalAmount: originalTab.originalAmount.replace(/,/g, ''),
    balanceAmount: null,
    checkNumber: null,
    transmissionDate: null,
    lockBoxNumber: null,
    relatedFTTCN: null,
    pendingReason: null,
    pendDate: null,
    invoice: null,
    lob: AppConstants.DEFAULT_LOB_VALUE,
    activityTypeCode: originalTab.activityTypeCode,
    entityType: payeeTab.entityType,
    entityIDTypeCode: payeeTab.entityIDTypeCode,
    payeePayerID: payeeTab.sysID,
    fcn: originalTab.fcn,
    crossReferenceDataList: [],
    entityID: addRefndValues.entityID ? addRefndValues.entityID : payeeTab.entityID,
    entityName: payeeTab.entityName,
    memberSysID: null,
    memberID: null,
    memberIDType: null,
    depositDate: originalTab.depositDate,
    itemNumber: null,
    batchNumber: null,
    lastAppliedDate: originalTab.lastAppliedDate,
    request: null,
    lastRequested: null,
    billingRsnCode: null,
    billingAmount: null,
    noOfClmMassAdjst: null,
    noOfDispClaims: null,
    prevVdClmNumber: null,
    voidedClmAmnt: null,
    sysID: null,
    voidCheckHistoryList: [],
    intTrackNum: null,
    memberName: null,
    fcnMediaCode: '0',
    fiscalYearNumber: null,
    cosCode: null,
    fundCode: null,
    receiptAuditTimeStamp: new Date(),
    receiptAddedAuditTimeStamp: new Date(),
    reversalRsnCode: null,
    lineOfBusinessValue: null,
    entityTypeValue: null,
    entityIDTypeCodeValue: null,
    memberIDTypeValue: '',
    financialReasonValue: '',
    activityTypeCodeValue: null,
    pendingReasonValue: null,
    returnedCheckEftNumber: null,
    returnedCheckEftAmount: null,
    billingRsnCodeValue: null,
    fcnSeqNum: null,
    commonEntitySK: null,
    commonEntityType: null,
    receiptVersionNo: 0,
    cmnEntityTypVersionNo: 0,
    cmnEntVersionNo: 0,
    voidIndicator: false,
    voidDate: null,
    approvalReqIndicator: false,
    recptTempBalAmount: '',
    recptTempFCN: '',
    depositNumber: null,
    fdetailVersionNo: 2,
    dispositionVOList: [],
    fetchHistoryVOList: [],
    globalHistoryVO: null,
    overPaymentVOList: [],
    refundRecoveryVOList: [
      {
        auditUserID: 'MDEAKINS',
        auditTimeStamp: null,
        addedAuditUserID: 'DM_User',
        addedAuditTimeStamp: null,
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        fcn: addRefndValues.fcn ? addRefndValues.fcn.toString() : null,
        entityID: addRefndValues.entityID,
        entityType: addRefndValues.entityType,
        fcnSeqNum: originalTab.fcn ? +(originalTab.fcn.toString().slice(originalTab.fcn.toString().length - 5, originalTab.fcn.toString().length)) : 49296,
        fcnMediaCode: null,
        entityIDTypeCode: addRefndValues.entityIDTypeCode,
        reasonCode: addRefndValues.reasonCode,
        updatedAuditTime: new Date(),
        fundCodeExistsFlag: false,
        orgAuditTimeStamp: moment(new Date()).format('MM/DD/YYYY hh:mm:ss A'),
        tempTransactionCtrlNumber: null,
        dispositionDate: null,
        fiscalYear: addRefndValues.fiscalYear.toString(),
        fundCode: addRefndValues.fundCode,
        cos: addRefndValues.cos === DEFAULT_DD_VALUE ? null : addRefndValues.cos,
        ffpCalculateDate: addRefndValues.ffpCalculateDate === '' ? DateUtilities.getDateInMMDDYYYYFormat(new Date()) : addRefndValues.ffpCalculateDate,
        payerAmount: addRefndValues.payerAmount.replace(/,/g, ''),
        oldPayerAmnt: null,
        refundVersionNo: 2,
        crossVersionNo: 0,
        reasonCodeValue: null,
        fundCodeValue: null,
        cosValue: null,
        entityTypeValue: null,
        entityIDTypeCodeValue: null,
        associatedPayoutApproved: false,
        receiptVersionNumber: 0,
        atrNumber: addRefndValues?.atrNumber?.toUpperCase()
      }
    ],
    noteSet: null,
    reverseVO: null

  };
};

export const buildMasterSaveObject = (loginUserID, updateInfo, payeeTab, originalTab, refundTab, dispostionTab, otherBillingTab, notesInput,isOriginalATR) => {
	
  if (updateInfo !== null) {
    const objectTemp = JSON.parse(JSON.stringify(updateInfo));
    objectTemp.auditUserID = loginUserID;
    objectTemp.auditTimeStamp = DateUtilities.getUTCTimeStamp();
    objectTemp.otherBillingRecvDispfundCode = otherBillingTab.otherBillingRecvDispfundCode === DEFAULT_DD_VALUE ? null : otherBillingTab.otherBillingRecvDispfundCode;
    objectTemp.otherBillingCosCode = otherBillingTab.otherBillingCosCode === DEFAULT_DD_VALUE ? null : otherBillingTab.otherBillingCosCode;
    objectTemp.dispositionVOList = dispostionTab;
    objectTemp.refundRecoveryVOList = refundTab;
    objectTemp.atrNumber= isOriginalATR? originalTab?.atrNumber?.toUpperCase() : otherBillingTab?.atrNumber.toUpperCase();
    objectTemp.financialReason = originalTab.financialReason;
    objectTemp.originalAmount = originalTab.originalAmount.replace(/,/g, '');
    objectTemp.balanceAmount = originalTab.balanceAmount.replace(/,/g, '');
    objectTemp.entityType = payeeTab.entityType.split('-')[0];
    objectTemp.entityIDTypeCode = payeeTab.entityIDTypeCode;
    objectTemp.pendingReason = originalTab.pendingReason === DEFAULT_DD_VALUE ? null : originalTab.pendingReason;
    objectTemp.pendDate = originalTab.pendDate;
    objectTemp.activityTypeCode = originalTab.activityTypeCode;
    objectTemp.memberID = payeeTab.memberID === DEFAULT_DD_VALUE ? null : payeeTab.memberID;
    objectTemp.memberIDType = payeeTab.memberIDType === DEFAULT_DD_VALUE ? null : payeeTab.memberIDType;
    objectTemp.depositDate = originalTab.depositDate === '' ? '' : originalTab.depositDate;
    objectTemp.billingRsnCode = otherBillingTab.billingRsnCode === DEFAULT_DD_VALUE ? null : otherBillingTab.billingRsnCode;
    objectTemp.billingAmount = otherBillingTab.billingAmount === 0 ? null : otherBillingTab.billingAmount.replace(/,/g, '');
    objectTemp.intTrackNum = originalTab.intTrackNum;
    objectTemp.fundCode = originalTab.fundCode === DEFAULT_DD_VALUE ? null : originalTab.fundCode;
    objectTemp.depositNumber = originalTab.depositNumber ? (originalTab.depositNumber !== undefined && originalTab.depositNumber !== '') ? Number(originalTab.depositNumber) : '' : ''
    objectTemp.reverseVO.auditUserID = loginUserID;
    objectTemp.reverseVO.addedAuditUserID = objectTemp.reverseVO.addedAuditUserID === null ? loginUserID : objectTemp.reverseVO.addedAuditUserID;
    objectTemp.reverseVO.auditTimeStamp = DateUtilities.getUTCTimeStamp();
    objectTemp.reverseVO.addedAuditTimeStamp = objectTemp.reverseVO.addedAuditTimeStamp === null ? DateUtilities.getUTCTimeStamp : objectTemp.reverseVO.addedAuditTimeStamp;
    objectTemp.receiptAuditTimeStamp = DateUtilities.getUTCTimeStamp();
    objectTemp.invoice = originalTab.invoice;
    objectTemp.lockBoxNumber =  originalTab.lockBoxNumber;
    objectTemp.voidIndicator = originalTab.voidIndicator;
    objectTemp.voidDate = originalTab.voidIndicator ? originalTab.voidDate ? originalTab.voidDate : DateUtilities.getUTCTimeStamp() : null;
    objectTemp.noteSetVO = notesInput;
    objectTemp.provBillSysId = payeeTab.provBillSysId ? payeeTab.provBillSysId : '';
    objectTemp.payeePayerID = payeeTab.sysID;
    if(payeeTab.sysID){
      objectTemp.sysID = payeeTab.sysID;
      objectTemp.commonEntitySK = payeeTab.sysID;
    }
    objectTemp.entityID = payeeTab.entityID;
    objectTemp.entityName = payeeTab.entityName
    return objectTemp;
  } else {
    return {
      auditUserID: loginUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: loginUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      atrNumber: originalTab?.atrNumber?.toUpperCase(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      memberSystemID: null,
      fetchHistoryVOList: [],
      globalHistoryVO: null,
      overPaymentVOList: [],
      noteSet: notesInput,
      reverseVO: null,
      financialReason: originalTab.financialReason,
      dispReasonCode: null,
      establishedDate: originalTab.establishedDate,
      appliedAmount: null,
      dispAmount: null,
      additionalInfoReqIndicator: null,
      tempOtherdispositionReasonCode: null,
      tempOtherBillingAmount: null,
      originalAmount: originalTab.originalAmount.replace(/,/g, ''),
      balanceAmount: originalTab.balanceAmount.replace(/,/g, ''),
      checkNumber: originalTab.checkNumber,
      transmissionDate: originalTab.establishedDate,
      lockBoxNumber: originalTab.lockBoxNumber,
      relatedFTTCN: originalTab.relatedFTTCN,
      pendingReason: originalTab.pendingReason === DEFAULT_DD_VALUE ? null : originalTab.pendingReason,
      pendDate: originalTab.pendDate,
      invoice: originalTab.invoice,
      lob: AppConstants.DEFAULT_LOB_VALUE,
      activityTypeCode: originalTab.activityTypeCode,
      entityType: payeeTab.entityType === DEFAULT_DD_VALUE ? null : payeeTab.entityType,
      entityIDTypeCode: payeeTab.entityIDTypeCode,
      payeePayerID: payeeTab.sysID,
      fcn: originalTab.fcn ? originalTab.fcn.toString() : null,
      crossReferenceDataList: [],
      entityID: payeeTab.entityID,
      entityName: payeeTab.entityName,
      memberSysID: null,
      memberID: payeeTab.memberID === DEFAULT_DD_VALUE ? null : payeeTab.memberID,
      memberIDType: payeeTab.memberIDType === DEFAULT_DD_VALUE ? null : payeeTab.memberIDType,
      provBillSysId: payeeTab.provBillSysId ? payeeTab.provBillSysId : '',
      depositDate: originalTab.depositDate === '' ? '' : originalTab.depositDate,
      itemNumber: originalTab.itemNumber === '' ? null : originalTab.itemNumber,
      batchNumber: originalTab.batchNumber === '' ? null : originalTab.batchNumber,
      lastAppliedDate: originalTab.lastAppliedDate === '' ? null : originalTab.lastAppliedDate,
      request: null,
      lastRequested: null,
      noOfClmMassAdjst: null,
      noOfDispClaims: null,
      prevVdClmNumber: null,
      voidedClmAmnt: null,
      sysID: payeeTab.sysID,
      voidCheckHistoryList: [],
      intTrackNum: originalTab.intTrackNum,
      memberName: null,
      fcnMediaCode: '0',
      fiscalYearNumber: 2019,
      cosCode: null,
      fundCode: originalTab.fundCode === DEFAULT_DD_VALUE ? null : originalTab.fundCode,
      receiptAuditTimeStamp: new Date(),
      receiptAddedAuditTimeStamp: new Date(),
      reversalRsnCode: null,
      lineOfBusinessValue: null,
      entityTypeValue: null,
      entityIDTypeCodeValue: null,
      memberIDTypeValue: '',
      financialReasonValue: '',
      activityTypeCodeValue: null,
      pendingReasonValue: null,
      returnedCheckEftNumber: null,
      returnedCheckEftAmount: null,
      billingRsnCodeValue: null,
      fcnSeqNum: originalTab.fcn ? (originalTab.fcn.toString().slice(originalTab.fcn.toString().length - 5, originalTab.fcn.toString().length)) : 49296,
      commonEntitySK: null,
      commonEntityType: payeeTab.entityType,
      receiptVersionNo: 0,
      cmnEntityTypVersionNo: 0,
      cmnEntVersionNo: 0,
      voidIndicator: false,
      voidDate: null,
      approvalReqIndicator: false,
      recptTempBalAmount: '',
      recptTempFCN: '',
      depositNumber: originalTab.depositNumber ? (originalTab.depositNumber !== undefined && originalTab.depositNumber !== '') ? Number(originalTab.depositNumber) : '' : '',
      fdetailVersionNo: 2,
      noteSetVO: notesInput
    };
  }
};
