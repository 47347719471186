/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { getLongDescription } from '../../../../../SharedModules/CommonUtilities/commonUtilities';
import { Link, Prompt } from 'react-router-dom';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import * as ErrorConstants from './ErrorConstants';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../../SharedModules/services/service';
import moment from 'moment';
import { saveARAdjust } from '../../../Store/Actions/accountReceivable/ARAdjustActions';
import { getDropdownDataAction, getPayeeIdType, agingDaysList, searchFCNAccountReceivableAction } from '../../../Store/Actions/accountReceivable/accountReceivableActions';

import * as ARSearchConstants from '../ARSearch/ARSearchConstants';
import { redirectSystemID, redirectGrossLevelFCN } from '../../../../../SharedModules/TabRedirections/TabRedirections';
import { LinkOfTcn, LinkOfFcn } from '../../../../../SharedModules/TabRedirections/customInputLink';
import numeral from 'numeral';
import dropdownCriteria from './ARAdjust.json';
import { refreshAttachmentsAction } from '../../../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../../../SharedModules/Layout/footer';
import Notes from '../../../../../SharedModules/Notes/Notes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import { getUTCTimeStamp, generateUUID, getDateInMMDDYYYYFormatWithApendZero } from '../../../../../SharedModules/DateUtilities/DateUtilities';
import Attachments from '../../../../../SharedModules/Attachments/Attachments';
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

function ARAdjust (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  // Notes Tab Functionality
  const [value, setValue] = React.useState(0);
  const [attchmentResponse,setAttachmentResponse] = React.useState([])
  
  const [tabOne, setTabOne] = React.useState(true);

  const [saveFlag, setSaveFlag] = React.useState(false);
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  const [atrError, showATRError] = React.useState(false);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
  };
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // Attachments Integration start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const [headerValuesDesc, setHeaderValuesDesc] = React.useState({
    lobDesc: "",
    payeePayerTypeCodeDesc: "",
    payeePayerIDTypeCodeDesc: "",
    activityTypeCodeDesc: "",
    ARReasonCodeDesc: ""
  });
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  React.useEffect(() => {
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description:
              value && value
                ? value.description
                : null,
            edmsStoredDate: null,
            anEDMSPageId: value? value.anEDMSPageId : null,
            fileName:
              attachmentsTableData && value
                ? value.fileName
                : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    ARAdjustDetails.attachmentVO = null;
    ARAdjustDetails.attachmentsList = attachmentVOList;
  }, [attachmentsTableData]);
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [adjustVONotesInput, setAdjustVONotesInput] = React.useState({

    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: [],
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [notesInput, setNotesInput] = React.useState({

    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({

  });

  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = (data) => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
    const notesAdjustlist = adjustVONotesInput.notesList;
    notesAdjustlist.push(noteText);
    setAdjustVONotesInput({ ...adjustVONotesInput, notesList: notesAdjustlist });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setsaveMessage([]);
    seterrorMessages([]);
    setReasonCodeError(false);
  };
  let paramsData;

  useEffect(() => {
    if (props.location.state.claimsData) {
      paramsData = props.location.state.claimsData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID ? noteSetVO.addedAuditUserID : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp ? noteSetVO.addedAuditTimeStamp : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
    refreshAttachmentResult = null;
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID ? notesInput.addedAuditUserID : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp ? notesInput.addedAuditTimeStamp : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum ? notesInput.noteCexVersionNum : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber ? notesInput.saNoteSequenceNumber : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue ? notesInput.notesCexnoteTextValue : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  const [open, setOpen] = React.useState(false);
  const ARAdjustDetails = JSON.parse(JSON.stringify(props.location.state.claimsData));
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const DropDownAPI = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  let dropDownDetails = [];
  dropDownDetails = useSelector(state => state.accountReceivableState.dropdownData);
  const ARletterSentAPI = () => { dispatch(agingDaysList()); };
  const ARLetterSentDetails = useSelector(state => state.accountReceivableTransferState.agingDaysList);
  const PayeeIDCodeAPI = (payee) => dispatch(getPayeeIdType(payee));
  let payeeIDTypeCodeDetails = [];
  payeeIDTypeCodeDetails = useSelector(state => state.accountReceivableState.payeeIdTypeDropdown);
  const saveARAdjustAPI = (values, index) => { dispatch(saveARAdjust(values, attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id ? attachmentFile : null)); };
  let saveARAdjustResult = [];
  saveARAdjustResult = useSelector(state => state.accountReceivableAdjustState.saveResults);
  const [ARletterSent, setARletterSent] = React.useState('');
  const [payeePayerTypeCodeDesc, setpayeePayerTypeCodeDesc] = React.useState('');
  const [payeePayerIDTypeCodeDesc, setpayeePayerIDTypeCodeDesc] = React.useState('');
  const [activityTypeCodeDesc, setActivityTypeCodeDesc] = React.useState('');
  const [ARReasonCodeDesc, setARReasonCodeDesc] = React.useState('');
  const [balance, setBalance] = React.useState(ARAdjustDetails.claimsReceivableDetailsVO.balanceAmount);
  const [adjFtTcn, setFtTcn] = React.useState(ARAdjustDetails.adjustmentVO.relatedFTTCN);
  const [adjustedAmt, setAdjustAmt] = React.useState(ARAdjustDetails.claimsReceivableDetailsVO.adjustedArAmt);
  const [penalty, setPenalty] = React.useState(ARAdjustDetails.claimsReceivableDetailsVO.penalty);
  const [atrNumber, setATRNumber] = React.useState(ARAdjustDetails?.adjustmentVO?.atrNumber);
  const [interest, setInterest] = React.useState(ARAdjustDetails.claimsReceivableDetailsVO.interest);
  const [fee, setFee] = React.useState(ARAdjustDetails.claimsReceivableDetailsVO.fee);
  const [reasonCodeDesc, setReaconCodeDesc] = React.useState('');
  const [saveMessage, setsaveMessage] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [reasonCodeError, setReasonCodeError] = React.useState(false);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [revisedARAmountError, setRevisedARAmountError] = React.useState(false);
  const [adjustedARAmountError, setAdjustedARAmountError] = React.useState(false);
  const [revisedARAmountErrorDes, setRevisedARAmountErrorDes] = React.useState(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
  const [revisedInterestError, setRevisedInterestError] = React.useState(false);
  const [revisedInterestErrorDes, setRevisedInterestErrorDes] = React.useState(ErrorConstants.REVISED_INTEREST_INVALID);
  const [revisedPenalityError, setRevisedPenalityError] = React.useState(false);
  const [revisedPenalityErrorDes, setRevisedPenalityErrorDes] = React.useState(ErrorConstants.REVISED_PENALITY_INVALID);
  const [revisedFeeError, setRevisedFeeError] = React.useState(false);
  const [revisedFeeErrorDes, setRevisedFeeErrorDes] = React.useState(ErrorConstants.REVISED_FESS_INVALID);
  const [verificationState, setVerificationState] = React.useState({});
  const [reasonCodeArray, setReasonCodeArray] = React.useState([]);
  const [linkEnable, setLinkEnable] = React.useState(false);

  useEffect(() => {
    saveARAdjustResult = [];
    setsaveMessage([]);
    seterrorMessages([]);

    PayeeIDCodeAPI(ARAdjustDetails.payeePayerTypeCode);
    DropDownAPI(dropdownCriteria);
    ARletterSentAPI();
  }, []);
  // System Payee redirection starts
  const [redirect, setRedirect] = React.useState(0);
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  React.useEffect(() => {
    if (redirect === 1 && searchSingleFinancialEntityRes && !searchSingleFinancialEntityRes.systemFailue) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  useEffect(() => {
    if (payeeIDTypeCodeDetails && payeeIDTypeCodeDetails.systemFailure === undefined && payeeIDTypeCodeDetails.payeeIdTypeCode && payeeIDTypeCodeDetails.payeeIdTypeCode.length > 0) {
      payeeIDTypeCodeDetails.payeeIdTypeCode.map(var1 => {
        if (var1.code === ARAdjustDetails.payeePayerIDTypeCode) {
          setpayeePayerIDTypeCodeDesc(var1.description);
        }
      });
      seterrorMessages([]);
    }
  }, [payeeIDTypeCodeDetails]);

  useEffect(() => {
    if (ARLetterSentDetails && ARLetterSentDetails.systemFailure === undefined) {
      ARLetterSentDetails.map(var1 => {
        if (ARAdjustDetails.agingCode === var1.code) {
          setARletterSent(var1.description);
        }
      });
    }
  }, [ARLetterSentDetails]);

  useEffect(() => {
    if (dropDownDetails && dropDownDetails.listObj) {
      if (dropDownDetails.listObj['General#6002']) {
        dropDownDetails.listObj['General#6002'].map(var1 => {
          if (var1.code === ARAdjustDetails.payeePayerTypeCode) {
            setpayeePayerTypeCodeDesc(var1.description);
          }
        });
      }
      if (dropDownDetails.listObj['Financial#3013']) {
        dropDownDetails.listObj['Financial#3013'].map(var1 => {
          if (var1.code === ARAdjustDetails.activityTypeCode) {
            setActivityTypeCodeDesc(var1.description);
          }
        });
      }
      if (dropDownDetails.listObj['Financial#8']) {
        setReasonCodeArray(dropDownDetails.listObj['Financial#8']);
      }
      if (dropDownDetails.listObj['General#1012']) {
        setUsageTypeCodeData(dropDownDetails.listObj['General#1012']);
      }
    }
  }, [dropDownDetails]);
  function updateDropdownDescription (addDropdowns, dropdownKey, valueToMatch, targetProperty) {
    if (addDropdowns && addDropdowns[dropdownKey]) {
      const dropdownData = addDropdowns[dropdownKey].filter((value) => value.code === valueToMatch?.split("-")[0]);
      setHeaderValuesDesc(prevState => ({
        ...prevState,
        [targetProperty]: dropdownData && dropdownData.length > 0
          ? dropdownData[0].description
          : valueToMatch || ""
      }));
    }
  }
  useEffect(() => {
    if (addDropdowns) {
      // Usage for 'payeePayerTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'G1#G_CMN_ENTY_TY_CD', ARAdjustDetails.payeePayerTypeCode, 'payeePayerTypeCodeDesc');
      // Usage for 'payeePayerIDTypeCodeDesc':
      const payeeIdTypeCdDD = {
        P: 'F1#P_ALT_ID_TY_CD',
        M: 'F1#B_ALT_ID_TY_CD',
        O: 'F1#G_ENTY_EXTL_ID_TY_CD',
        TC: 'F1#DM_G_CARR_ENTY_ID_TY_CD'
      };
      const selectedPayeeIdTypeCdDD = payeeIdTypeCdDD[ARAdjustDetails.payeePayerTypeCode] || '';
      updateDropdownDescription(addDropdowns, selectedPayeeIdTypeCdDD, ARAdjustDetails.payeePayerIDTypeCode, 'payeePayerIDTypeCodeDesc');
      // Usage for 'activityTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_ACTV_TY_CD', ARAdjustDetails.activityTypeCode, 'activityTypeCodeDesc');
      // Usage for 'ARReasonCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_RSN_CD', ARAdjustDetails.accountReceivableReasonCode, 'ARReasonCodeDesc');
    }
  }, [addDropdowns]);
  const [RCLongDesc, setRCLongDesc] = React.useState('');
  useEffect(() => {
    if (addDropdowns && addDropdowns['F1#F_RSN_CD']) {
      addDropdowns['F1#F_RSN_CD'].map(var2 => {
        if (var2.code === ARAdjustDetails.accountReceivableReasonCode) {
          setARReasonCodeDesc(var2.description);
          setRCLongDesc(var2.longDescription ? var2.longDescription : var2.description);
        }
      });
    }
  }, [addDropdowns]);

  useEffect(() => {
    const saveMSG = [];
    if (saveARAdjustResult && saveARAdjustResult.respcode && saveARAdjustResult.respcode === '01') {
      setSaveFlag(true);
      saveMSG.push(ErrorConstants.SAVE_SUCCESS);
      setsaveMessage(saveMSG);
      setLinkEnable(true);
      seterrorMessages([]);
      setBalance('0.00');
      // setAdjustAmt(values.adjustedArAmt);
      setPenalty(values.revisedPenality);
      setATRNumber(values?.atrNumber);
      setInterest(values.revisedInterest);
      setFee(values.revisedFee);
      ARAdjustDetails.claimsReceivableDetailsVO.balanceAmount = '0.00';
      ARAdjustDetails.claimsReceivableDetailsVO.adjustedArAmt = values.adjustedArAmt;
      ARAdjustDetails.claimsReceivableDetailsVO.penalty = values.revisedPenality;
      ARAdjustDetails.claimsReceivableDetailsVO.atrNumber = values?.atrNumber;
      ARAdjustDetails.claimsReceivableDetailsVO.interest = values.revisedInterest;
      ARAdjustDetails.claimsReceivableDetailsVO.fee = values.revisedFee;
      setAllowNavigation(false);

      if (ARAdjustDetails && ARAdjustDetails.adjustmentVO && ARAdjustDetails.adjustmentVO.fcn) {
        dispatch(searchFCNAccountReceivableAction(ARAdjustDetails.adjustmentVO.fcn));
      };

      // Refresh Attachmnets section, pass FCN as argument
      if (ARAdjustDetails && ARAdjustDetails.adjustmentVO && ARAdjustDetails.adjustmentVO.fcn) {
        dispatch(refreshAttachmentsAction(ARAdjustDetails.adjustmentVO.fcn));
      };
    } else if (saveARAdjustResult && saveARAdjustResult.respcode && saveARAdjustResult.respcode === 'Fail') {
      seterrorMessages([saveARAdjustResult.respdesc]);
    } else if (saveARAdjustResult && saveARAdjustResult.respcode && saveARAdjustResult.respcode !== '01') {
      seterrorMessages([saveARAdjustResult.errorMessages]);
    } else if (saveARAdjustResult && saveARAdjustResult.SystemError) {
      seterrorMessages([ErrorConstants.SYS_ERROR]);
    }
  }, [saveARAdjustResult]);
  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);
  const [values, setValues] = React.useState({
    revisedARAmount: ARAdjustDetails.claimsReceivableDetailsVO.balanceAmount,
    revisedInterest: '',
    revisedPenality: '',
    revisedFee: '',
    reasonCode: DEFAULT_DD_VALUE,
    adjustedArAmt: ARAdjustDetails.claimsReceivableDetailsVO.balanceAmount,
    atrNumber: '',
    atrNumberData: ''
  });
  useEffect(() => {
    if (open === true) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [open]);

  const handleOpen = () => {
    const errorArray = [];
    const regex = /[^A-Za-z0-9]/;
    if (Number(values.revisedARAmount.replace(/,/g, '')) <= 0) {
      setRevisedARAmountError(true);
      setRevisedARAmountErrorDes(ErrorConstants.REVISED_AMOUNT_ZERO);
      errorArray.push(ErrorConstants.REVISED_AMOUNT_ZERO);
    }
    if (values.atrNumber) {
      if (regex.test(values.atrNumber)) {
        showATRError(true);
        errorArray.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}`);
      } else { showATRError(false); }
    }
    if (values.revisedFee !== '' && Number(values.revisedFee.replace(/,/g, '')) <= 0) {
      setRevisedFeeError(true);
      setRevisedFeeErrorDes(ErrorConstants.REVISED_INTEREST_INVALID);
      errorArray.push(ErrorConstants.REVISED_INTEREST_INVALID);
    }
    if (values.revisedInterest !== '' && Number(values.revisedInterest.replace(/,/g, '')) <= 0) {
      setRevisedInterestError(true);
      setRevisedInterestErrorDes(ErrorConstants.REVISED_INTEREST_INVALID);
      if (!errorArray.includes(ErrorConstants.REVISED_INTEREST_INVALID)) {
        errorArray.push(ErrorConstants.REVISED_INTEREST_INVALID);
      }
    }
    if (values.revisedPenality !== '' && Number(values.revisedPenality.replace(/,/g, '')) <= 0) {
      setRevisedPenalityError(true);
      setRevisedPenalityErrorDes(ErrorConstants.REVISED_INTEREST_INVALID);
      if (!errorArray.includes(ErrorConstants.REVISED_INTEREST_INVALID)) {
        errorArray.push(ErrorConstants.REVISED_INTEREST_INVALID);
      }
    }
    // if (revisedARAmountError || revisedFeeError || revisedInterestError || revisedPenalityError) {
    //   errorArray.push(revisedARAmountErrorDes);
    // }
    // if (Number(values.revisedARAmount) <= 0) {
    //   errorArray.push(ErrorConstants.REVISED_AMOUNT_ZERO);
    // }
    if (values.reasonCode === DEFAULT_DD_VALUE) {
      errorArray.push(ErrorConstants.REASON_CODE);
      setReasonCodeError(true);
    }
    if (
      values.adjustedArAmt
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}?$/) === null
    ) {
      setAdjustedARAmountError(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
      errorArray.push(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
    } else {
      if (
        !(
          values.adjustedArAmt
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
        )
      ) {
        setAdjustedARAmountError(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
        errorArray.push(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
      } else {
        setAdjustedARAmountError(false);
      }
    }
    if (errorArray.length === 0) {
      setVerificationState(values);
      setOpen(true);
      seterrorMessages([]);
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      seterrorMessages(errorArray);
    }
  };

  const handleChange = name => event => {
    setAllowNavigation(true);
    setOpen(false);
    if (name === 'revisedARAmount') {
      if (event.target.value.replace(/,/g, '').match(ErrorConstants.AR_AMOUNT_REGEX) === null) {
        setRevisedARAmountError(true);
        setRevisedARAmountErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
      } else {
        if (!(event.target.value.replace(/,/g, '').match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null)) {
          setRevisedARAmountError(true);
          setRevisedARAmountErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
        } else {
          setRevisedARAmountError(false);
        }
      }
    } else {
      if (event.target.value.replace(/,/g, '').match(ErrorConstants.INTEREST_AMOUNT_REGEX) === null) {
        if (name === 'revisedFee') {
          setRevisedFeeError(true);
          setRevisedFeeErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
        }
        if (name === 'revisedInterest') {
          setRevisedInterestError(true);
          setRevisedInterestErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
        }
        if (name === 'revisedPenality') {
          setRevisedPenalityError(true);
          setRevisedPenalityErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
        }
      } else {
        if (!(event.target.value.replace(/,/g, '').match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null)) {
          if (name === 'revisedFee') {
            setRevisedFeeError(true);
            setRevisedFeeErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
          }
          if (name === 'revisedInterest') {
            setRevisedInterestError(true);
            setRevisedInterestErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
          }
          if (name === 'revisedPenality') {
            setRevisedPenalityError(true);
            setRevisedPenalityErrorDes(ErrorConstants.REVISED_AR_AMOUNT_INVALID);
          }
        } else {
          if (name === 'revisedFee') {
            setRevisedFeeError(false);
          }
          if (name === 'revisedInterest') {
            setRevisedInterestError(false);
          }
          if (name === 'revisedPenality') {
            setRevisedPenalityError(false);
          }
        }
      }
    }
    if (name === 'reasonCode') {
      reasonCodeArray.map(var1 => {
        if (var1.code === event.target.value) {
          setReaconCodeDesc(var1.description);
        }
      });
      setReasonCodeError(false);
    }
    setValues({ ...values, [name]: event.target.value.trim() });
  };
  const AdjustAmount = () => {
    let rARamt = values.revisedARAmount; let rInst = values.revisedInterest; let rFee = values.revisedFee; let rPenality = values.revisedPenality;
    const amount = Number(values.revisedARAmount.replace(/,/g, '')) + Number(values.revisedInterest.replace(/,/g, '')) + Number(values.revisedPenality.replace(/,/g, '')) + Number(values.revisedFee.replace(/,/g, ''));
    if (values.revisedARAmount === '' || Number(values.revisedARAmount.replace(/,/g, '')) === 0) {
      rARamt = '0.00';
    }
    if (values.revisedInterest && Number(values.revisedInterest.replace(/,/g, '')) === 0) {
      rInst = '0.00';
    }
    if (values.revisedFee && Number(values.revisedFee.replace(/,/g, '')) === 0) {
      rFee = '0.00';
    }
    if (values.revisedPenality && Number(values.revisedPenality.replace(/,/g, '')) === 0) {
      rPenality = '0.00';
    }
    if (!revisedARAmountError && !revisedFeeError && !revisedInterestError && !revisedPenalityError) {
      setValues({
        ...values,
        revisedARAmount: numeral(rARamt).format('0,0.00'),
        revisedInterest: values.revisedInterest ? numeral(rInst).format('0,0.00') : '',
        revisedPenality: values.revisedPenality ? numeral(rPenality).format('0,0.00') : '',
        revisedFee: values.revisedFee ? numeral(rFee).format('0,0.00') : '',
        adjustedArAmt: String(amount)
      });
    }
  };

  function masterSave () {
    const errorArray = [];
    if (revisedARAmountError === true) {
      errorArray.push(revisedARAmountErrorDes);
    }
    if (atrError) {
      errorArray.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}`);
    }
    if (revisedFeeError === true) {
      errorArray.push(revisedFeeErrorDes);
    }
    if (revisedInterestError === true) {
      errorArray.push(revisedInterestErrorDes);
    }
    if (revisedPenalityError === true) {
      errorArray.push(revisedPenalityErrorDes);
    }
    if (values.reasonCode === DEFAULT_DD_VALUE) {
      errorArray.push(ErrorConstants.REASON_CODE);
      setReasonCodeError(true);
    }
    if (Number(balance) === 0) {
      errorArray.push(ErrorConstants.BALANCE_ZERO);
    }
    if (open === false) {
      errorArray.push(ErrorConstants.CALCULATE_ADJUST);
    }
    // if (Number(ARAdjustDetails.claimsReceivableDetailsVO.adjustedArAmt.replace(/,/g, '')) > 0 && Number(balance.replace(/,/g, '')) > 0) {
    //   errorArray.push(ErrorConstants.ARADJUST_DONE);
    // }
    if (errorArray.length === 0) {
      ARAdjustDetails.auditUserID = logInUserID;
      ARAdjustDetails.auditTimeStamp = getUTCTimeStamp();
      ARAdjustDetails.addedAuditUserID = ARAdjustDetails.addedAuditUserID ? ARAdjustDetails.addedAuditUserID : logInUserID;
      ARAdjustDetails.addedAuditTimeStamp = ARAdjustDetails.addedAuditTimeStamp ? ARAdjustDetails.addedAuditTimeStamp : getUTCTimeStamp();
      ARAdjustDetails.adjustmentVO.auditUserID = logInUserID;
      ARAdjustDetails.adjustmentVO.auditTimeStamp = getUTCTimeStamp();
      ARAdjustDetails.adjustmentVO.addedAuditUserID = ARAdjustDetails.adjustmentVO.addedAuditUserID ? ARAdjustDetails.adjustmentVO.addedAuditUserID : logInUserID;
      ARAdjustDetails.adjustmentVO.addedAuditTimeStamp = ARAdjustDetails.adjustmentVO.addedAuditTimeStamp ? ARAdjustDetails.adjustmentVO.addedAuditTimeStamp : getUTCTimeStamp();
      ARAdjustDetails.adjustmentVO.adjustmentReasonCode = values.reasonCode;
      ARAdjustDetails.adjustmentVO.atrNumber = values?.atrNumber?.toUpperCase();
      ARAdjustDetails.adjustmentVO.interest = values.revisedInterest;
      ARAdjustDetails.adjustmentVO.penalty = values.revisedPenality;
      ARAdjustDetails.adjustmentVO.fee = values.revisedFee;
      ARAdjustDetails.adjustmentVO.adjustmentAmount = values.adjustedArAmt;
      ARAdjustDetails.adjustmentVO.originalAmount = ARAdjustDetails.claimsReceivableDetailsVO.originalAmount;
      ARAdjustDetails.adjustmentVO.balanceAmount = values.adjustedArAmt;
      ARAdjustDetails.adjustmentVO.revisedARAmount = values.revisedARAmount;
      ARAdjustDetails.provBillSysId = ARAdjustDetails.adjustmentVO.provBillSysId;
      ARAdjustDetails.adjustmentVO.establishedDateStr = moment(new Date()).format('L');
      if (ARAdjustDetails.noteSetVO) {
        const noteSK = ARAdjustDetails.noteSetVO.noteSetSK;
        ARAdjustDetails.noteSetVO = adjustVONotesInput;
        ARAdjustDetails.noteSetVO.noteSetSK = noteSK;
      } else {
        ARAdjustDetails.noteSetVO = adjustVONotesInput;
      }
      const index = attachmentsTableData.length - 1;
      const attachmentVOList = [];
      if (attachmentsTableData && attachmentsTableData.length > 0) {
        attachmentsTableData.map((value) => {
          if (value.id) {
            const attachmentVO = {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: true,
              sortColumn: null,
              attachmentSK: null,
              attachmentPageID: null,
              finalPath: null,
              detachInd: false,
              historyIndicator: false,
              cascadeKey: null,
              attachmentIndicator: null,
              dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
              fileUrl: null,
              showDeleteForSearched: false,
              rowIndex: 0,
              addedBy: logInUserID,
              description:
                value && value
                  ? value.description
                  : null,
              edmsStoredDate: null,
              anEDMSPageId: value? value.anEDMSPageId : null,
              fileName:
                attachmentsTableData && value
                  ? value.fileName
                  : null,
              file1: null,
              edmsWrkUnitLevel: null,
              edmsDocType: null
            };
            attachmentVOList.push(attachmentVO);
          }
        });
      }
      ARAdjustDetails.attachmentVO = null;
      ARAdjustDetails.attachmentsList = attachmentVOList;
      saveARAdjustAPI(ARAdjustDetails, index);
      seterrorMessages([]);
    } else {
      seterrorMessages(errorArray);
    }
  }
  const payload = useSelector(state => state.accountReceivableState.payload);
  let errorMessagesArray;
  /* Show error messages if record not found for FCN number entered */
  useEffect(() => {
    if (payload && payload.respcode === '02' && payload.respdesc !== '') {
      errorMessagesArray = [];
      errorMessagesArray.push(payload.respdesc);
      seterrorMessages(errorMessagesArray);
    }
    if (payload && payload.systemFailure !== undefined) {
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    }

    /* If FCN number found and only one record found redirecting to edit screen with search results
  * redirect to claims receivable if activity code is "CR"
  */
    if (payload != null && payload !== '' && payload.systemFailure === undefined) {
      setFtTcn(payload.relatedFTTCN);
    }
  }, [payload]);

  return (
    <div>
      <div>
        <Prompt
          when={allowNavigation}
          message={location => `Are you sure you want to go to ${location.pathname}`}
        />

        <div className="tabs-container" ref={toPrintRef}>
          <ErrorMessages errorMessages={errorMessages} />
          {saveMessage.length > 0 && errorMessages.length === 0
            ? <SuccessMessages successMessages={saveMessage} /> : null
          }
          <div className="tab-header">
            <h1 className="tab-heading float-left">
              Account Receivable Adjustment
            </h1>
            <div className="float-right mt-2">
              <Button className='btn btn-success' onClick={masterSave}>
                <i className="fa fa-check" aria-hidden="true"></i>
                Save
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-body">
            <form autoComplete="off">
              <div className="form-wrapper">
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandard-Payee/Payer"
                    fullWidth
                    label="Payee/Payer Type"
                    value={headerValuesDesc.payeePayerTypeCodeDesc || ""}
                    inputProps={{ maxLength: 13 }}
                    // disabled
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardPayee/PayerIDType"
                    fullWidth
                    label="Payee/Payer ID Type Code"
                    value={headerValuesDesc.payeePayerIDTypeCodeDesc || ""}
                    inputProps={{ maxLength: 13 }}
                    // disabled
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardPayeeID"
                    fullWidth
                    label="Payee ID"
                    value={ARAdjustDetails.payeePayerID}
                    inputProps={{ maxLength: 15 }}
                    // disabled
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardPayee/PayerName"
                    fullWidth
                    label="Payee/Payer Name"
                    value={ARAdjustDetails.payeePayerNameFromDB}
                    // disabled
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    inputProps={{ maxLength: 60 }}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md has-link">
                  <TextField
                    id="ARAjuststandardSystemPayeeID"
                    fullWidth
                    label="System Payee ID"
                    // disabled
                    InputProps={{
                      readOnly: true,
                      className: 'Mui-disabled',
                      startAdornment: (
                        <InputAdornment position="start">
                          <Link to={redirectSystemID(ARAdjustDetails)}>
                            {ARAdjustDetails.sysID}
                          </Link>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardActivityType"
                    fullWidth
                    label="Activity Type"
                    // disabled
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    value={headerValuesDesc.activityTypeCodeDesc || ""}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardReasonCode"
                    fullWidth
                    label="Reason Code"
                    title={RCLongDesc !== '' ? RCLongDesc : ARReasonCodeDesc !== '' ? ARReasonCodeDesc : ARAdjustDetails.accountReceivableReasonCode}
                    // disabled
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    value={headerValuesDesc.ARReasonCodeDesc || ""}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
              </div>

              <div className="mui-custom-form override-width-95">
                <div >
                  <fieldset className="r-custom-fieldset">
                    <legend>Original Request:</legend>
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-md has-link">
                        <TextField
                          id="ARAjuststandard-FCN11"
                          fullWidth
                          label="FCN"
                          inputProps={{ maxLength: 3 }}
                          // disabled
                          InputProps={{
                            readOnly: true,
                            className: 'Mui-disabled',
                            startAdornment: (
                              <InputAdornment position="start">
                                <Link to={redirectGrossLevelFCN(ARAdjustDetails.receivableFCN)}>
                                  {ARAdjustDetails.receivableFCN}
                                </Link>
                              </InputAdornment>
                            )
                          }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-originalamt"
                          fullWidth
                          label="Original A/R Amount"
                          value={numeral(ARAdjustDetails.claimsReceivableDetailsVO.originalAmount).format('0,0.00')}
                          // disabled
                          inputProps={{ maxLength: 11 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-md">
                        <TextField
                          id="ARAjuststandard-payeeid1"
                          fullWidth
                          label="Establishment Date"
                          value={moment(ARAdjustDetails.establishedDate).format('L')}
                          inputProps={{ maxLength: 10 }}
                          // disabled
                          InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-balanceamt1"
                          fullWidth
                          label="Balance Amount"
                          value={numeral(balance).format('0,0.00')}
                          // disabled
                          inputProps={{ maxLength: 14 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-md has-link">
                        <TextField
                          id="ARAjuststandard-systempayeeid1"
                          fullWidth
                          label="Related FT TCN"
                          InputProps={{
                            readOnly: true,
                            className: 'Mui-disabled',
                            startAdornment: (
                              <InputAdornment position="start">
                                <a href={CLAIMS_INQUIRY_TCN_LINK + ARAdjustDetails.relatedFTTCN} target='_blank' rel="noreferrer">
                                  {ARAdjustDetails.relatedFTTCN}
                                </a>
                              </InputAdornment>
                            )
                          }}
                          inputProps={{ maxLength: 10 }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-fttcn1"
                          fullWidth
                          label="Adjusted A/R Amount"
                          value={numeral(adjustedAmt).format('0,0.00')}
                          inputProps={{ maxLength: 17 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          // disabled
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-md">
                        <TextField
                          id="ARAjuststandard-lettersent1"
                          fullWidth
                          label="A/R Letter Sent"
                          value={ARletterSent}
                          inputProps={{ maxLength: 3 }}
                          // disabled
                          InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-interest11"
                          fullWidth
                          // disabled
                          label="Interest"
                          value={numeral(interest).format('0,0.00')}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-penalty1"
                          fullWidth
                          // disabled
                          label="Penalty"
                          value={numeral(penalty).format('0,0.00')}
                          placeholder=""
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandardFees11"
                          fullWidth
                          // disabled
                          label="Fees Assessed"
                          value={numeral(fee).format('0,0.00')}
                          placeholder=""
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ATRNumber"
                          fullWidth
                          label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={atrNumber}
                          inputProps={{ maxLength: 18 }}
                          InputProps={{
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="mui-custom-form override-width-95">
                <div >
                  <fieldset className="r-custom-fieldset">
                    <legend>Adjustment Action</legend>
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandardoriginalamt1"
                          fullWidth
                          label="Revised A/R Amount"
                          onChange={handleChange('revisedARAmount')}
                          value={values.revisedARAmount}
                          onBlur={AdjustAmount}
                          onFocus={() => {
                            setValues({
                              ...values, revisedARAmount: values.revisedARAmount !== '' ? numeral(values.revisedARAmount).format('0.00') : ''
                            });
                          }}
                          inputProps={{ maxLength: 12 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                          }}
                          error={revisedARAmountError ? revisedARAmountErrorDes : null}
                          helperText={revisedARAmountError ? revisedARAmountErrorDes : null}
                        />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-fttcn"
                          fullWidth
                          label="Revised Interest"
                          value={values.revisedInterest}
                          onChange={handleChange('revisedInterest')}
                          onBlur={AdjustAmount}
                          onFocus={() => {
                            setValues({
                              ...values, revisedInterest: values.revisedInterest !== '' ? numeral(values.revisedInterest).format('0.00') : ''
                            });
                          }}
                          inputProps={{ maxLength: 14 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                          }}
                          error={revisedInterestError ? revisedInterestErrorDes : null}
                          helperText={revisedInterestError ? revisedInterestErrorDes : null}
                        />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandard-lettersent"
                          fullWidth
                          label="Revised Penality"
                          onBlur={AdjustAmount}
                          value={values.revisedPenality}
                          onFocus={() => {
                            setValues({
                              ...values, revisedPenality: values.revisedPenality !== '' ? numeral(values.revisedPenality).format('0.00') : ''
                            });
                          }}
                          onChange={handleChange('revisedPenality')}
                          inputProps={{ maxLength: 14 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                          }}
                          error={revisedPenalityError ? revisedPenalityErrorDes : null}
                          helperText={revisedPenalityError ? revisedPenalityErrorDes : null}
                        />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="ARAjuststandardRevisedFee12"
                          fullWidth
                          label="Revised Fee"
                          onBlur={AdjustAmount}
                          onFocus={() => {
                            setValues({
                              ...values, revisedFee: values.revisedFee !== '' ? numeral(values.revisedFee).format('0.00') : ''
                            });
                          }}
                          value={values.revisedFee}
                          onChange={handleChange('revisedFee')}
                          inputProps={{ maxLength: 14 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                          }}
                          error={revisedFeeError ? revisedFeeErrorDes : null}
                          helperText={revisedFeeError ? revisedFeeErrorDes : null} />
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <NativeDropDown
                        id="ARAjuststandard"
                        label="Reason Code"
                        isRequired
                        value={values.reasonCode}
                        onChange={handleChange('reasonCode')}
                        onBlur={AdjustAmount}
                        inputProps={{ maxLength: 3 }}
                        errTxt={reasonCodeError ? ErrorConstants.REASON_CODE : null}
                        title={getLongDescription(reasonCodeArray, values.reasonCode)}
                        dropdownList={reasonCodeArray.map(option => (
                          <option key={option.code} value={option.code}>{option.description}
                          </option>))}
                      />
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="standard-fttcn"
                          fullWidth
                          label="Adjusted A/R Amount"
                          value={numeral(values.adjustedArAmt).format('0,0,000,000,000,000,000.00')}
                          inputProps={{ maxLength: 12 }}
                          // disabled
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                            className: 'Mui-disabled'
                          }}
                          error={adjustedARAmountError || null}
                          helperText={adjustedARAmountError || null}
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </div>
                      <div className="mui-custom-form input-Adronment">
                        <TextField
                          id="textATRNumber"
                          fullWidth
                          label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={values.atrNumber}
                          onChange={handleChange('atrNumber')}
                          inputProps={{ maxLength: 18 }}
                          helperText={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}` : null}
                          error={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}` : null}
                        />
                      </div>
                    </div>
                    <div className="float-right mr-4 mb-2">
                      <Button className="btn btn-primary" onClick={ handleOpen }>
            Calculate
                      </Button>
                    </div>
                  </fieldset>
                  <div>

                  </div>
                </div>
              </div>
              {open === true ? <div className="mui-custom-form override-width-95">
                <div >
                  <fieldset className="custom-fieldset">
                    <legend>Adjustment Verification:</legend>
                    <form autoComplete="off">
                      <div className="form-wrapper">
                        <div className={saveFlag ? "mui-custom-form input-md has-fcn-link" : "mui-custom-form input-md"}>
                          <TextField
                            id="ARAjuststandard-FCN2"
                            fullWidth
                            label="FCN"
                            inputProps={{ maxLength: 3 }}
                            disabled
                            InputProps={{
                              inputComponent: LinkOfFcn,
                              inputProps: { value: ARAdjustDetails.adjustmentVO.fcn, redirection: redirectGrossLevelFCN, saveFlag: saveFlag }
                            }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-md">
                          <TextField
                            id="ARAjuststandard-reasoncode1"
                            fullWidth
                            label="Reason Code"
                            title={getLongDescription(reasonCodeArray, reasonCodeDesc)}
                            disabled
                            value={reasonCodeDesc}
                            onChange={handleChange('reasonCode')}
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >

                          </TextField>
                        </div>
                        <div className="mui-custom-form input-md">
                          <TextField
                            id="ARAjuststandard-EstablishedDate"
                            fullWidth
                            label="Established Date"
                            value={moment(new Date()).format('L')}
                            inputProps={{ maxLength: 10 }}
                            disabled
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-md has-link">
                          <TextField
                            id="ARAjuststandardRelated FT"
                            fullWidth
                            label="Related FT TCN"
                            disabled
                            InputProps={{
                              inputComponent: LinkOfTcn,
                              inputProps: { value: adjFtTcn, saveFlag: saveFlag }
                            }}
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-Adronment">
                          <TextField
                            id="ARAjuststandard-RevisedA/R"
                            fullWidth
                            disabled
                            label="Revised A/R Amount"
                            value={verificationState.revisedARAmount}
                            onBlur={AdjustAmount}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            inputProps={{ maxLength: 16 }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>

                        <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                          <TextField
                            id="standard-originalamt"
                            fullWidth
                            label="Original A/R Amount"
                            value={numeral(ARAdjustDetails.claimsReceivableDetailsVO.originalAmount).format('0,0,000,000,000,000,000.00')}
                            disabled
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            inputProps={{ maxLength: 11 }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                          <TextField
                            id="standard-fttcn1"
                            fullWidth
                            label="Adjusted A/R Amount"
                            value={numeral(verificationState.adjustedArAmt).format('0,0,000,000,000,000,000.00')}
                            inputProps={{ maxLength: 17 }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            disabled
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>

                        <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                          <TextField
                            id="standard-balanceamt1"
                            fullWidth
                            label="Balance Amount"
                            value={numeral(verificationState.adjustedArAmt).format('0,0,000,000,000,000,000.00')}
                            disabled
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>

                        <div className="mui-custom-form input-Adronment ">
                          <TextField
                            id="standard-interest1"
                            fullWidth
                            disabled
                            label="Interest"
                            value={numeral(verificationState.revisedInterest).format('0,0,000,000,000,000,000.00')}
                            placeholder=""
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                          <TextField
                            id="standard-penalty1"
                            fullWidth
                            disabled
                            label="Penalty"
                            value={numeral(verificationState.revisedPenality).format('0,0,000,000,000,000,000.00')}
                            placeholder=""
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                          <TextField
                            id="standard-lettersent1"
                            fullWidth
                            disabled
                            label="Fees Assessed"
                            value={numeral(verificationState.revisedFee).format('0,0,000,000,000,000,000.00')}
                            placeholder=""
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            InputLabelProps={{
                              shrink: true
                            }} />
                        </div>
                        <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                          <TextField
                            id='Standard-ATRnumber'
                            fullWidth
                            label={ process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                            placeholder=""
                            value={values.atrNumber}
                            disabled
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </form>
                  </fieldset>
                </div>
              </div> : null}

            </form>

            <div className="tab-panelbody">
              <div className="tab-holder mb-3 mt-2">
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Notes" />
                    <Tab label="Attachments" />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className="tab-holder p-0">
                    <Notes addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setFocusCheck={setFocusCheck}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs} />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="tab-holder p-0">
                    <Attachments
                      userInquiryPrivileges={userInquiryPrivileges}
                      attachmentFile={attachmentFile}
                      setAttachmentFile={setAttachmentFile}
                      attachmentsTableData={attachmentsTableData}
                      setAttachmentsTableData={setAttachmentsTableData}
                      parentErrorMesage={seterrorMessages}
                      setAllowNavigation={setAllowNavigation}
                      setSystemSuccesMessages={clearSuccessErrorMsgs}
                      setFocusCheck={setFocusCheck}
                      ARAdjustDetails={ARAdjustDetails}
                      attchmentResponse={attchmentResponse}
                      setAttachmentResponse={setAttachmentResponse}


                    />
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
          <Footer print />
        </div>
      </div>
    </div >

  );
}
export default withRouter((ARAdjust));
