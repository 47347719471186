import React, { useEffect, forwardRef } from 'react';
function MicroFrontend (
  { host, history, privileges, componentProps, containerId },
  ref
) {
  const commonName = 'CommonModule';
  useEffect(() => {
    const scriptId = `micro-frontend-script-${commonName}`;

    const renderMicroFrontend = () => {
      if (document.getElementById(containerId)) {
        console.log("containerId=>27", containerId, "host=>", host, "");
        if (typeof window[`renderCommonModule`] !== 'undefined') {
          window[`renderCommonModule`](
            containerId,
            history,
            privileges,
            componentProps,
            ref
          );
         }
      }
    };

    if (document.getElementById(scriptId)) {
      console.log("scriptId=>57", scriptId);
      renderMicroFrontend();
      return;
    }

    fetch(`${host}/asset-manifest.json`)
      .then(res => res.json())
      .then(manifest => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${host}${manifest.files['main.js']}`;
        const link = document.createElement('link');
        link.id = `micro-frontend-link-${commonName}`;
        link.crossOrigin = '';
        link.rel = 'stylesheet';
        link.href = `${host}${manifest.files['main.css']}`;
        script.onload = () => {
          renderMicroFrontend();
        };
        document.head.appendChild(script);
        document.head.appendChild(link);
      });
    return () => {
      // for un
      window[`unmount${commonName}`] &&
      window[`unmount${commonName}`](containerId);
    };
  });

  return <section id={containerId} className={`${(componentProps?.type != 'AuditLog' || componentProps?.type != 'Notes') ? "commonModuleClass" : ""}`}/>;
}

MicroFrontend.defaultProps = {
  document,
  window
};

export default forwardRef(MicroFrontend);
