/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import * as actionTypes from '../Actions/ReceiptReissueActionType';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler';

export const ReceiptDropdownActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(ReceiptDropdown(response.data));
      })
      .catch(error => {
        dispatch(ReceiptDropdown(error));
      });
  };
};
export const ReceiptDropdown = (receiptDropdown) => {
  return ({
    type: actionTypes.RECEIPT_DROPDOWNS,
    receiptDropdown: receiptDropdown
  });
};

export const searchCriteriaAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FUND_CODE_SEARCHCRITERIA}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === null) {
          dispatch(setSearchDetails([]));
        } else {
          dispatch(setSearchDetails(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSearchDetails(sendingResponse));
      });
  };
};
export const setInternalError = (data) => {
  return {
    type: actionTypes.FUND_CODE_SEARCH_ERROR,
    searchError: data
  };
};
export const setSearchDetails = (data) => {
  return {
    type: actionTypes.FUND_CODE_SEARCH_CRITERIA,
    searchResults: data
  };
};
export const resetFundCodeSearch = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const addFundCode = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FUND_CODE_ADD}`, value)
      .then(response => {
        if (response.data === undefined) {
          dispatch(setFundCodeAdd([]));
        } else {
          dispatch(setFundCodeAdd(response.data));
        }
      })
      .catch(error => {
        dispatch(setFundCodeAdd([error]));
      });
  };
};

export const setFundCodeAdd = (addFundCodeData) => {
  return {
    type: actionTypes.FUND_CODE_ADD,
    addFundCodeData: addFundCodeData
  };
};

export const searchFundCodeOnRowClickAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FCN_SEARCH_ENDPOINT}?receivableFCN=${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setFundCodeEdit([]));
        } else {
          dispatch(setFundCodeEdit(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFundCodeEdit(sendingResponse));
      });
  };
};

export const setFundCodeEdit = (payloadFundCode) => {
  return {
    type: actionTypes.SEARCH_FUND_CODE,
    payloadFundCode: payloadFundCode
  };
};

export const saveReissueCheckReplacement = (value, file) => {
  const formInfo = new FormData();
 
  formInfo.append('receiptVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_RECEIPT_SAVE_CHECK_REPLACEMENT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setSaveReissueCheckReplacement(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error)
        dispatch(setSaveReissueCheckReplacement(sendingResponse));
      });
  };
};

export const systemListReceiptAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response, true);
        dispatch(setSystemListReceipt(sendingRes));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error, true);
        dispatch(setSystemListReceipt(sendingRes));
      });
  };
};

export const setSystemListReceipt = (receiptData) => {
  return {
    type: actionTypes.SYSTEM_LIST_RECEIPT,
    rowReceiptSyslist: receiptData
  };
};


export const setSaveReissueCheckReplacement = (saveReplacement) => {
  return {
    type: actionTypes.SAVE_REISSUE_CHECK_REPLACEMENT,
    saveReplacement: saveReplacement
  };
};
