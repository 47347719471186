/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import TableComponent from '../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { searchBudgetMaintenanceIDAction } from '../Store/Actions/budgetMaintenance/budgetMaintenanceActions';

const headCells = [
  {
    id: 'fiscalYearDesc',
    disablePadding: false,
    label: 'Budget Year',
    enableHyperLink: true,
    width: '20%'
  },
  {
    id: 'agencyCodeDesc',
    disablePadding: false,
    label: 'Agency',
    enableHyperLink: false,
    width: '15%',
    isVarChar: true
  },
  {
    id: 'glCompanyCodeDesc',
    disablePadding: false,
    label: 'GL Company',
    isVarChar: true,
    enableHyperLink: false,
    width: '22%'
  },
  {
    id: 'organizationCodeDesc',
    disablePadding: false,
    label: 'GL Accounting Unit',
    enableHyperLink: false,
    width: '30%'
  },
  {
    id: 'fundCodeDesc',
    disablePadding: false,
    label: 'Fund Code',
    enableHyperLink: false,
    width: '20%',
    isTooltip: true,
    isTitle: 'fundCodelongDesc'
  },
  {
    id: 'cosDesc',
    disablePadding: false,
    label: 'COS',
    enableHyperLink: false,
    width: '10%',
    isVarChar: true,
    isTooltip: true,
    isTitle: 'COSCodelongDesc'
  },
  {
    id: 'voidDate',
    disablePadding: false,
    label: 'Void Date',
    isDate: true,
    enableHyperLink: false,
    width: '20%'
  }
];

function FinancialBudgetSearchTable (props) {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = React.useState(false);

  const onRowClick = values =>
    dispatch(searchBudgetMaintenanceIDAction(values));
  let payloadDataRowData = [];
  payloadDataRowData = useSelector(
    state => state.budgetMaintenanceState.searchBudgetID
  );
  if (redirect === 1) {
    if (payloadDataRowData) {
      props.history.push({
        pathname: '/BudgetDataUpdate',
        state: { payloadDataRowData, values: props.values, searchValues: props.searchValues}
      });
    }
  }
  useEffect(() => {
    if (payloadDataRowData != null && payloadDataRowData.length === 0) {

    }
  }, [payloadDataRowData]);
  const formatSearchCriteria = _row => _row.budgetID;

  const editRow = row => event => {
    onRowClick(row.budgetID);
    setRedirect(1);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const glCompanyCode =
            props.dropdowns &&
            props.dropdowns['F1#F_GL_CO_CD'] &&
            props.dropdowns['F1#F_GL_CO_CD'].filter(
              value => value.code === each.glCompanyCode
            );
          each.glCompanyCodeDesc =
          glCompanyCode && glCompanyCode.length > 0
              ? glCompanyCode[0].description
              : each.glCompanyCode ? each.glCompanyCode : '';

          const fiscalYear =
            props.dropdowns &&
            props.dropdowns['F1#DM_YR_NUM'] &&
            props.dropdowns['F1#DM_YR_NUM'].filter(
              value1 => value1.code == each.fiscalYear
            );
          each.fiscalYearDesc =
          fiscalYear && fiscalYear.length > 0
              ? fiscalYear[0].description
              : each.fiscalYear ? each.fiscalYear : '';

          const agencyCode =
            props.dropdowns &&
            props.dropdowns['F1#F_AGCY_CD'] &&
            props.dropdowns['F1#F_AGCY_CD'].filter(
              value1 => value1.code == each.agencyCode
            );
          each.agencyCodeDesc =
          agencyCode && agencyCode.length > 0
              ? agencyCode[0].description
              : each.agencyCode ? each.agencyCode : '';

          const organizationCode =
            props.dropdowns &&
            props.dropdowns['F1#F_ORG_CD'] &&
            props.dropdowns['F1#F_ORG_CD'].filter(
              value1 => value1.code == each.organizationCode
            );
          each.organizationCodeDesc =
          organizationCode && organizationCode.length > 0
              ? organizationCode[0].description
              : each.organizationCode ? each.organizationCode : '';

          const fundCode =
            props.dropdowns &&
            props.dropdowns['R1#R_FUND_CD'] &&
            props.dropdowns['R1#R_FUND_CD'].filter(
              value1 => value1.code == each.fundCode
            );
          each.fundCodeDesc =
          fundCode && fundCode.length > 0
              ? fundCode[0].description
              : each.fundCode ? each.fundCode : '';

          const cos =
            props.dropdowns &&
            props.dropdowns['C1#C_COS_CD'] &&
            props.dropdowns['C1#C_COS_CD'].filter(
              value1 => value1.code == each.cos
            );
          each.cosDesc =
          cos && cos.length > 0
              ? cos[0].description
              : each.cos ? each.cos : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <TableComponent
      print={props.print}
      pathTo="/BudgetDataUpdate?data="
      formatSearchCriteria={formatSearchCriteria}
      isSearch={true}
      headCells={headCells}
      tableData={getTableData(props.tableData ? props.tableData : [])}
      onTableRowClick={editRow}
      defaultSortColumn={'fiscalYear'}
      order={'desc'}
      fixedTable
    />
  );
}
export default withRouter(FinancialBudgetSearchTable);
