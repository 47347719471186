/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FCNComponent from '../../../AccountPayables/Components/PayoutApproval/PayoutApprovalFCNSearch';
import PayeeReceivablesComponent from './PayoutApprovalPayeeReceivables';
import FinancialPayoutSearchResultsComponent from './PayoutApprovalSearchResults';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';

import { getDropdownDataAction, setFPASearchData } from '../../../AccountPayables/Store/Actions/AccountPayableActions';
import dropdownCriteria from './PayeeSearch.json';
import Footer from '../../../../SharedModules/Layout/footer';
import { GET_APP_DROPDOWNS } from '../../../../SharedModules/Dropdowns/actions';
import * as Dropdowns from '../../../../SharedModules/Dropdowns/dropdowns';
function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function FinancialPayOutInquiry () {
  const [value, setValue] = React.useState(0);
  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [successMessages, setsuccessMessages] = React.useState([]);

  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);

  useEffect(() => {
    getDropdownData(dropdownCriteria);
    onDropdowns([Dropdowns.PAYEE_TYPE, Dropdowns.F_RSN_CD]);
    seterrorMessages([]);
  }, []);

  const dropdown = useSelector(state => state.accountPayableState.dropdownData);
  const searchQuery = useSelector(state => state.accountPayableState.searchValuesFPA);
  React.useEffect(() => {
    if(searchQuery?.tabIndex){
      setValue(searchQuery.tabIndex);
    }
  }, []);
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setReasonCodeData(dropdown.listObj['Financial#3006']);
      }
    }
  }, [dropdown]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    seterrorMessages([]);
    setsuccessMessages([]);
    dispatch(setFPASearchData({...searchQuery, tabIndex: newValue}));
  };

  const showValidationError = errorMsgArray => {
    seterrorMessages(errorMsgArray);
  };

  const showSuccessMessage = successMsgArray => {
    setsuccessMessages(successMsgArray);
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? <SuccessMessages successMessages={successMessages} /> : null}
        <div className="tab-header">
          <h1 className="float-left tab-heading">Search Payout Approval</h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body padTop-10">
          <div className="tab-panelbody">
            <div className="tab-holder-p-7 my-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="FCN" />
                  <Tab label="Payee" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <FCNComponent
                  addDropdowns={addDropdowns}
                  reasonCodeData={reasonCodeData}
                  payeeTypeData={payeeTypeData}
                  showError={showValidationError}
                  showSuccess={showSuccessMessage}
                  value={value}
                  searchQuery={searchQuery}
                ></FCNComponent>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <PayeeReceivablesComponent
                  showError={showValidationError}
                  addDropdowns={addDropdowns}
                  reasonCodeData={reasonCodeData}
                  payeeTypeData={payeeTypeData}
                  showSuccess={showSuccessMessage}
                  value={value}
                  searchQuery={searchQuery}
                ></PayeeReceivablesComponent>

                <div className="mt-3">
                  {showTable ? (
                    <div className="tab-holder-p-8 my-3 p-0">
                      <FinancialPayoutSearchResultsComponent />
                    </div>
                  ) : null}
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
