/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/accountReceivable/accountReceivableActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ACCOUNT_RECEIVABLE:
      return { ...state, payload: action.fcns };
    case actionTypes.PAYEE_SEARCH_ACCOUNT_RECEIVABLE:
      return { ...state, payloadPayees: action.payees, searchValues: action.searchValues };
    case actionTypes.PAYEE_OUTSTANDING_SEARCH_ACCOUNT_RECEIVABLE:
      return { ...state, payloadPayeesOutstanding: action.payeesOutstanding };
    case actionTypes.PAYEE_ID_TYPE_DROPDOWN:
      return { ...state, payeeIdTypeDropdown: action.payeeIdTypeDropdown };
    case actionTypes.PAYOUT_DETAILS:
      return { ...state, payoutDetails: action.payoutDetails };
    case actionTypes.MCN_SEARCH_ACCOUNT_RECEIVABLE:
      return { ...state, payloadMcns: action.mcns, searchValues: action.searchValues };
    case actionTypes.GOTONEXTPAGE:
      return { ...state, goToNextPagesData: action.nextPageData };
    case actionTypes.SEARCH_CLAIMS_RECEIVABLE:
      return { ...state, payloadClaims: action.claims };
    case actionTypes.RESET_EDIT_AR_SINGLE_RECORD:
      return { ...state, payloadClaims: null, payload: null };
    case actionTypes.CLAIMS_RECEIVABLE_SAVE:
      return { ...state, saveClaimsPayload: action.saveClaims };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.GROSS_LEVEL_ADD:
      return { ...state, addData: action.addData };
    case actionTypes.LIEN_LEVY_ADD:
      return { ...state, lienLevyAdd: action.addLienLevyData };
    case actionTypes.LIEN_LEVY_UPDATE:
      return { ...state, lienLevyUpdate: action.lienLevyUpdateData };
    case actionTypes.LIEN_LEVY_HOLDER_TYPE_ID_DROPDOWN:
      return {
        ...state, lienLevyHolderIdType: action.lienLevyHolderIdType
      };
    case actionTypes.AR_AGING_DAYS:
      return { ...state, agingDaysList: action.agingDaysList };
    case actionTypes.ARTRANSFER_ELIGIBLE:
      return { ...state, arTransferEligibleDetails: action.arTransferEligibleDetails };
    case actionTypes.ARREVERSE_ELIGIBLE:
      return { ...state, arReverseEligibleDetails: action.arReverseEligibleDetails };
    case actionTypes.ARADJUST_ELIGIBLE:
      return { ...state, arAdjustEligibleDetails: action.arAdjustEligibleDetails };
    case actionTypes.SAVE_REVERSE:
      return { ...state, arReverseResults: action.arReverseResults };
    case actionTypes.FETCH_WRITE_OFF:
      return { ...state, writeOffData: action.writeOffData };
    case actionTypes.FETCH_WRITE_OFF_RESTABLISHMENT:
      return { ...state, writeOffRestablishmentData: action.writeOffRestablishmentData };
    case actionTypes.DROPDOWNDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.SUB_DROPDOWNDATA:
      return { ...state, subdropdownData: action.subdropdownData };
    case actionTypes.REASON_CODE_MAP:
      return { ...state, reasonCodeMapdropdownData: action.reasonCodeMapdropdownData };
    case actionTypes.GROSSLEVEL_EDIT:
      return { ...state, GrossLevelEditData: action.GrossLevelEditData };
    case actionTypes.ADVANCE_PAYMENT_UPDATE:
      return { ...state, advancePaymentResponse: action.advancePaymentResponse };
    case actionTypes.FETCH_RECOUPMENT_DETAILS:
      return { ...state, fetchRecoupmetDetails: action.fetchRecoupmetDetails };
    case actionTypes.VALIDATE_LIEN_HOLDER_DETAILS:
      return { ...state, lienHolderDetails: action.lienHolderDetails };
    case actionTypes.FINANCIALENTITY_PAYEE_SEARCH_DATA:
      return { ...state, payeeDetails: action.payeeDetails };
    case actionTypes.BULK_VIEW_TRANSFER:
      return { ...state, bulkViewTransfer: action.bulkViewTransfer };
    case actionTypes.BULK_VIEW_TRANSFER_ERROR:
      return { ...state, bulkViewErrorTransfer: action.bulkViewErrorTransfer };
    case actionTypes.SAVE_BULK_TRANSFER:
      return { ...state, saveBulkTransfer: action.saveBulkTransfer };
    case actionTypes.BULK_VIEW_REVERSE:
      return { ...state, bulkViewReverse: action.bulkViewReverse };
    case actionTypes.BULK_VIEW_REVERSE_ERROR:
      return { ...state, bulkViewReverseError: action.bulkViewReverseError };
    case actionTypes.SAVE_BULK_REVERSE:
      return { ...state, saveBulkViewReverse: action.saveBulkViewReverse };
    case actionTypes.BULK_VIEW_WRITEOFF:
      return { ...state, viewBulkWriteOff: action.viewBulkWriteOff };
    case actionTypes.BULK_VIEW_WRITEOFF_ERROR:
      return { ...state, viewBulkWriteOffError: action.viewBulkWriteOffError };
    case actionTypes.SAVE_BULK_VIEW_WRITE_OFF:
      return { ...state, saveBulkViewWriteOffResult: action.saveBulkViewWriteOffResult };
      case actionTypes.BULK_VIEW_PAYEE_STATUS:
        return { ...state, changePayeeStatus: action.changePayeeStatus };
      case actionTypes.BULK_VIEW_PAYEE_STATUS_ERROR:
        return { ...state, changePayeeStatusError: action.changePayeeStatusError };
      case actionTypes.SAVE_BULK_VIEW_PAYEE_STATUS:
        return { ...state, savePayeeStatusResult: action.savePayeeStatusResult };
      case actionTypes.BULK_VIEW_WRITE_OFF_REEST:
        return { ...state, viewBulkWriteOffReest: action.viewBulkWriteOffReest };
        case actionTypes.RESET_LIENLEVY_UPDATE_RESPONSE:
        return { ...state, lienLevyUpdate: null };
        case actionTypes.RESET_GROSS_UPDATE_RESPONSE:
          return { ...state, GrossLevelEditData: null };
          case actionTypes.RESET_ADV_PAY_UPDATE_RESPONSE:
          return { ...state, advancePaymentResponse: null };
          case actionTypes.RESET_ACC_RECEIVABLE_UPDATE_RESPONSE:
          return { ...state, saveClaimsPayload: null };
        case actionTypes.BULK_VIEW_WRITE_OFF_REEST_ERROR:
          return { ...state, viewBulkWriteOffReestError: action.viewBulkWriteOffReestError };
          case actionTypes.SAVE_BULK_WRITE_OFF_REEST:
      return { ...state, saveBulkViewWriteOffReestResult: action.saveBulkViewWriteOffReestResult };
case actionTypes.RESET_AR_LINK_DATA:
return { ...state, arAdjustEligibleDetails: null, arTransferEligibleDetails: null, arReverseEligibleDetails: null, arReverseResults: null, writeOffData: null, writeOffRestablishmentData: null };
    default: return state;
  }
};

export default reducer;
