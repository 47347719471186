/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { Link, Prompt } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import { redirectSystemID } from '../../../../SharedModules/TabRedirections/TabRedirections';

import * as ClaimsReceivableConstants from './ClaimsReceivableConstants';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import ClaimReceivableTabDetail from './ClaimReceivableTabDetail';
import ClaimsReceivableHistory from './ClaimsReceivableHistory';
import ClaimsOriginating from './ClaimsOriginating';
import { withRouter } from 'react-router';
import reasonCodeCriteria from '../ARInquiryUpdate/ReasonCode.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWriteOffRestablishmentDetailsReset,
  getWriteOffRestablishmentDetails,
  getWriteOffDetailsReset,
  getDropdownDataAction,
  getPayeeIdType,
  ARAdjustEligibility,
  saveClaimsReceivable,
  ARReverseEligibility,
  searchClaimsReceivableAction,
  ARTransferEligibility,
  getWriteOffDetails,
  getDefaultRecoupmentDetails,
  getSubDropdownDataAction,
  agingDaysList,
  setAccReceivableUpdateResponse
} from '../../Store/Actions/accountReceivable/accountReceivableActions';
import '../../AccountReceivables.scss';
import moment from 'moment';
import numeral from 'numeral';
import * as ErrorConstants from './ErrorCheck';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  getUTCTimeStamp,
  compareTwoDatesGreaterThanOrEqual,
  validateDateMinimumValue,
  checkForValidDateString,
  generateUUID,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import {
  DialogContent,
  DialogActions
} from '../../../../SharedModules/Dialog/DialogUtilities';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';

import TableComponent from '../../../../SharedModules/Table/Table';
import dropdownCriteria from './ClaimsReceivable.json';
import {
  getAuditLogDetailsAccountingAction,
  getMaxFrequencyParameter
} from '../../../../SharedModules/store/Actions/SharedAction';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import Footer from '../../../../SharedModules/Layout/footer';
import Notes from '../../../../SharedModules/Notes/Notes';
import NoSaveMessage from '../../../../SharedModules/Errors/NoSaveMessage';
import Attachments from '../../../../SharedModules/Attachments/Attachments';
import { checkingDecimal, determineAmountToUse, getLongDescription, parseAmount } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import Radio from '@material-ui/core/Radio';
import { searchSystemParameterAction } from '../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import { isArray } from 'util';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { GET_APP_DROPDOWNS, GET_VOID_RSCD_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import { resetL2KeyDataFromStore } from '../../../../SharedModules/store/Actions/SharedAction';
/* ClaimsReceivable()
 * This component is used to display Claims Receivable details
 */
function ClaimsReceivable (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const searchValues = useSelector(
    state => state.accountReceivableState.searchValues);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [attchmentResponse,setAttachmentResponse] = React.useState([])
  
  const [focusCheck, setFocusCheck] = React.useState(false);
  const toPrintRef = useRef();
  const [writeOffSaveDetails, setWriteOffSaveDetails] = React.useState();
  const [ClaimsReceivableSaveWO, setClaimsReceivableSaveWO] = React.useState();
  const claimsReceivableDataWriteOff = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  const successOutputMessages = [];
  const optSelectOne = DEFAULT_DD_VALUE;
  const drpOpt6002 = 'General#6002';
  const dateTime = 'MM/DD/YYYY';
  const drpOpt3013 = 'Financial#3013';
  const drpOpt1009 = 'Financial#1009';
  // Attachment Integration Start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  /* Setting initial values */
  const [values, setValues] = React.useState({
    payeeType: '',
    payeeIdTypeCode: '',
    payeeId: '',
    payeePayerName: '',
    systemPayeeId: '',
    activityCode: '',
    fcn: '',
    ftTcn: '',
    establishedDate: '',
    lastDate: '',
    arLetter: '',
    arInDays: '',
    originalAmount: '',
    adjustedAmount: '',
    balanceAmount: '',
    reasonCode: '',
    recoupmentCode: DEFAULT_DD_VALUE,
    frequency: DEFAULT_DD_VALUE,
    payeeStatus: DEFAULT_DD_VALUE,
    installmentValue: '',
    percentValue: '',
    defaultOnPaymentSchedule: 'N',
    amountDue: '',
    originalAmountHistory: '',
    appliedAmount: '',
    balanceAmountHistory: '',
    payeeTypeDataDesc: '',
    activityTypeDataDesc: '',
    reasonCodeDesc: '',
    payeePayerIDTypeCodeDesc: '',
    arLetterDesc: '',
    paymentScheduleType: optSelectOne,
    recoupmentStartDate: null,
    numberofInstallments: '',
    interestCalculation: 'N',
    interestPercentage: '',
    paymentScheduleFrequency: optSelectOne,
    recoupedAmount: '0.00',
    installmentAmount: '',
    fiscalYear: '',
    categoryOfService: '',
    collocationCode: optSelectOne,
    federal: '',
    state: '',
    county: '',
    other: ''
  });
  const writeOffDetailsSave = useSelector(state => state.writeOffState.saveWriteOffData);
  const finRSNCD = 'Financial#F_RSN_CD';
  const [payeeIDTypeCodeData, setPayeeIDTypeCodeData] = React.useState([]);
  const [recoupmentCodeData, setRecoupmentCodeData] = React.useState([]);
  const [frequencyData, setFrequencyData] = React.useState([]);
  const [payoutRCodes, setPayoutRCodes] = React.useState([]);
  const [receiptRCodes, setReceiptRCodes] = React.useState([]);
  const [accountRRCodes, setAccountRRCodes] = React.useState([]);
  const [dispositionRCodes, setDispositionRCodes] = React.useState([]);
  const [adjustedReasonCode, setAdjustedReasonCode] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const ARLetterSentDetails = useSelector(
    state => state.accountReceivableTransferState.agingDaysList
  );
  const [arLetterSentData, setArLetterSentData] = React.useState([]);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [redirect, setRedirect] = React.useState(0);

  React.useEffect(() => {
    if (writeOffDetailsSave !== undefined) {
      setWriteOffSaveDetails(writeOffDetailsSave);
      dispatch(searchClaimsReceivableAction(writeOffDetailsSave.newFCN));
    }
  }, [writeOffDetailsSave]);

  React.useEffect(() => {
    if (claimsReceivableDataWriteOff !== undefined) { setClaimsReceivableSaveWO(claimsReceivableDataWriteOff); }
  }, [claimsReceivableDataWriteOff]);
  let claimsData = [];
  if (
    props.location.state &&
    props.location.state.claimsReceivableData !== undefined
  ) {
    claimsData = writeOffSaveDetails && writeOffSaveDetails.respcode === '01' && ClaimsReceivableSaveWO !== undefined
      ? ClaimsReceivableSaveWO : props.location.state.claimsReceivableData;

    var saveClick = false;
    claimsData.transactionCtrlNumber =
      isArray(claimsData.originatingClaimsList) &&
      claimsData.originatingClaimsList.length > 0
        ? claimsData.originatingClaimsList[0].transactionCtrlNumber
        : null;
  } else if (
    props.location.state &&
    props.location.state.payload !== undefined
  ) {
    claimsData = props.location.state.payload;
    claimsData.transactionCtrlNumber =
      isArray(claimsData.originatingClaimsList) &&
      claimsData.originatingClaimsList.length > 0
        ? claimsData.originatingClaimsList[0].transactionCtrlNumber
        : null;
  } else if (
    props.location.state &&
    props.location.state.payloadMCN !== undefined
  ) {
    claimsData = props.location.state.payloadMCN;
    claimsData.transactionCtrlNumber =
      isArray(claimsData.originatingClaimsList) &&
      claimsData.originatingClaimsList.length > 0
        ? claimsData.originatingClaimsList[0].transactionCtrlNumber
        : null;
  }

  // Audit Log Implementation starts
  const [close, setClose] = React.useState(true);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const onClickAuditLog = fcn => {
    dispatch(
      getAuditLogDetailsAccountingAction(fcn, '', false, 'auditLogData')
    );
  };
  const onClickAuditDialogLog = data => {
    setShowLogDialogTable(true);
    dispatch(
      getAuditLogDetailsAccountingAction(
        data.scheduleSK,
        '',
        true,
        'auditDialogData'
      )
    );
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  const auditLogData = useSelector(state => state.sharedState.auditLogData);
  const auditDialogData = useSelector(
    state => state.sharedState.auditDialogData
  );
  const [auditDialogRowData, setAuditDialogRowData] = React.useState([]);
  const [auditRowData, setAuditRowData] = React.useState([]);
  useEffect(() => {
    if (auditDialogData) {
      setAuditDialogRowData(auditDialogData);
    }
    if (auditLogData) {
      setAuditRowData(auditLogData);
    }
  }, [auditDialogData, auditLogData]);
  const [recoupmentTypeClicked, setRecoupmentTypeClicked] = React.useState(false);
  var defaultRecoupmentClicked = false;

  // Audit Log Implementation ends

  // Notes Integration
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [paymentScheduleData, setPaymentSchedleData] = React.useState([]);
  const [rowPaymentScheduleData, setRowPaymentScheduleData] = React.useState(
    []
  );
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [startDatePress, setStartDatePress] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [writeOff, setWriteOff] = React.useState(true);
  const [recoupmentDisabled, setRecoupmentDisabled] = React.useState(false);
  const [validationApply, setValidationApply] = React.useState(true);
  const [
    {
      showDueDateError,
      showAmountDueError,
      showRecoupmentCodeError,
      showInstallmentAmountError,
      showPercentAmountError,
      showStartDateError,
      showFrequencyError,
      showFiscalYearError,
      showCollocationCodeError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showDueDateErrorText,
      showAmountDueErrorText,
      showRecoupmentCodeErrorText,
      showInstallmentAmountErrorText,
      showPercentAmountErrorText,
      showStartDateErrorText,
      showFrequencyErrorText,
      showFiscalYearErrorText,
      showCollocationCodeErrorText
    },
    setShowErrorText
  ] = React.useState('');
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  let paramsData;
  const reasonCodeMapdropdown = useSelector(
    state => state.accountReceivableState.subdropdownData
  );
  useEffect(() => {
    if (
      reasonCodeMapdropdown &&
      reasonCodeMapdropdown.listObj &&
      reasonCodeMapdropdown.listObj[finRSNCD]
    ) {
      setReasonCodeData(reasonCodeMapdropdown.listObj[finRSNCD]);
      for (var key in reasonCodeMapdropdown.listObj[finRSNCD]) {
        if (
          reasonCodeMapdropdown.listObj[finRSNCD][key].code ===
          values.reasonCode
        ) {
          setValues({
            ...values,
            reasonCodeDesc:
              reasonCodeMapdropdown.listObj[finRSNCD][key]
                .description
          });
        }
      }
    }
  }, [reasonCodeMapdropdown]);
  useEffect(() => {
    if (claimsData) {
      paramsData = claimsData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      if (
        paramsData &&
        paramsData.attachmentsList &&
        paramsData.attachmentsList.length > 0
      ) {
        const claimsReceivableAttachmentList = paramsData.attachmentsList;
        setAttachmentsTableData(claimsReceivableAttachmentList);
      }
      setAttachmentFile([]);
    }
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: optSelectOne,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End
  const checkARREverse = values => {
    dispatch(ARReverseEligibility(values));
  };
  const reverseDetails = useSelector(
    state => state.accountReceivableState.arReverseEligibleDetails
  );
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const onClaimsReceivableSave = (value, index) =>
    dispatch(
      saveClaimsReceivable(
        value,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : null
      )
    );
  const transferDetails = useSelector(
    state => state.accountReceivableState.arTransferEligibleDetails
  );
  const adjustDetails = useSelector(
    state => state.accountReceivableState.arAdjustEligibleDetails
  );
  const dropdown = useSelector(
    state => state.accountReceivableState.dropdownData
  );

  useEffect(() => {
    if (
      ARLetterSentDetails &&
      ARLetterSentDetails.systemFailure === undefined
    ) {
      setArLetterSentData(ARLetterSentDetails);
      ARLetterSentDetails.map(var1 => {
        if (values.arLetter === var1.code) {
          setValues({ ...values, arLetterDesc: var1.description });
        }
      });
    }
  }, [ARLetterSentDetails]);

  const systemErrorMessges = [];
  const saveClaimsReceivableResponse = useSelector(
    state => state.accountReceivableState.saveClaimsPayload
  );
  const onReloadClaimsReceivable = values =>
    dispatch(searchClaimsReceivableAction(values));
  const claimsReceivableData = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  const PayeeIDCodeAPI = payee => dispatch(getPayeeIdType(payee));
  const [frequencyDataDropdown, setFrequencyDataDropdown] = React.useState([]);
  const [
    selectedRecoupmentStartDate,
    setSelectedRecoupmentStartDate
  ] = React.useState(null);
  const [retainSelectedDueDate, setRetainSelectedDueDate] = React.useState(
    null
  );
  const [paymentSettingChanged, setPaymentSettingChanged] = React.useState(
    false
  );
  const handleRecoupmentStartDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentStartDate(date);
    setPaymentSettingChanged(generateUUID());
    setPaymentSchedleData([]);
  };
  const [paymentScheduleVO, setPaymentScheduleVO] = React.useState({
    installmentAmount: '',
    recoupmentAmount: '0.00'
  });
  const handleChangePaymentSchedule = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === 'installmentAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
      } else {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
    } else {
    setPaymentScheduleVO({
      ...paymentScheduleVO,
      [name]: event.target.value
    });
  }
};
  const [paymentScheduleChanged, setPaymentScheduleChanged] = React.useState(
    false
  );
  const [paymentInd, setpaymentInd] = React.useState(false);
  useEffect(() => {
    if (paymentScheduleChanged) {
      setPaymentSchedleData([]);
      setValues({
        ...values,
        recoupmentStartDate: null,
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      });
      setSelectedRecoupmentStartDate(null);
      setpaymentInd(true);
    }
  }, [paymentScheduleChanged]);

  useEffect(() => {
    if (paymentSettingChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
    }
  }, [paymentSettingChanged]);
  const [
    paymentScheduleTypeError,
    setPaymentScheduleTypeError
  ] = React.useState('');
  const [
    recoupmentStartDateError,
    setRecoupmentStartDateError
  ] = React.useState('');
  const [
    numberofInstallmentError,
    setNumberofinstallmentError
  ] = React.useState('');
  const [frequencyError, setFrequencyError] = React.useState('');
  const [interestCalError, setInstallmentCalError] = React.useState('');
  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getMaxFrequencyParameter(systemParameter));
  const frequencyResult = useSelector(
    state => state.sharedState.frequencyParameter
  );
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);
  const [retainPaymentSchedule, setRetainPaymentSchedule] = React.useState({});
  const [paymentScheduleTypeData, setPaymentScheduleTypeData] = React.useState(
    []
  );
  const [frequencyRecoupData, setFrequencyRecoupData] = React.useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const onGetRecoupmetDetails = values => {
    dispatch(getDefaultRecoupmentDetails(values));
  };
  const getRecoupmetDetails = useSelector(
    state => state.accountReceivableState.fetchRecoupmetDetails
  );
  const payeeIDTypeCodeDetails = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );
  const userInquiryPrivileges = !global.globalIsReadOnly();

  useEffect(() => {
    if (
      claimsReceivableData &&
      claimsReceivableData.attachmentsList &&
      claimsReceivableData.attachmentsList.length > 0
    ) {
      const claimsReceivableAttachmentList =
        claimsReceivableData.attachmentsList;
      setAttachmentsTableData(claimsReceivableAttachmentList);
    }
    if (
      claimsReceivableData &&
      claimsReceivableData.recoupmentVO &&
      claimsReceivableData.recoupmentVO.startDateStr
    ) {
      setSelectedStartDate(claimsReceivableData.recoupmentVO.startDateStr);
    }
    setAttachmentFile([]);
    if (claimsReceivableData) {
      const scheduleDataInitial = claimsReceivableData.schedulePaymentList.map(
        (option) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);
    }
  }, [claimsReceivableData]);

  // Attachment integration End

  /* Display success/error message after updating claims receivable details based on the response and response description coming from the API */
  useEffect(() => {
    if (
      saveClaimsReceivableResponse &&
      !saveClaimsReceivableResponse.systemFailure
    ) {
      if (
        saveClaimsReceivableResponse &&
        saveClaimsReceivableResponse.respcode === '03' &&
        saveClaimsReceivableResponse.respdesc === ''
      ) {
        setSystemSuccesMessages([]);

        const ErrorMessages = [];
        ErrorMessages.push(ClaimsReceivableConstants.TRANSACTION_ERROR);
        setErrorMessages(ErrorMessages);
      }
      if (
        saveClaimsReceivableResponse &&
        saveClaimsReceivableResponse.respcode === '03' &&
        saveClaimsReceivableResponse.respdesc !== ''
      ) {
        setSystemSuccesMessages([]);

        const ErrorMessages = [];
        ErrorMessages.push(ClaimsReceivableConstants.TRANSACTION_ERROR);
        setErrorMessages(ErrorMessages);
      }
      if (
        saveClaimsReceivableResponse &&
        saveClaimsReceivableResponse.respcode === '01' &&
        saveClaimsReceivableResponse.respdesc !== ''
      ) {
        const success = AppConstants.SAVE_SUCCESS;
        setAllowNavigation(false);
        setErrorMessages([]);
        setSystemSuccesMessages([]);

        successOutputMessages.push(success);
        setSystemSuccesMessages(successOutputMessages);
        setFocusCheck(false);
        onReloadClaimsReceivable(claimsData.receivableFCN);
        const valuetoredirect = redirect + 1;
        setRedirect(valuetoredirect);
      }
      if (
        saveClaimsReceivableResponse &&
        saveClaimsReceivableResponse.respcode === '02' &&
        saveClaimsReceivableResponse.respdesc !== ''
      ) {
        setSystemSuccesMessages([]);

        setErrorMessages(saveClaimsReceivableResponse.errorMessages);
      }
    }
    if (
      saveClaimsReceivableResponse &&
      saveClaimsReceivableResponse.systemFailure
    ) {
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [saveClaimsReceivableResponse]);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const onVoidRscd = (values) => dispatch(GET_VOID_RSCD_DROPDOWNS(values));
  useEffect(() => {
    if (claimsData && claimsData.payeePayerTypeCode) {
      PayeeIDCodeAPI(claimsData.payeePayerTypeCode.split('-')[0]);
    }

    dispatch(getSubDropdownDataAction(reasonCodeCriteria));
    dispatch(agingDaysList());
    onDropdowns(reasonCodeCriteria);
    onVoidRscd([Dropdowns.ADJST_RSN_CD]);
  }, []);
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  React.useEffect(() => {
    if (
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
  }, [searchSingleFinancialEntityRes]);

  // System Payee redirection ends
  useEffect(() => {
    if (payeeIDTypeCodeDetails && payeeIDTypeCodeDetails.payeeIdTypeCode) {
      setPayeeIDTypeCodeData(payeeIDTypeCodeDetails.payeeIdTypeCode);
      payeeIDTypeCodeDetails.payeeIdTypeCode.map(var1 => {
        if (var1.code === values.payeeIdTypeCode) {
          values.payeePayerIDTypeCodeDesc = var1.description;
        }
      });
    }
  }, [payeeIDTypeCodeDetails]);

  const checkARTransfer = values => {
    dispatch(ARTransferEligibility(values));
  };
  const checkARAdjust = values => {
    dispatch(ARAdjustEligibility(values));
  };
  const fetchWriteOffData = values => {
    dispatch(getWriteOffDetails(values));
  };
  const writeOffDetails = useSelector(
    state => state.accountReceivableState.writeOffData
  );

  const fetchWriteOffReEstablishmentData = values => {
    dispatch(getWriteOffRestablishmentDetails(values));
  };
  const writeOffReEstablishmentDetails = useSelector(
    state => state.accountReceivableState.writeOffRestablishmentData
  );
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const voidRscdDropdown = useSelector(state => state.appDropDowns.voidRscdDropdown);
  const [color, setColor] = React.useState('');
  var errorMessagesArray = [];
  const popUpErrorMessagesArray = [];
  const [value, setValue] = React.useState(0);
  const [add, setAdd] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (open) {
      document.getElementById('form_pop_up').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [open]);

  const headCells = [
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: true,
      label: 'Due Date',
      isDate: true,
      width: 100
    },
    {
      id: 'installmentAmount',
      numeric: false,
      disablePadding: false,
      label: 'Installment Amount',
      isBalance: true,
      width: 160
    },
    {
      id: 'recoupedAmount',
      numeric: false,
      disablePadding: false,
      label: 'Recouped Amount',
      isBalance: true,
      width: 260
    }
  ];
  useEffect(() => {
    if (
      reverseDetails &&
      reverseDetails.reversalVO !== undefined &&
      reverseDetails.reversalVO !== null
    ) {
      claimsData.reversalVO = reverseDetails.reversalVO;
      props.history.push({
        pathname: '/ARReverse',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (reverseDetails && reverseDetails.reversalVO === null) {
      setErrorMessages([ErrorConstants.REVERSE_ERROR]);
    }
    if (reverseDetails && reverseDetails.SystemError === true) {
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
    if (
      reverseDetails &&
      reverseDetails.errorMessgaes &&
      reverseDetails.errorMessgaes !== null &&
      reverseDetails.errorMessgaes.length > 0
    ) {
      setErrorMessages(reverseDetails.errorMessgaes);
    }
  }, [reverseDetails]);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog(values.fcn);
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditDialogRowData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditDialogRowData([]);
    }
  }, [auditLogRow]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDropdownData(dropdownCriteria);
    getPayeeIdFromSystemParameter({
      paramNumber: 9025,
      functionalArea: 'F1',
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      asOfDate: moment(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      ).format('YYYY-DD-MM')
    });
  }, []);

  useEffect(() => {
    if (
      transferDetails &&
      transferDetails.transferVO !== null &&
      transferDetails.transferVO !== undefined
    ) {
      claimsData.transferVO = transferDetails.transferVO;
      props.history.push({
        pathname: '/ARTransfer',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (transferDetails && transferDetails.SystemError === true) {
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
    if (
      transferDetails &&
      transferDetails.errorMessgaes &&
      transferDetails.errorMessgaes !== null &&
      transferDetails.errorMessgaes.length > 0
    ) {
      setErrorMessages(transferDetails.errorMessgaes);
    }
  }, [transferDetails]);
  useEffect(() => {
    if (
      adjustDetails &&
      adjustDetails.adjustmentVO !== null &&
      adjustDetails.adjustmentVO !== undefined
    ) {
      claimsData.adjustmentVO = adjustDetails.adjustmentVO;
      props.history.push({
        pathname: '/ARAdjust',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (adjustDetails && adjustDetails.SystemError === true) {
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
    if (
      adjustDetails &&
      adjustDetails.errorMessgaes &&
      adjustDetails.errorMessgaes !== null &&
      adjustDetails.errorMessgaes.length > 0
    ) {
      setErrorMessages(adjustDetails.errorMessgaes);
    }
  }, [adjustDetails]);

  function ARAdjust () {
    validateClaimsReceivableFields();
    if (errorMessagesArray.length === 0) {
      if (
        claimsData.balanceAmount === '0' ||
        claimsData.balanceAmount === '0.0' ||
        claimsData.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.ADJUSTED_BALNACE_AMOUNT_ZERO_ERROR
        );
        setErrorMessages(systemErrorMessges);
      } else {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
          selectedStartDate
        );
        claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
          '-'
        )[0];
        claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
          '-'
        )[0];
        claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
        checkARAdjust(claimsData);
      }
    }
  }

  useEffect(() => {
    if (
      writeOffDetails &&
      writeOffDetails.length !== 0 &&
      !writeOffDetails.SystemError
    ) {
      if (writeOffDetails.errorMessgaes !== null) {
        setErrorMessages([writeOffDetails.errorMessgaes[0]]);
      } else {
        props.history.push({
          pathname: '/WriteOff',
          state: { writeOffDetails, values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }
    if (writeOffDetails && writeOffDetails.SystemError === true) {
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffDetails]);

  useEffect(() => {
    if (
      writeOffReEstablishmentDetails &&
      !writeOffReEstablishmentDetails.SystemError &&
      writeOffReEstablishmentDetails.length !== 0
    ) {
      if (writeOffReEstablishmentDetails.errorMessgaes !== null) {
        setErrorMessages([writeOffReEstablishmentDetails.errorMessgaes[0]]);
      } else {
        props.history.push({
          pathname: '/WriteOffReEstablishment',
          state: { writeOffReEstablishmentDetails, values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }
    if (
      writeOffReEstablishmentDetails &&
      writeOffReEstablishmentDetails.SystemError === true
    ) {
      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffReEstablishmentDetails]);

  function ARReverse () {
    validateClaimsReceivableFields();
    if (errorMessagesArray.length === 0) {
      claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
        selectedStartDate
      );
      claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
        '-'
      )[0];
      claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
        '-'
      )[0];
      claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
      const adjustedAmount = parseAmount(claimsData.claimsReceivableDetailsVO.adjustedArAmt);
      const originalAmount = parseAmount(claimsData.claimsReceivableDetailsVO.originalAmt);
      const balanceAmount = parseAmount(claimsData.claimsReceivableDetailsVO.balanceAmount);
      const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);
      if (balanceAmount === amountToUse) {
        checkARREverse(claimsData);
      } else {
        setErrorMessages([ErrorConstants.REVERSE_ERROR]);
      }
    }
  }
  function ARTransfer () {
    validateClaimsReceivableFields();
    if (errorMessagesArray.length === 0) {
      if (
        claimsData.balanceAmount === '0' ||
        claimsData.balanceAmount === '0.0' ||
        claimsData.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.TRANSFER_BALNACE_AMOUNT_ZERO_ERROR
        );
        setErrorMessages(systemErrorMessges);
      } else {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        modifyClaimsData();
        checkARTransfer(claimsData);
      }
    }
  }

  const formatDecimalField = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0,0.00')
      });
    }
  };
  const removeFormatDecimalFieldPercent = () => () => {
    if (values.interestPercentage) {
      setValues({
        ...values,
        interestPercentage: numeral(values.interestPercentage).format('0.00')
      });
    }
  };
  const formatPercent = name => event => {
    if (values.percentValue) {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0.00')
      });
    }
  };
  const removeFormatDecimalFieldInstallment = () => () => {
    if (values.installmentValue) {
      setValues({
        ...values,
        installmentValue: numeral(values.installmentValue).format('0.00')
      });
    }
  };

  const formatDecimalFieldInstallment = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0,0.00'
        )
      });
    }
  };

  const removeFormatDecimalFieldAmountDue = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0.00'
        )
      });
    }
  };

  const calculateSchedule = () => {
    const errorArray = [];
    var originalArAmountRequired = false;
    var originalArAmountRequiredText = '';
    let paymentScheduleTypeText = '';
    let recoupmentStartDateText = '';
    let noOfInstallmentText = '';
    let frequencyText = '';
    let calPercentText = '';
    setPaymentScheduleTypeError('');
    setRecoupmentStartDateError('');
    setFrequencyError('');
    setNumberofinstallmentError('');
    setInstallmentCalError('');
    if (values.paymentScheduleType === optSelectOne) {
      paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (values.originalAmount === '' || values.originalAmount === undefined) {
      originalArAmountRequired = true;
      originalArAmountRequiredText = ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorArray.push(originalArAmountRequiredText);
    }
    if (!selectedRecoupmentStartDate) {
      recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      if (!moment(startDate).isValid()) {
        recoupmentStartDateText = ErrorConstants.DATA_INVALID;
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format(dateTime))
      ) {
        recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format(dateTime))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          recoupmentStartDateText =
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (values.paymentScheduleFrequency === optSelectOne) {
      frequencyText = ErrorConstants.FREQUENCY;
      setFrequencyError(ErrorConstants.FREQUENCY);
      errorArray.push(ErrorConstants.FREQUENCY);
    }
    if (values.numberofInstallments === '') {
      noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
      setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
    }
    if (values.numberofInstallments !== '') {
      if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
        noOfInstallmentText =
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.';
        setNumberofinstallmentError(
          ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
        );
        errorArray.push(
          ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
        );
      }
    }
    if (
      values.interestCalculation === 'Y' &&
      values.interestPercentage === ''
    ) {
      calPercentText = ErrorConstants.INTEREST_PERCENT;
      setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
      errorArray.push(ErrorConstants.INTEREST_PERCENT);
    }
    if (
      values.interestPercentage &&
      values.interestPercentage !== '' &&
      values.interestPercentage
        .toString()
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
      values.paymentScheduleType === 'A'
    ) {
      calPercentText = ErrorConstants.DATA_INVALID;
      setInstallmentCalError(ErrorConstants.DATA_INVALID);
      errorArray.push(ErrorConstants.DATA_INVALID);
    }
    if (errorArray.length === 0) {
      const scheduleArray = [];
      const amountStep = numeral(
        Number(
          Number(
            values.balanceAmount
              .toString()
              .replace(/,/g, '')
              .replace('$', '')
          ) / Number(values.numberofInstallments)
        )
      ).format('0.00');
      const numberOfRows = Number(
        numeral(
          Number(
            values.balanceAmount
              .toString()
              .replace(/,/g, '')
              .replace('$', '')
          ) / Number(amountStep)
        ).format('0')
      );
      const dateTemp = new Date(selectedRecoupmentStartDate);
      let daysStep = 0;
      switch (values.paymentScheduleFrequency) {
        case '2':
          daysStep = 7;
          break;
        case '3':
          daysStep = 14;
          break;
        case '4':
          daysStep = 30;
          break;
      }
      let sumOfInstallmentAmount = 0;
      for (let i = 0; i < numberOfRows; i++) {
        let value;
        if (i === 0) {
          value = {
            scheduleSK: null,
            dueDate: moment(dateTemp.setDate(dateTemp.getDate())).format(
              dateTime
            ),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        } else {
          value = {
            scheduleSK: null,
            dueDate: moment(
              dateTemp.setDate(dateTemp.getDate() + daysStep)
            ).format(dateTime),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        }
        sumOfInstallmentAmount = numeral(
          Number(numeral(sumOfInstallmentAmount).format('0.00')) +
            Number(numeral(amountStep).format('0.00'))
        ).format('0.00');
        scheduleArray.push(value);
      }
      if (
        numeral(
          values.balanceAmount
            .replace('$', '')
            .replace(/,/g, '')
            .trim()
        ).format('0.00') !== numeral(sumOfInstallmentAmount).format('0.00')
      ) {
        scheduleArray[numberOfRows - 1].installmentAmount = numeral(
          parseFloat(
            numeral(scheduleArray[numberOfRows - 1].installmentAmount).format(
              '0.00'
            )
          ) +
            (parseFloat(
              numeral(
                values.balanceAmount
                  .replace('$', '')
                  .replace(/,/g, '')
                  .trim()
              ).format('0.00')
            ) -
              parseFloat(numeral(sumOfInstallmentAmount).format('0.00')))
        ).format('0,0.00');
      }

      setPaymentSchedleData(scheduleArray);
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
      setErrorMessages([]);
    } else {
      window.scrollTo(0, 0);
      setShowError({
        showOriginalArAmountPopError: originalArAmountRequired
      });
      setShowErrorText({
        showOriginalArAmountPopErrorText: originalArAmountRequiredText
      });
      setPaymentScheduleTypeError(paymentScheduleTypeText);
      setRecoupmentStartDateError(recoupmentStartDateText);
      setFrequencyError(frequencyText);
      setNumberofinstallmentError(noOfInstallmentText);
      setInstallmentCalError(calPercentText);
      setErrorMessages(errorArray);
    }
  };

  const NavigateToWriteOff = () => {
    validateClaimsReceivableFields();
    if (errorMessagesArray.length === 0) {
      if (
        claimsData.balanceAmount === '0' ||
        claimsData.balanceAmount === '0.0' ||
        claimsData.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.WRITE_OFF_BALNACE_AMOUNT_ZERO_ERROR
        );
        setErrorMessages(systemErrorMessges);
      } else {
        setErrorMessages([]);
        setSystemSuccesMessages([]);

        dispatch(getWriteOffDetailsReset());
        modifyClaimsData();
        fetchWriteOffData(claimsData);
      }
    }
  };

  const NavigateToWriteOffRestablish = () => {
    validateClaimsReceivableFields();
    if (errorMessagesArray.length === 0) {
      if (
        claimsData.balanceAmount === '0' ||
        claimsData.balanceAmount === '0.0' ||
        claimsData.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(ErrorConstants.BALNACE_AMOUNT_ZERO_ERROR);
        setErrorMessages(systemErrorMessges);
      } else {
        dispatch(getWriteOffRestablishmentDetailsReset());
        modifyClaimsData();
        fetchWriteOffReEstablishmentData(claimsData);
      }
    }
  };

  const modifyClaimsData = () => {
    claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
      selectedStartDate
    );
    claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
      '-'
    )[0];
    claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split('-')[0];
    claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
  };
  const [COSData, setCOSData] = React.useState([]);
  const [aRRSNCDData, setARRSNCDData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      let payeeTypeDataDesc = null;
      let activityTypeDataDesc = null;
      if (dropdown.listObj['Claims#1020']) {
        setCOSData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Financial#6015']) {
        setARRSNCDData(dropdown.listObj['Financial#6015']);
      }
      if (dropdown.listObj['Financial#3200']) {
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj[drpOpt6002]) {
        setPayeeTypeData(dropdown.listObj[drpOpt6002]);
        dropdown.listObj[drpOpt6002].map(var1 => {
          if (var1.code === values.payeeType) {
            payeeTypeDataDesc = var1.description;
          }
        });
      }
      if (dropdown.listObj[drpOpt3013]) {
        setActivityTypeData(dropdown.listObj[drpOpt3013]);
        dropdown.listObj[drpOpt3013].map(var1 => {
          if (var1.code === values.activityCode) {
            activityTypeDataDesc = var1.description;
          }
        });
      }
      if (dropdown.listObj['Financial#1007']) {
        setRecoupmentCodeData(dropdown.listObj['Financial#1007']);
      }
      if (dropdown.listObj[drpOpt1009]) {
        setFrequencyData(dropdown.listObj[drpOpt1009]);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setPayoutRCodes(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#3007']) {
        setReceiptRCodes(dropdown.listObj['Financial#3007']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setAccountRRCodes(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3009']) {
        setDispositionRCodes(dropdown.listObj['Financial#3009']);
      }
      if (dropdown.listObj['Financial#8']) {
        setAdjustedReasonCode(dropdown.listObj['Financial#8']);
      }
      if (dropdown.listObj[drpOpt1009]) {
        setFrequencyDataDropdown(dropdown.listObj[drpOpt1009]);
      }
      if (dropdown.listObj['Financial#6017']) {
        setPaymentScheduleTypeData(dropdown.listObj['Financial#6017']);
      }
      if (dropdown.listObj['Financial#6016']) {
        setFrequencyRecoupData(dropdown.listObj['Financial#6016']);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
      setValues({
        ...values,
        payeeTypeDataDesc: payeeTypeDataDesc,
        activityTypeDataDesc: activityTypeDataDesc
      });
    }
  }, [dropdown]);

  /* If balance amount is "0"...
   * Disabled recoupment fields
   * Setting recoupment fields with default values coming from the API
   * Else Enabled recoupment fields with default values as "Please Select One" and blank
   */
  const [reasonCodeDD, setReasonCodeDD] = React.useState([]);
  useEffect(() => {
    if (
      payoutRCodes.length > 0 &&
      receiptRCodes.length > 0 &&
      accountRRCodes.length > 0 &&
      dispositionRCodes.length > 0
    ) {
      payoutRCodes.map(pvalue => {
        if (
          values.reasonCode === pvalue.code ||
          values.reasonCodeDesc === pvalue.description
        ) {
          setReasonCodeDD(payoutRCodes);
          setValues({ ...values, reasonCodeLongDesc: value.longDescription });
        }
      });
      receiptRCodes.map(rvalue => {
        if (
          values.reasonCode === rvalue.code ||
          values.reasonCodeDesc === rvalue.description
        ) {
          setReasonCodeDD(receiptRCodes);
          setValues({ ...values, reasonCodeLongDesc: value.longDescription });
        }
      });
      accountRRCodes.map(avalue => {
        if (
          values.reasonCode === avalue.code ||
          values.reasonCodeDesc === avalue.description
        ) {
          setReasonCodeDD(accountRRCodes);
          setValues({ ...values, reasonCodeLongDesc: value.longDescription });
        }
      });
      dispositionRCodes.map(dvalue => {
        if (
          values.reasonCode === dvalue.code ||
          values.reasonCodeDesc === dvalue.description
        ) {
          setReasonCodeDD(dispositionRCodes);
          setValues({ ...values, reasonCodeLongDesc: value.longDescription });
        }
      });
      for (var historyValue in claimsData.historyList) {
        if (claimsData.historyList[historyValue].receivableFCN) {
          if (
            claimsData.historyList[historyValue].receivableFCN.length === 14
          ) {
            payoutRCodes.map(pRCValue => {
              if (
                claimsData.historyList[historyValue].reasonCode ===
                pRCValue.code
              ) {
                claimsData.historyList[historyValue].activityType = 'Payout';
              }
              if (
                claimsData.historyList[historyValue].reasonCode ===
                pRCValue.code
              ) {
                claimsData.historyList[historyValue].reasonCodeLongDesc =
                pRCValue.longDescription;
              }
              if (
                values.reasonCode === pRCValue.code ||
                values.reasonCodeDesc === pRCValue.description
              ) {
                setReasonCodeDD(payoutRCodes);
                setValues({
                  ...values,
                  reasonCodeLongDesc: pRCValue.longDescription
                });
              }
            });
            receiptRCodes.map(rRCvalue => {
              if (
                claimsData.historyList[historyValue].reasonCode ===
                rRCvalue.code
              ) {
                claimsData.historyList[historyValue].activityType = 'Receipt';
              }
              if (
                claimsData.historyList[historyValue].reasonCode ===
                rRCvalue.code
              ) {
                claimsData.historyList[historyValue].reasonCodeLongDesc =
                rRCvalue.longDescription;
                setReasonCodeDD(receiptRCodes);
              }
              if (
                values.reasonCode === rRCvalue.code ||
                values.reasonCodeDesc === rRCvalue.description
              ) {
                setReasonCodeDD(receiptRCodes);
                setValues({
                  ...values,
                  reasonCodeLongDesc: rRCvalue.longDescription
                });
              }
            });
            accountRRCodes.map(aRCvalue => {
              if (
                claimsData.historyList[historyValue].reasonCode ===
                aRCvalue.code
              ) {
                claimsData.historyList[historyValue].activityType = 'AccountR';
              }
              if (
                claimsData.historyList[historyValue].reasonCode ===
                aRCvalue.code
              ) {
                claimsData.historyList[historyValue].reasonCodeLongDesc =
                aRCvalue.longDescription;
              }
              if (
                values.reasonCode === aRCvalue.code ||
                values.reasonCodeDesc === aRCvalue.description
              ) {
                setReasonCodeDD(accountRRCodes);
                setValues({
                  ...values,
                  reasonCodeLongDesc: aRCvalue.longDescription
                });
              }
            });
            dispositionRCodes.map(dRCvalue => {
              if (
                claimsData.historyList[historyValue].reasonCode ===
                dRCvalue.code
              ) {
                claimsData.historyList[historyValue].activityType = 'AccountR';
              }
              if (
                claimsData.historyList[historyValue].reasonCode ===
                dRCvalue.code
              ) {
                claimsData.historyList[historyValue].reasonCodeLongDesc =
                dRCvalue.longDescription;
              }
              if (
                values.reasonCode === dRCvalue.code ||
                values.reasonCode === value.description
              ) {
                setReasonCodeDD(dispositionRCodes);
                setValues({
                  ...values,
                  reasonCodeLongDesc: value.longDescription
                });
              }
            });
          } else {
            claimsData.historyList[historyValue].activityType = 'FTTCN';
            claimsData.historyList[historyValue].reasonCodeLongDesc =
              claimsData.historyList[historyValue].reasonCode;
            setReasonCodeDD(reasonCodeData);
          }
        }
      }

      for (var historyValue in claimsData.originatingClaimsList) {
        if (
          claimsData.originatingClaimsList[historyValue].transactionCtrlNumber
        ) {
          if (
            claimsData.originatingClaimsList[historyValue].transactionCtrlNumber
              .length === 14
          ) {
            payoutRCodes.map(value => {
              if (
                claimsData.originatingClaimsList[historyValue]
                  .accountReceivableReasonCode === value.code
              ) {
                claimsData.originatingClaimsList[historyValue].activityType =
                  'Payout';
              }
            });
            receiptRCodes.map(value => {
              if (
                claimsData.originatingClaimsList[historyValue]
                  .accountReceivableReasonCode === value.code
              ) {
                claimsData.originatingClaimsList[historyValue].activityType =
                  'Receipt';
              }
            });
            accountRRCodes.map(value => {
              if (
                claimsData.originatingClaimsList[historyValue]
                  .accountReceivableReasonCode === value.code
              ) {
                claimsData.originatingClaimsList[historyValue].activityType =
                  'AccountR';
              }
            });
            dispositionRCodes.map(value => {
              if (
                claimsData.originatingClaimsList[historyValue]
                  .accountReceivableReasonCode === value.code
              ) {
                claimsData.originatingClaimsList[historyValue].activityType =
                  'AccountR';
              }
            });
          } else {
            claimsData.originatingClaimsList[historyValue].activityType =
              'FTTCN';
          }
        }
      }
    }
  }, [payoutRCodes, receiptRCodes, accountRRCodes, dispositionRCodes]);
  useEffect(() => {
    if (
      claimsData.balanceAmount === '0' ||
      claimsData.balanceAmount === '0.0' ||
      claimsData.balanceAmount === '0.00'
    ) {
      setDisabled(true);
      setRecoupmentDisabled(true);
      setFieldValues(
        claimsData.recoupmentVO.recoupmentTypeCode,
        claimsData.recoupmentVO.installmentAmount == null
          ? ''
          : claimsData.recoupmentVO.installmentAmount,
        claimsData.recoupmentVO.percentageOriginalAmount,
        claimsData.recoupmentVO.frequency,
        '',
        'N',
        {
          paymentScheduleType: claimsData.paymentScheduleType
            ? claimsData.paymentScheduleType
            : optSelectOne,
          recoupmentStartDate: claimsData.recoupmentStartDate
            ? claimsData.recoupmentStartDate
            : null,
          numberofInstallments: claimsData.numberofInstallments
            ? claimsData.numberofInstallments
            : '',
          interestCalculation: claimsData.interestCalculation
            ? claimsData.interestCalculation
            : 'N',
          interestPercentage: claimsData.interestPercentage
            ? numeral(claimsData.interestPercentage).format('0,0.00')
            : '',
          paymentScheduleFrequency: claimsData.paymentScheduleFrequency
            ? claimsData.paymentScheduleFrequency
            : optSelectOne
        }
      );
      setSelectedStartDate(claimsData.recoupmentVO.startDateStr);
      setValidationApply(false);
    } else {
      setFieldsBasedOnRecoupmentType(
        claimsData.recoupmentVO.recoupmentTypeCode
      );
      setFieldValues(
        claimsData.recoupmentVO.recoupmentTypeCode,
        claimsData.recoupmentVO.installmentAmount == null
          ? ''
          : claimsData.recoupmentVO.installmentAmount,
        claimsData.recoupmentVO.percentageOriginalAmount == null
          ? ''
          : claimsData.recoupmentVO.percentageOriginalAmount,
        claimsData.recoupmentVO.frequency,
        '',
        'N',
        {
          paymentScheduleType: claimsData.paymentScheduleType
            ? claimsData.paymentScheduleType
            : optSelectOne,
          recoupmentStartDate: claimsData.recoupmentStartDate
            ? claimsData.recoupmentStartDate
            : null,
          numberofInstallments: claimsData.numberofInstallments
            ? claimsData.numberofInstallments
            : '',
          interestCalculation: claimsData.interestCalculation
            ? claimsData.interestCalculation
            : 'N',
          interestPercentage: claimsData.interestPercentage
            ? numeral(claimsData.interestPercentage).format('0,0.00')
            : '',
          paymentScheduleFrequency: claimsData.paymentScheduleFrequency
            ? claimsData.paymentScheduleFrequency
            : optSelectOne
        }
      );
      setSelectedStartDate(claimsData.recoupmentVO.startDateStr);

      const scheduleDataInitial = claimsData.schedulePaymentList.map(
        (option) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );

      setPaymentSchedleData(scheduleDataInitial);
    }
    if (claimsData.activityTypeCode === 'WO') {
      setWriteOff(false);
    }
    if (
      claimsData.payeePayerIDTypeCode &&
      claimsData.payeePayerIDTypeCode !== ''
    ) {
      PayeeIDCodeAPI(claimsData.payeePayerTypeCode.split('-')[0]);
    }
  }, [claimsData]);

  const [manualEditFlag, setManualEditFlag] = React.useState(false);
  useEffect(() => {
    if (paymentScheduleData && paymentScheduleData.length > 0) {
      paymentScheduleData.map(value => {
        if (
          value.recoupedAmount &&
          numeral(value.recoupedAmount).format('0.00') !== '0.00'
        ) {
          setManualEditFlag(true);
        }
      });
    }
  }, [paymentScheduleData]);

  /* This function is used to set start date using date picker */
  const handleStartDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedStartDate(date);
    setStartDatePress(false);
  };

  const systemParamValuetoPercentage = {
    parameterNumber: '9005',
    paramNumber: null,
    functionalArea: 'F1',
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };

  const searchSystemParameterPercentage = value => {
    dispatch(searchSystemParameterAction(value));
  };

  const systemParameterPayloadPercentage = useSelector(
    state => state.appConfigState.systemParameterState.payload
  );

  useEffect(() => {
    if (
      systemParameterPayloadPercentage &&
      systemParameterPayloadPercentage.length > 0
    ) {
      const systemParameterPercentage =
        systemParameterPayloadPercentage[0].systemParameterDetail[0].valuePCT *
        100;
      setSelectedStartDate(claimsData.recoupmentVO
        ? claimsData.recoupmentVO.startDateStr
        : new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
      setFieldValues('D', '', systemParameterPercentage, '1', '', 'N');
      setDisabled(false);
    }
  }, [systemParameterPayloadPercentage]);

  /* This function is used to set recoupment fields based on the recoupment type code selected */
  const setFieldsBasedOnRecoupmentType = recoupmentTypeCode => {
    setPaymentSchedleData([]);
    setErrorMessages([]);
    setShowErrorText([]);
    if (recoupmentTypeCode === ClaimsReceivableConstants.A) {
      setFieldValues(recoupmentTypeCode, '', '', '1', '', 'N');
      setDisabled(false);
      setSelectedStartDate(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    } else if (recoupmentTypeCode === ClaimsReceivableConstants.B) {
      setPaymentSchedleData([]);
      setFieldValues(recoupmentTypeCode, '', "100", '1', '', 'N');
      setDisabled(false);
      setSelectedStartDate(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    } else if (recoupmentTypeCode === ClaimsReceivableConstants.D) {
      setPaymentSchedleData([]);
      setValues({
        ...values,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne,
        paymentScheduleType: optSelectOne
      }
      );
      if (recoupmentTypeClicked || defaultRecoupmentClicked) {
        onGetRecoupmetDetails(claimsData);
      }
      searchSystemParameterPercentage(systemParamValuetoPercentage);
    } else if (recoupmentTypeCode === ClaimsReceivableConstants.N) {
      setPaymentSchedleData([]);
      setFieldValues(
        recoupmentTypeCode,
        '0',
        '0',
        DEFAULT_DD_VALUE,
        '',
        'N'
      );
      setSelectedStartDate(null);
      setDisabled(false);
    } else if (recoupmentTypeCode === ClaimsReceivableConstants.S) {
      setFieldValues(
        recoupmentTypeCode,
        '',
        '',
        DEFAULT_DD_VALUE,
        '',
        'N'
      );
      setSelectedStartDate(null);
      setDisabled(true);
    } else {
      setFieldValues(
        DEFAULT_DD_VALUE,
        '',
        '',
        DEFAULT_DD_VALUE,
        '',
        'N'
      );
      setSelectedStartDate(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    }
  };

  /* This function is used to set start date using keyboard or key press */
  const handleStartDateText = startDateText => {
    setFocusCheck(true);
    setSelectedStartDate(startDateText.target.value);
    setStartDatePress(true);
  };

  /* This function is used to set due date using date picker
   *  displaying unsaved changes will be lost pop-up if something change on screen
   */
  const handleDueDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDueDate(date);
  };

  /* This function is called on tab changes
   *  Setting tab color as "red" if any validation fails inside the tab on tab changes
   */
  const handleChangeTabs = (event, newValue) => {
    saveClick = false;
    setValue(newValue);
    if (validationApply) {
      validateClaimsReceivableFields();
    }
    if (newValue > 0) {
      if (errorMessagesArray.length > 0) {
        setColor('red');
      } else {
        setColor('green');
      }
    }
  };

  /* This function is called on change in any field on screen
   *  Setting filed values based on field type
   *  displaying unsaved changes will be lost pop-up if something change on screen
   */
  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (
      name === 'paymentScheduleType' &&
      values.paymentScheduleType !== event.target.value
    ) {
      setPaymentScheduleChanged(generateUUID());
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
    }
    setValues({ ...values, [name]: event.target.value });
    if (name === ClaimsReceivableConstants.RECOUPMENT_CODE) {
      setRecoupmentTypeClicked(true);
      defaultRecoupmentClicked = true;
      setShowErrorText('');
      setFieldsBasedOnRecoupmentType(event.target.value);
    } else if (name === 'installmentValue') {
      setValues({
        ...values,
        installmentValue: event.target.value
      });
    } else if (name === 'percentValue') {
      setValues({
        ...values,
        percentValue: event.target.value
      });
    } else if (name === 'frequency') {
      setValues({
        ...values,
        frequency: event.target.value
      });
      setFieldValues(
        values.recoupmentCode,
        values.installmentValue,
        values.percentValue,
        event.target.value,
        values.amountDue,
        values.defaultOnPaymentSchedule
      );
    } else if (name === 'amountDue') {
      setFieldValues(
        values.recoupmentCode,
        values.installmentValue,
        values.percentValue,
        values.frequency,
        event.target.value,
        values.defaultOnPaymentSchedule
      );
    } else if (name === 'defaultOnPaymentSchedule') {
      setFieldValues(
        values.recoupmentCode,
        values.installmentValue,
        values.percentValue,
        values.frequency,
        values.amountDue,
        event.target.value
      );
    }
    if (
      name === 'numberofInstallments' ||
      name === 'paymentScheduleFrequency'
    ) {
      setPaymentSettingChanged(generateUUID());
    }
    if (name === 'interestCalculation') {
      setValues({
        ...values,
        [name]: event.target.value,
        interestPercentage: ''
      });
    }
  };

  /* This function is called on click of Add Payment Schedule button
   *  Open dialog box only if recoupment type code selected is "Schedule" to input payment schedule parameters
   */
  const handleClickOpenPaymentSchedule = () => {
    const errorArray = [];
    if (values.paymentScheduleType === optSelectOne) {
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (!selectedRecoupmentStartDate) {
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);

      if (!moment(startDate).isValid()) {
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format(dateTime))
      ) {
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format(dateTime))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (errorArray.length === 0) {
      setSystemSuccesMessages([]);
      setSelectedDueDate(null);
      setOpen(true);
      setAdd(true);
      setShowError(false);
      setShowErrorText('');
      setPaymentScheduleVO({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainPaymentSchedule({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainSelectedDueDate(selectedDueDate);
      setRecoupmentStartDateError('');
      setPaymentScheduleTypeError('');
      setErrorMessages([]);
    } else {
      setErrorMessages(errorArray);
    }
  };

  /* This function is called on click of cross button inside add payment schedule pop-up
   *  Close pop-up
   */
  const handleClose = () => {
    setErrorMessages([]);
    setAuditLogRow(null);
    setOpen(false);
    setPaymentScheduleVO({
      installmentAmount: '',
      recoupedAmount: '0.00'
    });
  };

  /* This function is called on click of add button inside add payment schedule pop-up
   *  Add a row inside table if Add
   *  Update a row inside table if click on particular row
   */
  const savePaymentSchedule = () => {
    const scheduleData = paymentScheduleData;
    var selectedDefaultDateError;
    var amountDueError = false;
    var selectedDueDateErrorText;
    var selectedDefaultDateErrorText;
    var amountDueErrorText = '';
    var showDueDateError = false;
    var showDueDateErrorText = '';
    const adjustedAmount = parseAmount(values.adjustedAmount);
    const originalAmount = parseAmount(values.originalAmount);
    const installmentValue = parseAmount(paymentScheduleVO.installmentAmount);
    const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);

    if (!selectedDueDate) {
      showDueDateError = true;
      showDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(selectedDueDateErrorText);
    }
    if (paymentScheduleVO.installmentAmount === '') {
      amountDueError = true;
      amountDueErrorText = ErrorConstants.AMOUNT_DUE;
      popUpErrorMessagesArray.push(amountDueErrorText);
    }
    if (paymentScheduleVO.installmentAmount) {
      if (values.originalAmount === '' || values.originalAmount === undefined) {
        popUpErrorMessagesArray.push(
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED
        );
      } else {
        if (
          paymentScheduleVO.installmentAmount
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
        ) {
          amountDueError = true;
          amountDueErrorText = ErrorConstants.DATA_INVALID;
          popUpErrorMessagesArray.push(amountDueErrorText);
        } else {
          if (
            !(
              paymentScheduleVO.installmentAmount
                .toString()
                .replace(/,/g, '')
                .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            amountDueError = true;
            amountDueErrorText = ErrorConstants.DATA_INVALID;
            popUpErrorMessagesArray.push(amountDueErrorText);
          }
        }
        if (+paymentScheduleVO.installmentAmount < 0) {
          amountDueError = true;
          amountDueErrorText = ErrorConstants.NEGATIVE_AMOUNT;
          popUpErrorMessagesArray.push(amountDueErrorText);
        }
        if (+paymentScheduleVO.installmentAmount === 0) {
          amountDueError = true;
          amountDueErrorText = ErrorConstants.GREATER_THAN_ZERO_AMOUNT;
          popUpErrorMessagesArray.push(amountDueErrorText);
        }
        if (installmentValue > amountToUse) {
          amountDueError = true;
          amountDueErrorText =
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION;
          popUpErrorMessagesArray.push(
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION
          );
        }
      }
    }
    if (selectedDueDate) {
      if (
        !checkForValidDateString(moment(selectedDueDate).format(dateTime))
      ) {
        showDueDateError = true;
        showDueDateErrorText = ClaimsReceivableConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(selectedDueDateErrorText);
      }
    }
    if (!selectedDueDate) {
      showDueDateError = true;
      showDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(showDueDateErrorText);
    }

    if (selectedDueDate) {
      if (!moment(selectedDueDate).isValid()) {
        showDueDateError = true;
        showDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        showDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(showDueDateErrorText);
      }
      if (!showDueDateError) {
        if (validateDateMinimumValue(selectedDueDate)) {
          showDueDateError = true;
          showDueDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(showDueDateErrorText);
        }
      }
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    ) {
      showDueDateError = true;
      showDueDateErrorText = ErrorConstants.DUE_DATE_ERROR;
      popUpErrorMessagesArray.push(showDueDateErrorText);
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(selectedRecoupmentStartDate)
      )
    ) {
      showDueDateError = true;
      showDueDateErrorText = ErrorConstants.DUE_DATE_RECOUP;
      popUpErrorMessagesArray.push(showDueDateErrorText);
    }
    setErrorMessages(popUpErrorMessagesArray);
    setShowError({
      showDueDateError: showDueDateError,
      showDefaultDateError: selectedDefaultDateError,
      showAmountDueError: amountDueError
    });
    setShowErrorText({
      showDueDateErrorText: showDueDateErrorText,
      showDefaultDateErrorText: selectedDefaultDateErrorText,
      showAmountDueErrorText: amountDueErrorText
    });
    if (add) {
      const scheduleData = paymentScheduleData;

      if (popUpErrorMessagesArray.length === 0) {
        var value = {
          id: generateUUID(),
          overlapId: generateUUID(),
          scheduleSK: null,
          dueDate: selectedDueDate
            ? moment(new Date(selectedDueDate).getTime()).format(dateTime)
            : null,
          installmentAmount: paymentScheduleVO.installmentAmount,
          recoupedAmount: paymentScheduleVO.recoupedAmount
        };
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (option.dueDate === value.dueDate) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            scheduleData.push(value);

            setPaymentSchedleData(scheduleData);
            handleClose();
          }
        } else {
          scheduleData.push(value);

          setPaymentSchedleData(scheduleData);
          handleClose();
        }
      }
    } else {
      if (popUpErrorMessagesArray.length === 0) {
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (
              option.dueDate ===
                moment(new Date(selectedDueDate)).format('L') &&
              option.overlapId !== paymentScheduleVO.overlapId
            ) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            paymentScheduleData.map(value => {
              if (paymentScheduleVO.id && value.id === paymentScheduleVO.id) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    dateTime
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              if (
                paymentScheduleVO.scheduleSK &&
                value.scheduleSK === paymentScheduleVO.scheduleSK
              ) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    dateTime
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }

              setPaymentSchedleData(paymentScheduleData);
              setOpen(false);
              setAdd(true);
              handleClose();
            });
          }
        }
      }
    }
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    setErrorMessages([]);
    setShowErrorText('');
    setShowError([]);
  };

  /* This function is called on click of reset button inside add payment schedule pop-up
   *  Setting all fields null if "Add" reset
   *  Setting all fields with initial filled values if "Edit" reset
   */
  const resetPaymentSchedule = () => {
    setErrorMessages([]);
    setShowError([]);
    setShowErrorText([]);
    setPaymentScheduleVO(retainPaymentSchedule);
    setSelectedDueDate(retainSelectedDueDate);
  };

  /* This function is called on click of delete checkbox to delete row(s) */
  function PaymentRowDeleteClick () {
    let temNewDialogData = [...paymentScheduleData];
    if (
      rowPaymentScheduleData.rowPaymentScheduleData != null ||
      rowPaymentScheduleData.rowPaymentScheduleData !== undefined
    ) {
      for (
        let i = 0;
        i < rowPaymentScheduleData.rowPaymentScheduleData.length;
        i++
      ) {
        if (rowPaymentScheduleData.rowPaymentScheduleData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            payment =>
              payment.id !== rowPaymentScheduleData.rowPaymentScheduleData[i]
          );
        }
      }

      setPaymentSchedleData(temNewDialogData);
      setRowPaymentScheduleData([]);
    }
  }
  // Delete Dialog implemented
  const [openDialog, setOpenDialog] = React.useState(false);
  const confirmDelete = () => {
    PaymentRowDeleteClick();
    setOpenDialog(false);
  };
  const openDeletePopUpDialog = () => {
    if (rowPaymentScheduleData.rowPaymentScheduleData.length !== 0) {
      setOpenDialog(true);
    }
  };
  const closeDeletePopUpDialog = () => {
    setOpenDialog(false);
  };
  /* This function is called on click of a row inside table to edit it's values
   *  Setting "add" variable false to recognise that it is for edit
   *  Setting all fields with initial filled values inside add payment schedule pop-up
   */
  const editRow = data => () => {
    setErrorMessages([]);
    setAuditLogRow(data);
    setAdd(false);
    setOpen(true);
    setPaymentScheduleVO({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainPaymentSchedule({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainSelectedDueDate(data.dueDate);
    setSelectedDueDate(data.dueDate);
    setShowError(false);
    setShowErrorText(false);
  };

  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && !recoupmentTypeClicked
    ) {
        if (
          getRecoupmetDetails.recoupmentVO.frequency !== values.frequency ||
          getRecoupmetDetails.recoupmentVO.recoupmentTypeCode !== values.recoupmentCode ||
          Number(getRecoupmetDetails.recoupmentVO.installmentAmount) !== Number(values.installmentValue) ||
          Number(getRecoupmetDetails.recoupmentVO.percentageOriginalAmount) !== Number(values.percentValue) ||
          getRecoupmetDetails.recoupmentVO.startDateStr !== moment(selectedStartDate).format(dateTime)
           ) { setFocusCheck(true); };
      setFieldValues(
        getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        getRecoupmetDetails.recoupmentVO.installmentAmount,
        getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        getRecoupmetDetails.recoupmentVO.frequency,
        values.amountDue,
        values.defaultOnPaymentSchedule
      );
      setSelectedStartDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format(dateTime));
      setErrorMessages([]);
    } else if (getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && recoupmentTypeClicked) {
      setSelectedStartDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format(dateTime));
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      setErrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);

  /* This function is called if default on payment schedule value selected as "Y" inside add payment schedule pop-up */
  const handleDefaultRecoupment = () => {
    setRecoupmentTypeClicked(false);
    if (claimsData.balanceAmount !== '0.00') {
      setAllowNavigation(true);
      setDisabled(false);
      onGetRecoupmetDetails(claimsData);
    }
  };

  /* This function is validating fields on screen */
  const validateClaimsReceivableFields = () => {
    var checkFormat = false;
    setSystemSuccesMessages([]);
    var showRecoupmentCodeError;
    var showInstallmentAmountError;
    var showPercentAmountError;
    var showStartDateError;
    var showFrequencyError = false;
    var showFiscalYearErrorTemp = false;
    var showCollocationCodeErrorTemp = false;
    var showRecoupmentCodeErrorText;
    var showInstallmentAmountErrorText;
    var showPercentAmountErrorText;
    var showStartDateErrorText;
    var showFrequencyErrorText = '';
    var showFiscalYearErrorTextTemp = '';
    var showCollocationCodeErrorTextTemp = '';
    errorMessagesArray = [];
    setErrorMessages([]);
    const adjustedAmount = parseAmount(values.adjustedAmount);
    const originalAmount = parseAmount(values.originalAmount);
    const balanceAmount = parseAmount(values.balanceAmount);
    const installmentValue = parseAmount(values.installmentValue);
    const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);
    if (
      values.recoupmentCode &&
      values.recoupmentCode !== DEFAULT_DD_VALUE
    ) {
      if (values.installmentValue && values.installmentValue < 0) {
        showInstallmentAmountError = true;
        showInstallmentAmountErrorText =
            ClaimsReceivableConstants.INSTALLMENT_NEGATIVE_ERROR;
        errorMessagesArray.push(showInstallmentAmountErrorText);
        errorMessagesArray.push(
          ClaimsReceivableConstants.INSTALLMENT_MUST_BE_GREATER_0
        );
        setErrorMessages(errorMessagesArray);
      } else if (installmentValue > amountToUse) {
        showInstallmentAmountError = true;
        showInstallmentAmountErrorText =
            ClaimsReceivableConstants.INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR;
        errorMessagesArray.push(showInstallmentAmountErrorText);
        setErrorMessages(errorMessagesArray);
      } else if (values.installmentValue) {
        if (
          values.installmentValue
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
        ) {
          showInstallmentAmountError = true;
          showInstallmentAmountErrorText =
              ClaimsReceivableConstants.DATA_INVALID;
          errorMessagesArray.push(showInstallmentAmountErrorText);
          setErrorMessages(errorMessagesArray);
        } else {
          if (
            !(
              values.installmentValue
                .toString()
                .replace(/,/g, '')
                .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            showInstallmentAmountError = true;
            showInstallmentAmountErrorText =
                ClaimsReceivableConstants.DATA_INVALID;
            errorMessagesArray.push(showInstallmentAmountErrorText);
            setErrorMessages(errorMessagesArray);
          }
        }
      }

      if (values.recoupmentCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT ||
        values.recoupmentCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT) {
        if (values.frequency === undefined ||
          values.frequency === DEFAULT_DD_VALUE
        ) {
          showFrequencyError = true;
          showFrequencyErrorText = ErrorConstants.FREQUENCY;
          errorMessagesArray.push(showFrequencyErrorText);
        }
        setErrorMessages(errorMessagesArray);
      }

      if (values.percentValue && values.percentValue > 100) {
        showPercentAmountError = true;
        showPercentAmountErrorText =
            ClaimsReceivableConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
        errorMessagesArray.push(showPercentAmountErrorText);
        errorMessagesArray.push(
          ClaimsReceivableConstants.PERCENT_MUST_BE_WITHIN_0_100
        );
        setErrorMessages(errorMessagesArray);
      }
      if (
        values.recoupmentTypeCode !== 'N' &&
          values.percentValue &&
          values.percentValue === 0
      ) {
        showPercentAmountError = true;
        showPercentAmountErrorText = ClaimsReceivableConstants.PERCENT_ZERO;
        errorMessagesArray.push(showPercentAmountErrorText);
        errorMessagesArray.push(
          ClaimsReceivableConstants.PERCENT_MUST_BE_WITHIN_0_100
        );
      }
      if (values.percentValue && values.percentValue < 0) {
        showPercentAmountError = true;
        showPercentAmountErrorText =
            ClaimsReceivableConstants.PERCENT_NEGATIVE_ERROR;
        errorMessagesArray.push(showPercentAmountErrorText);
        errorMessagesArray.push(
          ClaimsReceivableConstants.PERCENT_MUST_BE_WITHIN_0_100
        );
        setErrorMessages(errorMessagesArray);
      }
      if (values.recoupmentCode && values.recoupmentCode === 'B') {
        if (!values.percentValue && !selectedStartDate) {
          errorMessagesArray.push(
            ClaimsReceivableConstants.START_DATE_PERCENT_REQUIRED
          );
          setErrorMessages(errorMessagesArray);
          showStartDateError = true;
          showStartDateErrorText =
              ClaimsReceivableConstants.START_DATE_REQUIRED;
          showPercentAmountError = true;
          showPercentAmountErrorText =
              ClaimsReceivableConstants.PERCENT_VALUE_REQUIRED;
        } else {
          if (!values.percentValue) {
            showPercentAmountError = true;
            showPercentAmountErrorText =
                ClaimsReceivableConstants.PERCENT_VALUE_REQUIRED;
            errorMessagesArray.push(showPercentAmountErrorText);
            setErrorMessages(errorMessagesArray);
          }
          if (!selectedStartDate) {
            showStartDateError = true;
            showStartDateErrorText =
                ClaimsReceivableConstants.START_DATE_REQUIRED;
            errorMessagesArray.push(showStartDateErrorText);
            setErrorMessages(errorMessagesArray);
          }
        }
        if (
          values.installmentValue !== null &&
            values.installmentValue !== ''
        ) {
          showInstallmentAmountError = true;
          showInstallmentAmountErrorText =
              ClaimsReceivableConstants.INSTALLMENT_MUST_BE_BLANK;
          errorMessagesArray.push(showInstallmentAmountErrorText);
          if (values.percentValue) {
            errorMessagesArray.push(
              ClaimsReceivableConstants.PERCENT_INSTALLMENT_BOTH_NOT_ALLOWED
            );
          }
          setErrorMessages(errorMessagesArray);
        }
      }
      if (values.recoupmentCode && values.recoupmentCode === 'D') {
        if (!values.percentValue && !selectedStartDate) {
          errorMessagesArray.push(
            ClaimsReceivableConstants.START_DATE_PERCENT_REQUIRED
          );
          setErrorMessages(errorMessagesArray);
          showStartDateError = true;
          showStartDateErrorText =
              ClaimsReceivableConstants.START_DATE_REQUIRED;
          showPercentAmountError = true;
          showPercentAmountErrorText =
              ClaimsReceivableConstants.PERCENT_VALUE_REQUIRED;
        } else {
          if (!values.percentValue) {
            showPercentAmountError = true;
            showPercentAmountErrorText =
                ClaimsReceivableConstants.PERCENT_VALUE_REQUIRED;
            errorMessagesArray.push(showPercentAmountErrorText);
            setErrorMessages(errorMessagesArray);
          }
          if (!selectedStartDate) {
            showStartDateError = true;
            showStartDateErrorText =
                ClaimsReceivableConstants.START_DATE_REQUIRED;
            errorMessagesArray.push(showStartDateErrorText);
            setErrorMessages(errorMessagesArray);
          }
        }
        if (
          values.installmentValue !== null &&
            values.installmentValue !== ''
        ) {
          showInstallmentAmountError = true;
          showInstallmentAmountErrorText =
              ClaimsReceivableConstants.INSTALLMENT_MUST_BE_BLANK;
          errorMessagesArray.push(showInstallmentAmountErrorText);
          if (values.percentValue) {
            errorMessagesArray.push(
              ClaimsReceivableConstants.PERCENT_INSTALLMENT_BOTH_NOT_ALLOWED
            );
          }
          setErrorMessages(errorMessagesArray);
        }
      }

      if (values.recoupmentCode && values.recoupmentCode === 'A') {
        if (!values.installmentValue && !selectedStartDate) {
          errorMessagesArray.push(
            ClaimsReceivableConstants.START_DATE_INSTALLMENT_REQUIRED
          );
          setErrorMessages(errorMessagesArray);
          showInstallmentAmountError = true;
          showInstallmentAmountErrorText =
              ClaimsReceivableConstants.INSTALLMENT_VALUE_REQUIRED;
          showStartDateError = true;
          showStartDateErrorText =
              ClaimsReceivableConstants.START_DATE_REQUIRED;
        } else {
          if (!values.installmentValue) {
            showInstallmentAmountError = true;
            showInstallmentAmountErrorText =
                ClaimsReceivableConstants.INSTALLMENT_VALUE_REQUIRED;
            errorMessagesArray.push(showInstallmentAmountErrorText);
            setErrorMessages(errorMessagesArray);
          } else if (parseInt(values.installmentValue) === 0) {
            showInstallmentAmountError = true;
            showInstallmentAmountErrorText =
                ClaimsReceivableConstants.INSTALLMENT_MUST_BE_GREATER_0;
            errorMessagesArray.push(showInstallmentAmountErrorText);
            setErrorMessages(errorMessagesArray);
          }
          if (!selectedStartDate) {
            showStartDateError = true;
            showStartDateErrorText =
                ClaimsReceivableConstants.START_DATE_REQUIRED;
            errorMessagesArray.push(showStartDateErrorText);
            setErrorMessages(errorMessagesArray);
          }
        }

        if (values.percentValue !== null && values.percentValue !== '') {
          showPercentAmountError = true;
          showPercentAmountErrorText =
              ClaimsReceivableConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentAmountErrorText);
          if (values.installmentValue) {
            errorMessagesArray.push(
              ClaimsReceivableConstants.PERCENT_INSTALLMENT_BOTH_NOT_ALLOWED
            );
          }
          setErrorMessages(errorMessagesArray);
        }
      }

      if (selectedStartDate) {
        var startDate = new Date(selectedStartDate);
        if (!moment(startDate).isValid()) {
          showStartDateError = true;
          showStartDateErrorText = ErrorConstants.DATA_INVALID;
          checkFormat = true;
        } else if (
          validateDateMinimumValue(moment(startDate).format(dateTime))
        ) {
          showStartDateError = true;
          showStartDateErrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(showStartDateErrorText);
          setErrorMessages(errorMessagesArray);
        }
      }

      if (values.recoupmentCode && values.recoupmentCode === 'N') {
        if (
          parseInt(values.installmentValue) > 0 ||
            parseInt(values.installmentValue) < 0 ||
            parseInt(values.percentValue) > 0 ||
            parseInt(values.percentValue) < 0
        ) {
          errorMessagesArray.push(
            ClaimsReceivableConstants.INSTALLMENT_PERCENT_MUST_BE_0
          );
          setErrorMessages(errorMessagesArray);
          if (values.percentValue !== '') {
            if (
              (values.percentValue !== '0' && values.percentValue !== '0.00') ||
                parseInt(values.percentValue) > 0 ||
                parseInt(values.percentValue) < 0
            ) {
              showPercentAmountError = true;
              showPercentAmountErrorText =
                  ClaimsReceivableConstants.INSTALLMENT_PERCENT_MUST_BE_0;
            }
          }
          if (values.installmentValue !== '') {
            if (
              (values.installmentValue !== '0' &&
                  values.installmentValue !== '0.00') ||
                parseInt(values.installmentValue) > 0 ||
                parseInt(values.installmentValue) < 0
            ) {
              showInstallmentAmountError = true;
              showInstallmentAmountErrorText =
                  ClaimsReceivableConstants.INSTALLMENT_PERCENT_MUST_BE_0;
            }
          }
        }

        if (selectedStartDate) {
          showStartDateError = true;
          showStartDateErrorText =
              ClaimsReceivableConstants.START_DATE_MUST_BE_BLANK;
          errorMessagesArray.push(showStartDateErrorText);
          setErrorMessages(errorMessagesArray);
        }

        if (
          values.frequency &&
          values.frequency !== DEFAULT_DD_VALUE
        ) {
          showFrequencyError = true;
          showFrequencyErrorText =
              ClaimsReceivableConstants.FREQUENCY_MUST_BE_BLANK;
          errorMessagesArray.push(showFrequencyErrorText);
          setErrorMessages(errorMessagesArray);
        }
      }

      if (values.recoupmentCode && values.recoupmentCode === 'S') {
        if (saveClick) {
          if (paymentScheduleData.length === 0) {
            errorMessagesArray.push(
              ClaimsReceivableConstants.PAYMENT_SCHEDULE_REQUIRED
            );
            setErrorMessages(errorMessagesArray);
          }
			if (recoupmentTypeClicked &&(amountToUse !== balanceAmount)) {
              showRecoupmentCodeError = true;
              showRecoupmentCodeErrorText =
                  ErrorConstants.RECOUPMENT_BALANCE_INVALID;
              errorMessagesArray.push(showRecoupmentCodeErrorText);
              setErrorMessages(errorMessagesArray);
            }
        }
      }

      if (checkFormat) {
        errorMessagesArray.push(ErrorConstants.DATA_INVALID);
        setErrorMessages(errorMessagesArray);
        checkFormat = false;
      }

      if (
        claimsData.recoupmentVO.recoupmentTypeCode === 'S' ||
          claimsData.recoupmentVO.recoupmentTypeCode === 'N'
      ) {
        if (
          !(values.recoupmentCode === 'S' || values.recoupmentCode === 'N') &&
            paymentScheduleData.length > 0
        ) {
          showRecoupmentCodeError = true;
          showRecoupmentCodeErrorText = ErrorConstants.RECOUPEMENT_INVALID;
          errorMessagesArray.push(showRecoupmentCodeErrorText);
          setErrorMessages(errorMessagesArray);
        }
      }

      if (
        !(
          claimsData.recoupmentVO.recoupmentTypeCode === 'S' ||
            claimsData.recoupmentVO.recoupmentTypeCode === 'N'
        ) &&
          values.recoupmentCode !== optSelectOne
      ) {
        if (values.recoupmentCode === 'S' || values.recoupmentCode === 'N') {
          if (
            adjustedReasonCode.some(
              values =>
                values.reasonCode?.split('-')[0] ===
                  claimsData.accountReceivableReasonCode.split('-')[0]
            )
          ) {
            if (recoupmentTypeClicked && (amountToUse !== balanceAmount)) {
              showRecoupmentCodeError = true;
              showRecoupmentCodeErrorText =
                  ErrorConstants.RECOUPMENT_BALANCE_INVALID;
              errorMessagesArray.push(showRecoupmentCodeErrorText);
              setErrorMessages(errorMessagesArray);
            }
          }
        }
      }

      if (paymentScheduleData.length > 0 && values.recoupmentCode === 'S') {
        let summation = 0;
        if (values.paymentScheduleType === 'M') {
          paymentScheduleData.map(value => {
            summation =
                summation +
                Number(numeral(value.installmentAmount).format('0.00'));
          });
          const summationAmount = parseAmount(summation);
          if (summationAmount !== amountToUse) {
            errorMessagesArray.push(ErrorConstants.SUM_AMOUNT);
            setErrorMessages(errorMessagesArray);
          }
        }
      }
    } else {
      showRecoupmentCodeError = true;
      showRecoupmentCodeErrorText =
          ClaimsReceivableConstants.RECOUPMENT_CODE_REQUIRED;
      errorMessagesArray.push(showRecoupmentCodeErrorText);
      setErrorMessages(errorMessagesArray);
    }
    setShowError({
      showRecoupmentCodeError: showRecoupmentCodeError,
      showInstallmentAmountError: showInstallmentAmountError,
      showPercentAmountError: showPercentAmountError,
      showStartDateError: showStartDateError,
      showFrequencyError: showFrequencyError,
      showFiscalYearError: showFiscalYearErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp
    });
    setShowErrorText({
      showRecoupmentCodeErrorText: showRecoupmentCodeErrorText,
      showInstallmentAmountErrorText: showInstallmentAmountErrorText,
      showPercentAmountErrorText: showPercentAmountErrorText,
      showStartDateErrorText: showStartDateErrorText,
      showFrequencyErrorText: showFrequencyErrorText,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp
    });
  };

  /* This function is deleting row */
  const rowDeletePaymentSchedule = data => {
    setRowPaymentScheduleData({
      ...rowPaymentScheduleData,
      rowPaymentScheduleData: data
    });
  };

  /* This function is called to save claims receivable on click of "Save" button on screen
   *  validate all fileds on screen
   *  if there is no error on screen, call save API
   */
  const saveClaimsReceivableData = () => {
    saveClick = true;
    if (validationApply) {
      validateClaimsReceivableFields();
    }
    if (values.recoupmentCode === 'S') {
      const errorArray = [];
      var originalArAmountRequired = false;
      var originalArAmountRequiredText = '';
      let paymentScheduleTypeText = '';
      let recoupmentStartDateText = '';
      let noOfInstallmentText = '';
      let frequencyText = '';
      let calPercentText = '';
      if (values.paymentScheduleType === optSelectOne) {
        paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
        errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
        setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      }
      if (values.originalAmount === '' || values.originalAmount === undefined) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorArray.push(originalArAmountRequiredText);
      }
      if (!selectedRecoupmentStartDate) {
        recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
        setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
        errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
      }

      if (selectedRecoupmentStartDate && !manualEditFlag) {
        var startDate = new Date(selectedRecoupmentStartDate);
        var estDate = new Date(values.establishedDate);
        if (!moment(startDate).isValid()) {
          recoupmentStartDateText = ErrorConstants.DATA_INVALID;
          setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
          errorArray.push(ErrorConstants.DATA_INVALID);
        } else if (
          validateDateMinimumValue(moment(startDate).format(dateTime))
        ) {
          recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
          setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
          errorArray.push(AppConstants.DATE_ERROR_1964);
        }
        if (
          moment(startDate).isValid() &&
          !validateDateMinimumValue(moment(startDate).format(dateTime))
        ) {
          if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
            recoupmentStartDateText =
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
            setRecoupmentStartDateError(
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
            );
            errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
          }
        }
      }
      if (
        values.paymentScheduleFrequency === optSelectOne &&
        values.paymentScheduleType === 'A'
      ) {
        frequencyText = ErrorConstants.FREQUENCY;
        setFrequencyError(ErrorConstants.FREQUENCY);
        errorArray.push(ErrorConstants.FREQUENCY);
      }
      if (
        values.numberofInstallments === '' &&
        values.paymentScheduleType === 'A'
      ) {
        noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
        setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
        errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      }
      if (
        values.numberofInstallments !== '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
          noOfInstallmentText =
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.';
          setNumberofinstallmentError(
            ErrorConstants.INSTALLMENT_MORE +
              frequencyResult.valueNum.toString() +
              '.'
          );
          errorArray.push(
            ErrorConstants.INSTALLMENT_MORE +
              frequencyResult.valueNum.toString() +
              '.'
          );
        }
      }
      if (
        values.interestCalculation === 'Y' &&
        values.interestPercentage === '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (!errorMessagesArray.includes(ErrorConstants.INTEREST_PERCENT)) {
          calPercentText = ErrorConstants.INTEREST_PERCENT;
          setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
          errorArray.push(ErrorConstants.INTEREST_PERCENT);
        }
      }
      if (
        values.interestPercentage &&
        values.interestPercentage !== '' &&
        values.interestPercentage
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
        values.paymentScheduleType === 'A'
      ) {
        calPercentText = ErrorConstants.DATA_INVALID;
        setInstallmentCalError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      }
      if (errorArray.length === 0) {
        setPaymentScheduleTypeError('');
        setRecoupmentStartDateError('');
        setFrequencyError('');
        setNumberofinstallmentError('');
        setInstallmentCalError('');
      } else {
        setPaymentScheduleTypeError(paymentScheduleTypeText);
        setRecoupmentStartDateError(recoupmentStartDateText);
        setFrequencyError(frequencyText);
        setNumberofinstallmentError(noOfInstallmentText);
        setInstallmentCalError(calPercentText);
        if (errorArray.length !== 0) {
          errorArray.map(value => {
            errorMessagesArray.push(value);
          });
        }
      }
    }

    const paymentScheduleTemp = paymentScheduleData;
    paymentScheduleTemp.map(value => {
      if (paymentScheduleChanged || paymentSettingChanged) {
        value.auditUserID = logInUserID;
        value.auditTimeStamp = getUTCTimeStamp();
        value.addedAuditUserID = logInUserID;
        value.addedAuditTimeStamp = getUTCTimeStamp();
      } else {
        if (value.edited && value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
        }
        if (!value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
          value.addedAuditUserID = logInUserID;
          value.addedAuditTimeStamp = getUTCTimeStamp();
        }
      }
      value.installmentAmount = numeral(value.installmentAmount).format('0.00');
      value.recoupedAmount = numeral(value.recoupedAmount).format('0.00');
    });

    if (errorMessagesArray.length === 0) {
      setFocusCheck(false);
      if (allowNavigation === false) {
        NoSaveMessage();
      } else {
        const finalPaymentScheduleData = JSON.parse(
          JSON.stringify(paymentScheduleTemp)
        );
        const value = {
          paymentInd: paymentInd,
          paymentScheduleType:
            values.paymentScheduleType === optSelectOne
              ? null
              : values.paymentScheduleType,
          recoupmentStartDate: selectedRecoupmentStartDate
            ? moment(selectedRecoupmentStartDate).format(dateTime)
            : null,
          numberofInstallments: values.numberofInstallments
            ? values.numberofInstallments
            : null,
          interestCalculation: values.interestCalculation,
          interestPercentage:
            values.interestPercentage !== ''
              ? numeral(values.interestPercentage).format('0.00')
              : null,
          paymentScheduleFrequency:
            values.paymentScheduleFrequency !== optSelectOne
              ? values.paymentScheduleFrequency
              : null,
          approvedDate: claimsData.approvedDate,
          remitAmount: claimsData.remitAmount,
          approvedUserID: claimsData.approvedUserID,
          adjustedArAmt: claimsData.adjustedArAmt,
          versionNoForFinancialDetail: claimsData.versionNoForFinancialDetail,
          versionNo: claimsData.versionNo,
          fcnMediaCode: claimsData.fcnMediaCode
            ? claimsData.fcnMediaCode
            : null,
          fcnSequenceNo: claimsData.fcnSequenceNo
            ? claimsData.fcnSequenceNo
            : null,
          receivableFCN: claimsData.receivableFCN
            ? claimsData.receivableFCN
            : null,
          payeePayerID: claimsData.payeePayerID
            ? claimsData.payeePayerID
            : null,
          payeePayerIDTypeCode: claimsData.payeePayerIDTypeCode.split('-')[0],
          payeePayerTypeCode: claimsData.payeePayerTypeCode.split('-')[0],
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: claimsData.recoupmentVO.addedAuditUserID
            ? claimsData.recoupmentVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: claimsData.recoupmentVO.addedAuditTimeStamp
            ? claimsData.recoupmentVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          payeePayerIdFromDB: claimsData.payeePayerIdFromDB,
          dbRecord: false,
          sortColumn: claimsData.sortColumn,
          auditKeyList: claimsData.auditKeyList,
          auditKeyListFiltered: claimsData.auditKeyListFiltered,
          versionNoForFinancialCrossReference:
            claimsData.versionNoForFinancialCrossReference,
          versionNoForCommonEntity: claimsData.versionNoForCommonEntity,
          versionNoForWLCommonEntity: claimsData.versionNoForWLCommonEntity,
          payoutFCN: claimsData.payoutFCN,
          noteSet: claimsData.noteSet,
          viewLienPayouts: claimsData.viewLienPayouts,
          payeePayerTypeCodeDesc: claimsData.payeePayerTypeCodeDesc,
          payeePayerIDTypeCodeDesc: claimsData.payeePayerIDTypeCodeDesc,
          memberName: claimsData.memberName,
          confirmDailog: claimsData.confirmDailog,
          pageLoadIndicator: claimsData.pageLoadIndicator,
          memberIdDisable: claimsData.memberIdDisable,
          memberID: claimsData.memberID,
          memberIDTypeCodeDisable: claimsData.memberIDTypeCodeDisable,
          memberIDTypeCode: claimsData.memberIDTypeCode,
          activityTypeCode: claimsData.activityTypeCode?.split('-')[0],
          activityTypeCodeDesc: claimsData.activityTypeCodeDesc,
          originalAmountDisable: claimsData.originalAmountDisable,
          agingCodeDesc: claimsData.agingCodeDesc,
          agingCodeDisable: claimsData.agingCodeDisable,
          arAgeInDays: claimsData.arAgeInDays,
          paymentSchedule: claimsData.paymentSchedule,
          exemptions: claimsData.exemptions,
          recoupment: claimsData.recoupment,

          withholdingVO: {
            auditUserID: claimsData.withholdingVO.auditUserID,
            auditTimeStamp: claimsData.withholdingVO.auditTimeStamp,
            addedAuditUserID: claimsData.withholdingVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.withholdingVO.addedAuditTimeStamp,
            versionNo: claimsData.withholdingVO.versionNo,
            dbRecord: claimsData.withholdingVO.dbRecord,
            sortColumn: claimsData.withholdingVO.sortColumn,
            auditKeyList: claimsData.withholdingVO.auditKeyList,
            auditKeyListFiltered: claimsData.withholdingVO.auditKeyListFiltered,
            versionNoForFinancialDetail:
              claimsData.withholdingVO.versionNoForFinancialDetail,
            versionNoForFinancialCrossReference:
              claimsData.withholdingVO.versionNoForFinancialCrossReference,
            versionNoForCommonEntity:
              claimsData.withholdingVO.versionNoForCommonEntity,
            versionNoForWLCommonEntity:
              claimsData.withholdingVO.versionNoForWLCommonEntity,
            payoutFCN: claimsData.withholdingVO.payoutFCN,
            noteSet: claimsData.withholdingVO.noteSet,
            adjustedArAmt: claimsData.withholdingVO.adjustedArAmt,
            receivableFCN: claimsData.withholdingVO.receivableFCN,
            viewLienPayouts: claimsData.withholdingVO.viewLienPayouts,
            fcnSequenceNo: claimsData.withholdingVO.fcnSequenceNo,
            lob: AppConstants.DEFAULT_LOB_VALUE,
            payeePayerTypeCode: claimsData.withholdingVO.payeePayerTypeCode,
            payeePayerTypeCodeDesc:
              claimsData.withholdingVO.payeePayerTypeCodeDesc,
            payeePayerIDTypeCode: claimsData.withholdingVO.payeePayerIDTypeCode,
            payeePayerIDTypeCodeDesc:
              claimsData.withholdingVO.payeePayerIDTypeCodeDesc,
            establishedDate: claimsData.withholdingVO.establishedDate,
            lastAppliedDate: claimsData.withholdingVO.lastAppliedDate,
            calculatedPenalty: claimsData.withholdingVO.calculatedPenalty,
            calculatedInterest: claimsData.withholdingVO.calculatedInterest,
            calculatedFees: claimsData.withholdingVO.calculatedFees,
            memberName: claimsData.withholdingVO.memberName,
            confirmDailog: claimsData.withholdingVO.confirmDailog,
            pageLoadIndicator: claimsData.withholdingVO.pageLoadIndicator,
            memberIdDisable: claimsData.withholdingVO.memberIdDisable,
            memberID: claimsData.withholdingVO.memberID,
            memberIDTypeCodeDisable:
              claimsData.withholdingVO.memberIDTypeCodeDisable,
            memberIDTypeCode: claimsData.withholdingVO.memberIDTypeCode,
            activityTypeCode: claimsData.withholdingVO.activityTypeCode,
            activityTypeCodeDesc: claimsData.withholdingVO.activityTypeCodeDesc,
            originalAmount: claimsData.withholdingVO.originalAmount,
            originalAmountDisable:
              claimsData.withholdingVO.originalAmountDisable,
            agingCode: claimsData.withholdingVO.agingCode,
            agingCodeDesc: claimsData.withholdingVO.agingCodeDesc,
            agingCodeDisable: claimsData.withholdingVO.agingCodeDisable,
            arAgeInDays: claimsData.withholdingVO.arAgeInDays,
            balanceAmount: claimsData.withholdingVO.balanceAmount,
            relatedFTTCN: claimsData.withholdingVO.relatedFTTCN,
            payeePayerID: claimsData.withholdingVO.payeePayerID,
            paymentSchedule: claimsData.withholdingVO.paymentSchedule,
            exemptions: claimsData.withholdingVO.exemptions,
            recoupment: claimsData.withholdingVO.recoupment,
            accountVO: claimsData.withholdingVO.accountVO,
            advancePaymentReceivableVO:
              claimsData.withholdingVO.advancePaymentReceivableVO,
            grossLevelReceivableVO:
              claimsData.withholdingVO.grossLevelReceivableVO,
            fundSourceVO: claimsData.withholdingVO.fundSourceVO,
            accountReceivableReasonCode:
              claimsData.withholdingVO.accountReceivableReasonCode,
            accountReceivableReasonCodeDesc:
              claimsData.withholdingVO.accountReceivableReasonCodeDesc,
            payeePayerIdFromDB: claimsData.withholdingVO.payeePayerIdFromDB,
            payeePayerNameFromDB: claimsData.withholdingVO.payeePayerNameFromDB,
            sysID: claimsData.withholdingVO.sysID,
            withHoldingStatus: claimsData.withholdingVO.withHoldingStatus,
            exemptionsVO: claimsData.withholdingVO.exemptionsVO,
            adjustmentVO: claimsData.withholdingVO.adjustmentVO,
            transferVO: claimsData.withholdingVO.transferVO,
            recoupmentVO: claimsData.withholdingVO.recoupmentVO,
            approvalIndicator: claimsData.approvalIndicator,
            approvalIndicatorDisable:
              claimsData.withholdingVO.approvalIndicatorDisable,
            schedulePaymentList: claimsData.withholdingVO.schedulePaymentList,
            schedulePaymentMap: claimsData.withholdingVO.schedulePaymentMap,
            schedulePaymentMapFromDB:
              claimsData.withholdingVO.schedulePaymentMapFromDB,
            paymentScheduleVO: {
              auditUserID:
                claimsData.withholdingVO.paymentScheduleVO.auditUserID,
              auditTimeStamp: claimsData.withholdingVO.auditTimeStamp,
              addedAuditUserID: claimsData.withholdingVO.addedAuditUserID,
              addedAuditTimeStamp: claimsData.withholdingVO.addedAuditTimeStamp,
              versionNo: claimsData.withholdingVO.versionNo,
              dbRecord: claimsData.withholdingVO.dbRecord,
              sortColumn: claimsData.withholdingVO.sortColumn,
              auditKeyList: claimsData.withholdingVO.auditKeyList,
              auditKeyListFiltered:
                claimsData.withholdingVO.auditKeyListFiltered,
              dueDate: claimsData.withholdingVO.dueDate,
              dueAmount: claimsData.withholdingVO.dueAmount,
              defaultedDate: claimsData.withholdingVO.defaultedDate,
              defaultedIndicator: claimsData.withholdingVO.defaultedIndicator,
              defaultedIndicatorImage:
                claimsData.withholdingVO.defaultedIndicatorImage,
              renderAddPaymentHeader:
                claimsData.withholdingVO.renderAddPaymentHeader,
              renderEditPaymentHeader:
                claimsData.withholdingVO.renderEditPaymentHeader,
              paymentScheduleRowIndex:
                claimsData.withholdingVO.paymentScheduleRowIndex,
              renderDeletePaymentSection:
                claimsData.withholdingVO.renderDeletePaymentSection,
              paymentScheduleKey: claimsData.withholdingVO.paymentScheduleKey,
              defaultDateStr: claimsData.withholdingVO.defaultDateStr,
              dueDateStr: claimsData.withholdingVO.dueDateStr,
              renderPaymentSection:
                claimsData.withholdingVO.renderPaymentSection,
              enableOnSchedulePayment:
                claimsData.withholdingVO.enableOnSchedulePayment,
              disableEditPaymentSchedule:
                claimsData.withholdingVO.disableEditPaymentSchedule
            },
            reversalVO: claimsData.withholdingVO.reversalVO,
            withholdingVO: claimsData.withholdingVO.withholdingVO,
            historyList: claimsData.withholdingVO.historyList,
            historyMap: claimsData.withholdingVO.historyMap,
            historyVO: claimsData.withholdingVO.historyVO,
            reestablishReceivableVO:
              claimsData.withholdingVO.reestablishReceivableVO,
            disableManualCheckEntry:
              claimsData.withholdingVO.disableManualCheckEntry,
            feeAssdAmount: claimsData.withholdingVO.feeAssdAmount,
            writeOffReasonCode: claimsData.withholdingVO.writeOffReasonCode,
            auditForUpdate: claimsData.withholdingVO.auditForUpdate,
            update: claimsData.withholdingVO.update,
            originatingClaimsList:
              claimsData.withholdingVO.originatingClaimsList,
            defaultEntityTypeDisable:
              claimsData.withholdingVO.defaultEntityTypeDisable,
            memberSysID: claimsData.withholdingVO.memberSysID,
            syspayeeID: claimsData.withholdingVO.syspayeeID,
            voidIndicator: claimsData.withholdingVO.voidIndicator,
            fcnMediaCode: claimsData.withholdingVO.fcnMediaCode,
            agingInDays: claimsData.withholdingVO.agingInDays,
            originalFcn: claimsData.withholdingVO.originalFcn,
            establishedDateStr: claimsData.withholdingVO.establishedDateStr,
            lastAppliedDateStr: claimsData.withholdingVO.lastAppliedDateStr,
            adjustedARAmountDisable:
              claimsData.withholdingVO.adjustedARAmountDisable,
            balanceAmountTemp: claimsData.withholdingVO.balanceAmountTemp,
            claimsReceivableDetailsVO:
              claimsData.withholdingVO.claimsReceivableDetailsVO,
            releaseFCN: claimsData.withholdingVO.releaseFCN,
            returnFCN: claimsData.withholdingVO.returnFCN,
            validateButtonDisable:
              claimsData.withholdingVO.validateButtonDisable,
            thirdPartyTypeDisable:
              claimsData.withholdingVO.thirdPartyTypeDisable,
            thirdPartyType: claimsData.withholdingVO.thirdPartyType,
            thirdPartyIdDisable: claimsData.withholdingVO.thirdPartyIdDisable,
            thirdPartyID: claimsData.withholdingVO.thirdPartyID,
            thirdPartyIDFromDB: claimsData.withholdingVO.thirdPartyIDFromDB,
            thirdPartyIDTypeDisable:
              claimsData.withholdingVO.thirdPartyIDTypeDisable,
            thirdPartyIDType: claimsData.withholdingVO.thirdPartyIDType,
            thirdPartyHolderNameDisable:
              claimsData.withholdingVO.thirdPartyHolderNameDisable,
            thirdPartyHolderName: claimsData.withholdingVO.thirdPartyHolderName,
            approvalReqIndicator: claimsData.withholdingVO.approvalReqIndicator
          },
          lob: AppConstants.DEFAULT_LOB_VALUE,
          originalAmount: claimsData.originalAmount,
          balanceAmount: claimsData.balanceAmount,
          establishedDate: claimsData.establishedDate,
          accountReceivableReasonCode: claimsData.accountReceivableReasonCode?.split(
            '-'
          )[0],
          approvalIndicator: claimsData.approvalIndicator,
          lastAppliedDate: claimsData.lastAppliedDate
            ? claimsData.lastAppliedDate
            : null,

          historyVO: {
            auditUserID: claimsData.historyVO.auditUserID,
            auditTimeStamp: claimsData.historyVO.auditTimeStamp,
            addedAuditUserID: claimsData.historyVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.historyVO.addedAuditTimeStamp,
            versionNo: claimsData.historyVO.versionNo,
            dbRecord: claimsData.historyVO.dbRecord,
            sortColumn: claimsData.historyVO.sortColumn,
            originalAmount: claimsData.historyVO.originalAmount,
            balanceAmount: claimsData.historyVO.balanceAmount,
            appliedAmount: claimsData.historyVO.appliedAmount,
            receivableFCN: claimsData.historyVO.receivableFCN,
            reasonCode: claimsData.historyVO.reasonCode,
            appliedDate: claimsData.historyVO.appliedDate,
            appliedDateStr: claimsData.historyVO.appliedDateStr,
            historyKey: claimsData.historyVO.historyKey,
            forTcn: claimsData.historyVO.forTcn
          },
          reestablishReceivableVO: {
            auditUserID: claimsData.reestablishReceivableVO.auditUserID,
            auditTimeStamp: claimsData.reestablishReceivableVO.auditTimeStamp,
            addedAuditUserID:
              claimsData.reestablishReceivableVO.addedAuditUserID,
            addedAuditTimeStamp:
              claimsData.reestablishReceivableVO.addedAuditTimeStamp,
            versionNo: claimsData.reestablishReceivableVO.versionNo,
            dbRecord: claimsData.reestablishReceivableVO.dbRecord,
            sortColumn: claimsData.reestablishReceivableVO.sortColumn,
            sysID: claimsData.reestablishReceivableVO.sysID,
            payeePayerIdFromDB:
              claimsData.reestablishReceivableVO.payeePayerIdFromDB,
            accountReceivableReasonCode:
              claimsData.reestablishReceivableVO.accountReceivableReasonCode,
            adjustedArAmt: claimsData.reestablishReceivableVO.adjustedArAmt,
            payeePayerTypeCode:
              claimsData.reestablishReceivableVO.payeePayerTypeCode,
            payeePayerName: claimsData.reestablishReceivableVO.payeePayerName,
            payeePayerIDTypeCode:
              claimsData.reestablishReceivableVO.payeePayerIDTypeCode,
            payeePayerID: claimsData.reestablishReceivableVO.payeePayerID,
            establishedDate: claimsData.reestablishReceivableVO.establishedDate,
            agingCode: claimsData.reestablishReceivableVO.agingCode,
            financialCtrlNumber:
              claimsData.reestablishReceivableVO.financialCtrlNumber,
            transactionCtrlNumber:
              claimsData.reestablishReceivableVO.transactionCtrlNumber,
            establishedDateStr:
              claimsData.reestablishReceivableVO.establishedDateStr
          },

          advancePaymentReceivableVO: {
            auditUserID: claimsData.advancePaymentReceivableVO.auditUserID,
            auditTimeStamp:
              claimsData.advancePaymentReceivableVO.auditTimeStamp,
            addedAuditUserID:
              claimsData.advancePaymentReceivableVO.addedAuditUserID,
            addedAuditTimeStamp:
              claimsData.advancePaymentReceivableVO.addedAuditTimeStamp,
            versionNo: claimsData.advancePaymentReceivableVO.versionNo,
            dbRecord: claimsData.advancePaymentReceivableVO.dbRecord,
            sortColumn: claimsData.advancePaymentReceivableVO.sortColumn,
            auditKeyList: claimsData.advancePaymentReceivableVO.auditKeyList,
            auditKeyListFiltered:
              claimsData.advancePaymentReceivableVO.auditKeyListFiltered,
            versionNoForFinancialDetail:
              claimsData.advancePaymentReceivableVO.versionNoForFinancialDetail,
            versionNoForFinancialCrossReference:
              claimsData.advancePaymentReceivableVO
                .versionNoForFinancialCrossReference,
            versionNoForCommonEntity:
              claimsData.advancePaymentReceivableVO.versionNoForCommonEntity,
            versionNoForWLCommonEntity:
              claimsData.advancePaymentReceivableVO.versionNoForWLCommonEntity,
            payoutFCN: claimsData.advancePaymentReceivableVO.payoutFCN,
            noteSet: claimsData.advancePaymentReceivableVO.noteSet,
            adjustedArAmt: claimsData.advancePaymentReceivableVO.adjustedArAmt,
            receivableFCN: claimsData.advancePaymentReceivableVO.receivableFCN,
            viewLienPayouts:
              claimsData.advancePaymentReceivableVO.viewLienPayouts,
            fcnSequenceNo: claimsData.advancePaymentReceivableVO.fcnSequenceNo,
            lob: AppConstants.DEFAULT_LOB_VALUE,
            payeePayerTypeCode:
              claimsData.advancePaymentReceivableVO.payeePayerTypeCode,
            payeePayerTypeCodeDesc:
              claimsData.advancePaymentReceivableVO.payeePayerTypeCodeDesc,
            payeePayerIDTypeCode:
              claimsData.advancePaymentReceivableVO.payeePayerIDTypeCode,
            payeePayerIDTypeCodeDesc:
              claimsData.advancePaymentReceivableVO.payeePayerIDTypeCodeDesc,
            establishedDate:
              claimsData.advancePaymentReceivableVO.establishedDate,
            lastAppliedDate:
              claimsData.advancePaymentReceivableVO.lastAppliedDate,
            calculatedPenalty:
              claimsData.advancePaymentReceivableVO.calculatedPenalty,
            calculatedInterest:
              claimsData.advancePaymentReceivableVO.calculatedInterest,
            calculatedFees:
              claimsData.advancePaymentReceivableVO.calculatedFees,
            memberName: claimsData.advancePaymentReceivableVO.memberName,
            confirmDailog: claimsData.advancePaymentReceivableVO.confirmDailog,
            pageLoadIndicator:
              claimsData.advancePaymentReceivableVO.pageLoadIndicator,
            memberIdDisable:
              claimsData.advancePaymentReceivableVO.memberIdDisable,
            memberID: claimsData.advancePaymentReceivableVO.memberID,
            memberIDTypeCodeDisable:
              claimsData.advancePaymentReceivableVO.memberIDTypeCodeDisable,
            memberIDTypeCode:
              claimsData.advancePaymentReceivableVO.memberIDTypeCode,
            activityTypeCode:
              claimsData.advancePaymentReceivableVO.activityTypeCode,
            activityTypeCodeDesc:
              claimsData.advancePaymentReceivableVO.activityTypeCodeDesc,
            originalAmount:
              claimsData.advancePaymentReceivableVO.originalAmount,
            originalAmountDisable:
              claimsData.advancePaymentReceivableVO.originalAmountDisable,
            agingCode: claimsData.advancePaymentReceivableVO.agingCode,
            agingCodeDesc: claimsData.advancePaymentReceivableVO.agingCodeDesc,
            agingCodeDisable:
              claimsData.advancePaymentReceivableVO.agingCodeDisable,
            arAgeInDays: claimsData.advancePaymentReceivableVO.arAgeInDays,
            balanceAmount: claimsData.advancePaymentReceivableVO.balanceAmount,
            relatedFTTCN: claimsData.advancePaymentReceivableVO.relatedFTTCN,
            payeePayerID: claimsData.advancePaymentReceivableVO.payeePayerID,
            paymentSchedule:
              claimsData.advancePaymentReceivableVO.paymentSchedule,
            exemptions: claimsData.advancePaymentReceivableVO.exemptions,
            recoupment: claimsData.advancePaymentReceivableVO.recoupment,
            accountVO: claimsData.advancePaymentReceivableVO.accountVO,
            advancePaymentReceivableVO:
              claimsData.advancePaymentReceivableVO.advancePaymentReceivableVO,
            grossLevelReceivableVO:
              claimsData.advancePaymentReceivableVO.grossLevelReceivableVO,
            fundSourceVO: claimsData.advancePaymentReceivableVO.fundSourceVO,
            accountReceivableReasonCode:
              claimsData.advancePaymentReceivableVO.accountReceivableReasonCode,
            accountReceivableReasonCodeDesc:
              claimsData.advancePaymentReceivableVO
                .accountReceivableReasonCodeDesc,
            payeePayerIdFromDB:
              claimsData.advancePaymentReceivableVO.payeePayerIdFromDB,
            payeePayerNameFromDB:
              claimsData.advancePaymentReceivableVO.payeePayerNameFromDB,
            sysID: claimsData.advancePaymentReceivableVO.sysID,
            withHoldingStatus:
              claimsData.advancePaymentReceivableVO.withHoldingStatus,
            exemptionsVO: claimsData.advancePaymentReceivableVO.exemptionsVO,
            adjustmentVO: claimsData.advancePaymentReceivableVO.adjustmentVO,
            transferVO: claimsData.advancePaymentReceivableVO.transferVO,
            recoupmentVO: claimsData.advancePaymentReceivableVO.recoupmentVO,
            approvalIndicator: claimsData.approvalIndicator,
            approvalIndicatorDisable:
              claimsData.advancePaymentReceivableVO.approvalIndicatorDisable,
            schedulePaymentList:
              claimsData.advancePaymentReceivableVO.schedulePaymentList,
            schedulePaymentMap:
              claimsData.advancePaymentReceivableVO.schedulePaymentMap,
            schedulePaymentMapFromDB:
              claimsData.advancePaymentReceivableVO.schedulePaymentMapFromDB,
            paymentScheduleVO: {
              auditUserID:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .auditUserID,
              auditTimeStamp:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .auditTimeStamp,
              addedAuditUserID:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .addedAuditUserID,
              addedAuditTimeStamp:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .addedAuditTimeStamp,
              versionNo:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .versionNo,
              dbRecord:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .dbRecord,
              sortColumn:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .sortColumn,
              auditKeyList:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .auditKeyList,
              auditKeyListFiltered:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .auditKeyListFiltered,
              dueDate:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO.dueDate,
              dueAmount:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .dueAmount,
              defaultedDate:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .defaultedDate,
              defaultedIndicator:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .defaultedIndicator,
              defaultedIndicatorImage:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .defaultedIndicatorImage,
              renderAddPaymentHeader:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .renderAddPaymentHeader,
              renderEditPaymentHeader:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .renderEditPaymentHeader,
              paymentScheduleRowIndex:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .paymentScheduleRowIndex,
              renderDeletePaymentSection:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .renderDeletePaymentSection,
              paymentScheduleKey:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .paymentScheduleKey,
              defaultDateStr:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .defaultDateStr,
              dueDateStr:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .dueDateStr,
              renderPaymentSection:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .renderPaymentSection,
              enableOnSchedulePayment:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .enableOnSchedulePayment,
              disableEditPaymentSchedule:
                claimsData.advancePaymentReceivableVO.paymentScheduleVO
                  .disableEditPaymentSchedule
            },
            reversalVO: claimsData.advancePaymentReceivableVO.reversalVO,
            withholdingVO: claimsData.advancePaymentReceivableVO.withholdingVO,
            historyList: claimsData.advancePaymentReceivableVO.historyList,
            historyMap: claimsData.advancePaymentReceivableVO.historyMap,
            historyVO: claimsData.advancePaymentReceivableVO.historyVO,
            reestablishReceivableVO:
              claimsData.advancePaymentReceivableVO.reestablishReceivableVO,
            disableManualCheckEntry:
              claimsData.advancePaymentReceivableVO.disableManualCheckEntry,
            feeAssdAmount: claimsData.advancePaymentReceivableVO.feeAssdAmount,
            writeOffReasonCode:
              claimsData.advancePaymentReceivableVO.writeOffReasonCode,
            auditForUpdate:
              claimsData.advancePaymentReceivableVO.auditForUpdate,
            update: claimsData.advancePaymentReceivableVO.update,
            originatingClaimsList:
              claimsData.advancePaymentReceivableVO.originatingClaimsList,
            defaultEntityTypeDisable:
              claimsData.advancePaymentReceivableVO.defaultEntityTypeDisable,
            memberSysID: claimsData.advancePaymentReceivableVO.memberSysID,
            syspayeeID: claimsData.advancePaymentReceivableVO.syspayeeID,
            voidIndicator: claimsData.advancePaymentReceivableVO.voidIndicator,
            fcnMediaCode: claimsData.advancePaymentReceivableVO.fcnMediaCode,
            agingInDays: claimsData.advancePaymentReceivableVO.agingInDays,
            originalFcn: claimsData.advancePaymentReceivableVO.originalFcn,
            establishedDateStr:
              claimsData.advancePaymentReceivableVO.establishedDateStr,
            lastAppliedDateStr:
              claimsData.advancePaymentReceivableVO.lastAppliedDateStr,
            adjustedARAmountDisable:
              claimsData.advancePaymentReceivableVO.adjustedARAmountDisable,
            balanceAmountTemp:
              claimsData.advancePaymentReceivableVO.balanceAmountTemp,
            claimsReceivableDetailsVO:
              claimsData.advancePaymentReceivableVO.claimsReceivableDetailsVO,
            manualCheckNumberDisable:
              claimsData.advancePaymentReceivableVO.manualCheckNumberDisable,
            manualCheckNumber:
              claimsData.advancePaymentReceivableVO.manualCheckNumber,
            manualCheckDate:
              claimsData.advancePaymentReceivableVO.manualCheckDate,
            manualCheckDateStrDisable:
              claimsData.advancePaymentReceivableVO.manualCheckDateStrDisable,
            manualCheckDateStr:
              claimsData.advancePaymentReceivableVO.manualCheckDateStr,
            approvalReqIndicator:
              claimsData.advancePaymentReceivableVO.approvalReqIndicator
          },
          manualCheckDateStr: claimsData.manualCheckDateStr,
          holdLetterIndicator: claimsData.holdLetterIndicator,
          receivablePurgeIndicator: claimsData.receivablePurgeIndicator,
          calculatedInterest: claimsData.calculatedInterest,
          calculatedPenalty: claimsData.calculatedPenalty,
          calculatedFees: claimsData.calculatedFees,
          agingCode: claimsData.agingCode,

          recoupmentVO: {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: claimsData.recoupmentVO.addedAuditUserID
              ? claimsData.recoupmentVO.addedAuditUserID
              : logInUserID,
            addedAuditTimeStamp: claimsData.recoupmentVO.addedAuditTimeStamp
              ? claimsData.recoupmentVO.addedAuditTimeStamp
              : getUTCTimeStamp(),
            versionNo: claimsData.recoupmentVO.versionNo,
            dbRecord: claimsData.recoupmentVO.dbRecord,
            sortColumn: claimsData.recoupmentVO.sortColumn,
            releaseDate: claimsData.recoupmentVO.releaseDate,
            returnDate: claimsData.recoupmentVO.returnDate,
            recoupmentTypeCodeDisable:
              claimsData.recoupmentVO.recoupmentTypeCodeDisable,
            recoupmentTypeCode: values.recoupmentCode
              ? values.recoupmentCode === DEFAULT_DD_VALUE
                ? null
                : values.recoupmentCode
              : null,
            installmentAmount: values.installmentValue,
            installmentAmountDisable:
              claimsData.recoupmentVO.installmentAmountDisable,
            percentageOriginalAmount: values.percentValue,
            percentageAmountDisable:
              claimsData.recoupmentVO.percentageAmountDisable,
            startDateStr: selectedStartDate
              ? moment(new Date(selectedStartDate).getTime()).format(
                dateTime
              )
              : null,
            frequency: values.frequency
              ? values.frequency === DEFAULT_DD_VALUE
                ? null
                : values.frequency
              : null,
            releaseDateStr: claimsData.recoupmentVO.releaseDateStr,
            returnDateStr: claimsData.recoupmentVO.returnDateStr,
            frequencyDisable: claimsData.recoupmentVO.frequencyDisable,
            renderedForWithHolding:
              claimsData.recoupmentVO.renderedForWithHolding,
            startDateStrDisable: claimsData.recoupmentVO.startDateStrDisable,
            releaseDateStrDisable:
              claimsData.recoupmentVO.releaseDateStrDisable,
            returnDateStrDisable: claimsData.recoupmentVO.returnDateStrDisable,
            defaultRecoupmentDisable:
              claimsData.recoupmentVO.defaultRecoupmentDisable
          },

          accountVO: {
            auditUserID: claimsData.accountVO.auditUserID,
            auditTimeStamp: claimsData.accountVO.auditTimeStamp,
            addedAuditUserID: claimsData.accountVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.accountVO.addedAuditTimeStamp,
            versionNo: claimsData.accountVO.versionNo,
            dbRecord: claimsData.accountVO.dbRecord,
            sortColumn: claimsData.accountVO.sortColumn,
            scoaFiscalYearDisable: claimsData.accountVO.scoaFiscalYearDisable,
            scoaFiscalYear: values.fiscalYear
              ? values.fiscalYear
              : claimsData.accountVO.scoaFiscalYear,
            fundCodeDisable: claimsData.accountVO.fundCodeDisable,
            fundCode:
              values.collocationCode !== optSelectOne
                ? values.collocationCode
                : claimsData.accountVO.fundCode,
            cosDisable: claimsData.accountVO.cosDisable,
            cos:
              values.categoryOfService !== optSelectOne
                ? values.categoryOfService
                : claimsData.accountVO.cos,
            cosValue: claimsData.accountVO.cosValue,
            fundCodeValue: claimsData.accountVO.fundCodeValue
          },

          fundSourceVO: {
            auditUserID: claimsData.fundSourceVO.auditUserID,
            auditTimeStamp: claimsData.fundSourceVO.auditTimeStamp,
            addedAuditUserID: claimsData.fundSourceVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.fundSourceVO.addedAuditTimeStamp,
            versionNo: claimsData.fundSourceVO.versionNo,
            dbRecord: claimsData.fundSourceVO.dbRecord,
            sortColumn: claimsData.fundSourceVO.sortColumn,
            auditKeyList: claimsData.fundSourceVO.auditKeyList,
            auditKeyListFiltered: claimsData.fundSourceVO.auditKeyListFiltered,
            voidIndicator: claimsData.fundSourceVO.voidIndicator,
            activeInd: claimsData.fundSourceVO.activeInd,
            dateDisable: claimsData.fundSourceVO.dateDisable,
            beginDate: claimsData.fundSourceVO.beginDate,
            fundVO: claimsData.fundSourceVO.fundVO,
            endDate: claimsData.fundSourceVO.endDate,
            federalPercentage: claimsData.fundSourceVO.federalPercentage,
            federal: claimsData.fundSourceVO.federal,
            statePercentage: claimsData.fundSourceVO.statePercentage,
            countyPercentage: claimsData.fundSourceVO.countyPercentage,
            otherPercentage: claimsData.fundSourceVO.otherPercentage,
            totalPercentage: claimsData.fundSourceVO.totalPercentage,
            sequenceNumber: claimsData.fundSourceVO.sequenceNumber,
            fundSourceVoidDate: claimsData.fundSourceVO.fundSourceVoidDate,
            voidValue: claimsData.fundSourceVO.voidValue,
            fundSourceVoidProtect: claimsData.fundSourceVO.fundSourceVoidProtect
          },

          exemptionsVO: {
            auditUserID: claimsData.exemptionsVO.auditUserID,
            auditTimeStamp: claimsData.exemptionsVO.auditTimeStamp,
            addedAuditUserID: claimsData.exemptionsVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.exemptionsVO.addedAuditTimeStamp,
            versionNo: claimsData.exemptionsVO.versionNo,
            dbRecord: claimsData.exemptionsVO.dbRecord,
            sortColumn: claimsData.exemptionsVO.sortColumn,
            exemptFromPenalty: claimsData.exemptionsVO.exemptFromPenalty,
            exemptFromCollectionAgency:
              claimsData.exemptionsVO.exemptFromCollectionAgency,
            exemptFromInterest: claimsData.exemptionsVO.exemptFromInterest,
            penaltyExemptionsIndDisable:
              claimsData.exemptionsVO.penaltyExemptionsIndDisable,
            interestExemptionsIndDisable:
              claimsData.exemptionsVO.interestExemptionsIndDisable,
            collectionAgencyIndDisable:
              claimsData.exemptionsVO.collectionAgencyIndDisable,
            penaltyExemptionsInd: claimsData.exemptionsVO.penaltyExemptionsInd,
            interestExemptionsInd:
              claimsData.exemptionsVO.interestExemptionsInd,
            collectionAgencyInd: claimsData.exemptionsVO.collectionAgencyInd
          },

          grossLevelReceivableVO: {
            auditUserID: claimsData.grossLevelReceivableVO.auditUserID,
            auditTimeStamp: claimsData.grossLevelReceivableVO.auditTimeStamp,
            addedAuditUserID:
              claimsData.grossLevelReceivableVO.addedAuditUserID,
            addedAuditTimeStamp:
              claimsData.grossLevelReceivableVO.addedAuditTimeStamp,
            versionNo: claimsData.grossLevelReceivableVO.versionNo,
            dbRecord: claimsData.grossLevelReceivableVO.dbRecord,
            sortColumn: claimsData.grossLevelReceivableVO.sortColumn,
            auditKeyList: claimsData.grossLevelReceivableVO.auditKeyList,
            auditKeyListFiltered:
              claimsData.grossLevelReceivableVO.auditKeyListFiltered,
            versionNoForFinancialDetail:
              claimsData.grossLevelReceivableVO.versionNoForFinancialDetail,
            versionNoForFinancialCrossReference:
              claimsData.grossLevelReceivableVO
                .versionNoForFinancialCrossReference,
            versionNoForCommonEntity:
              claimsData.grossLevelReceivableVO.versionNoForCommonEntity,
            versionNoForWLCommonEntity:
              claimsData.grossLevelReceivableVO.versionNoForWLCommonEntity,
            payoutFCN: claimsData.grossLevelReceivableVO.payoutFCN,
            noteSet: claimsData.grossLevelReceivableVO.noteSet,
            adjustedArAmt: claimsData.grossLevelReceivableVO.adjustedArAmt,
            receivableFCN: claimsData.grossLevelReceivableVO.receivableFCN,
            viewLienPayouts: claimsData.grossLevelReceivableVO.viewLienPayouts,
            fcnSequenceNo: claimsData.grossLevelReceivableVO.fcnSequenceNo,
            lob: AppConstants.DEFAULT_LOB_VALUE,
            payeePayerTypeCode:
              claimsData.grossLevelReceivableVO.payeePayerTypeCode,
            payeePayerTypeCodeDesc:
              claimsData.grossLevelReceivableVO.payeePayerTypeCodeDesc,
            payeePayerIDTypeCode:
              claimsData.grossLevelReceivableVO.payeePayerIDTypeCode,
            payeePayerIDTypeCodeDesc:
              claimsData.grossLevelReceivableVO.payeePayerIDTypeCodeDesc,
            establishedDate: claimsData.grossLevelReceivableVO.establishedDate,
            lastAppliedDate: claimsData.grossLevelReceivableVO.lastAppliedDate,
            calculatedPenalty:
              claimsData.grossLevelReceivableVO.calculatedPenalty,
            calculatedInterest:
              claimsData.grossLevelReceivableVO.calculatedInterest,
            calculatedFees: claimsData.grossLevelReceivableVO.calculatedFees,
            memberName: claimsData.grossLevelReceivableVO.memberName,
            confirmDailog: claimsData.grossLevelReceivableVO.confirmDailog,
            pageLoadIndicator:
              claimsData.grossLevelReceivableVO.pageLoadIndicator,
            memberIdDisable: claimsData.grossLevelReceivableVO.memberIdDisable,
            memberID: claimsData.grossLevelReceivableVO.memberID,
            memberIDTypeCodeDisable:
              claimsData.grossLevelReceivableVO.memberIDTypeCodeDisable,
            memberIDTypeCode:
              claimsData.grossLevelReceivableVO.memberIDTypeCode,
            activityTypeCode:
              claimsData.grossLevelReceivableVO.activityTypeCode,
            activityTypeCodeDesc:
              claimsData.grossLevelReceivableVO.activityTypeCodeDesc,
            originalAmount: claimsData.grossLevelReceivableVO.originalAmount,
            originalAmountDisable:
              claimsData.grossLevelReceivableVO.originalAmountDisable,
            agingCode: claimsData.grossLevelReceivableVO.agingCode,
            agingCodeDesc: claimsData.grossLevelReceivableVO.agingCodeDesc,
            agingCodeDisable:
              claimsData.grossLevelReceivableVO.agingCodeDisable,
            arAgeInDay: claimsData.grossLevelReceivableVO.arAgeInDay,
            balanceAmount: claimsData.grossLevelReceivableVO.balanceAmount,
            relatedFTTCN: claimsData.grossLevelReceivableVO.relatedFTTCN,
            payeePayerID: claimsData.grossLevelReceivableVO.payeePayerID,
            paymentSchedule: claimsData.grossLevelReceivableVO.paymentSchedule,
            exemptions: claimsData.grossLevelReceivableVO.exemptions,
            recoupment: claimsData.grossLevelReceivableVO.recoupment,
            accountVO: claimsData.grossLevelReceivableVO.accountVO,
            advancePaymentReceivableVO:
              claimsData.grossLevelReceivableVO.advancePaymentReceivableVO,
            grossLevelReceivableVO:
              claimsData.grossLevelReceivableVO.grossLevelReceivableVO,
            fundSourceVO: claimsData.grossLevelReceivableVO.fundSourceVO,
            accountReceivableReasonCode:
              claimsData.grossLevelReceivableVO.accountReceivableReasonCode,
            accountReceivableReasonCodeDesc:
              claimsData.grossLevelReceivableVO.accountReceivableReasonCodeDesc,
            payeePayerIdFromDB:
              claimsData.grossLevelReceivableVO.payeePayerIdFromDB,
            payeePayerNameFromDB:
              claimsData.grossLevelReceivableVO.payeePayerNameFromDB,
            sysID: claimsData.grossLevelReceivableVO.sysID,
            withHoldingStatus:
              claimsData.grossLevelReceivableVO.withHoldingStatus,
            exemptionsVO: claimsData.grossLevelReceivableVO.exemptionsVO,
            adjustmentVO: claimsData.grossLevelReceivableVO.adjustmentVO,
            transferVO: claimsData.grossLevelReceivableVO.transferVO,
            recoupmentVO: claimsData.grossLevelReceivableVO.recoupmentVO,
            approvalIndicator: claimsData.approvalIndicator,
            approvalIndicatorDisable:
              claimsData.grossLevelReceivableVO.approvalIndicatorDisable,
            schedulePaymentList:
              claimsData.grossLevelReceivableVO.schedulePaymentList,
            schedulePaymentMap:
              claimsData.grossLevelReceivableVO.schedulePaymentMap,
            schedulePaymentMapFromDB:
              claimsData.grossLevelReceivableVO.schedulePaymentMapFromDB,
            paymentScheduleVO: {
              auditUserID:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.auditUserID,
              auditTimeStamp:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .auditTimeStamp,
              addedAuditUserID:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .addedAuditUserID,
              addedAuditTimeStamp:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .addedAuditTimeStamp,
              versionNo:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.versionNo,
              dbRecord:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.dbRecord,
              sortColumn:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.sortColumn,
              auditKeyList:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .auditKeyList,
              auditKeyListFiltered:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .auditKeyListFiltered,
              dueDate:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.dueDate,
              dueAmount:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.dueAmount,
              defaultedDate:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .defaultedDate,
              defaultedIndicator:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .defaultedIndicator,
              defaultedIndicatorImage:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .defaultedIndicatorImage,
              renderAddPaymentHeader:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .renderAddPaymentHeader,
              renderEditPaymentHeader:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .renderEditPaymentHeader,
              paymentScheduleRowIndex:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .paymentScheduleRowIndex,
              renderDeletePaymentSection:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .renderDeletePaymentSection,
              paymentScheduleKey:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .paymentScheduleKey,
              defaultDateStr:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .defaultDateStr,
              dueDateStr:
                claimsData.grossLevelReceivableVO.paymentScheduleVO.dueDateStr,
              renderPaymentSection:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .renderPaymentSection,
              enableOnSchedulePayment:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .enableOnSchedulePayment,
              disableEditPaymentSchedule:
                claimsData.grossLevelReceivableVO.paymentScheduleVO
                  .disableEditPaymentSchedule
            },
            reversalVO: claimsData.grossLevelReceivableVO.reversalVO,
            withholdingVO: claimsData.grossLevelReceivableVO.withholdingVO,
            historyList: claimsData.grossLevelReceivableVO.historyList,
            historyMap: claimsData.grossLevelReceivableVO.historyMap,
            historyVO: claimsData.grossLevelReceivableVO.historyVO,
            reestablishReceivableVO:
              claimsData.grossLevelReceivableVO.reestablishReceivableVO,
            disableManualCheckEntry:
              claimsData.grossLevelReceivableVO.disableManualCheckEntry,
            feeAssdAmount: claimsData.grossLevelReceivableVO.feeAssdAmount,
            writeOffReasonCode:
              claimsData.grossLevelReceivableVO.writeOffReasonCode,
            auditForUpdate: claimsData.grossLevelReceivableVO.auditForUpdate,
            update: claimsData.grossLevelReceivableVO.update,
            originatingClaimsList:
              claimsData.grossLevelReceivableVO.originatingClaimsList,
            defaultEntityTypeDisable:
              claimsData.grossLevelReceivableVO.defaultEntityTypeDisable,
            memberSysID: claimsData.grossLevelReceivableVO.memberSysID,
            syspayeeID: claimsData.grossLevelReceivableVO.syspayeeID,
            voidIndicator: claimsData.grossLevelReceivableVO.voidIndicator,
            fcnMediaCode: claimsData.grossLevelReceivableVO.fcnMediaCode,
            agingInDays: claimsData.grossLevelReceivableVO.agingInDays,
            originalFcn: claimsData.grossLevelReceivableVO.originalFcn,
            establishedDateStr:
              claimsData.grossLevelReceivableVO.establishedDateStr,
            lastAppliedDateStr:
              claimsData.grossLevelReceivableVO.lastAppliedDateStr,
            adjustedARAmountDisable:
              claimsData.grossLevelReceivableVO.adjustedARAmountDisable,
            balanceAmountTemp:
              claimsData.grossLevelReceivableVO.balanceAmountTemp,
            claimsReceivableDetailsVO:
              claimsData.grossLevelReceivableVO.claimsReceivableDetailsVO,
            beginDate: claimsData.grossLevelReceivableVO.beginDate,
            endDate: claimsData.grossLevelReceivableVO.endDate,
            beginDateStrDisable:
              claimsData.grossLevelReceivableVO.beginDateStrDisable,
            beginDateStr: claimsData.grossLevelReceivableVO.beginDateStr,
            endDateStrDisable:
              claimsData.grossLevelReceivableVO.endDateStrDisable,
            endDateStr: claimsData.grossLevelReceivableVO.endDateStr,
            appealIndicatorDisable:
              claimsData.grossLevelReceivableVO.appealIndicatorDisable,
            appealIndicator: claimsData.grossLevelReceivableVO.appealIndicator,
            tempEndDateStr: claimsData.grossLevelReceivableVO.tempEndDateStr,
            tempBeginDateStr:
              claimsData.grossLevelReceivableVO.tempBeginDateStr,
            approvalReqIndicator:
              claimsData.grossLevelReceivableVO.approvalReqIndicator
          },
          schedulePaymentList: finalPaymentScheduleData,
          relatedFTTCN: claimsData.relatedFTTCN,
          voidIndicator: claimsData.voidIndicator,
          accountReceivableReasonCodeDesc:
            claimsData.accountReceivableReasonCodeDesc,
          payeePayerNameFromDB: claimsData.payeePayerNameFromDB,
          sysID: claimsData.sysID,
          withHoldingStatus: claimsData.withHoldingStatus,
          adjustmentVO: claimsData.adjustmentVO,
          approvalIndicatorDisable: claimsData.approvalIndicatorDisable,
          schedulePaymentMap: claimsData.schedulePaymentMap,
          schedulePaymentMapFromDB: claimsData.schedulePaymentMapFromDB,
          historyList: claimsData.historyList,
          historyMap: claimsData.historyMap,
          disableManualCheckEntry: claimsData.disableManualCheckEntry,
          feeAssdAmount: claimsData.feeAssdAmount,
          writeOffReasonCode: claimsData.writeOffReasonCode,
          auditForUpdate: claimsData.auditForUpdate,
          update: claimsData.update,
          defaultEntityTypeDisable: claimsData.defaultEntityTypeDisable,
          memberSysID: claimsData.memberSysID,
          syspayeeID: claimsData.syspayeeID,
          agingInDays: claimsData.agingInDays,
          originalFcn: claimsData.originalFcn,
          establishedDateStr: claimsData.establishedDateStr,
          lastAppliedDateStr: claimsData.lastAppliedDateStr,
          adjustedARAmountDisable: claimsData.adjustedARAmountDisable,
          balanceAmountTemp: claimsData.balanceAmountTemp,
          approvalReqIndicator: claimsData.approvalReqIndicator,
          paymentScheduleVO: {
            auditUserID: claimsData.paymentScheduleVO.auditUserID,
            auditTimeStamp: claimsData.paymentScheduleVO.auditTimeStamp,
            addedAuditUserID: claimsData.paymentScheduleVO.addedAuditUserID,
            addedAuditTimeStamp:
              claimsData.paymentScheduleVO.addedAuditTimeStamp,
            versionNo: claimsData.paymentScheduleVO.versionNo,
            dbRecord: claimsData.paymentScheduleVO.dbRecord,
            sortColumn: claimsData.paymentScheduleVO.sortColumn,
            auditKeyList: claimsData.paymentScheduleVO.auditKeyList,
            auditKeyListFiltered:
              claimsData.paymentScheduleVO.auditKeyListFiltered,
            dueDate: claimsData.paymentScheduleVO.dueDate,
            dueAmount: claimsData.paymentScheduleVO.dueAmount,
            defaultedDate: claimsData.paymentScheduleVO.defaultedDate,
            defaultedIndicator: claimsData.paymentScheduleVO.defaultedIndicator,
            defaultedIndicatorImage:
              claimsData.paymentScheduleVO.defaultedIndicatorImage,
            renderAddPaymentHeader:
              claimsData.paymentScheduleVO.renderAddPaymentHeader,
            renderEditPaymentHeader:
              claimsData.paymentScheduleVO.renderEditPaymentHeader,
            paymentScheduleRowIndex:
              claimsData.paymentScheduleVO.paymentScheduleRowIndex,
            renderDeletePaymentSection:
              claimsData.paymentScheduleVO.renderDeletePaymentSection,
            paymentScheduleKey: claimsData.paymentScheduleVO.paymentScheduleKey,
            defaultDateStr: claimsData.paymentScheduleVO.defaultDateStr,
            dueDateStr: claimsData.paymentScheduleVO.dueDateStr,
            renderPaymentSection:
              claimsData.paymentScheduleVO.renderPaymentSection,
            enableOnSchedulePayment:
              claimsData.paymentScheduleVO.enableOnSchedulePayment,
            disableEditPaymentSchedule:
              claimsData.paymentScheduleVO.disableEditPaymentSchedule
          },
          reversalVO: {
            auditUserID: claimsData.reversalVO.auditUserID,
            auditTimeStamp: claimsData.reversalVO.auditTimeStamp,
            addedAuditUserID: claimsData.reversalVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.reversalVO.addedAuditTimeStamp,
            versionNo: claimsData.reversalVO.versionNo,
            dbRecord: claimsData.reversalVO.dbRecord,
            sortColumn: claimsData.reversalVO.sortColumn,
            reversedAppliedAmount: claimsData.reversalVO.reversedAppliedAmount,
            reversedEstablishedDate:
              claimsData.reversalVO.reversedEstablishedDate,
            reversedAgingCode: claimsData.reversalVO.reversedAgingCode,
            reversedTCN: claimsData.reversalVO.reversedTCN,
            reversedFundCode: claimsData.reversalVO.reversedFundCode,
            reasonCode: claimsData.reversalVO.reasonCode,
            memberName: claimsData.reversalVO.memberName,
            penalty: claimsData.reversalVO.penalty,
            fee: claimsData.reversalVO.fee,
            reversedEstablishedDateStr:
              claimsData.reversalVO.reversedEstablishedDateStr,
            interest: claimsData.reversalVO.interest
          },
          transferVO: {
            auditUserID: claimsData.transferVO.auditUserID,
            auditTimeStamp: claimsData.transferVO.auditTimeStamp,
            addedAuditUserID: claimsData.transferVO.addedAuditUserID,
            addedAuditTimeStamp: claimsData.transferVO.addedAuditTimeStamp,
            versionNo: claimsData.transferVO.versionNo,
            dbRecord: claimsData.transferVO.dbRecord,
            sortColumn: claimsData.transferVO.sortColumn,
            fcn: claimsData.transferVO.fcn,
            transferActivityTypeCodeDesc:
              claimsData.transferVO.transferActivityTypeCodeDesc,
            transferReasonCodeDesc:
              claimsData.transferVO.transferReasonCodeDesc,
            transferReasonCode: claimsData.transferVO.transferReasonCode,
            establishedDate: claimsData.transferVO.establishedDate,
            relatedFTTCN: claimsData.transferVO.relatedFTTCN,
            originalAmount: claimsData.transferVO.originalAmount,
            balanceAmount: claimsData.transferVO.balanceAmount,
            payeePayerTypeCode: claimsData.transferVO.payeePayerTypeCode,
            payeePayerIDTypeCode: claimsData.transferVO.payeePayerIDTypeCode,
            payeePayerID: claimsData.transferVO.payeePayerID,
            payeePayerIdFromDB: claimsData.transferVO.payeePayerIdFromDB,
            payeePayerNameFromDB: claimsData.transferVO.payeePayerNameFromDB,
            sysID: claimsData.transferVO.sysID,
            fcnSeqNum: claimsData.transferVO.fcnSeqNum,
            fcnMediaCode: claimsData.transferVO.fcnMediaCode,
            agingCode: claimsData.transferVO.agingCode,
            establishedDateStr: claimsData.transferVO.establishedDateStr
          },
          claimsReceivableDetailsVO: {
            receivableFCN: claimsData.claimsReceivableDetailsVO.receivableFCN,
            establishedDate:
              claimsData.claimsReceivableDetailsVO.establishedDate,
            lastAppliedDate:
              claimsData.claimsReceivableDetailsVO.lastAppliedDate,
            agingCode: claimsData.claimsReceivableDetailsVO.agingCode,
            adjustedArAmt: claimsData.claimsReceivableDetailsVO.adjustedArAmt,
            originalAmount: claimsData.claimsReceivableDetailsVO.originalAmount,
            balanceAmount: claimsData.claimsReceivableDetailsVO.balanceAmount,
            relatedFTTCN: claimsData.claimsReceivableDetailsVO.relatedFTTCN
          },
          noteSetVO: notesInput
        };
        const index = attachmentsTableData.length - 1;
        const attachmentVOList = [];
        if (
          attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
        ) {
          attachmentsTableData.map(value => {
            if (value.id) {
              const attachmentVO = {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: true,
                sortColumn: null,
                attachmentSK: null,
                attachmentPageID: null,
                finalPath: null,
                detachInd: false,
                historyIndicator: false,
                cascadeKey: null,
                attachmentIndicator: null,
                dateAdded: getDateInMMDDYYYYFormatWithApendZero(
                  new Date(
                    new Date().toLocaleString('en-US', {
                      timeZone: process.env.REACT_APP_TIMEZONE
                    })
                  )
                ),
                fileUrl: null,
                showDeleteForSearched: false,
                rowIndex: 0,
                addedBy: logInUserID,
                description: value ? value.description : null,
                edmsStoredDate: null,
                anEDMSPageId: value? value.anEDMSPageId : null,
                fileName: attachmentsTableData && value ? value.fileName : null,
                file1: null,
                edmsWrkUnitLevel: null,
                edmsDocType: null
              };
              attachmentVOList.push(attachmentVO);
            }
          });
        }
        if (values.recoupmentCode === 'D') {
          value.recoupmentVO.startDateStr = claimsData.establishedDateStr ? moment(claimsData.establishedDateStr).format(dateTime)
            : '';
        };
        value.attachmentVO = null;
        value.attachmentsList = attachmentVOList;
        value.payeeStatus = values.payeeStatus !== DEFAULT_DD_VALUE
        ? values.payeeStatus
        : null;
        onClaimsReceivableSave(value, index);
      }
    }
  };

  /* This function is setting values */
  const setFieldValues = (
    recoupmentVal,
    installmentVal,
    percentVal,
    frequencyVal,
    amountDueVal,
    defaultVal,
    paymentScheduleObj = null
  ) => {
    var payeeTypeDesc = '';
    var payeeTypeIdCodeDesc = '';
    var activityDesc = '';
    var reasonDesc = '';
    var arLetterDesc = '';
    if (payeeTypeData && payeeTypeData.length > 0) {
      payeeTypeData.map(payee => {
        if (payee.code === claimsData.payeePayerTypeCode) {
          payeeTypeDesc = payee.description;
          setValues({ ...values, payeeTypeDataDesc: payeeTypeDesc });
        }
      });
    }

    if (payeeIDTypeCodeData && payeeIDTypeCodeData.length > 0) {
      payeeIDTypeCodeData.map(payee => {
        if (payee.code === claimsData.payeePayerIDTypeCode) {
          payeeTypeIdCodeDesc = payee.description;
          setValues({
            ...values,
            payeePayerIDTypeCodeDesc: payeeTypeIdCodeDesc
          });
        }
      });
    }

    if (activityTypeData && activityTypeData.length > 0) {
      activityTypeData.map(activity => {
        if (activity.code === claimsData.activityTypeCode) {
          activityDesc = activity.description;
          setValues({ ...values, activityTypeDataDesc: activityDesc });
        }
      });
    }

    if (arLetterSentData && arLetterSentData.length > 0) {
      arLetterSentData.map(arCode => {
        if (arCode.code === claimsData.agingCode) {
          arLetterDesc = arCode.description;
          setValues({ ...values, arLetterDesc: arLetterDesc });
        }
      });
    }

    if (reasonCodeData && reasonCodeData.length > 0) {
      reasonCodeData.map(reasonCode => {
        if (reasonCode.code === claimsData.accountReceivableReasonCode) {
          reasonDesc = reasonCode.description;
          const longDesc = reasonCode.longDescription;
          setValues({
            ...values,
            reasonCodeDesc: reasonDesc,
            reasonCodeLongDesc: longDesc
          });
        }
      });
    }
    if (paymentScheduleObj) {
      setValues({
        ...values,
        lob: AppConstants.DEFAULT_LOB_VALUE,
        payeeType:
          payeeTypeDesc !== '' ? payeeTypeDesc : claimsData.payeePayerTypeCode,
        payeeIdTypeCode:
          payeeTypeIdCodeDesc !== ''
            ? payeeTypeIdCodeDesc
            : claimsData.payeePayerIDTypeCode,
        payeeId: claimsData.payeePayerID ? claimsData.payeePayerID : '',
        payeePayerName: claimsData.payeePayerNameFromDB
          ? claimsData.payeePayerNameFromDB
          : '',
        activityCode:
          activityDesc !== '' ? activityDesc : claimsData.activityTypeCode,
        reasonCode:
          reasonDesc !== ''
            ? reasonDesc
            : claimsData.accountReceivableReasonCode,
        systemPayeeId: claimsData.sysID ? claimsData.sysID : '',
        fcn: claimsData.receivableFCN ? claimsData.receivableFCN : '',
        payeeStatus: claimsData.payeeStatus ? claimsData.payeeStatus : '',
        ftTcn: claimsData.relatedFTTCN ? claimsData.relatedFTTCN : '',
        establishedDate: claimsData.establishedDateStr
          ? moment(claimsData.establishedDateStr).format(dateTime)
          : '',
        lastDate: claimsData.lastAppliedDateStr
          ? moment(claimsData.lastAppliedDateStr).format(dateTime)
          : '',
        arLetter: arLetterDesc !== '' ? arLetterDesc : claimsData.agingCode,
        originalAmount: claimsData.originalAmount
          ? numeral(claimsData.originalAmount).format('$0,0.00')
          : '',
        arInDays: claimsData.arAgeInDays ? claimsData.arAgeInDays : '',
        adjustedAmount: claimsData.adjustedArAmt
          ? numeral(claimsData.adjustedArAmt).format('$0,0.00')
          : '',
        balanceAmount: claimsData.balanceAmount
          ? numeral(claimsData.balanceAmount).format('$0,0.00')
          : '',
        recoupmentCode: recoupmentVal,
        frequency: frequencyVal,
        installmentValue: installmentVal
          ? numeral(installmentVal).format('0,0.00')
          : '',
        percentValue: percentVal ? numeral(percentVal).format('0,0.00') : '',
        defaultOnPaymentSchedule: defaultVal,
        amountDue: amountDueVal,
        originalAmountHistory: claimsData.originalAmount
          ? numeral(claimsData.originalAmount).format('$0,0.00')
          : '',
        appliedAmount: claimsData.historyVO.appliedAmount
          ? numeral(claimsData.historyVO.appliedAmount).format('$0,0.00')
          : '',
        balanceAmountHistory: claimsData.balanceAmount
          ? numeral(claimsData.balanceAmount).format('$0,0.00')
          : '',
        paymentScheduleType: claimsData.paymentScheduleType
          ? claimsData.paymentScheduleType
          : optSelectOne,
        recoupmentStartDate: claimsData.recoupmentStartDate
          ? claimsData.recoupmentStartDate
          : null,
        numberofInstallments: claimsData.numberofInstallments
          ? claimsData.numberofInstallments
          : '',
        interestCalculation: claimsData.interestCalculation
          ? claimsData.interestCalculation
          : 'N',
        interestPercentage: claimsData.interestPercentage
          ? numeral(claimsData.interestPercentage).format('0,0.00')
          : '',
        paymentScheduleFrequency: claimsData.paymentScheduleFrequency
          ? claimsData.paymentScheduleFrequency
          : optSelectOne,
        fiscalYear:
          claimsData &&
          claimsData.accountVO &&
          claimsData.accountVO.scoaFiscalYear
            ? claimsData.accountVO.scoaFiscalYear
            : '',
        categoryOfService:
          claimsData && claimsData.accountVO && claimsData.accountVO.cos
            ? claimsData.accountVO.cos
            : '',
        collocationCode:
          claimsData && claimsData.accountVO && claimsData.accountVO.fundCode
            ? claimsData.accountVO.fundCode
            : optSelectOne,
        federal:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(
              claimsData.fundSourceAllocationVO.federalPercentage
            ).format('0.00')
            : '',
        state:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(claimsData.fundSourceAllocationVO.statePercentage).format(
              '0.00'
            )
            : '',
        county:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(
              claimsData.fundSourceAllocationVO.countyPercentage
            ).format('0.00')
            : '',
        other:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(claimsData.fundSourceAllocationVO.otherPercentage).format(
              '0.00'
            )
            : ''
      });
      setSelectedRecoupmentStartDate(
        claimsData.recoupmentStartDate ? claimsData.recoupmentStartDate : null
      );
    } else {
      setValues({
        ...values,
        lob: AppConstants.DEFAULT_LOB_VALUE,
        payeeType:
          payeeTypeDesc !== '' ? payeeTypeDesc : claimsData.payeePayerTypeCode,
        payeeIdTypeCode:
          payeeTypeIdCodeDesc !== ''
            ? payeeTypeIdCodeDesc
            : claimsData.payeePayerIDTypeCode,
        payeeId: claimsData.payeePayerID ? claimsData.payeePayerID : '',
        payeePayerName: claimsData.payeePayerNameFromDB
          ? claimsData.payeePayerNameFromDB
          : '',
        activityCode:
          activityDesc !== '' ? activityDesc : claimsData.activityTypeCode,
        reasonCode:
          reasonDesc !== ''
            ? reasonDesc
            : claimsData.accountReceivableReasonCode,
        systemPayeeId: claimsData.sysID ? claimsData.sysID : '',
        fcn: claimsData.receivableFCN ? claimsData.receivableFCN : '',
        ftTcn: claimsData.relatedFTTCN ? claimsData.relatedFTTCN : '',
        establishedDate: claimsData.establishedDateStr
          ? moment(claimsData.establishedDateStr).format(dateTime)
          : '',
        lastDate: claimsData.lastAppliedDateStr
          ? moment(claimsData.lastAppliedDateStr).format(dateTime)
          : '',
        arLetter: arLetterDesc !== '' ? arLetterDesc : claimsData.agingCode,
        originalAmount: claimsData.originalAmount
          ? numeral(claimsData.originalAmount).format('$0,0.00')
          : '',
        arInDays: claimsData.arAgeInDays ? claimsData.arAgeInDays : '',
        adjustedAmount: claimsData.adjustedArAmt
          ? numeral(claimsData.adjustedArAmt).format('$0,0.00')
          : '',
        balanceAmount: claimsData.balanceAmount
          ? numeral(claimsData.balanceAmount).format('$0,0.00')
          : '',
        recoupmentCode: recoupmentVal,
        frequency: frequencyVal,
        installmentValue: installmentVal
          ? numeral(installmentVal).format('0,0.00')
          : '',
        percentValue: percentVal ? numeral(percentVal).format('0,0.00') : '',
        defaultOnPaymentSchedule: defaultVal,
        amountDue: amountDueVal,
        originalAmountHistory: claimsData.originalAmount
          ? numeral(claimsData.originalAmount).format('$0,0.00')
          : '',
        appliedAmount: claimsData.historyVO.appliedAmount
          ? numeral(claimsData.historyVO.appliedAmount).format('$0,0.00')
          : '',
        balanceAmountHistory: claimsData.balanceAmount
          ? numeral(claimsData.balanceAmount).format('$0,0.00')
          : '',
        fiscalYear:
          claimsData &&
          claimsData.accountVO &&
          claimsData.accountVO.scoaFiscalYear
            ? claimsData.accountVO.scoaFiscalYear
            : '',
        categoryOfService:
          claimsData && claimsData.accountVO && claimsData.accountVO.cos
            ? claimsData.accountVO.cos
            : '',
        collocationCode:
          claimsData && claimsData.accountVO && claimsData.accountVO.fundCode
            ? claimsData.accountVO.fundCode
            : optSelectOne,
        federal:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(
              claimsData.fundSourceAllocationVO.federalPercentage
            ).format('0.00')
            : '',
        state:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(claimsData.fundSourceAllocationVO.statePercentage).format(
              '0.00'
            )
            : '',
        county:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(
              claimsData.fundSourceAllocationVO.countyPercentage
            ).format('0.00')
            : '',
        other:
          claimsData && claimsData.fundSourceAllocationVO
            ? numeral(claimsData.fundSourceAllocationVO.otherPercentage).format(
              '0.00'
            )
            : ''
      });
      setSelectedRecoupmentStartDate(
        claimsData.recoupmentStartDate ? claimsData.recoupmentStartDate : null
      );
    }
  };
  const valuesFromAREdit = props.history && props.history.location &&
    props.history.location.state && props.history.location.state.values;

  const payloadPayees = props.history && props.history.location &&
    props.history.location.state && props.history.location.state.payloadPayees;
  const tableData = props?.history?.location?.state?.tableData;

    const cancelMinorSection = () => {
      setDialogOpen(false);
      setFocusCheck(false);
      dispatch(resetL2KeyDataFromStore('accountReceivableState', 'payloadClaims'));
      props.history.push({
        pathname: searchValues?.fromPath || '/FinancialAccountInquiry',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { valuesFromAREdit: valuesFromAREdit, payloadPayees: payloadPayees, tableData: (tableData || payloadPayees) }
      });
  };

  const majorCancel = () => {
	sessionStorage.setItem('isCancel', true);
    setAllowNavigation(false);
    dispatch(setAccReceivableUpdateResponse());
    setTimeout(() => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      dispatch(resetL2KeyDataFromStore('accountReceivableState', 'payloadClaims'));
      props.history.push({
        pathname: searchValues?.fromPath || '/FinancialAccountInquiry',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { valuesFromAREdit: valuesFromAREdit, payloadPayees: payloadPayees, tableData: (tableData || payloadPayees) }
      });
    }
  }, 100);
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <div
          align="right"
          className="nav-menu"
          style={{ paddingRight: '5px' }}
        >
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARAdjust}>&nbsp;&nbsp;Adjust</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Adjust</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARReverse} data-testid="reverse_hyperlink_testid">&nbsp;&nbsp;Reverse</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Reverse</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARTransfer}>&nbsp;&nbsp;Transfer</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Transfer</span>
          )}
          {writeOff ? (
            userInquiryPrivileges ? (
              <span>
                <a onClick={NavigateToWriteOff}>&nbsp;&nbsp;Write-Off</a>
              </span>
            ) : (
              <span>&nbsp;&nbsp;Write-Off</span>
            )
          ) : userInquiryPrivileges ? (
            <span>
              <a onClick={NavigateToWriteOffRestablish}>
                &nbsp;&nbsp;Write-Off Re-establish
              </a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Write-Off Re-establish</span>
          )}
        </div>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}

        <div className="tab-header">
          <h1 className="tab-heading float-left">
            {ClaimsReceivableConstants.CR_HEAD}
          </h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveClaimsReceivableData()}
              disabled={!userInquiryPrivileges}
              data-testid='major_Save'
            >
              <i className="fa fa-check" aria-hidden="true"></i> {AppConstants.SAVE}
            </Button>
            <Button
              color="primary"
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>
            {window.location.pathname === '/ClaimsReceivable' && (
              <Button title="Cancel" data-testid="test-cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form with-select input-md ">
                <TextField
                  id="payeeType"
                  fullWidth
                  label={AppConstants.PAYEE_TYPE}
                  value={
                    values.payeeTypeDataDesc
                      ? values.payeeTypeDataDesc
                      : values.payeeType
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                />
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  id="payeeIdTypeCode"
                  fullWidth
                  label={AppConstants.PAYEE_ID_TYPE_CODE}
                  value={
                    values.payeePayerIDTypeCodeDesc
                      ? values.payeePayerIDTypeCodeDesc
                      : values.payeeIdTypeCode
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeId"
                  fullWidth
                  label={AppConstants.PAYEE_ID}
                  disabled={true}
                  value={values.payeeId}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  disabled={true}
                  fullWidth
                  id="payeePayerName"
                  label={AppConstants.PAYEE_NAME}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                />
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md has-link">
                <TextField
                  disabled={true}
                  fullWidth
                  id="systemPayeeId"
                  label={AppConstants.SYSTEM_PAYEE_ID}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values)} target='_blank'>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  disabled={true}
                  fullWidth
                  id="activityCode"
                  label={AppConstants.ACTIVITY_TYPE}
                  value={
                    values.activityTypeDataDesc
                      ? values.activityTypeDataDesc
                      : values.activityCode
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  disabled={true}
                  fullWidth
                  id="reasonCode"
                  label={AppConstants.REASON_CODE}
                  value= {
                    values.reasonCodeDesc
                      ? values.reasonCodeDesc
                      : values.reasonCode
                  }
                  title={
                    values.reasonCodeLongDesc
                      ? values.reasonCodeLongDesc
                      : getLongDescription(reasonCodeDD, values.reasonCode)
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>
          </form>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={ClaimsReceivableConstants.CR_TEXT}
                    className={'original-tab' + color}
                  />
                  <Tab label={ClaimsReceivableConstants.ORIGINATING_CLAIMS} />
                  <Tab label={AppConstants.PAYMENT_SCHEDULE} data-testid="payment_schedule_tab_testid" />
                  <Tab label={AppConstants.HISTORY} />
                  <Tab label={AppConstants.NOTES} />
                  <Tab label={AppConstants.ATTACHMENT} />
                </Tabs>
              </AppBar>

              <TabPanel
                value={value}
                index={0}
              >
                <ClaimReceivableTabDetail
                  payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                  values={values}
                  recoupmentCodeData={recoupmentCodeData}
                  frequencyData={frequencyData}
                  handleChange={handleChange}
                  frequencyDataDropdown={frequencyDataDropdown}
                  errors={[
                    showRecoupmentCodeError,
                    showInstallmentAmountError,
                    showPercentAmountError,
                    showStartDateError,
                    showFrequencyError,
                    showFiscalYearError,
                    showCollocationCodeError
                  ]}
                  errorText={[
                    showRecoupmentCodeErrorText,
                    showInstallmentAmountErrorText,
                    showPercentAmountErrorText,
                    showStartDateErrorText,
                    showFrequencyErrorText,
                    showFiscalYearErrorText,
                    showCollocationCodeErrorText
                  ]}
                  fundCodeData={fundCodeData}
                  COSData={COSData}
                  selectedStartDate={selectedStartDate}
                  handleStartDateChange={handleStartDateChange}
                  startDatePress={startDatePress}
                  handleStartDateText={handleStartDateText}
                  disabled={disabled}
                  recoupmentDisabled={recoupmentDisabled}
                  defaultRecoupment={handleDefaultRecoupment}
                  formatDecimalField={formatDecimalField}
                  formatPercent={formatPercent}
                  removeFormatDecimalFieldInstallment={
                    removeFormatDecimalFieldInstallment
                  }
                ></ClaimReceivableTabDetail>
              </TabPanel>

              <TabPanel
                value={value}
                index={1}
              >
                <ClaimsOriginating tabData={claimsData} aRRSNCDData={aRRSNCDData} values={values}
                addDropdowns={addDropdowns}
                voidRscdDropdown={voidRscdDropdown}
                ></ClaimsOriginating>
              </TabPanel>

              <TabPanel
                value={value}
                index={2}
              >
                <>
                  <div className="tab-holder">
                    <form autoComplete="off">
                      <div className="form-wrapper">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <NativeDropDown
                            className='min-md'
                            id="Payment_Schedule_DD"
                              label="Payment Schedule"
                              value={values.paymentScheduleType}
                            onChange={handleChange('paymentScheduleType')}
                            inputProps={{ maxlength: 3, "data-testid": "payment_schedule_testid" }}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentCode !== 'S' ||
                                    manualEditFlag
                              }
                            isRequired={values.recoupmentCode === 'S'}
                            errTxt={
                                paymentScheduleTypeError !== ''
                                  ? paymentScheduleTypeError
                                  : null
                              }
                            dropdownList={paymentScheduleTypeData.map((option, index) => (
                              <option key={index} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                          <div className="mui-custom-form with-date">
                            <KeyboardDatePicker
                              fullWidth
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id="GrossLevelDetailBegindate-picker-dialog"
                              label="Recoupment Start Date"
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              inputProps={{ "data-testid": "recoupment_start_date_testid" }}
                              minDate={
                                new Date(
                                  new Date().toLocaleString('en-US', {
                                    timeZone: process.env.REACT_APP_TIMEZONE
                                  })
                                )
                              }
                              initialFocusedDate={
                                new Date(
                                  new Date().toLocaleString('en-US', {
                                    timeZone: process.env.REACT_APP_TIMEZONE
                                  })
                                )
                              }
                              value={selectedRecoupmentStartDate}
                              onChange={handleRecoupmentStartDateChange}
                              InputLabelProps={{
                                shrink: true
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              required={values.recoupmentCode === 'S'}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentCode !== 'S' ||
                                    manualEditFlag
                              }
                              helperText={
                                recoupmentStartDateError !== ''
                                  ? recoupmentStartDateError
                                  : null
                              }
                              error={
                                recoupmentStartDateError !== ''
                                  ? recoupmentStartDateError
                                  : null
                              }
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                        <div className="mui-custom-form input-md override-m-2">
                          <TextField
                            fullWidth
                            id="Number_of_Installments"
                            label="Number of Installments"
                            placeholder=""
                            inputProps={{ maxlength: 10 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={values.numberofInstallments}
                            onChange={handleChange('numberofInstallments')}
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : values.recoupmentCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  manualEditFlag
                            }
                            required={values.paymentScheduleType === 'A'}
                            helperText={
                              numberofInstallmentError !== ''
                                ? numberofInstallmentError
                                : null
                            }
                            error={
                              numberofInstallmentError !== ''
                                ? numberofInstallmentError
                                : null
                            }
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/,
                                ''
                              );
                            }}
                          ></TextField>
                        </div>
                        <NativeDropDown
                          className='min-md override-m-2'
                          id="Frequency"
                            label="Frequency"
                            value={values.paymentScheduleFrequency}
                          onChange={handleChange('paymentScheduleFrequency')}
                            inputProps={{ maxlength: 3 }}
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : values.recoupmentCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  manualEditFlag
                            }
                          isRequired={values.paymentScheduleType === 'A'}
                          errTxt={
                              frequencyError !== '' ? frequencyError : null
                            }
                          dropdownList={frequencyRecoupData.map((option, index) => (
                            <option key={index} value={option.code}>
                                {option.description}
                            </option>
                            ))}
                        />
                      </div>
                      <div className="form-wrapper">
                        <div
                          className="mui-custom-form min-md"
                          style={{ lineHeight: '100%' }}
                        >
                          <label className="MuiFormLabel-root small-label">
                            Interest Calculation
                          </label>
                          <div className="sub-radio no-margin">
                            <Radio
                              value="Y"
                              id="GroosLevelDetailsapprovalIdYes"
                              checked={values.interestCalculation === 'Y'}
                              onChange={handleChange('interestCalculation')}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                              }
                            />
                            <label
                              className="text-black"
                              htmlFor="GroosLevelDetailsapprovalIdYes"
                            >
                              Yes
                            </label>
                            <Radio
                              value="N"
                              id="GroosLevelDetailsapprovalIdNo"
                              checked={values.interestCalculation === 'N'}
                              onChange={handleChange('interestCalculation')}
                              className="ml-2"
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                              }
                            />
                            <label
                              className="text-black"
                              htmlFor="GroosLevelDetailsapprovalIdNo"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        <div className="mui-custom-form input-md">
                          <TextField
                            fullWidth
                            id="Interest_Percentage"
                            label="Interest Percentage"
                            placeholder=""
                            inputProps={{ maxlength: 14 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              endAdornment: <InputAdornment>%</InputAdornment>
                            }}
                            value={values.interestPercentage}
                            onBlur={formatDecimalField('interestPercentage')}
                            onFocus={removeFormatDecimalFieldPercent(
                              'interestPercentage'
                            )}
                            onChange={handleChange('interestPercentage')}
                            onKeyDown={evt =>
                              evt.key === 'e' && evt.preventDefault()
                            }
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : values.recoupmentCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  values.interestCalculation === 'N' ||
                                  manualEditFlag
                            }
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                ''
                              );
                            }}
                            required={values.interestCalculation === 'Y'}
                            helperText={
                              interestCalError !== '' ? interestCalError : null
                            }
                            error={
                              interestCalError !== '' ? interestCalError : null
                            }
                          ></TextField>
                        </div>
                      </div>
                    </form>
                    <div className="float-right my-3">
                      <Button
                        className="btn-text-main-add btn-success"
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : values.recoupmentCode !== 'S' ||
                              values.paymentScheduleType !== 'A' ||
                              manualEditFlag
                        }
                        title={'Calculate'}
                        onClick={calculateSchedule}
                      >
                        Calculate
                      </Button>
                      <Button
                        className="btn-text-main-delete btn-transparent ml-1"
                        onClick={openDeletePopUpDialog}
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : values.recoupmentCode !== 'S' ||
                              values.paymentScheduleType === 'A'
                        }
                        title={'Delete'}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i> Delete
                      </Button>
                      <Button
                        className="btn-text-main-add btn-success ml-1"
                        onClick={handleClickOpenPaymentSchedule}
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : values.recoupmentCode !== 'S' ||
                              values.paymentScheduleType === 'A'
                        }
                        title={'Add Payment Schedule'}
                        data-testid="payment_schedule_add_btn"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i> Add
                      </Button>
                    </div>
                    <div className="clearfix"></div>
                    <div>
                      {values.paymentScheduleType === 'M' ? (
                        <TableComponent
                          headCells={headCells}
                          setClose={setClose}
                          close={close}
                          tableData={paymentScheduleData}
                          onTableCellButtonClick={onClickAuditDialogLog}
                          uniqueKey={'scheduleSK'}
                          onTableRowClick={editRow}
                          onTableRowDelete={rowDeletePaymentSchedule}
                          defaultSortColumn={'dueDate'}
                          isSearch={false}
                          align={'left'}
                        />
                      ) : (
                        <TableComponent
                          headCells={headCells}
                          setClose={setClose}
                          close={close}
                          tableData={paymentScheduleData}
                          onTableCellButtonClick={onClickAuditDialogLog}
                          uniqueKey={'scheduleSK'}
                          onTableRowClick={editRow}
                          onTableRowDelete={rowDeletePaymentSchedule}
                          defaultSortColumn={'dueDate'}
                          align={'left'}
                          isSearch={true}
                        />
                      )}
                    </div>
                    {open ? (
                      <div className="tabs-container" id="form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {add
                              ? 'Add Payment Schedule'
                              : 'Edit Payment Schedule'}
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              onClick={savePaymentSchedule}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentCode !== 'S' ||
                                    values.paymentScheduleType === 'A' ||
                                    paymentScheduleVO.recoupedAmount !== '0.00'
                              }
                              title={add ? 'Add' : 'Update'}
                              data-testid="payment_schedule_save_btn"
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {add ? 'Add' : 'Update'}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent ml-1"
                              onClick={resetPaymentSchedule}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentCode !== 'S' ||
                                    values.paymentScheduleType === 'A' ||
                                    paymentScheduleVO.recoupedAmount !== '0.00'
                              }
                              title={'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                              title={'Cancle'}

                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className="form-wrapper">
                                <div className="mui-custom-form with-date override-width-20">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      '31 Dec 9999 00:00:00 GMT'
                                    )}
                                    required
                                    fullWidth
                                    id="dueDate"
                                    label="Due Date"
                                    inputProps={{ "data-testid": "payment_schedule_due_date" }}
                                    minDate={
                                      new Date(
                                        new Date().toLocaleString('en-US', {
                                          timeZone:
                                            process.env.REACT_APP_TIMEZONE
                                        })
                                      )
                                    }
                                    initialFocusedDate={
                                      new Date(
                                        new Date().toLocaleString('en-US', {
                                          timeZone:
                                            process.env.REACT_APP_TIMEZONE
                                        })
                                      )
                                    }
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedDueDate}
                                    onChange={handleDueDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : values.paymentScheduleType === 'A' ||
                                          paymentScheduleVO.recoupedAmount !==
                                            '0.00'
                                    }
                                    helperText={
                                      showDueDateError
                                        ? showDueDateErrorText
                                        : null
                                    }
                                    error={
                                      showDueDateError
                                        ? showDueDateErrorText
                                        : null
                                    }
                                  />
                                </div>
                                <div className="mui-custom-form input-md override-width-20">
                                  <TextField
                                    id="installmentAmount"
                                    fullWidth
                                    label="Installment Amount"
                                    required
                                    inputProps={{ maxlength: 14, "data-testid": "payment_schedule_due_amount" }}
                                    value={paymentScheduleVO.installmentAmount}
                                    onChange={handleChangePaymentSchedule(
                                      'installmentAmount'
                                    )}
                                    onBlur={formatDecimalFieldInstallment(
                                      'installmentAmount'
                                    )}
                                    onFocus={removeFormatDecimalFieldAmountDue(
                                      'installmentAmount'
                                    )}
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : values.paymentScheduleType === 'A' ||
                                          paymentScheduleVO.recoupedAmount !==
                                            '0.00'
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                    helperText={
                                      showAmountDueError
                                        ? showAmountDueErrorText
                                        : null
                                    }
                                    error={
                                      showAmountDueError
                                        ? showAmountDueErrorText
                                        : null
                                    }
                                    onInput={e => {
                                      e.target.value = e.target.value.replace(
                                        /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                        ''
                                      );
                                    }}
                                  ></TextField>
                                </div>
                                <div className="mui-custom-form input-md override-width-20">
                                  <TextField
                                    id="recoupedAmount"
                                    fullWidth
                                    label="Recouped Amount"
                                    required
                                    disabled
                                    inputProps={{ maxlength: 14 }}
                                    value={paymentScheduleVO.recoupedAmount}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                  ></TextField>
                                </div>
                              </div>
                            </MuiPickersUtilsProvider>
                          </form>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditDialogRowData}
                                setAuditRowData={setAuditDialogRowData}
                                setShowLogTable={setShowLogDialogTable}
                              />{' '}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              </TabPanel>
              <TabPanel
                value={value}
                index={3}
              >
                <ClaimsReceivableHistory
                  tabData={claimsData}
                  values={values}
                  addDropdowns={addDropdowns}
                ></ClaimsReceivableHistory>
              </TabPanel>
              <TabPanel
                value={value}
                index={4}
              >
                <div>
                  <Notes
                    addNotes={addNotes}
                    notesTableData={notesTableData}
                    noteSetListInput={noteSetListInput}
                    setNoteSetListInput={setNoteSetListInput}
                    usageTypeCodeData={usageTypeCodeData}
                    editNoteData={editNoteData}
                    setFocusCheck={setFocusCheck}
                    setEditNoteData={setEditNoteData}
                    setSuccessMessages={clearSuccessErrorMsgs}
                    setErrorMessages={setErrorMessages}
                    userInquiryPrivileges={userInquiryPrivileges}
                  />
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={5}
              >
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={userInquiryPrivileges}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={setErrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    setFocusCheck={setFocusCheck}
                    values={values}
                    setValues={setValues}
                    attchmentResponse={attchmentResponse}
                    setAttachmentResponse={setAttachmentResponse}
                  />
                </div>
              </TabPanel>
              {showLogTable ? (
                <div id="audit-table">
                  <AuditTableComponent
                    print
                    auditRowData={auditRowData}
                    setAuditRowData={setAuditRowData}
                    setShowLogTable={setShowLogTable}
                  />{' '}
                </div>
              ) : null}
            </div>
          </div>
          <Dialog
            className="custom-dialog dialog-500 with-padding"
            fullWidth={true}
            maxWidth={'sm'}
            data-testid="test-minorCancel"
            open={openDialog}
            disableBackdropClick
          >
            <DialogContent dividers>
              <div>Are you sure you want to Delete?</div>
            </DialogContent>

            <DialogActions>
              <Button
                className="btn btn-primary ml-1"
                onClick={confirmDelete}
                disabled={!userInquiryPrivileges}
              >
                OK
              </Button>
              <Button
                className="btn btn-primary ml-1"
                onClick={closeDeletePopUpDialog}
                disabled={!userInquiryPrivileges}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(ClaimsReceivable);
