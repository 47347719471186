/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "beginDate",
    numeric: false,
    disablePadding: true,
    label: "Begin Date",
    isDate: true,
    width: "15%",
    fontSize: 12
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    isDate: true,
    width: "15%",
    fontSize: 12
  },
  {
    id: "federalPercentage",
    numeric: false,
    disablePadding: false,
    label: "Federal",
    isPercent: true,
    width: "15%",
    fontSize: 12
  },
  {
    id: "statePercentage",
    numeric: false,
    disablePadding: false,
    label: "State",
    isPercent: true,
    width: "10%",
    fontSize: 12
  },
  {
    id: "countyPercentage",
    numeric: false,
    disablePadding: false,
    label: "County",
    isPercent: true,
    width: "10%",
    fontSize: 12
  },
  {
    id: "otherPercentage",
    numeric: false,
    disablePadding: false,
    label: "Other",
    isPercent: true,
    width: "10%",
    fontSize: 12
  },
  {
    id: "totalPercentage",
    numeric: false,
    disablePadding: false,
    label: "Total",
    isPercent: true,
    width: "10%",
    fontSize: 12
  },
  {
    id: "fundSourceVoidDate",
    numeric: false,
    disablePadding: false,
    label: "Void Date",
    isDate: true,
    width: "15%",
    fontSize: 12
  }
];

export default function SystemParameterTable(props) {
  const { classes } = props;

  return (
    <div className={classes}>
      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      />
      <TableComponent
        print={props.print}
        isSearch={true}
        fixedTable
        headCells={headCells}
        tableData={props.tableData ? props.tableData : []}
        defaultSortColumn={headCells[0].id}
        sortOrder="desc"
      />

      <div className="clearfix"></div>
    </div>
  );
}
