/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Constants from './ChangePayeeStatusConstants';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import { resetL2KeyDataFromStore } from '../../../../../SharedModules/store/Actions/SharedAction';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import dropDownCriteria from './DropDownCriteria.json';
import { getDropdownDataAction, saveChangePayeeStatus } from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import TextField from '@material-ui/core/TextField';
import { getUTCTimeStamp } from '../../../../../SharedModules/DateUtilities/DateUtilities';
import Swal from "sweetalert2";
import Footer from '../../../../../SharedModules/Layout/footer';
import TableComponent from '../../../../../SharedModules/Table/Table';
import {
  DialogContent,
  DialogActions
} from '../../../../../SharedModules/Dialog/DialogUtilities';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import NoSaveMessage from '../../../../../SharedModules/Errors/NoSaveMessage';
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

function ChangePayStatus (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [clearPayeeeStatus, setClearPayeeStatus] = React.useState(false);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const DropDownAPI = (dropdownValues) => { dispatch(getDropdownDataAction(dropdownValues)); };
  const dropDownDetails = useSelector(state => state.accountReceivableState.dropdownData);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const dropdownData = useSelector(state => state.appDropDowns.appdropdowns);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [changedPayeeHeader, setChangedPayeeHeader] = React.useState(false);

  /* This function is used to handle and set the clearPayeeeStatus checkbox */
  const handleClearPayeeStatus = () => {
    setClearPayeeStatus(!clearPayeeeStatus);
    setAllowNavigation(true);
    setChangedPayeeHeader(true);
  };

  const [showError, setShowError] = React.useState({ showATRError: false });
  const [showErrorMessage, setShowErrorMessage] = React.useState({ atrErrorMsg: '' });

  /* Setting initial values */
  const [values, setValues] = React.useState({
    fromPayeeType: DEFAULT_DD_VALUE,
    fromPayeeIDTypeCode: DEFAULT_DD_VALUE,
    fromPayeeID: '',
    fromPayeeName: '',
    toPayeeType: DEFAULT_DD_VALUE,
    toPayeeIDTypeCode: DEFAULT_DD_VALUE,
    payeeStatus: DEFAULT_DD_VALUE,
    toPayeeID: '',
    toPayeeName: '',
    atrNumber: ''
  });
  const [tableData, setTableData] = React.useState([]);
  const handleTablePayeeStatus = (row, value) => event => {

  };
  const saveBulkChangePayeeResult = useSelector(
    state => state.accountReceivableState.savePayeeStatusResult
  );
  useEffect(() => {
    if (saveBulkChangePayeeResult && !saveBulkChangePayeeResult.systemFailure && saveBulkChangePayeeResult.errorMessages && saveBulkChangePayeeResult.errorMessages.length > 0) {
      const errorMessages = [];
      saveBulkChangePayeeResult.errorMessages.map((value) => {
        const message = `${saveBulkChangePayeeResult.originalFCN}: ${value}`;
        errorMessages.push(message);
      });
      setErrorMessages(errorMessages);
    } else if (saveBulkChangePayeeResult && !saveBulkChangePayeeResult.systemFailure && saveBulkChangePayeeResult.respcode === '01') {
      const successMessage = [];
      const message = Constants.SAVED_SUCCESS;
      successMessage.push(message);
      setSystemSuccesMessages(successMessage);
      setFocusCheck(false);
      setAllowNavigation(false);
      const tempData = JSON.parse(JSON.stringify(tableData));
      tempData.map((value) => {
        saveBulkChangePayeeResult.fcnamountlist.map((values) => {
          if (value.receivableFCN === values.oldFCN) {
            value.payeeStatus = values.payeeStatus;
          }
        });
        value.errorKey = false;
      });
      setTableData(tempData);
      setClearPayeeStatus(false);
    } else if (saveBulkChangePayeeResult && saveBulkChangePayeeResult.systemFailure) {
      const errorMessages = [];
      errorMessages.push(Constants.GENERIC_SYSTEM_ERROR);
      setErrorMessages(errorMessages);
    }
  }, [saveBulkChangePayeeResult]);
  const setPayeeStatusData = data => {
    setErrorMessages([]);
    setSystemSuccesMessages([]);
    setChangedPayeeHeader(false);
    setTableData(JSON.parse(JSON.stringify(data)));
  };

  const handlePayeeValue = (name) => e => {
    setErrorMessages([]);
    setSystemSuccesMessages([]);
    setFocusCheck(true);
    setAllowNavigation(true);
    if (e.target.value !== DEFAULT_DD_VALUE) {
      setChangedPayeeHeader(true);
    } else {
      setChangedPayeeHeader(false);
    }
    setValues({ ...values, [name]: e.target.value });
  };
  React.useEffect(() => {
    DropDownAPI(dropDownCriteria);
    onDropdowns([
      Dropdowns.F_RSN_CD,
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_STATUS_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL
    ]);
  }, []);

  React.useEffect(() => {
    if (dropDownDetails) {
      const tableValues = JSON.parse(JSON.stringify(props.location.state?.selectedFcnObj));
      if (dropDownDetails.listObj && dropDownDetails.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropDownDetails.listObj['Financial#6023']);
      }
      const updatetableData = tableValues.map(each => {
        const payeeStatusExistsInDropDown = dropDownDetails.listObj['Financial#6023'].some(value => value.code === each.payeeStatus?.split("-")[0]);
        return { ...each, payeeStatus: payeeStatusExistsInDropDown ? each.payeeStatus?.split("-")[0] : null };
      });

      setTableData(updatetableData);
    }
  }, [dropDownDetails]);

  const ValidateFieldsForSave = () => {
    var _error = true;
    const errorMsgs = [];
    const regex = /[^A-Za-z0-9]/;
    var atrErrorTemp = false;
    var atrErrorMsgTemp = '';

    if (values.atrNumber && regex.test(values.atrNumber)) {
      atrErrorTemp = true;
      atrErrorMsgTemp = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}`;
      errorMsgs.push(atrErrorMsgTemp);
      _error = false;
    } else {
      atrErrorTemp = false;
      _error = true;
    }

    if (tableData && tableData.length > 0 && !changedPayeeHeader) {
      let count = 0;
      for (let i = 0; i <= tableData.length - 1; i++) {
        if (!tableData[i].payeeStatus || tableData[i].payeeStatus === DEFAULT_DD_VALUE) {
          tableData[i].errorKey = true;
          count = count + 1;
        } else {
          tableData[i].errorKey = false;
        }
      }
      if (count > 0) {
        errorMsgs.push(Constants.PAYEE_STATUS_REQUIRED);
        _error = false;
        setTableData(tableData);
      } else {
        setTableData(tableData);
      }
    }
    setErrorMessages(errorMsgs);
    setShowError({
      showATRError: atrErrorTemp
    });
    setShowErrorMessage({
      atrErrorMsg: atrErrorMsgTemp
    });
    return _error;
  };

  const saveChangePayeeStatusAPI = (value) => {
    dispatch(saveChangePayeeStatus(value));
  };
  const savePayeeStatus = () => {
    if (clearPayeeeStatus) {
      values.payeeStatus = "";
    }
    setErrorMessages([]);
    setSystemSuccesMessages([]);
    if (ValidateFieldsForSave()) {
      const bulkChangePayeeResult = props.location.state.viewChangePayeeStatusResult;
      const selectedChangePayeeResult = tableData;
      const saveChangePayeeArr = [];
      for (let i = 0; i < bulkChangePayeeResult.length; i++) {
        if (bulkChangePayeeResult[i].originalFCN === selectedChangePayeeResult[i].receivableFCN) {
          const payeeObj = {
            receivableFCN: selectedChangePayeeResult[i].receivableFCN,
            changePayeeStatusVO: {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: false,
              sortColumn: null,
              fcn: bulkChangePayeeResult[i].changePayeeStatusVO.fcn,
              establishedDate: bulkChangePayeeResult[i].changePayeeStatusVO.establishedDate,
              relatedFTTCN: bulkChangePayeeResult[i].changePayeeStatusVO.relatedFTTCN,
              currentPayeeStatus: null,
              originalAmount: bulkChangePayeeResult[i].changePayeeStatusVO.originalAmount,
              balanceAmount: bulkChangePayeeResult[i].changePayeeStatusVO.balanceAmount,
              payeePayerTypeCode: values.fromPayeeType?.split('-')[0],
              payeePayerIDTypeCode: values.fromPayeeIDTypeCode?.split('-')[0],
              payeePayerID: values.fromPayeeID ? values.fromPayeeID.trim() : values.fromPayeeID,
              payeePayerIdFromDB: '',
              payeePayerNameFromDB: values.fromPayeeName,
              atrNumber: values?.atrNumber?.toUpperCase(),
              sysID: '',
              fcnSeqNum: bulkChangePayeeResult[i].changePayeeStatusVO.fcnSeqNum,
              fcnMediaCode: "0",
              payeeStatus: changedPayeeHeader ? values.payeeStatus : selectedChangePayeeResult[i].payeeStatus,
              agingCode: null,
              establishedDateStr: bulkChangePayeeResult[i].changePayeeStatusVO.establishedDateStr
            }
          };
          saveChangePayeeArr.push(payeeObj);
        }
      }
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      if (errorMessages.length === 0) {
        if (!allowNavigation) {
          NoSaveMessage();
        } else {
          saveChangePayeeStatusAPI(saveChangePayeeArr);
        }
      }
    }
  };

  const openSwalDialog = tempSearchObj => {
    Swal.fire({
      customClass: {
        confirmButton: "btn-text1 btn-transparent mr-3",
        cancelButton: "btn1 btn-success",
        container: "unsaved-changes-popup2"
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: "warning",
      html: "<h4>The selected Payee Status will be updated for the following FCN(s).</h4 >",
      showCancelButton: true,
      confirmButtonText: "Cancel!",
      cancelButtonText: 'Save! <i class="fa fa-arrow-right ml-1"></i>'
    }).then(result => {
      if (result.value) {
        setValues({ ...values, payeeStatus: DEFAULT_DD_VALUE });
        setChangedPayeeHeader(false);
      } else {
        savePayeeStatus();
      }
    });
  };

  /* This function is used to navigate to Accounts Receivable Add Screen */
  useEffect(() => {
    if (props.location.state && props.location.state.selectedPayee) {
      const selectedPayeeFrmSearch = props.location.state.selectedPayee;
      const payeeType = selectedPayeeFrmSearch.payeeType?.split("-")[0];
      const fromPayeeTypeDesc = dropdownData && dropdownData['G1#G_CMN_ENTY_TY_CD'] &&
      dropdownData['G1#G_CMN_ENTY_TY_CD']?.find(value => {
        return value.code === payeeType;
      })?.description || selectedPayeeFrmSearch.payeeType;
      let payeeIdTypeCdDD;
      if (payeeType === 'P') {
        payeeIdTypeCdDD = "F1#P_ALT_ID_TY_CD";
      } else if (payeeType === 'M') {
        payeeIdTypeCdDD = "F1#B_ALT_ID_TY_CD";
      } else if (payeeType === 'O') {
        payeeIdTypeCdDD = "F1#G_ENTY_EXTL_ID_TY_CD";
      } else if (payeeType === 'TC') {
        payeeIdTypeCdDD = "F1#DM_G_CARR_ENTY_ID_TY_CD";
      }
      const fromPayeeIdTypeDesc = dropdownData && dropdownData[payeeIdTypeCdDD] &&
      dropdownData[payeeIdTypeCdDD]?.find(value => {
        return value.code === selectedPayeeFrmSearch.payeeTypeCode?.split("-")[0];
      })?.description || selectedPayeeFrmSearch.payeeTypeCode;
      if (props.location.state && props.location.state.selectedPayee) {
        setValues({
          ...values,
          fromPayeeType: fromPayeeTypeDesc,
          fromPayeeID: props.location.state.selectedPayee.payee,
          fromPayeeIDTypeCode: fromPayeeIdTypeDesc,
          fromPayeeName: props.location.state.selectedPayee.payeeName
        });
      }
    }
  }, []);
  const headCells = [
    {
      id: 'receivableFCN',
      numeric: false,
      disablePadding: true,
      label: 'FCN',
      width: '18%',
      enableHyperLink: true
    },
    {
      id: 'reasonCodeDesc',
      numeric: false,
      disablePadding: false,
      label: 'Original A/R Reason Code',
      width: '21%',
      isTooltip: true,
      isTitle: 'resonCodeLongDesc'
    },
    {
      id: 'establishedDateStr',
      numeric: false,
      disablePadding: false,
      label: 'Established Date',
      width: '17%',
      isDate: true
    },
    {
      id: 'balanceAmount',
      numeric: false,
      disablePadding: false,
      label: 'Balance Amount',
      width: '21%',
      isBalance: true
    },
    {
      id: 'payeeStatus',
      numeric: false,
      disablePadding: false,
      isPayeeStatus: true,
      label: 'Payee Status',
      width: '23%',
      errorKey: 'errorKey',
      dropDownData: []
    }
  ];
  const formatSearchCriteria = row => {
    return (row.receivableFCN);
  };

  const editRow = row => event => {
    const payloadData = formatSearchCriteria(row);
    props.history.push({
      pathname: '/ChangePayStatus',
      state: { payloadData }
    });
  };

  const valuesFromBulkMaintenance = props.history?.location?.state?.values;
  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }, 100);
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      dispatch(resetL2KeyDataFromStore('accountReceivableState', 'changePayeeStatus'));
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }
  };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const reasonCode =
              dropdownData &&
              dropdownData['F1#F_RSN_CD'] &&
              dropdownData['F1#F_RSN_CD'].filter(
                value => value.code === each.reasonCode?.split("-")[0]
              );
            // updating the record with desc
          each.reasonCodeDesc =
              reasonCode && reasonCode.length > 0
                ? reasonCode[0].description
                : each.reasonCode ? each.reasonCode : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <div>
      <div className="pos-relative w-100 h-100">
        <Prompt
          when={allowNavigation}
          message={location => `Are you sure you want to go to ${location.pathname}`}
        />
        <ErrorMessages errorMessages={[...new Set(errorMessages)]} />
        {systemSuccessMessages.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}

        <div className="tabs-container" ref={toPrintRef}>
          <div className="tab-header">
            <h1 className="tab-heading float-left">
              {Constants.BULK_TRANFER_HEADER}
            </h1>
            <div className="float-right mt-2">
              <Button className='btn btn-success ml-1' onClick={() => savePayeeStatus()}>
                <i className="fa fa-check" aria-hidden="true"></i>
                                Save
              </Button>
              <Button className='btn btn-primary ml-1' title="Cancel" onClick={majorCancel}>Cancel</Button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-body">
            <form noValidate autoComplete="off">
              <div className="tab-holder">
                <div className="row mb-2">
                  <div className="col-6" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <fieldset className="custom-fieldset">
                      <legend>Payee Details:</legend>
                      <div className="form-wrapper">

                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeType-bulkTransfer"
                            label="Payee Type"
                            required
                            disabled
                            value={values.fromPayeeType}
                            onChange={handlePayeeValue('fromPayeeType')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeIDTypeCode-bulkTransfer"
                            label="Payee ID Type Code"
                            required
                            disabled
                            value={values.fromPayeeIDTypeCode}
                            onChange={handlePayeeValue('fromPayeeIDTypeCode')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                      <div className="form-wrapper">

                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeID-bulkTransfer"
                            label="Payee ID"
                            required
                            disabled
                            inputProps={{ maxLength: 15 }}
                            value={values.fromPayeeID}
                            onChange={handlePayeeValue('fromPayeeID')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeName-bulkTransfer"
                            label="Payee Name"
                            disabled
                            value={values.fromPayeeName}
                            onChange={handlePayeeValue('fromPayeeName')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-6" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <fieldset className="custom-fieldset">
                      <legend>Change Payee Status To:</legend>
                      <div className="form-wrapper">
                        <NativeDropDown
                          className='override-width-45 override-m-3'
                          id="changePayeeStatus"
                          label="Payee Status"
                          value={clearPayeeeStatus ? DEFAULT_DD_VALUE : values.payeeStatus}
                          onBlur={openSwalDialog}
                          onChange={handlePayeeValue('payeeStatus')}
                          dropdownList={payeePaymentStatusFrmState.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.description}
                            </option>
                          ))}
                          disabled={clearPayeeeStatus}
                        />
                        <div className="mui-custom-form sub-radio float-right no-margin-label margin-top-24 override-width-45">
                          <FormControlLabel
                            value="startWith"
                            control={
                              <Checkbox
                                color="primary"
                                checked={clearPayeeeStatus}
                                value={clearPayeeeStatus}
                                onClick={handleClearPayeeStatus}
                              />
                            }
                            label="Clear Payee Status"
                            labelPlacement="end"
                          />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="atrNumber"
                            label= {process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
                            inputProps={{ maxLength: 18 }}
                            value={values.atrNumber}
                            onChange={handlePayeeValue('atrNumber')}
                            helperText={showError.showATRError ? showErrorMessage.atrErrorMsg : null}
                            error={showError.showATRError ? showErrorMessage.atrErrorMsg : null}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </form>
            <div className="tab-holder table-no-wrap">
              <TableComponent
                print={props.print}
                pathTo='/FinancialAccountGrossLevelUpdate?data='
                formatSearchCriteria={formatSearchCriteria}
                fixedTable
                fixedView
                headCells={headCells}
                isSearch={true}
                dropDownData={payeePaymentStatusFrmState}
                handleDropDown={handleTablePayeeStatus}
                tableData={getTableData(tableData || [])}
                onTableRowClick={editRow}
                defaultSortColumn={headCells[0].id}
                setPayeeStatusData={setPayeeStatusData}
                setFocusCheck={setFocusCheck}
                setAllowNavigation={setAllowNavigation}

              />
            </div>
          </div>
          <Footer print />
          <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <>
                                Unsaved changes will be lost. <br />
                                Are you sure you want to continue?
                </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDialogOpen(false);
                }}
                color="primary"
                className="btn btn-transparent"
              >
                            STAY ON THIS PAGE!
              </Button>
              <Button
                onClick={cancelMinorSection}
                color="primary"
                className="btn btn-success"
                autoFocus
              >
                            CONTINUE <i className="fa fa-arrow-right ml-1" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default withRouter((ChangePayStatus));
