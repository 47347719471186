/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import * as Constants from './WriteOffContants';
import WriteOffRestablismentTabDetail from './WriteOffRestablismentTabDetail';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../SharedModules/TabPanel/TabPanel';
import { withRouter } from 'react-router';
import { redirectSystemID } from '../../../SharedModules/TabRedirections/TabRedirections';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { removeLeadingTrailingSpaces } from '../../../SharedModules/CommonUtilities/commonUtilities';
import moment from 'moment';
import numeral from 'numeral';
import '../WriteOff.scss';
import { validatePayeeID, agingDaysList, getPayeeIdType, getDropdownDataAction, saveWriteOffRestablishment } from '../Store/Actions/WriteOffActions';
import TextField from '@material-ui/core/TextField';
import NoSaveMessage from '../../../SharedModules/Errors/NoSaveMessage';
import { getUTCTimeStamp, getDateInMMDDYYYYFormatWithApendZero, checkForValidDateString, getDateInMMDDYYYYFormat, generateUUID } from '../../../SharedModules/DateUtilities/DateUtilities';
import dropdownCriteria from './WriteOffReEstd.json';
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import { refreshAttachmentsAction } from '../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../SharedModules/Layout/footer';
import Notes from '../../../SharedModules/Notes/Notes';
import Attachments from '../../../SharedModules/Attachments/Attachments';
import SystemPayeeTable from '../../../SharedModules/SystemIDTable/SystemPayeeIDTable';

function WriteOffReEstablishment (props) {
	const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // Attachments Integration start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
    const [attchmentResponse,setAttachmentResponse] = React.useState([])
  
  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [saveFlag, setSaveFlag] = React.useState(false);
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  React.useEffect(() => {
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description:
              value && value
                ? value.description
                : null,
            edmsStoredDate: null,
            anEDMSPageId: value? value.anEDMSPageId : null,
            fileName:
              attachmentsTableData && value
                ? value.fileName
                : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    writeOffRestablishmentDetailsData.attachmentVO = null;
    writeOffRestablishmentDetailsData.attachmentsList = attachmentVOList;
  }, [attachmentsTableData]);
  // Attachment Integration ends
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [writeVONotesInput, setWriteVONotesInput] = React.useState({

    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: [],
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [notesInput, setNotesInput] = React.useState({

    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({

  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = (data) => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
    const noteswritelist = writeVONotesInput.notesList;
    noteswritelist.push(noteText);
    setWriteVONotesInput({ ...writeVONotesInput, notesList: noteswritelist });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setErrorMessages([]);
    setShowError(false);
    setShowErrorText('');
    setSystemSuccesMessages([]);
  };

  let paramsData;

  useEffect(() => {
    if (props.location.state.writeOffReEstablishmentDetails) {
      paramsData = props.location.state.writeOffReEstablishmentDetails;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID ? noteSetVO.addedAuditUserID : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp ? noteSetVO.addedAuditTimeStamp : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID ? notesInput.addedAuditUserID : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp ? notesInput.addedAuditTimeStamp : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum ? notesInput.noteCexVersionNum : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber ? notesInput.saNoteSequenceNumber : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue ? notesInput.notesCexnoteTextValue : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  const [{ showEstablishDateError, showReasonCodeError, showPayeeIdError, showPayeeTypeError, showPayeeIdCodeError, showATRError }, setShowError] = React.useState(false);
  const [{ showPayeeIdErrorText, showReasonCodeErrorText, showPayeeTypeErrorText, showPayeeIdCodeErrorText, showEstablishDateErrorText }, setShowErrorText] = React.useState('');
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const [woReasonCodeList, setWOReasonCodeList] = React.useState([]);
  const [reasonCodeList, setReasonCodeList] = React.useState([]);
  const [arLetterSentList, setArLetterList] = React.useState([]);
  const [payeeList, setPayeeList] = React.useState([]);
  const [payeeIdCodeList, setPayeeIdCodeList] = React.useState([]);
  const [payeeIdTypeCodeDataList, setPayeeIdTypeCodeDataList] = React.useState([]);
  const [payeeIdCodeListDropdown, setPayeeIdCodeListDropdown] = React.useState([]);
  const [activityCodeList, setActivityCodeList] = React.useState([]);
  const sucessMessages = [];
  const systemErrorMessges = [];
  var errorMessagesArray = [];
  const [selectedEstablishDate, setSelectedEstablishDate] = React.useState(null);
  const [establishDatePress, setEstablishDatePress] = React.useState(false);
  const [payeeNameRest, setPayeeNameRest] = React.useState('');
  const [systemPayeeIdRest, setToSysID] = React.useState('');
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const getDropdownData = (dropdownValues) => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  const onSearchPayeeIdType = (values) => dispatch(getPayeeIdType(values));
  const onSaveWriteOffRestablishment = (value, index) => dispatch(saveWriteOffRestablishment(value, attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id ? attachmentFile : null));
  const onARLetterSentDropdown = () => { dispatch(agingDaysList()); };

  let dropdown = '';
  dropdown = useSelector(state => state.writeOffState.dropdownData);
  const saveWriteOffRestablishmentResponse = useSelector(state => state.writeOffState.saveWriteOffRestablishmentData);

  const writeOffRestablishmentDetailsData = props.location.state.writeOffReEstablishmentDetails ? props.location.state.writeOffReEstablishmentDetails : null;
  const payeeIdTypeDropdown = useSelector(state => state.writeOffState.payeeIdTypeDropdown);

  const ARLetterSentDetails = useSelector(state => state.writeOffState.agingDaysListData);

  const validatePayeeIDAPI = (values) => { dispatch(validatePayeeID(values)); };
  let validateIDResult = [];
  validateIDResult = useSelector(state => state.writeOffState.validatePayeeIDvalues);
  /* Setting initial values */
  const [values, setValues] = React.useState({
    payeeName: '',
    systemPayeeId: '',
    payeeId: '',
    payeeIdTypeCode: '',
    payeeType: '',
    fcn: '',
    activityType: '',
    reasonCode: '',
    establishedDate: '',
    originalArAmount: '',
    adjustedAmount: '',
    relatedFtTcn: '',
    balanceAmount: '',
    penalty: '',
    interest: '',
    fees: '',
    arLetterSent: '',
    memberName: '',
    fcnRest: '',
    payeeIdRest: '',
    reasonCodeRest: DEFAULT_DD_VALUE,
    relatedFtTcnRest: '',
    originalArAmountRest: '',
    balanceAmountRest: '',
    penaltyRest: '',
    memberNameRest: '',
    interestRest: '',
    feesRest: '',
    adjustedOriginalAmountRest: '',
    arLetterSentRest: DEFAULT_DD_VALUE,
    atrNumber: '',
    atrNumberData: ''
  });

  /* Setting payee type and payee id code values array initially with some default inputs */
  const [payeeValues, setPayeeValues] = React.useState({
    payeeTypeRest: DEFAULT_DD_VALUE,
    payeeIdCodeRest: DEFAULT_DD_VALUE
  });

  useEffect(() => {
    if (validateIDResult && validateIDResult.respcode && validateIDResult.respcode === '02') {
      setErrorMessages(validateIDResult.errorMessages);
      setPayeeNameRest('');
      setToSysID('');
      setShowError(false);
      setShowErrorText('');
    }
    if (validateIDResult && validateIDResult.respcode && validateIDResult.respcode === '01') {
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      if (validateIDResult && validateIDResult.enterpriseSearchresultVO && validateIDResult.enterpriseSearchresultVO?.searchResults && validateIDResult.enterpriseSearchresultVO?.searchResults[0] && validateIDResult.enterpriseSearchresultVO.searchResults[0].multiplePayeeDetails && validateIDResult.enterpriseSearchresultVO?.searchResults[0].multiplePayeeDetails.length > 1) {
        setOpenSysPayeeTable(true);
        setSystemPayeeTableData(validateIDResult.enterpriseSearchresultVO.searchResults[0]?.multiplePayeeDetails);
      } else {
        setPayeeNameRest(validateIDResult.enterpriseSearchresultVO?.searchResults[0].transferVO?.payeePayerNameFromDB);
        setToSysID(validateIDResult.enterpriseSearchresultVO?.searchResults[0]?.transferVO.sysID);
      }
      setShowError(false);
      setShowErrorText('');
    }
  }, [validateIDResult]);
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState?.singleSearchResults);
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    if (redirect === 1 && searchSingleFinancialEntityRes && !searchSingleFinancialEntityRes.systemFailue) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  /* Display success/error message based on the response and response description coming from the API after saving write-off ReEstablishment details */
  useEffect(() => {
    if (saveWriteOffRestablishmentResponse && saveWriteOffRestablishmentResponse.respcode === '03' && saveWriteOffRestablishmentResponse.respdesc !== '') {
      const error = saveWriteOffRestablishmentResponse.respdesc;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      setErrorMessages(systemErrorMessges);
    }
    if (saveWriteOffRestablishmentResponse && saveWriteOffRestablishmentResponse.respcode === '01' && saveWriteOffRestablishmentResponse.respdesc !== '') {
      const success = AppConstants.SAVE_SUCCESS;
      setAllowNavigation(false);
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setSaveFlag(true);
      dispatch(refreshAttachmentsAction(writeOffRestablishmentDetailsData.reestablishReceivableVO.financialCtrlNumber));
    }
    if (saveWriteOffRestablishmentResponse && saveWriteOffRestablishmentResponse.respcode === '02') {
      if (saveWriteOffRestablishmentResponse.errorMessages) {
        const error = saveWriteOffRestablishmentResponse.errorMessages;
        setSystemSuccesMessages([]);
        systemErrorMessges.push(error);
        setErrorMessages(error);
      } else if (saveWriteOffRestablishmentResponse.respdesc !== '') {
        const error = saveWriteOffRestablishmentResponse.respdesc;
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        systemErrorMessges.push(error);
        setErrorMessages(systemErrorMessges);
      }
    }
    if (saveWriteOffRestablishmentResponse != null && (saveWriteOffRestablishmentResponse.errorMessages !== undefined || saveWriteOffRestablishmentResponse.message !== undefined)) {
      if ((saveWriteOffRestablishmentResponse.errorMessages && saveWriteOffRestablishmentResponse.errorMessages === Constants.NETWORK_ERROR)) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(Constants.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      } else if (saveWriteOffRestablishmentResponse && saveWriteOffRestablishmentResponse.message && saveWriteOffRestablishmentResponse.message === Constants.NETWORK_ERROR) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(Constants.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      }
    }
  }, [saveWriteOffRestablishmentResponse]);

  /* Calling api to get write-off reason code dropdown */
  useEffect(() => {
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.ACTIVITY_TYPE,
      Dropdowns.F_RSN_CD
    ]);
    getDropdownData(dropdownCriteria);
    onARLetterSentDropdown();
    refreshAttachmentResult = null;
  }, []);

  function updateDropdownDescription (addDropdowns, dropdownKey, valueToMatch, targetProperty) {
    if (addDropdowns && addDropdowns[dropdownKey]) {
    const dropdownData = addDropdowns[dropdownKey].filter((value) => value.code === valueToMatch?.split("-")[0]);
    setValues(prevState => ({
      ...prevState,
      [targetProperty]: dropdownData && dropdownData.length > 0
        ? dropdownData[0].description
        : valueToMatch || ""
    }));
    }
  }
  useEffect(() => {
    if (addDropdowns) {
      // Usage for 'payeePayerTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'G1#G_CMN_ENTY_TY_CD', values.payeeType, 'payeePayerTypeCodeDesc');
      // Usage for 'payeePayerIDTypeCodeDesc':
      const payeeIdTypeCdDD = {
        P: 'F1#P_ALT_ID_TY_CD',
        M: 'F1#B_ALT_ID_TY_CD',
        O: 'F1#G_ENTY_EXTL_ID_TY_CD',
        TC: 'F1#DM_G_CARR_ENTY_ID_TY_CD'
      };
      const selectedPayeeIdTypeCdDD = payeeIdTypeCdDD[values.payeeType] || '';
      updateDropdownDescription(addDropdowns, selectedPayeeIdTypeCdDD, values.payeeIdTypeCode, 'payeePayerIDTypeCodeDesc');
      // Usage for 'activityTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_ACTV_TY_CD', values.activityType, 'activityTypeCodeDesc');
      // Usage for 'ARReasonCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_RSN_CD', values.reasonCode, 'ARReasonCodeDesc');
    }
  }, [addDropdowns]);
  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);

  /* Setting dropdown values */
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      const tempData = JSON.parse(JSON.stringify(payeeValues));
      const tempDataReasonCode = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj['General#6002']) {
        if (dropdown.listObj['General#6002'].length === 1) {
          tempData.payeeTypeRest(dropdown.listObj['General#6002'][0].code);
        }
        setPayeeList(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#2']) {
        setPayeeIdCodeList(dropdown.listObj['Financial#2']);
      }
      if (dropdown.listObj['Financial#3']) {
        setPayeeIdTypeCodeDataList(dropdown.listObj['Financial#3']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setActivityCodeList(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj['Financial#3003']) {
        if (dropdown.listObj['Financial#3003'].length === 1) {
          tempDataReasonCode.reasonCodeRest = dropdown.listObj['Financial#3003'][0].code;
        }
        setReasonCodeList(dropdown.listObj['Financial#3003']);
      }
      if (dropdown.listObj['Financial#3004']) {
        setWOReasonCodeList(dropdown.listObj['Financial#3004']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      setPayeeValues(tempData);
      setValues(tempDataReasonCode);
    }
  }, [dropdown]);

  /* Setting payee type id code dropdown values */
  useEffect(() => {
    if (payeeIdTypeDropdown && payeeIdTypeDropdown.payeeIdTypeCode) {
      setPayeeIdCodeListDropdown(payeeIdTypeDropdown.payeeIdTypeCode);
      setPayeeValues({
        payeeTypeRest: payeeValues.payeeTypeRest,
        payeeIdCodeRest: DEFAULT_DD_VALUE
      });
    }
  }, [payeeIdTypeDropdown]);

  /* Setting AR Letter Sent dropdown values */
  useEffect(() => {
    if (ARLetterSentDetails) {
      setArLetterList(ARLetterSentDetails);
    }
  }, [ARLetterSentDetails]);

  /* Getting Write-Off ReEstablishment details using props from different screens
  * setting screen fields based on the Write-Off ReEstablishment details
  */
  const [RClongDesc, setRClongDesc] = React.useState(null);
  useEffect(() => {
    if (writeOffRestablishmentDetailsData && payeeList && payeeIdCodeList && payeeIdTypeCodeDataList) {
      for (var payeeCode in payeeList) {
        if (payeeList[payeeCode].code === writeOffRestablishmentDetailsData.payeePayerTypeCode) {
          writeOffRestablishmentDetailsData.payeePayerTypeCode = payeeList[payeeCode].description;
        }
      }
      for (var payeeIdTypeCode in payeeIdCodeList) {
        if (payeeIdCodeList[payeeIdTypeCode].code === writeOffRestablishmentDetailsData.payeePayerIDTypeCode) {
          writeOffRestablishmentDetailsData.payeePayerIDTypeCode = payeeIdCodeList[payeeIdTypeCode].description;
        }
      }

      for (var payeeIdTypeCodeData in payeeIdTypeCodeDataList) {
        if (payeeIdTypeCodeDataList[payeeIdTypeCodeData].code === writeOffRestablishmentDetailsData.payeePayerIDTypeCode) {
          writeOffRestablishmentDetailsData.payeePayerIDTypeCode = payeeIdTypeCodeDataList[payeeIdTypeCodeData].description;
        }
      }

      for (var acType in activityCodeList) {
        if (activityCodeList[acType].code === writeOffRestablishmentDetailsData.activityTypeCode) {
          writeOffRestablishmentDetailsData.activityTypeCode = activityCodeList[acType].description;
        }
      }

      for (var rsnCode in woReasonCodeList) {
        if (woReasonCodeList[rsnCode].code === writeOffRestablishmentDetailsData.accountReceivableReasonCode || woReasonCodeList[rsnCode].description === writeOffRestablishmentDetailsData.accountReceivableReasonCode) {
          writeOffRestablishmentDetailsData.accountReceivableReasonCode = woReasonCodeList[rsnCode].description;
          setRClongDesc(woReasonCodeList[rsnCode].longDescription);
        }
      }

      setValues({
        atrNumber: writeOffRestablishmentDetailsData?.atrNumber || '',
        payeeName: writeOffRestablishmentDetailsData.payeePayerNameFromDB ? writeOffRestablishmentDetailsData.payeePayerNameFromDB : '',
        systemPayeeId: writeOffRestablishmentDetailsData.sysID ? writeOffRestablishmentDetailsData.sysID : '',
        payeeId: writeOffRestablishmentDetailsData.payeePayerID ? writeOffRestablishmentDetailsData.payeePayerID : '',
        payeeIdTypeCode: writeOffRestablishmentDetailsData.payeePayerIDTypeCode ? writeOffRestablishmentDetailsData.payeePayerIDTypeCode : '',
        payeeType: writeOffRestablishmentDetailsData.payeePayerTypeCode ? writeOffRestablishmentDetailsData.payeePayerTypeCode : '',
        fcn: writeOffRestablishmentDetailsData.receivableFCN ? writeOffRestablishmentDetailsData.receivableFCN : '',
        activityType: writeOffRestablishmentDetailsData.activityTypeCode ? writeOffRestablishmentDetailsData.activityTypeCode : '',
        reasonCode: writeOffRestablishmentDetailsData.accountReceivableReasonCode ? writeOffRestablishmentDetailsData.accountReceivableReasonCode : '',
        establishedDate: writeOffRestablishmentDetailsData.establishedDateStr ? moment(writeOffRestablishmentDetailsData.establishedDateStr).format('MM/DD/YYYY') : '',
        originalArAmount: writeOffRestablishmentDetailsData.originalAmount ? numeral(writeOffRestablishmentDetailsData.originalAmount).format('0,0.00') : '',
        adjustedAmount: writeOffRestablishmentDetailsData.adjustedArAmt ? numeral(writeOffRestablishmentDetailsData.adjustedArAmt).format('0,0.00') : '',
        relatedFtTcn: writeOffRestablishmentDetailsData.relatedFTTCN ? writeOffRestablishmentDetailsData.relatedFTTCN : '',
        balanceAmount: writeOffRestablishmentDetailsData.balanceAmount ? numeral(writeOffRestablishmentDetailsData.balanceAmount).format('0,0.00') : '',
        penalty: writeOffRestablishmentDetailsData.calculatedPenalty ? numeral(writeOffRestablishmentDetailsData.calculatedPenalty).format('0,0.00') : '',
        interest: writeOffRestablishmentDetailsData.calculatedInterest ? numeral(writeOffRestablishmentDetailsData.calculatedInterest).format('0,0.00') : '',
        fees: writeOffRestablishmentDetailsData.calculatedFees ? numeral(writeOffRestablishmentDetailsData.calculatedFees).format('0,0.00') : '',
        arLetterSent: writeOffRestablishmentDetailsData.agingCode ? writeOffRestablishmentDetailsData.agingCode : '',
        agingCode: writeOffRestablishmentDetailsData.agingCode ? writeOffRestablishmentDetailsData.agingCode : '',
        memberName: writeOffRestablishmentDetailsData.memberName ? writeOffRestablishmentDetailsData.memberName : '',
        fcnRest: writeOffRestablishmentDetailsData.reestablishReceivableVO.financialCtrlNumber ? writeOffRestablishmentDetailsData.reestablishReceivableVO.financialCtrlNumber : '',
        payeeIdRest: '',
        reasonCodeRest: DEFAULT_DD_VALUE,
        relatedFtTcnRest: writeOffRestablishmentDetailsData.reestablishReceivableVO.transactionCtrlNumber ? writeOffRestablishmentDetailsData.reestablishReceivableVO.transactionCtrlNumber : '',
        originalArAmountRest: writeOffRestablishmentDetailsData.originalAmount ? numeral(writeOffRestablishmentDetailsData.originalAmount).format('0,0.00') : '',
        balanceAmountRest: writeOffRestablishmentDetailsData.balanceAmount ? numeral(writeOffRestablishmentDetailsData.balanceAmount).format('0,0.00') : '',
        memberNameRest: writeOffRestablishmentDetailsData.memberName ? writeOffRestablishmentDetailsData.memberName : '',
        penaltyRest: writeOffRestablishmentDetailsData.calculatedPenalty ? numeral(writeOffRestablishmentDetailsData.calculatedPenalty).format('0,0.00') : '',
        interestRest: writeOffRestablishmentDetailsData.calculatedInterest ? numeral(writeOffRestablishmentDetailsData.calculatedInterest).format('0,0.00') : '',
        feesRest: writeOffRestablishmentDetailsData.calculatedFees ? numeral(writeOffRestablishmentDetailsData.calculatedFees).format('0,0.00') : '',
        adjustedOriginalAmountRest: writeOffRestablishmentDetailsData.reestablishReceivableVO?.adjustedArAmt ? numeral(writeOffRestablishmentDetailsData.reestablishReceivableVO.adjustedArAmt).format('0,0.00') : '',
        arLetterSentRest: '6'
      });
      setSelectedEstablishDate(writeOffRestablishmentDetailsData.reestablishReceivableVO.establishedDateStr ? writeOffRestablishmentDetailsData.reestablishReceivableVO.establishedDateStr : moment(new Date()).format('MM/DD/YYYY'));
    }
  }, [writeOffRestablishmentDetailsData, payeeList, payeeIdCodeList, payeeIdTypeCodeDataList]);

  const [value, setValue] = React.useState(0);

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  /* This function is used to handle change in any input field */
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    setAllowNavigation(true);
  };

  /* This function is used to handle and set the values of payee id type code based on payee type selected by user */
  const handleChangePayeeType = name => event => {
    setPayeeValues({ ...values, [name]: event.target.value });
    setAllowNavigation(true);

    if (name === 'payeeTypeRest') {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        onSearchPayeeIdType(event.target.value);
        const payeeTypeDesc = fieldDescription(payeeList, event.target.value);
        setPayeeTypeDesc(payeeTypeDesc);
      }
      else{
        setPayeeIdCodeListDropdown([]);
        setPayeeValues({
          payeeTypeRest: DEFAULT_DD_VALUE,
          payeeIdCodeRest: DEFAULT_DD_VALUE
        });
      }
    } else if (event.target.value === DEFAULT_DD_VALUE) {
      setPayeeValues({
        payeeTypeRest: payeeValues.payeeTypeRest,
        payeeIdCodeRest: DEFAULT_DD_VALUE
      });
    } else {
      if (name === 'payeeIdCodeRest' && event.target.value !== DEFAULT_DD_VALUE) {
        const payeeTypeIdCodeDesc = fieldDescription(payeeIdCodeListDropdown, event.target.value);
        setPayeeTypeIdCodeDesc(payeeTypeIdCodeDesc);
      }
      setPayeeValues({
        payeeTypeRest: payeeValues.payeeTypeRest,
        payeeIdCodeRest: event.target.value
      });
    }
  };

  /* This function is used to handle tab change */
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  /* This function is used to set establish date using date picker */
  const handleEstablishDateChange = date => {
    setAllowNavigation(true);
    setSelectedEstablishDate(date);
  };

  /* This function is used to set establish date using keyboard or key press */
  const handleEstablishDateText = establishDateText => {
    setAllowNavigation(true);
    setSelectedEstablishDate(establishDateText.target.value);
    setEstablishDatePress(true);
  };

  function validate () {
    writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerTypeCode = payeeValues.payeeTypeRest;
    writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerIDTypeCode = payeeValues.payeeIdCodeRest;
    writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerID = values.payeeIdRest;
    writeOffRestablishmentDetailsData.transferVO.payeePayerTypeCode = payeeValues.payeeTypeRest;
    writeOffRestablishmentDetailsData.transferVO.payeePayerIDTypeCode = payeeValues.payeeIdCodeRest;
    writeOffRestablishmentDetailsData.transferVO.payeePayerID = values.payeeIdRest;

    writeOffRestablishmentDetailsData.reestablishReceivableVO = removeLeadingTrailingSpaces(writeOffRestablishmentDetailsData.reestablishReceivableVO);
    writeOffRestablishmentDetailsData.transferVO = removeLeadingTrailingSpaces(writeOffRestablishmentDetailsData.transferVO);

    validatePayeeIDAPI(writeOffRestablishmentDetailsData);
  }

  /* This function is used to save write-off restablishment details by hitting API
  *  If all required fields entered and validations passes, hit the API
  */
  const saveWriteOffRestablishDetails = (values) => {
    errorMessagesArray = [];
    var showPayeeIdErrorText = '';
    var showEstablishDateError; var showReasonCodeError; var showPayeeTypeError; var showPayeeIdError; var showPayeeIdCodeError = false;
    var showATRError = false;
    const regex = /[^A-Za-z0-9]/;
    if ((!(values.reasonCodeRest) || values.reasonCodeRest === DEFAULT_DD_VALUE)) {
      showReasonCodeError = true;
      errorMessagesArray.push(Constants.REASON_CODE_REQUIRED);
      setErrorMessages(errorMessagesArray);
    }
    if (values.atrNumberData && regex.test(values.atrNumberData)) {
      showATRError = true;
      errorMessagesArray.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}`);
      setErrorMessages(errorMessagesArray);
    } else { showATRError = false; }
    if ((!(payeeValues.payeeTypeRest) || payeeValues.payeeTypeRest === DEFAULT_DD_VALUE)) {
      showPayeeTypeError = true;
      errorMessagesArray.push(Constants.PAYEE_TYPE_REQUIRED);
      setErrorMessages(errorMessagesArray);
    }

    if ((!(payeeValues.payeeIdCodeRest) || payeeValues.payeeIdCodeRest === DEFAULT_DD_VALUE)) {
      showPayeeIdCodeError = true;
      errorMessagesArray.push(Constants.PAYEE_TYPE_ID_CODE_REQUIRED);
      setErrorMessages(errorMessagesArray);
    }

    if (isNullOrEmpty(values.payeeIdRest)) {
      showPayeeIdError = true;
      errorMessagesArray.push(Constants.PAYEE_ID_REQUIRED);
      showPayeeIdErrorText = Constants.PAYEE_ID_REQUIRED;
      setErrorMessages(errorMessagesArray);
    } else if ((values.payeeIdRest).indexOf(' ') >= 0) {
      showPayeeIdError = true;
      errorMessagesArray.push(Constants.PAYEE_INVALID);
      showPayeeIdErrorText = Constants.PAYEE_INVALID;
      setErrorMessages(errorMessagesArray);
    }

    if (selectedEstablishDate) {
      if (!checkForValidDateString(getDateInMMDDYYYYFormat(selectedEstablishDate))) {
        showEstablishDateError = true;
        errorMessagesArray.push(Constants.ESTABLISH_DATE_INVALID);
        setErrorMessages(errorMessagesArray);
      }
    }

    setShowError({
      showReasonCodeError: showReasonCodeError,
      showPayeeTypeError: showPayeeTypeError,
      showPayeeIdCodeError: showPayeeIdCodeError,
      showPayeeIdError: showPayeeIdError,
      showEstablishDateError: showEstablishDateError,
      showATRError: showATRError
    });

    setShowErrorText({
      showPayeeIdErrorText: showPayeeIdErrorText
    });

    if (errorMessagesArray.length === 0) {
      errorMessagesArray = [];
      setErrorMessages([]);
      if (!allowNavigation) {
        NoSaveMessage();
      } else {
        writeOffRestablishmentDetailsData.auditUserID = logInUserID;
        writeOffRestablishmentDetailsData.auditTimeStamp = getUTCTimeStamp();
        writeOffRestablishmentDetailsData.addedAuditUserID = writeOffRestablishmentDetailsData.addedAuditUserID ? writeOffRestablishmentDetailsData.addedAuditUserID : logInUserID;
        writeOffRestablishmentDetailsData.addedAuditTimeStamp = writeOffRestablishmentDetailsData.addedAuditTimeStamp ? writeOffRestablishmentDetailsData.addedAuditTimeStamp : getUTCTimeStamp();

        if (writeOffRestablishmentDetailsData.payeePayerTypeCode != null && writeOffRestablishmentDetailsData.payeePayerTypeCode !== undefined) {
          writeOffRestablishmentDetailsData.payeePayerTypeCode = writeOffRestablishmentDetailsData.payeePayerTypeCode.split('-')[0];
        }

        if (writeOffRestablishmentDetailsData.accountReceivableReasonCode != null && writeOffRestablishmentDetailsData.accountReceivableReasonCode !== undefined) {
          writeOffRestablishmentDetailsData.writeOffReasonCode = writeOffRestablishmentDetailsData.accountReceivableReasonCode.split('-')[0];
        }
        writeOffRestablishmentDetailsData.activityTypeCode = writeOffRestablishmentDetailsData.activityTypeCode.split('-')[0];
        if (writeOffRestablishmentDetailsData.accountReceivableReasonCode != null && writeOffRestablishmentDetailsData.accountReceivableReasonCode !== undefined) {
          writeOffRestablishmentDetailsData.accountReceivableReasonCode = writeOffRestablishmentDetailsData.accountReceivableReasonCode.split('-')[0];
        }
        if (writeOffRestablishmentDetailsData.payeePayerIDTypeCode != null && writeOffRestablishmentDetailsData.payeePayerIDTypeCode !== undefined) {
          writeOffRestablishmentDetailsData.payeePayerIDTypeCode = writeOffRestablishmentDetailsData.payeePayerIDTypeCode.split('-')[0];
        }
        writeOffRestablishmentDetailsData.lob = AppConstants.DEFAULT_LOB_VALUE;
        writeOffRestablishmentDetailsData.atrNumber = writeOffRestablishmentDetailsData?.atrNumber;

        writeOffRestablishmentDetailsData.reestablishReceivableVO.establishedDateStr = moment(selectedEstablishDate).format('MM/DD/YYYY');
        writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerID = values.payeeIdRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.atrNumber = values?.atrNumberData?.toUpperCase();
        writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerTypeCode = payeeValues.payeeTypeRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerIDTypeCode = payeeValues.payeeIdCodeRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.accountReceivableReasonCode = values.reasonCodeRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.agingCode = values.arLetterSentRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerIdFromDB = systemPayeeIdRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.payeePayerName = payeeNameRest;
        writeOffRestablishmentDetailsData.reestablishReceivableVO.balanceAmount = writeOffRestablishmentDetailsData.balanceAmount;
        if (writeOffRestablishmentDetailsData.noteSetSK) {
          const noteSK = writeOffRestablishmentDetailsData.noteSetVO.noteSetSK;
          writeOffRestablishmentDetailsData.noteSetVO = writeVONotesInput;
          writeOffRestablishmentDetailsData.noteSetVO.noteSetSK = noteSK;
        } else {
          writeOffRestablishmentDetailsData.noteSetVO = writeVONotesInput;
        }
        const index = attachmentsTableData.length - 1;
        const attachmentVOList = [];
        if (attachmentsTableData && attachmentsTableData.length > 0) {
          attachmentsTableData.map((value) => {
            if (value.id) {
              const attachmentVO = {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: true,
                sortColumn: null,
                attachmentSK: null,
                attachmentPageID: null,
                finalPath: null,
                detachInd: false,
                historyIndicator: false,
                cascadeKey: null,
                attachmentIndicator: null,
                dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
                fileUrl: null,
                showDeleteForSearched: false,
                rowIndex: 0,
                addedBy: logInUserID,
                description:
                  value && value
                    ? value.description
                    : null,
                edmsStoredDate: null,
                anEDMSPageId: value? value.anEDMSPageId : null,
                fileName:
                  attachmentsTableData && value
                    ? value.fileName
                    : null,
                file1: null,
                edmsWrkUnitLevel: null,
                edmsDocType: null
              };
              attachmentVOList.push(attachmentVO);
            }
          });
        }
        writeOffRestablishmentDetailsData.reestablishReceivableVO = removeLeadingTrailingSpaces(writeOffRestablishmentDetailsData.reestablishReceivableVO);
        writeOffRestablishmentDetailsData.attachmentVO = null;
        writeOffRestablishmentDetailsData.attachmentsList = attachmentVOList;
        onSaveWriteOffRestablishment(removeLeadingTrailingSpaces(writeOffRestablishmentDetailsData), index);
      }
    }
  };

  /* Check if text enetered is empty or contains only white spaces */
  const isNullOrEmpty = (str) => {
    return !str || !str.trim();
  };
  // Payee Popup Implementation Starts
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const [canCel, setCancel] = React.useState(false);
  const handleCloseSysPayeeTable = () => {
    setCancel(true);
    setOpenSysPayeeTable(false);
    setValues({
      ...values,
      payeeIdRest: ''
    });
  };

  useEffect(() => {
    if (inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  }, [canCel]);
  const onRadioButtonClick = (row) => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
      setPayeeNameRest(currentSysID.payeeName);
      setToSysID(currentSysID.sysID);
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  // Payee popup Implementation ends
  /* This function is used to navigate to Accounts Receivable Add Screen */
  const inputRefCursor = useRef(null);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
        when={allowNavigation}
        message={location => `Are you sure you want to go to ${location.pathname}`}
      />
      <ErrorMessages errorMessages={errorMessages} />
      {systemSuccessMessages.length > 0 && errorMessages.length === 0 ? (
        <SuccessMessages successMessages={systemSuccessMessages} />
      ) : null}

      <div className="tabs-container" ref={toPrintRef}>
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            {Constants.WRITEOFFESTABLISHMENT_HEADER}
          </h1>
          <div className="float-right mt-2">
            <Button className='btn btn-success ml-1' onClick={() => saveWriteOffRestablishDetails(values)}>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form with-select input-sm  min-md">
                <TextField
                  id="payee-type"
                  fullWidth
                  label="Payee Type"
                  value={values.payeePayerTypeCodeDesc || ''}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-15  min-md">
                <TextField
                  id="payee-type-code"
                  fullWidth
                  label="Payee ID Type Code"
                  value={values.payeeIdTypeCode || ''}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-15  min-md">
                <TextField
                  id="payee-id"
                  fullWidth
                  label="Payee ID"
                  value={values.payeeId}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-15 with-link  min-md has-link">
                <TextField
                  id="system-payee-id"
                  fullWidth
                  label="System Payee ID"
                  disabled={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values)}>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-xl min-md">
                <TextField
                  id="payee-name"
                  fullWidth
                  label="Payee Name"
                  value={values.payeeName}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md  min-md">
                <TextField
                  id="activityType"
                  fullWidth
                  label="Activity Type"
                  value={values.activityTypeCodeDesc || ''}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md min-md">
                <TextField
                  id="reasonCode"
                  fullWidth
                  label="Reason Code"
                  value={values.ARReasonCodeDesc || ''}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  title={RClongDesc || values.reasonCode}
                >
                </TextField>
              </div>
            </div>
          </form>
          <div className='tab-panelbody'>
            <div className="tab-holder mb-3 mt-1" >
              <AppBar position="static">
                <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example" >
                  <Tab label="Write-Off Re-establishment" />
                  <Tab label="Notes" />
                  <Tab label="Attachments" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div style={{ marginTop: '20px' }}></div>
                <WriteOffRestablismentTabDetail values={values}
                  saveFlag={saveFlag}
                  payeeValues={payeeValues}
                  handleChange={handleChange}
                  handleChangePayeeType={handleChangePayeeType}
                  inputRefCursor={inputRefCursor}
                  reasonCodeList={reasonCodeList}
                  arLetterSentList={arLetterSentList}
                  payeeList={payeeList}
                  payeeIdCodeList={payeeIdCodeList}
                  payeeIdCodeListDropdown={payeeIdCodeListDropdown}
                  selectedEstablishDate={selectedEstablishDate}
                  handleEstablishDateChange={handleEstablishDateChange}
                  handleEstablishDateText={handleEstablishDateText}
                  validate={validate}
                  payeeNameRest={payeeNameRest}
                  systemPayeeIdRest={systemPayeeIdRest}
                  errors={[showReasonCodeError, showPayeeTypeError, showPayeeIdCodeError, showEstablishDateError, showPayeeIdError, showATRError]}
                  errorText={[showReasonCodeErrorText, showPayeeTypeErrorText, showPayeeIdCodeErrorText, showEstablishDateErrorText, showPayeeIdErrorText]}
                ></WriteOffRestablismentTabDetail>

              </TabPanel>
              <TabPanel value={value} index={1}>
                <div>
                  <Notes addNotes={addNotes}
                    notesTableData={notesTableData}
                    noteSetListInput={noteSetListInput}
                    setNoteSetListInput={setNoteSetListInput}
                    usageTypeCodeData={usageTypeCodeData}
                    editNoteData={editNoteData}
                    setFocusCheck={setFocusCheck}
                    setEditNoteData={setEditNoteData}
                    setSuccessMessages={clearSuccessErrorMsgs} />
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={userInquiryPrivileges}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={setErrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    setFocusCheck={setFocusCheck}
                    values={values}
                    setValues={setValues}
                    attchmentResponse={attchmentResponse}
                    setAttachmentResponse={setAttachmentResponse}
                  />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
      <SystemPayeeTable
        payeeType={payeeTypeDesc}
        payeeIdTypeCode={payeeTypeIdCodeDesc}
        payeeId={values.payeeIdRest}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
      />
    </div>
  );
}

export default withRouter((WriteOffReEstablishment));
