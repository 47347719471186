/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  NavLink,
  Route,
  Link,
  useLocation,
  withRouter,
} from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { Dropdown, Button } from "react-bootstrap";
import Routes from "./Routes";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Hamburger from "./hamburger";
import Footer from "../Layout/footer";
import Orange from "./orange";
import { getActivePath } from "./navStructure";
import ErrorHandler from "../ErrorHandler";
import * as AppConstants from "../AppConstants";
import SessionExpire from "../Dialog/SessionExpireDialog";
import {
  safeLocalStorage,
  setTokenInStore,
  logOut,
} from "../store/Actions/SharedAction";
import "../../LeftNav.scss";
import {
  FADS_INSIGHT_LINK,
  MARS_INSIGHT_LINK,
  CLAIMS_INQUIRY_COMP,
  HELP_DOCUMENT,
} from "../services/service";
import routeHelpTabConfig from "./HelperTab";
import TopLogo from "../../images/logo2.png";
import { isReadOnly } from "../../SharedModules/CommonUtilities/commonUtilities";
import ProfileIcon from "../../SharedModules/MicroFrontend/MicrofrontendContainer";
import ProfileCard from "../../SharedModules/MicroFrontend/MicrofrontendContainer";
import { My_ACCOUNT } from "./RouteConstants";
import Spinner from "../../SharedModules/Spinner/Spinner";
import { navigateToClaims } from "../../SharedModules/TabRedirections/TabRedirections";

const drawerWidth = 240;
global.globalIsReadOnly = isReadOnly;
global.requestCount = 0;
global.enableSpinner = false;
function PrintElem() {
  window.print();
}
let getHelpUrl = null;
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    height: "100%",
    padding: "20px 0 0 0",
  },
  drawer: {
    boxSizing: "border-box",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    position: "relative",
    boxSizing: "border-box",
    width: drawerWidth,
  },
  drawerPaperShrink: {
    position: "relative",
    boxSizing: "border-box",
    width: 100,
  },
  drawerFooter: {
    display: "flex",
    marginTop: "auto",
    width: "100%",
    alignItems: "center",
    padding: theme.spacing(4, 1),
    ...theme.mixins.toolbar,
    justifyContent: "center",
  },
  drawerFooterText: {
    color: "#000",
    fontSize: "0.788em",
    marginBottom: " 0px",
    letterDpacing: "-0.4px",
    fontWeight: "600",
  },
  loginStatus: {
    display: "flex",
    alignItems: "center",
  },
  dropDownItem: {
    padding: "10px 24px",
  },
  nestedFolder: {
    paddingRight: "16px!important",
  },
}));

const Item = ({
  navTo,
  value,
  icon = null,
  hasChildren = false,
  isSelected = true,
  toggleDrawer,
  drawerIsOpen,
}) => {
  let HamburgerIcon = () => (
    <Hamburger isOpen={drawerIsOpen} toggleDrawer={toggleDrawer} />
  );
  let openInNewTab =
    value === "MARS" ||
    value === "FADS" ||
    value === "Operational Insights" ||
    value === "Performance Insights" ||
    value === "Claims";

  if ((!hasChildren || value === "CMdS-Financial") && !openInNewTab) {
    return (
      <>
        {value === "CMdS-Financial" && <HamburgerIcon />}
        <NavLink
          classes={isSelected ? "side-menu-with-active" : ""}
          style={{ display: "inline-flex" }}
          to={navTo}
          onClick={() => {
            if (value === "CMdS-Financial") {
              if (document.getElementById("iframe")) {
                document.getElementById("iframe").classList.add("hide");
              }
              if (document.getElementById("tableDisplay")) {
                document
                  .getElementById("tableDisplay")
                  .classList.remove("hide");
              }
              if (document.getElementById("sidebar-div")) {
                document.getElementById("sidebar-div").classList.remove("hide");
              }
              if (document.getElementById("alert-div")) {
                document.getElementById("alert-div").classList.remove("hide");
              }
              if (document.getElementById("main-col")) {
                document
                  .getElementById("main-col")
                  .classList.remove("col-lg-12");
              }
              if (document.getElementById("dashboard-heading")) {
                document
                  .getElementById("dashboard-heading")
                  .classList.remove("hide");
              }
              if (document.getElementById("banner-section")) {
                document
                  .getElementById("banner-section")
                  .classList.remove("hide");
              }
            }
          }}
        >
          {icon && icon}
          <ListItemText primary={value} />
          {hasChildren ? isSelected ? <ExpandLess /> : <ExpandMore /> : null}
        </NavLink>
      </>
    );
  } else if (!hasChildren && openInNewTab) {
    return (
      <>
        <a
          classes={isSelected ? "side-menu-with-active" : ""}
          style={{ display: "inline-flex", width: "100%", height: "100%" }}
          href={navTo}
          target={"_blank"}
        >
          {icon && icon}
          <ListItemText primary={value} />
          {hasChildren ? isSelected ? <ExpandLess /> : <ExpandMore /> : null}
        </a>
      </>
    );
  } else
    return (
      <>
        {icon && icon}
        <ListItemText
          primary={value}
          onClick={() => openInNewTab && window.open(navTo)}
        />
        {hasChildren ? isSelected ? <ExpandLess /> : <ExpandMore /> : null}
      </>
    );
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedItems: [],
  };
  selectActive = (id, nestLvl, isFolder) => {
    let _selectedItems = [...this.state.selectedItems];
    _selectedItems[nestLvl] !== id
      ? (_selectedItems[nestLvl] = id)
      : isFolder && _selectedItems.splice(nestLvl);
    _selectedItems.length > nestLvl && _selectedItems.splice(nestLvl + 1);
    this.setState({ selectedItems: _selectedItems });
  };
  rerenderActive(path) {
    let _selectedItems = getActivePath(path);
    this.setState({ selectedItems: _selectedItems || [] });
  }
  componentDidMount() {
    this.rerenderActive(this.props.location.pathname);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.rerenderActive(this.props.location.pathname);
    }
  }
  renderSubMenu({ menuItems, nestedLevel = 0 }) {
    const MenuItems = menuItems.map((structure, i) => {
      let { classes, drawerIsOpen, toggleDrawer, userInquiry } = this.props;
      let userPrivilegesCheck =
        structure.privilegesNeeded && !structure.privilegesNeeded;
      let userInquiryCheck =
        (structure.userInquiry && userInquiry[structure.userInquiry]) ||
        !structure.userInquiry;
      if (!userPrivilegesCheck || !userInquiryCheck) return <></>;
      let newNestedLevel = nestedLevel + 1;
      let isSelected = this.state.selectedItems[nestedLevel] === structure.id;
      const Item_1 = Item({
        ...structure,
        nestedLevel,
        isSelected,
        ...this.props,
      });
      let SubMenu = structure.hasChildren
        ? this.renderSubMenu({
            menuItems: structure.children,
            nestedLevel: newNestedLevel,
          })
        : null; //&& isSelected
      let colorLvl = nestedLevel === 0 ? 1 : 1 + nestedLevel * 0.2;
      return (
        <React.Fragment key={structure.id}>
          <ListItem
            button
            key={structure.value}
            className={clsx(
              classes.nestedFolder,
              isSelected ? "highlighted-side-nav" : ""
            )}
            id={structure.id}
            style={{
              ...(isSelected
                ? {
                    background: `rgb(${39 * colorLvl},${68 * colorLvl},${
                      99 * colorLvl
                    })`,
                  }
                : {}),
            }}
            onClick={() => {
              !drawerIsOpen && toggleDrawer();
              this.selectActive(
                structure.id,
                nestedLevel,
                structure.hasChildren
              );
              !structure.hasChildren &&
                this.rerenderActive(this.props.location.pathname);
            }}
          >
            {Item_1}
          </ListItem>
          <Collapse
            style={{ paddingLeft: 16 + nestedLevel * 8 }}
            in={isSelected && drawerIsOpen}
          >
            {SubMenu}
          </Collapse>
        </React.Fragment>
      );
    });

    return MenuItems;
  }
  render() {
    return (
      <List className="side-menu-list">
        {this.renderSubMenu({ menuItems: this.props.menuItems })}
      </List>
    );
  }
}

export const DrawerFooter = ({ print, drawerIsOpen = true }) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={clsx(classes.drawerHeader1, "bottom", "left-bottom-logo")}
      >
        <div className="poweredBy">Powered By</div>
        <div className="left-bottom-brand">
          {" "}
          <img
            src={drawerIsOpen ? "bottom_logo.png" : "logo2.png"}
            alt="CONDUENT TM"
            title="CONDUENT"
          />{" "}
        </div>
      </div>
      <div align="left" className="build-version">
        Build Version No:{" "}
        <span>
          <a title={process.env.REACT_APP_PRODUCT_BUILD_NO}>
            {" "}
            {process.env.REACT_APP_PRODUCT_BUILD_NO}
          </a>
        </span>
      </div>
    </>
  );
};

function App(props) {
  var $;
  $ = require("jquery");
  const printRef = useRef();
  const printRef2 = useRef();
  const isNormalUser = localStorage.getItem("isNormalUser");
  const roles = useSelector((state) => state.sharedState.userPrivileges);
  const lsValue = JSON.parse(localStorage.getItem("loginState"));
  const loginUserName = lsValue?.loginUserName;
  let navMenuItems = lsValue?.menuItems || [];
  const copyMenu = navMenuItems;
  const loggedInUser = useSelector((state) => state.sharedState.logInUserID);
  const [timedOutMsg, setTimedOutMsg] = useState(null);
  const { setLoginDone } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userPrivileges, setUserPrivileges] = useState("");
  const [roleName, setRoleName] = useState("");
  const [userName, setUserName] = useState("");
  const [drawerIsOpen, setDrawerOpen] = useState(true);
  const [menuState, setMenuState] = useState({});
  const [menuActiveState, setMenuActiveState] = useState({});
  const [menuElementActive, setMenuElementActive] = useState({});
  const [menuChildActive, setMenuChildActive] = useState({});
  const [open, setOpen] = useState(true);
  const [mainClass, setMainClass] = useState("");
  const [NavCancelD, setNavCancelD] = useState(false);
  const logInUserID = useSelector((state) => state.sharedState.logInUserID);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerIsOpen);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    setMainClass("");
  };

  const [openTimer, setOpenTimer] = React.useState(false);
  const [spinnerOn, setSpinnerOn] = React.useState(false);

  axios.interceptors.request.use(
    async function (config) {
      const loginState = JSON.parse(localStorage.getItem("loginState"));
      let accessToken = localStorage.getItem("accessToken");
      config.headers.Username = loginState?.logInUser
        ? loginState.logInUser
        : null;
      config.headers.Authorization = accessToken;
      config.headers["X-CMDS-APP-CODE"] = process.env.REACT_APP_CMDS_APP_CODE;
      if (
        config.url.includes("ApplicationConfigurationUser") ||
        config.url.includes("cxf/api/")
      ) {
        return config;
      }
      config.timeout = 3 * 60 * 1000;
      global.requestCount = global.requestCount + 1;
      if (global.enableSpinner && !spinnerOn && global.requestCount) {
        setSpinnerOn(true);
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      global.requestCount = global.requestCount - 1;
      spinnerOff();
      return response;
    },
    function (error) {
      let e;
      try {
        e = error.toJSON();
      } catch (err) {
        e = error;
      }
      if (window.location.pathname === "/dashboard") {
        setTimedOutMsg(null);
      } else if (e.code == "ECONNABORTED" || error.message == "Network Error") {
        setTimedOutMsg(
          "There was an error processing the request. Please retry the transaction."
        );
      } else if (error?.message === "Request failed with status code 401") {
        props.setLogOut();
        setTimeout(() => {
          window.location = "/";
        }, 1000);
      } else {
        setTimedOutMsg(null);
      }
      global.requestCount = global.requestCount - 1;
      spinnerOff();
      return Promise.reject(error);
    }
  );
  const spinnerOff = () => {
    if (!global.requestCount) {
      setSpinnerOn(false);
    }
  };
  const profileIconElement = (profileData = {}) => {
    return (
      <ProfileIcon type="profile_Icon" from="FSS" userData={profileData} />
    );
  };
  const handleClick = (item, parent) => {
    $("body").removeClass("leftNavOff");
    setMenuState((prevState) => ({
      [item]: !prevState[item],
      [parent]: prevState[parent],
    }));
  };
  const changeRoute = (routeName) => {
    window.open(routeName, "_blank");
  };
  function formactiveElement() {
    setTimeout(function () {
      $("button.focused-button").removeClass("focused-button");
      $("div.focused-input").removeClass("focused-input");
      var $focused = $(":focus");
      // Check if it is a button
      if ($("button.btn").is(":focus")) {
        $("button.btn").removeClass("focused-button");
        $focused.addClass("focused-button");
      } else if ($("input").is(":focus") || $("select").is(":focus")) {
        $("div.MuiInput-root").removeClass("focused-input");
        $focused.closest("div.MuiInput-root").addClass("focused-input");
      } else {
        //Do nothing
      }
    }, 0);
  }
  $("body").ready(function () {
    formactiveElement();
    $(document).on("focus blur", "button, input, select", function () {
      formactiveElement();
    });

    $(document).on("click", "button.btn", function () {
      var btn = $(this);
      setTimeout(function () {
        if (btn.hasClass("focused-button")) {
          $("button.btn").removeClass("focused-button");
        }
      }, 100);
    });

    $(document).on("click", "button.btn, a.sideNav-Link", function () {
      if (timedOutMsg) setTimedOutMsg(null);
    });

    $(
      'div.MuiButtonBase-root.MuiListItem-root.undefined.MuiListItem-gutters.MuiListItem-button[title*="Clearing House"] svg'
    ).hide();
    $(
      'span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock:contains("Clearing House")'
    ).click(function () {
      $(
        "div.MuiButtonBase-root.MuiListItem-root.undefined.MuiListItem-gutters.MuiListItem-button"
      ).removeClass("activeNav");
      $(
        'div.MuiButtonBase-root.MuiListItem-root.undefined.MuiListItem-gutters.MuiListItem-button[title*="Clearing House"]'
      ).addClass("activeNav");
    });
    if (window.location.pathname === "/SearchEncounterClaims") {
      $(
        "div.MuiButtonBase-root.MuiListItem-root.undefined.MuiListItem-gutters.MuiListItem-button"
      ).removeClass("activeNav");
      $(
        'div.MuiButtonBase-root.MuiListItem-root.undefined.MuiListItem-gutters.MuiListItem-button[title*="Clearing House"]'
      ).addClass("activeNav");
    }
  });
  const handleinitialClick = (item, parent) => {
    setMenuState((prevState) => ({
      [item]: !prevState[item],
      [parent]: !prevState[parent],
    }));
  };
  const handelClickDashboard = () => {
    $(".MuiListItem-root").removeClass("activeNav");
    $(".menu-level-3").removeClass("activeNavLi");
    $(".dashBoard").addClass("activeNav");
    setMenuState(true);
  };
  const [elemStore, setElemStore] = useState({});
  const handelLinkClick = (item, subName, subUrl, NavCancelD) => {
    setTimedOutMsg(null);
    $(".MuiListItem-root").removeClass("activeNav");
    $(item.target)
      .closest(".muiMainNav")
      .find(".MuiListItem-root")
      .first()
      .addClass("activeNav");
    if (!NavCancelD) {
      setMenuElementActive({ [item.target.textContent]: true });
      setElemStore((prevState) => ({ ...prevState, ...menuElementActive }));
      let thisparentA = "";
      let itemA = "";
      copyMenu.map((parent) => {
        parent.children &&
          parent.children.map((children) => {
            if (
              children.url === subUrl &&
              children.name === item.target.textContent
            ) {
              itemA = parent.name;
              setMenuActiveState({ [itemA]: true });
              setMenuElementActive({ [children.name]: true });
              setMenuState((prevState) => ({
                [children.name]: !prevState[children.name],
                [itemA]: prevState[itemA],
              }));
            } else if (children.children) {
              children.children.map((subchildren) => {
                if (
                  subchildren.name === item.target.textContent &&
                  subchildren.url === subUrl
                ) {
                  thisparentA = parent.name;
                  itemA = children.name;
                  setMenuActiveState({ [thisparentA]: true });
                  setMenuChildActive({ [itemA]: true });
                  setMenuElementActive({ [subchildren.name]: true });
                  setElemStore({ [subchildren.name]: true });
                }
              });
            }
          });
      });
      $("body").addClass("leftNavOff");
    }
    $(item.target).closest("menu-level-3").addClass("activeNavLi");
    var screen = $(window);
    if (screen.width() < 1024) {
      $("body").addClass("leftNavOff");
    } else {
      $("body").removeClass("leftNavOff");
    }
  };
  /* TCN Hyper Link */

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        $("body").addClass("leftNavOff");
      }
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("message", (e) => {
      if (e.origin === process.env.REACT_APP_LANDING_SCREEN_URL) {
        if (e.data === "logOut") {
          localStorage.clear();
          dispatch(logOut());
        } else {
          dispatch(setTokenInStore(e.data));
        }
      }
    });
  });
  const toggleSideBar = () => {
    setMenuState({});
    if ($("body").hasClass("leftNavOff")) {
      $("body").removeClass("leftNavOff");
    } else {
      $("body").addClass("leftNavOff");
    }
  };

 
  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (safeLocalStorage("get", "loginState")) {
        setLoginDone(true);
      } else {
        let id = window.setTimeout(function () {}, 0);
        id = id + 1;
        while (id--) {
          window.clearTimeout(id);
          window.clearInterval(id);
        }
      }
    });
    window.addEventListener("beforeunload", (e) => {
      if (localStorage.getItem("lsso")) {
        if (window.opener) {
          window.opener.postMessage(
            `checkTabClose-${window.name || ""}`,
            process.env.REACT_APP_SSO_LOGIN_URL
          );
        }
      }
      window.onbeforeunload = null;
      e.stopImmediatePropagation();
    });
    window.addEventListener("click", (e) => {
      // Get all <li> elements with class name 'MuiBreadcrumbs-li'
      const liElements = document.querySelectorAll(".MuiBreadcrumbs-li");

      // Select the second <p> element that meets the criteria
      const pElement = Array.from(liElements).filter((liElement) =>
        liElement.classList.contains("MuiBreadcrumbs-li")
      );
      const pElement2 = pElement.map((liElement) =>
        liElement.querySelector(".MuiTypography-root.MuiTypography-body1")
      );
      const pElement3 = pElement2.filter(
        (ele, index, array) =>
          ele !== null &&
          (ele.innerText.toLowerCase().includes("add") ||
            ele.innerText.toLowerCase().includes("edit") ||
            ele.innerText.toLowerCase().includes("search"))
      )[0];
      // Verify if a valid <p> element was found
      const content1 = pElement3?.textContent;
      if (content1?.toLowerCase().includes("add")) {
        localStorage.setItem("addOrEdit", "Add");
      } else {
        localStorage.setItem("addOrEdit", "Edit");
      }
    });
    window.addEventListener(
      "click",
      (e) => {
        if(localStorage.getItem("globalSpinnerInd")){
        global.enableSpinner = true;
        }     
        if (
          e.target.type === "button" &&
          e.target?.innerText?.toLowerCase()?.trim() == "search"
        ) {
          global.enableSpinner = true;
        }else if(e.target?.innerText?.toLowerCase()?.trim() == "add"){
			global.enableSpinner = true;
			localStorage.setItem("IsAddOrCancel","Add")
		}
		else if(e.target?.innerText?.toLowerCase()?.trim() == "save"){
			global.enableSpinner = true;
		}
		else if(e.target?.innerText?.toLowerCase()?.trim() == "cancel"){
			global.enableSpinner = true;
			localStorage.setItem("IsAddOrCancel","Cancel")
		}
		else if(e.target?.innerText?.toLowerCase()?.trim() == "submit"){
			global.enableSpinner = true;
		}else if(e.target?.innerText?.toLowerCase()?.trim() == "continue"){
			global.enableSpinner = true;
		}
      },
      true
    );
    window.addEventListener("blur", (event) => {
      if(localStorage.getItem("globalSpinnerInd")){
        global.enableSpinner = true;
        }
    });


    window.addEventListener("click", (event) => {
      // Get the clicked element
      const clickedElement = event.target;
      // Get the href attribute
      const href = clickedElement.getAttribute("href");
      // Check if the clicked element is an anchor tag
      if (clickedElement.tagName.toLowerCase() === "a") {
        const params = !href?.includes("/dashboard") && href?.split("?");
        if (params && params.length > 0 && CLAIMS_INQUIRY_COMP === params[0]) {
          event.preventDefault();
          const loginState = JSON.parse(localStorage.getItem("loginState"));
          const accessToken = localStorage.getItem("accessToken");
          const refreshToken = localStorage.getItem("refreshToken");
          const userName = loginState?.logInUser;
          window.open(
            navigateToClaims(href, userName, accessToken, refreshToken),
            "_blank"
          );
        }
      }
    });
  }, []);
    
  useEffect(() => {
    if (window.location.pathname) {
      let thisparent = "";
      let item = "";
      copyMenu.map((parent) => {
        parent.children &&
          parent.children.map((children) => {
            if (children.url == window.location.pathname) {
              item = parent.name;
              setMenuActiveState({ [item]: true });
              setMenuElementActive({ [children.name]: true });
              setElemStore({ [children.name]: true });
              handleinitialClick(item, thisparent);
            } else if (children.children) {
              children.children.map((subchildren) => {
                if (subchildren.url == window.location.pathname) {
                  thisparent = parent.name;
                  item = children.name;
                  setMenuActiveState({ [thisparent]: true });
                  setMenuElementActive({ [subchildren.name]: true });
                  setMenuChildActive({ [item]: true });
                  setElemStore({ [subchildren.name]: true });
                  handleinitialClick(item, thisparent);
                }
              });
            }
          });
      });
    }
    var screen = $(window);
    if (screen.width() < 1024) {
      $("body").addClass("leftNavOff");
    }
  }, [loggedInUser, roles]);
  const handler = (children, name, disable = true, roles = "") => {
    let linkDisable = "";
    let privileges = "";
    if (children) {
      return children.map((subOption) => {
        if (name && !subOption.children?.length) {
          if (roleName == "Inquiry All") {
            if (subOption.url == "/Inquiry") {
              return (
                <List
                  component="div"
                  className={`menu-level-3 ${
                    menuActiveState[name] && menuElementActive[subOption.name]
                      ? "activeNavLi"
                      : ""
                  }`}
                  key={subOption.name}
                  disablePadding
                >
                  <Link
                    className="sideNav-Link"
                    to={subOption.url}
                    id={subOption.url}
                    onClick={handelLinkClick}
                    title={subOption.name}
                  >
                    <ListItem className={classes.nested}>
                      <ListItemText primary={subOption.name} />
                    </ListItem>
                  </Link>
                </List>
              );
            } else {
              return false;
            }
          } else {
            return (
              <List
                component="div"
                className={`menu-level-3 ${
                  !menuChildActive[name]
                    ? menuActiveState[name] && menuElementActive[subOption.name]
                      ? "activeNavLi"
                      : ""
                    : menuChildActive[name] && menuElementActive[subOption.name]
                    ? "activeNavLi"
                    : ""
                }`}
                key={subOption.name}
                disablePadding
              >
                {roleName == "Inquiry All" && subOption.url == "/Inquiry" ? (
                  <Link
                    className="sideNav-Link"
                    to={subOption.url}
                    id={subOption.url}
                    onClick={handelLinkClick}
                    title={subOption.name}
                  >
                    <ListItem className={classes.nested}>
                      <ListItemText primary={subOption.name} />
                    </ListItem>
                  </Link>
                ) : roleName == "Inquiry All" && subOption.url != "/Inquiry" ? (
                  ""
                ) : !disable &&
                  (!roles.hasOwnProperty("subOptName") ||
                    (roles.hasOwnProperty("subOptName") &&
                      roles.subOptName.includes(subOption.name))) ? (
                  subOption.url === "/OperationalInsights" ? (
                    <Link
                      className="sideNav-Link"
                      to={subOption.url}
                      id={subOption.url}
                      onClick={() =>
                        changeRoute(
                          process.env.REACT_APP_OPERATIONAL_INSIGHTS_URL
                          
                        )
                      }
                      title={subOption.name}
                    >
                      <ListItem className={classes.nested}>
                        <ListItemText primary={subOption.name} />
                      </ListItem>
                    </Link>
                  ) : subOption.url === "/FinancialATR" ? (
                    <Link
                        className="sideNav-Link"
                        to={subOption.url}
                        id={subOption.url}
                        onClick={() => {
                          const s = localStorage.getItem("ssoToken");

                          if (s) {
                            document.cookie = `iPlanetDirectoryPro=${s}; Domain=apps.conduentsecure.com; Path=/;`;
                          changeRoute(process.env.REACT_APP_FINANCIAL_ATR);
                          } else {
                            console.error('SSO tokens are missing');
                          }
                        }}
                      title={subOption.name}
                    >
                      <ListItem className={classes.nested}>
                        <ListItemText primary={subOption.name} />
                      </ListItem>
                    </Link>
                  ) : subOption.url === "/PerformanceInsights" ? (
                    <Link
                      className="sideNav-Link"
                      to={subOption.url}
                      id={subOption.url}
                      onClick={() =>
                        changeRoute(
                          process.env.REACT_APP_PERFORMANCE_INSIGHTS_URL
                        )
                      }
                      title={subOption.name}
                    >
                      <ListItem className={classes.nested}>
                        <ListItemText primary={subOption.name} />
                      </ListItem>
                    </Link>
                  ) : subOption.name === "MARS" ? (
                    <Link
                      className="sideNav-Link"
                      to={subOption.url}
                      id={subOption.url}
                      onClick={() => window.open(MARS_INSIGHT_LINK, "_blank")}
                      title={subOption.name}
                    >
                      <ListItem className={classes.nested}>
                        <ListItemText primary={subOption.name} />
                      </ListItem>
                    </Link>
                  ) : subOption.name === "FADS" ? (
                    <Link
                      className="sideNav-Link"
                      to={subOption.url}
                      id={subOption.url}
                      onClick={() => window.open(FADS_INSIGHT_LINK, "_blank")}
                      title={subOption.name}
                    >
                      <ListItem className={classes.nested}>
                        <ListItemText primary={subOption.name} />
                      </ListItem>
                    </Link>
                  ) : (
                    <Link
                      className="sideNav-Link"
                      to={subOption.url}
                      id={subOption.url}
                      onClick={(e) => {
                        handelLinkClick(
                          e,
                          subOption.name,
                          subOption.url,
                          NavCancelD
                        );
                      }}
                      title={subOption.name}
                    >
                      <ListItem className={classes.nested}>
                        <ListItemText primary={subOption.name} />
                      </ListItem>
                    </Link>
                  )
                ) : (
                  <Link className="sideNav-Link" title={subOption.name}>
                    <ListItem className={classes.nested}>
                      <ListItemText primary={subOption.name} />
                    </ListItem>
                  </Link>
                )}
              </List>
            );
          }
        }
        if (roleName == "Inquiry All") {
          if (subOption.name == "Claims") {
            return (
              <List
                className={`side-menu-list ${
                  subOption.icon ? "muiMainNav" : ""
                }`}
                key={subOption.name}
              >
                <ListItem
                  title={subOption.name}
                  button
                  className={`${classes.nested} ${
                    menuActiveState[subOption.name] ? "activeNav" : ""
                  }`}
                  onClick={() => handleClick(subOption.name, name)}
                  
                >
                  {subOption.icon && (
                    <div className="side-menu-icon">
                      <i className={`fa ${subOption.icon}`} />
                    </div>
                  )}
                  <ListItemText primary={subOption.name} />
                  {subOption.name !== AppConstants.FINANCIAL ? (
                    menuState[subOption.name] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItem>
                <Collapse
                  in={menuState[subOption.name]}
                  timeout="auto"
                  unmountOnExit
                >
                  {handler(
                    subOption.children,
                    subOption.name,
                    linkDisable,
                    privileges
                  )}
                </Collapse>
              </List>
            );
          } else {
            return false;
          }
        } else {
          return (
            <List
              className={`side-menu-list ${subOption.icon ? "muiMainNav" : ""}`}
              key={subOption.name}
            >
              <ListItem
                title={subOption.name}
                button
                className={`${classes.nested} ${
                  menuActiveState[subOption.name] ? "activeNav" : ""
                }`}
                onClick={() => handleClick(subOption.name, name)}
              
              >
                {subOption.icon && (
                  <div className="side-menu-icon">
                    <i className={`fa ${subOption.icon}`} />
                  </div>
                )}
                <ListItemText primary={subOption.name} />
                {subOption.name !== AppConstants.FINANCIAL ? (
                  menuState[subOption.name] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              <Collapse
                in={menuState[subOption.name]}
                timeout="auto"
                unmountOnExit
              >
                {handler(
                  subOption.children,
                  subOption.name,
                  linkDisable,
                  privileges
                )}
              </Collapse>
            </List>
          );
        }
      });
    }
  };
  const loginState = JSON.parse(localStorage.getItem("loginState"));

  const profileData = {
    firstName: loginState?.firstname,
    lastName: loginState?.lastname,
    email: loginState?.email,
  };

  const handleMyaccount = () => {
    props.history.push(My_ACCOUNT);
  };
  const logOutFromPortal = () => {
    dispatch(logOut());
  };
  // Help Implementation Configuration Starts
  getHelpUrl = useLocation();

  // Help Implementation Configuration Ends
  return (
    <>
      <Spinner on={spinnerOn} />
      <Orange />
      <div
        className={clsx(
          classes.wrapper,
          "main-body container-fluid",
          !drawerIsOpen ? "minimize-sidebar" : ""
        )}
        ref={printRef2}
      >
        <CssBaseline />
        <Drawer
          className={clsx(
            open ? classes.drawer : "",
            "side-drawer hide-on-print"
          )}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className="nav-list-container" id="sideNav">
            <div className="db-space">
              <div
                title="Dashboard"
                className={`MuiButtonBase-root side-menu-list MuiListItem-root MuiListItem-gutters MuiListItem-button dashBoard ${
                  Object.keys(menuActiveState).length > 0 ? "" : "activeNav"
                }`}
              >
                <div className="side-menu-icon" onClick={toggleSideBar}>
                  <i className="fa fa-bars" />
                </div>
                <div className="MuiListItemText-root">
                  <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                    <Link
                      to="/dashboard"
                      onClick={handelClickDashboard}
                      className="MuiTypography-body1"
                    >
                      CMdS-Financial{" "}
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div>{handler(copyMenu, null)}</div>
          </div>
          <DrawerFooter drawerIsOpen={drawerIsOpen} />
        </Drawer>
        <MainFrame
          loginUserName={loginUserName}
          profileIconElement={profileIconElement}
          isNormalUser={isNormalUser}
          handleDrawerOpen={handleDrawerOpen}
          logOutFromPortal={logOutFromPortal}
          handleMyaccount={handleMyaccount}
          profileData={profileData}
          setLogOut={props.setLogOut}
          classes={classes}
        />
      </div>
    </>
  );
}

const getMainFrameSizing = (_ref) => {
  let mainContent = document.getElementById("mainContent");

  let header = document.getElementById("mainContentHeader"),
    footer = document.getElementById("mainContentFooter"),
    headerH = header
      ? header.getBoundingClientRect().bottom -
        header.getBoundingClientRect().top
      : 0,
    footerH = footer
      ? footer.getBoundingClientRect().bottom -
        footer.getBoundingClientRect().top
      : 0,
    mainContentBounds = mainContent
      ? mainContent.getBoundingClientRect()
      : null,
    mainContentH = mainContentBounds
      ? mainContentBounds.bottom - mainContentBounds.top
      : 0,
    _minHeight = mainContentH ? mainContentH - headerH - footerH : 0;

  let _hasScroll = mainContent
    ? mainContent.scrollHeight !== mainContent.clientHeight
    : false;

  return { _minHeight, _hasScroll };
};

const getMainScrollbarWidth = (_ref) => {
  let mainContent = document.getElementById("mainContent");

  mainContent.style.overflow = "scroll";
  let _marginRightValue = mainContent.offsetWidth - mainContent.clientWidth - 2;
  mainContent.style.overflow = "auto";

  return _marginRightValue;
};

class MainFrame extends React.Component {
  state = {
    scrollbar: false,
    marginRightValue: 0,
    minHeight: 0,
    mainFrameRef: React.createRef(),
  };
  timer = null;
  PrintElem = () => {
    window.print();
  };
  helpBtnHandler = () => {
    const helpPathUrl = getHelpUrl.pathname;
    const getHelpIdFrmJson = routeHelpTabConfig.filter((path) => {
      if (helpPathUrl.toLowerCase() == path.route.toLowerCase()) {
        return path;
      }
    });
    let getHelpId;
    if (
      getHelpIdFrmJson.length > 0 &&
      getHelpIdFrmJson[0].hasOwnProperty("helperId2")
    ) {
      if (localStorage.getItem("addOrEdit") == "Add") {
        getHelpId = getHelpIdFrmJson[0].helperId;
      } else {
        getHelpId = getHelpIdFrmJson[0].helperId2;
      }
    } else {
      getHelpId = getHelpIdFrmJson[0].helperId || getHelpIdFrmJson[1]?.helperId;
    }
    const formHelpUrl = `${HELP_DOCUMENT}${getHelpId}`;

    window.open(formHelpUrl, "_blank");
  };
  updateWithrateLimit = () => {
    if (this.timer) return;
    this.timer = setTimeout(() => {
      this.update();
      clearInterval(this.timer);
    }, 100);
  };

  update = () => {
    let { _minHeight, _hasScroll } = getMainFrameSizing();
    if (_hasScroll !== this.state.scrollbar) {
      this.setState({ scrollbar: _hasScroll });
    }
    if (_minHeight !== this.state.minHeight) {
      this.setState({ minHeight: _minHeight });
    }
  };
  observer;

  handleProfileCardElement = (props) => {
    const profilecardElement = (
      <ProfileCard
        type="profile_card"
        showLink={["MyAcc", "Home"]}
        userData={this.props.profileData}
        fromPortal={true}
        handleMyaccount={this.props.handleMyaccount}
        portalLogOut={true}
        logOutFromPortal={this.props.logOutFromPortal}
      />
    );
    return profilecardElement;
  };

  componentWillMount() {
    let temp1 = this.handleProfileCardElement();
  }
  componentDidMount() {
    this.setState({
      marginRightValue: getMainScrollbarWidth(this.state.mainFrameRef),
    });
    this.observer = new MutationObserver(this.update);
    this.observer.observe(this.state.mainFrameRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }
  componentDidUpdate() {
    this.updateWithrateLimit();
  }
  componentWillUnmount() {
    this.observer.disconnect();
  }
  render() {
    let {
        loginUserName = null,
        setLogOut,
        classes,
        uuid,
        profileData,
        isNormalUser,
        profileIcon,
      } = this.props,
      { scrollbar, marginRightValue, minHeight, mainFrameRef } = this.state;

    return (
      <div
        className="main-content has-scrollbar container-fluid"
        id="mainContent"
        style={{ marginRight: scrollbar ? 0 : marginRightValue }}
      >
        <div ref={mainFrameRef} style={{ minWidth: 500, height: "100%" }}>
          <div className="container-fluid content-wrapper">
            <div className="row" id="mainContentHeader">
              <div className="col-12">
                <nav
                  position="relative"
                  className="navbar bg-white rounded mb-2 flex-nowrap"
                >
                  <div className="top-logo-bar">
                    <a href="#">
                      <img
                        src={TopLogo}
                        alt={process.env.REACT_APP_PRODUCT_TITLE}
                        title={process.env.REACT_APP_PRODUCT_TITLE}
                      />
                    </a>
                    <span className="logo-text">
                      {process.env.REACT_APP_PRODUCT_TITLE}
                    </span>
                  </div>
                  <Dropdown className="user-action-btn absolute ml-auto nav-user-dropdown">
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      className={`float-right`}
                    >
                      {profileData?.email &&
                        this.props.profileIconElement(profileData)}
                    </Dropdown.Toggle>{" "}
                    <Dropdown.Menu>
                      {profileData?.email && this.handleProfileCardElement()}
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* Help and Print Implementation Configuration Starts */}
                  <div className="print-help-btn-wrapper">
                    <Button
                      title="Print"
                      variant="outlined"
                      color="primary"
                      className="btn btn-print-new"
                      onClick={() => this.PrintElem()}
                    />

                    <Button
                      title={AppConstants.HELP}
                      data-testid="help-id"
                      variant="outlined"
                      color="primary"
                      className="btn btn-help-new"
                      onClick={this.helpBtnHandler}
                    ></Button>
                  </div>
                  {/* Help and Print Implementation Configuration Ends */}
                </nav>
              </div>
            </div>
            <div
              className="row main-content-wrapper"
              style={{ minHeight: minHeight }}
            >
              <div className="col-12">
                <ErrorHandler element="MainFrame">
                  <div>
                    {this.state.timedOutMsg ? (
                      <div
                        className="alert alert-danger custom-alert"
                        role="alert"
                      >
                        <li>{this.state.timedOutMsg}</li>
                      </div>
                    ) : null}
                    <SessionExpire />
                  </div>
                  <Route>
                    <Routes uuid={uuid} />
                  </Route>
                </ErrorHandler>
              </div>
            </div>
          </div>
          <Footer main />
        </div>
      </div>
    );
  }
}

export default withRouter(App);
