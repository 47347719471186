// eslint-disable-next-line no-unused-vars
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import * as receiptReissueConstant from './ReceiptReissueConstant';
import { checkForValidDateString, getDateInMMDDYYYYFormat, validateDateMinimumValue } from '../../../SharedModules/DateUtilities/DateUtilities';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { TRANSACTION_NUMBER_FORMAT } from '../../../SharedModules/Errors/ErrorMsgConstant';

/* This function is used to validate check replacement field's values */
export const validateReplacement = (values, selectedCheckDate, fundRsnCode17) => {
  var checkDateErrorMsg = '';
  const errorMessagesArray = [];
  var showReasonCodeError; var showCheckDateError; var showCheckNumberError = false; var showCheckNumberRangeError = false; var showFridayCheck = false;
  var showReasonCodeErrorText; var showCheckDateErrorText; var showCheckNumberErrorText = ''; var showCheckNumberRangeErrorText = ''; var showFridayCheckText = '';
  const setErrors = { showReasonCodeError, showCheckDateError, showCheckNumberError, showCheckNumberRangeError, showFridayCheck};
  const setErrorText = { showReasonCodeErrorText, showCheckDateErrorText, showCheckNumberErrorText, showCheckNumberRangeErrorText, showFridayCheckText};

  if (values.originalCheckNumber && values.checkNumber && (values.originalCheckNumber === values.checkNumber)) {
    setErrors.showCheckNumberError = true;
    setErrorText.showCheckNumberErrorText = receiptReissueConstant.CHECK_NUMBERS_EQUAL_ERROR;
    errorMessagesArray.push(receiptReissueConstant.CHECK_NUMBERS_EQUAL_ERROR);
  }

  if ((!(values.finanReasonCode) || values.finanReasonCode === AppConstants.PLEASE_SELECT_ONE)) {
    setErrors.showReasonCodeError = true;
    setErrorText.showReasonCodeErrorText = receiptReissueConstant.FINANCIAL_REASON_CODE_REQUIRED;
    errorMessagesArray.push(receiptReissueConstant.FINANCIAL_REASON_CODE_REQUIRED);
  }
  if(values.atrNumber) {
    const regex = /[^A-Za-z0-9]/
     if (regex.test(values.atrNumber)){  
      setErrors.showATRError = true
      errorMessagesArray.push(process.env.REACT_APP_TRANSACTION_NUMBER_LABEL+' '+TRANSACTION_NUMBER_FORMAT);

     } 
  }
  const receiptManulCodeVal = fundRsnCode17 && values?.finanReasonCode  && fundRsnCode17.filter(x => values?.finanReasonCode === x).toString()
  if (values.finanReasonCode && values.finanReasonCode === receiptManulCodeVal && values.finanReasonCode !== AppConstants.PLEASE_SELECT_ONE) {
    if ((!values.checkNumber) || (!selectedCheckDate)) {
      errorMessagesArray.push(receiptReissueConstant.CHECK_NUMBER_AND_DATE_REQUIRED);
    }
    if (!values.checkNumber) {
      setErrors.showCheckNumberError = true;
      setErrorText.showCheckNumberErrorText = receiptReissueConstant.CHECK_NUMBER_REQUIRED;
    }else{
      if(values?.checkNumber){
        var regex = /^(9999999999[0-8])$/;
  
        if(!regex.test(values.checkNumber)){
          setErrors.showCheckNumberRangeError = true;
          setErrorText.showCheckNumberRangeErrorText = receiptReissueConstant.WARRANT_RANGE;
          errorMessagesArray.push(receiptReissueConstant.WARRANT_RANGE);
        }
        
        if(new Date(selectedCheckDate).getDay() === 5){
          setErrors.showFridayCheck = true;
          setErrorText.showFridayCheckText = receiptReissueConstant.FRIDAY_CHECK;
          errorMessagesArray.push(receiptReissueConstant.FRIDAY_CHECK);
          }
      }
    }

    if (!selectedCheckDate) {
      setErrors.showCheckDateError = true;
      setErrorText.showCheckDateErrorText = receiptReissueConstant.CHECK_DATE_REQUIRED;
      checkDateErrorMsg = receiptReissueConstant.CHECK_DATE_REQUIRED;
    }
  }

  let isCheckDateInValid = false;
  if (selectedCheckDate) {
    if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedCheckDate))) {
      isCheckDateInValid = validateDateMinimumValue(getDateInMMDDYYYYFormat(selectedCheckDate));
      if (isCheckDateInValid) {
        setErrors.showCheckDateError = true;
        setErrorText.showCheckDateErrorText = AppConstants.DATE_ERROR_1964;
        checkDateErrorMsg = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    } else {
      setErrors.showCheckDateError = true;
      setErrorText.showCheckDateErrorText = receiptReissueConstant.CHECK_DATE_INVALID;
      checkDateErrorMsg = receiptReissueConstant.CHECK_DATE_INVALID;
      errorMessagesArray.push(AppConstants.INCORRECT_FORMAT);
    }
  }


  const errors = {
    setErrors: setErrors,
    setErrorText: setErrorText,
    checkDateErrorMsg: checkDateErrorMsg,
    errorMessagesArray: errorMessagesArray
  };
  return errors;
};
