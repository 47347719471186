/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import * as Constants from './AdjustmentConstants';
import Common from './AdjustCommon';
import Originalrequest from './OriginalRequest';
import AdjustmentAction from './AdjustmentAction';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import { useDispatch, useSelector } from 'react-redux';

import { Prompt } from 'react-router-dom';
import numeral from 'numeral';
import {
  getDropdownDataAction,
  getAdjustmentCalculationAction,
  saveAdjustmentCalculationAction
} from '../../Store/Actions/FinancialReceipt/financialReceiptActions';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import dropdownCriteria from './FinancialReceiptAdjustment.json';
import moment from 'moment';
import * as APPCONSTANTS from '../../../../SharedModules/AppConstants';
import Notes from '../../../../SharedModules/Notes/Notes';
import { generateUUID, getUTCTimeStamp, getDateInMMDDYYYYFormatWithApendZero } from '../../../../SharedModules/DateUtilities/DateUtilities';
import { refreshAttachmentsAction } from '../../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../../SharedModules/Layout/footer';
import Attachments from '../../../../SharedModules/Attachments/Attachments';
import { TRANSACTION_NUMBER_FORMAT } from '../../../../SharedModules/Errors/ErrorMsgConstant';
import { checkingDecimal } from '../../../../SharedModules/CommonUtilities/commonUtilities';

export default function FinancialReceiptAdjustment (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const loginUserID = useSelector(state => state.sharedState.logInUserID);
  const userInquiry = !global.globalIsReadOnly();
  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const errorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [attchmentResponse,setAttachmentResponse] = React.useState([])

  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [payeeType, setPayeeType] = React.useState([]);
  const [payeeIDType, setPayeeIDType] = React.useState([]);
  const [adjustmentReasonCode, setAdjustmentReasonCode] = React.useState([]);
  const [originalReasonCodeLD, setOriginalReasonCodeLD] = React.useState('');
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  const [{ showNewOriginalAmountErrorText, showReasonCodeErrorText, showATRerror }, setShowErrorText] = React.useState('');
  // Attachments variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [showAdjustVerification, setShowAdjustVerification] = React.useState(false);
  const [linkEnable, setLinkEnable] = React.useState(false);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const onCalculate = (receipt) => dispatch(getAdjustmentCalculationAction(receipt)); ;
  const saveReceipt = (receipt, index) => dispatch(saveAdjustmentCalculationAction(receipt, attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id ? attachmentFile : null));
  const editData = props.location.state.adjustData;
  const atrNumber = props.location.state.atrNumber;
  const adjustData = useSelector(
    state => state.financialReceiptState.adjustmentData
  );
  const receiptVo = useSelector(
    state => state.financialReceiptState.calculatedData
  );
  const saveResult = useSelector(
    state => state.financialReceiptState.saveResult
  );
  const [saveFlag, setSaveFlag] = React.useState(false);
  const dropdown = useSelector(
    state => state.financialReceiptState.dropdownData
  );
  const [calculatedData, setCalculatedData] = React.useState();

  useEffect(() => {
    dispatch(getDropdownDataAction(dropdownCriteria));
    refreshAttachmentResult = null;
  }, []);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeType(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3007']) {
        if (values.reasonCode && values.reasonCode.indexOf('-') > 0) {
          dropdown.listObj['Financial#3007'].map((value) => {
            if (value.code === values.reasonCode.split('-')[0]) {
              setOriginalReasonCodeLD(value.longDescription);
            }
          });
        }
      }
      if (props.location.state && props.location.state.adjustData && props.location.state.adjustData.receiptVO) {
        // member
        if (props.location.state.adjustData.receiptVO.entityType &&
          (props.location.state.adjustData.receiptVO.entityType === 'M' ||
            props.location.state.adjustData.receiptVO.entityType === 'M-Member') &&
          dropdown.listObj['Financial#2']) {
          setPayeeIDType(dropdown.listObj['Financial#2']);
        }
        // provider
        if (props.location.state.adjustData.receiptVO.entityType &&
          (props.location.state.adjustData.receiptVO.entityType === 'P' ||
            props.location.state.adjustData.receiptVO.entityType === 'P-Provider') &&
          dropdown.listObj['Financial#3']) {
          setPayeeIDType(dropdown.listObj['Financial#3']);
        }
        // TPC carrier
        if (props.location.state.adjustData.receiptVO.entityType &&
          (props.location.state.adjustData.receiptVO.entityType === 'TC' ||
            props.location.state.adjustData.receiptVO.entityType === 'TC-TPLCarrier') &&
          dropdown.listObj['Financial#1023']) {
          setPayeeIDType(dropdown.listObj['Financial#1023']);
        }
        // others
        if (props.location.state.adjustData.receiptVO.entityType &&
          (props.location.state.adjustData.receiptVO.entityType === 'O' ||
            props.location.state.adjustData.receiptVO.entityType === 'O-Other') &&
          dropdown.listObj['Financial#1024']) {
          setPayeeIDType(dropdown.listObj['Financial#1024']);
        }
      }
      if (dropdown.listObj['Financial#3011']) {
        setAdjustmentReasonCode(dropdown.listObj['Financial#3011']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);
  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);

  const [values, setValues] = React.useState({
    fcn: '',
    adjustedFcn: '',
    adjustedLob: DEFAULT_DD_VALUE,
    establishedDate: null,
    adjustedEstablisedDate: null,
    reasonCode: DEFAULT_DD_VALUE,
    adjustmentReasonCode: DEFAULT_DD_VALUE,
    originalAmount: '',
    relatedfttcn: '',
    balanceamount: '',
    adjustedAmount: '',
    newOriginalAmount: '',
    payeeType: DEFAULT_DD_VALUE,
    newAtrNumber: '',
    payeeId: '',
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    payeeName: '',
    systemPayeeId: '',
    memberIdType: '',
    memberId: '',
    memberName: '',
    adjustmentDate: null,
    atrNumber: ''
  });

  const resonDropDown = props.location?.state?.dropdowns;
  const getShortDecsription = (code) => {
    const reasonVal =
    resonDropDown &&
    resonDropDown['F1#F_RSN_CD'] &&
    resonDropDown['F1#F_RSN_CD'].filter(
      value => value.code === code
    );
   const reasonCodedescription = reasonVal && reasonVal.length > 0
    ? reasonVal[0].description
    : '';
    return reasonCodedescription;
  };

  const setValueResults = values => {
    const res = values.receiptVO && values.receiptVO.entityType ? values.receiptVO.entityType : '';
    setValues({
      fcn: values.receiptVO.fcn,
      adjustedFcn: values.receiptVO.receiptAdjustmentVO.adjustedFcn,
      fundCode: values.receiptVO.fundCode ? values.receiptVO.fundCode : "",
      adjustedLob: values.receiptVO.receiptAdjustmentVO.adjustedLOB,
      establishedDate: moment(
        new Date(values.receiptVO.receiptAuditTimeStamp)
      ).format('L'),
      adjustedEstablisedDate:
        values.receiptVO.receiptAdjustmentVO.establishedDate,
      reasonCode: getShortDecsription(values.receiptVO.financialReason),
      adjustmentReasonCode: DEFAULT_DD_VALUE,
      originalAmount: values.receiptVO.originalAmount,
      relatedfttcn: values.receiptVO.relatedFTTCN,
      balanceamount: values.receiptVO.balanceAmount,
      adjustedAmount: '',
      newOriginalAmount: numeral(values.receiptVO.receiptAdjustmentVO.newOriginalAmount).format('0,0.00'),
      payeeType: res || DEFAULT_DD_VALUE,
      atrNumber: values.receiptVO.atrNumber,
      payeeId: values.receiptVO.entityID,
      payeeIdTypeCode: values.receiptVO.entityIDTypeCode,
      payeeName: values.receiptVO.entityName,
      systemPayeeId: values.receiptVO.payeePayerID,
      memberIdType: values.receiptVO.memberIDTypeValue,
      memberId: values.receiptVO.memberID,
      memberName: values.receiptVO.memberName,
      adjustmentDate: null,
      adjustedTcn: values.receiptVO.receiptAdjustmentVO.adjustedTcn
    });
  };

  useEffect(() => {
    if (props.location.state && props.location.state.adjustData) {
      setValueResults(props.location.state.adjustData);
      const payData = props.location.state.adjustData.receiptVO;
      if (payData.noteSetVO) {
        const noteSetVO = payData.noteSetVO;
        const notesTable = payData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: loginUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : loginUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });

        setNotesTableData(notesTable);
      }
      setAttachmentFile([]);
    }
  }, [props.location.state]);
  useEffect(() => {
    if (adjustData && receiptVo) {
      receiptVo.receiptVO = adjustData.receiptVO;
      setValueResults(adjustData);
    }
  }, [adjustData]);

  useEffect(() => {
    if (saveResult && saveResult.respcode && saveResult.respcode === '01') {
      setSystemSuccesMessages([saveResult.respdesc]);
      dispatch(refreshAttachmentsAction(values.adjustedFcn));
      setLinkEnable(true);
    } else if (saveResult && !saveResult.systemFailure && saveResult.respdesc) {
      seterrorMessages([saveResult.respdesc]);
      setSystemSuccesMessages([]);
    } else if (saveResult && saveResult.systemFailure) {
      seterrorMessages([APPCONSTANTS.ERR_PROCESSING_REQ]);
    }
  }, [saveResult]);

  useEffect(() => {
    if (receiptVo && !receiptVo.systemFailure) {
      setCalculatedData(receiptVo);
    } else if (receiptVo && receiptVo.systemFailure) {
      seterrorMessages([APPCONSTANTS.ERR_PROCESSING_REQ]);
    }
  }, [receiptVo]);
  // Field values setting
  const handleChange = name => event => {
    if (name === 'newOriginalAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setAllowNavigation(true);
      setValues({ ...values, [name]: event.target.value });
      }
    } else {
      setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
    }
  };

  // Show the adjusted data on calculation
  useEffect(() => {
    if (calculatedData) {
      setShowAdjustVerification(true);
    } else {
      setShowAdjustVerification(false);
    }
  }, [calculatedData]);

  // Validation of the fields
  const handleValidation = () => {
    // eslint-disable-next-line no-useless-escape
    var showNewOriginalAmountErrorText;
    var showReasonCodeErrorText;
  let _showATRerr;
    if (!values.newOriginalAmount) {
      showNewOriginalAmountErrorText = Constants.NEW_ORIGINAL_AMOUNT_REQUIRED;
      errorMessagesArray.push(Constants.NEW_ORIGINAL_AMOUNT_REQUIRED);
    } else if (parseFloat(values.newOriginalAmount.replace(/,/g, '')) <= 0) {
      showNewOriginalAmountErrorText = Constants.GREATER_THAN_ZERO;
      errorMessagesArray.push(Constants.GREATER_THAN_ZERO);
    } else if (values.newOriginalAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null) {
      showNewOriginalAmountErrorText = Constants.INVALID_FORMAT_ERROR;
      errorMessagesArray.push(Constants.INVALID_FORMAT_ERROR);
    } else {
      if (!(values.newOriginalAmount.toString().replace(/,/g, '').match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null)) {
        showNewOriginalAmountErrorText = Constants.INVALID_FORMAT_ERROR;
        errorMessagesArray.push(Constants.INVALID_FORMAT_ERROR);
      }
    }
    if (values.adjustmentReasonCode === DEFAULT_DD_VALUE) {
      showReasonCodeErrorText = Constants.REASON_CODE_REQUIRED;
      errorMessagesArray.push(Constants.REASON_CODE_REQUIRED);
    }
    if (values?.newAtrNumber) {
      const regex = /[^A-Za-z0-9]/;
       if (regex.test(values?.newAtrNumber)) {
        _showATRerr = TRANSACTION_NUMBER_FORMAT;
         errorMessagesArray.push(process.env.REACT_APP_TRANSACTION_NUMBER_LABEL + ' ' + TRANSACTION_NUMBER_FORMAT);
       }
    }

    setSystemSuccesMessages([]);
    setShowErrorText({
      showNewOriginalAmountErrorText: showNewOriginalAmountErrorText,
      showReasonCodeErrorText: showReasonCodeErrorText,
      showATRerror: _showATRerr
    });
    setAllowNavigation(false);
    if (errorMessagesArray.length <= 0) {
      editData.receiptVO.receiptAdjustmentVO.newOriginalAmount =
        values.newOriginalAmount.replace(/,/g, '');
      editData.receiptVO.receiptAdjustmentVO.adjusmentReasonCode =
        values.adjustmentReasonCode;
      editData.receiptVO.sysID = values.systemPayeeId;
      editData.receiptVO.entityType = values.payeeType;
      editData.receiptVO.noteSetVO = notesInput;
      editData.receiptVO.atrNumber = atrNumber;
      editData.receiptVO.receiptAdjustmentVO.recptAdjAtrNumber = values?.newAtrNumber;
      onCalculate(editData.receiptVO);
      seterrorMessages([]);
    } else {
      seterrorMessages(errorMessagesArray);
    }
  };
  React.useEffect(() => {
    if (saveFlag && receiptVo && receiptVo.receiptVO) {
      const res = editData.receiptVO && editData.receiptVO.entityType ? editData.receiptVO.entityType.split('-') : [];
      receiptVo.receiptVO.entityType = res && res[0] ? res[0] : null;
      receiptVo.receiptVO.atrNumber = values?.newAtrNumber?.toUpperCase();
      const index = attachmentsTableData.length - 1;

      const attachmentVOList = [];
      if (attachmentsTableData && attachmentsTableData.length > 0) {
        attachmentsTableData.map((value) => {
          if (value.id) {
            const attachmentVO = {
              auditUserID: loginUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: loginUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: true,
              sortColumn: null,
              attachmentSK: null,
              attachmentPageID: null,
              finalPath: null,
              detachInd: false,
              historyIndicator: false,
              cascadeKey: null,
              attachmentIndicator: null,
              dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
              fileUrl: null,
              showDeleteForSearched: false,
              rowIndex: 0,
              addedBy: loginUserID,
              description:
                value
                  ? value.description
                  : null,
              edmsStoredDate: null,
              anEDMSPageId: value? value.anEDMSPageId : null,
              fileName:
                attachmentsTableData && value
                  ? value.fileName
                  : null,
              file1: null,
              edmsWrkUnitLevel: null,
              edmsDocType: null
            };
            attachmentVOList.push(attachmentVO);
          }
        });
      }
      receiptVo.receiptVO.attachmentVO = null;
      receiptVo.receiptVO.attachmentsList = attachmentVOList;
receiptVo.receiptVO.atrNumber = atrNumber;
      saveReceipt(receiptVo.receiptVO, index);
      setSaveFlag(false);
    }
  }, [saveFlag, calculatedData]);
  // Adjust Save logic
  const onSave = () => {
    setSaveFlag(true);
    if (receiptVo) {
    } else {
      handleValidation();
    }
  };
  // Tab handeling code
  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  let notesDataArray = [];

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: loginUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: loginUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: loginUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: loginUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: loginUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd
  const clearSuccessErrorMsgs = () => {
    seterrorMessages([]);
    setShowErrorText('');
    setSystemSuccesMessages([]);
  };
  return (
    <div>
      <div className="tabs-container" ref={ toPrintRef }>
        <Prompt
          when={ allowNavigation }
          message={ location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

        <ErrorMessages errorMessages={ errorMessages } />
        { errorMessages.length === 0 ? <SuccessMessages successMessages={ systemSuccessMessages } /> : null }
        <div className="tab-header">
          <div className="tab-heading float-left">
            { Constants.FARINQUIRYSET_HEADER }
          </div>
          <div className="float-right mt-2">
            <Button className="btn btn-success" onClick={ () => onSave() }>
              <i className="fa fa-check" aria-hidden="true"></i> Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <Common
          values={ values }
          payeeType={ payeeType }
          payeeIDType={ payeeIDType }
        />
        <div className="tab-body">
          <div className="tab-bodypanel">
            <div className="tab-holder mb-3">
              <AppBar position="static">
                <Tabs
                  value={ value }
                  onChange={ handleChangeTabs }
                  aria-label="simple tabs example"
                >
                  <Tab label="Details" />
                  <Tab label="Notes" />
                  { <Tab label="Attachments" /> }
                </Tabs>
              </AppBar>
              <TabPanel value={ value } index={ 0 }>
                { value === 0 && (
                  <div className="tab-holder my-3 p-0">
                   <h4 className="hide-on-screen hide-on-print">
                      <span className="badge badge-primary">Details</span>
                    </h4>
                    <Originalrequest values={ values }
                      atrNumber={atrNumber}
                    originalReasonCodeLD={ originalReasonCodeLD } />
                   <AdjustmentAction
                      linkEnable={ linkEnable }
                      values={ values }
                      handleChange={ handleChange }
                      showAdjustVerification={showAdjustVerification}
                      errors={ [
                        showNewOriginalAmountErrorText,
                        showReasonCodeErrorText,
                        showATRerror
                      ] }
                      handleValidation={ handleValidation }
                      calculatedData={ calculatedData }
                      reasonCodeDropDown={ adjustmentReasonCode }
                      setValues={ setValues }
                    />
                  </div>
                ) }
              </TabPanel>
              <TabPanel value={ value } index={ 1 }>
                { value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <Notes
                      addNotes={ addNotes }
                      notesTableData={ notesTableData }
                      noteSetListInput={ noteSetListInput }
                      setNoteSetListInput={ setNoteSetListInput }
                      usageTypeCodeData={ usageTypeCodeData }
                      editNoteData={ editNoteData }
                      setEditNoteData={ setEditNoteData }
                      setSuccessMessages={ clearSuccessErrorMsgs }
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                ) }
              </TabPanel>
              { <TabPanel value={ value } index={ 2 }>
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={ userInquiry }
                    attachmentFile={ attachmentFile }
                    setAttachmentFile={ setAttachmentFile }
                    attachmentsTableData={ attachmentsTableData }
                    setAttachmentsTableData={ setAttachmentsTableData }
                    parentErrorMesage={ seterrorMessages }
                    setAllowNavigation={ setAllowNavigation }
                    setSystemSuccesMessages={ clearSuccessErrorMsgs }
                    setFocusCheck={setFocusCheck}
                    values={values}
                    setValues={setValues}
                    attchmentResponse={attchmentResponse}
                    setAttachmentResponse={setAttachmentResponse}
                  />
                </div>
              </TabPanel> }

            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
