/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogContent
} from '../../../../SharedModules/Dialog/DialogUtilities';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { Button } from 'react-bootstrap';
import Common from './FinancialEntityCommon';
import RecoupmentDetailTab from './RecoupmentDetailTab';
import BankingDetails from './BankingDetailsTab';
import MiscellaneousTab from './MiscellaneousTab';
import AddressDetailTab from './AddAddressTab';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import NoSaveMessage from '../../../../SharedModules/Errors/NoSaveMessage';
import Footer from '../../../../SharedModules/Layout/footer';

import numeral from 'numeral';
import { Prompt } from 'react-router-dom';
import { withRouter } from 'react-router';
import Notes from '../../../../SharedModules/Notes/Notes';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import {
  SearchPayeeIDNameAction,
  searchSingleRecordFinancialEntity,
  addFinancialEntity,
  updateFinancialEntity,
  getDropdownDataAction,
  getBankingDropdownTypeDataAction,
  ResetPayeeResponse,
  getPayeeIdType,
  ResetResponseData,
  ResetSearchResponseData,
  SearchPayeeAction,
  getAuditLogDetailsAction,
  getAuditLogMiscellaneousDetailsAction,
  getAuditLogBankingDetailsAction,
  setSearchPayeeDetails,
  getAuditLogAddressDetailsAction,
  resetFinancialEntitySearch
} from '../../Store/Actions/FinancialEntityActions';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import * as FinancialEntityConstants from '../FinancialEntityConstants';
import {
  validatePayeeDetails,
  validateRecoupmentDetails,
  validatePayeeStatus,
  validatePayeeStatusLength,
  validateAddressStatus
} from '../AddValidationUtility';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import dropDownCriteria from './FinancialEntityDropDownCriteria.json';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import bankingDropdownCriteria from './BankingDropdownCriteria.json';
import NavHOC from '../../../../SharedModules/Navigation/NavHOC';
import PayeeIdentifiers from './PayeeIdentifiers';
import {
  checkingDecimal,
  checkValueExists,
  removeLeadingTrailingSpaces
} from '../../../../SharedModules/CommonUtilities/commonUtilities';

import PayeeStatusTab from './PayeeStatusTab';
import {
  getSystemParameterSpecificValue,
  ResetAddressResponseData
} from "../../../../SharedModules/store/Actions/SharedAction";
import { DEFAULT_LOB_VALUE } from '../../../../SharedModules/AppConstants';
import { searchSystemParameterAction, searchSystemParameterAction9047 } from '../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import { setFocus } from '../../../ApplicationConfiguration/Store/Actions/AppConfigCommon/AppConfigActions';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import * as RouteUrls from "../../../../SharedModules/Navigation/RouteConstants";

function FinancialEntityAddUpdate (props) {
	console.log("props...............", props)
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const onReset = () => dispatch(resetFinancialEntitySearch());
  const [color, setColor] = React.useState({
    recoupmentTabClr: '',
    bankingTabCr: '',
    miscellaneousTabClr: '',
    addressTabClr: ''
  });

  // Add Get Api result
  const [onCreateEntityGetRecordResult, setOnCreateEntityGetRecordResult] =
    React.useState('');
  sessionStorage.setItem('tableRender', false);

  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const swalFireData = sessionStorage.getItem('isSwalFire');
  // For Selection Tab value
  const [value, setValue] = React.useState(0);
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [activeMiscellaneousData, setActiveMiscellaneousData] = React.useState(
    null
  );
  const [activeAddressData, setActiveAddressData] = React.useState(null);
  const [activeBankingData, setActiveBankingData] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initialFocusCheck, setInitialFocusCheck] = React.useState(false);

  // Payee payment status tab
const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
const [showPayeePaymentStatus, setShowPayeePaymentStatus] = React.useState('');

  // Common Component Business.
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [recoupmentErrorArray, setRecoupmentErrorArray] = React.useState([]);
  const [showEditPage, setShowEditPage] = React.useState(false);
  const [addToUpdate, setAddToUpdate] = React.useState(false);
  const [miscellaneousTableData, setMiscellaneousTableData] = React.useState(
    []
  );
  const [addressTableData, setAddressTableData] = React.useState([]);
  const [
    payeeIdentifierTableData,
    setPayeeIdentifierTableData
  ] = React.useState([]);
  const [
    payeeIdentifierDataForTable,
    setPayeeIdentifierDataForTable
  ] = React.useState([]);
  const [systemParamAddresscheck, setSystemParamAddresscheck] = React.useState(
    ''
  );
  // Dropdowns starts
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const getBankingDropdown = values =>
    dispatch(getBankingDropdownTypeDataAction(values));
  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  // Add, Update and Get Financial Entity API's
  const createFinancialEntity = AddJsonData =>
    dispatch(addFinancialEntity(AddJsonData));

  const userInquiryPrivileges = !global.globalIsReadOnly();

  let payeeSearchResult = useSelector(
    state => state.financialEntityState.searchPayeeDetails
  );
  const systemParameter = {
    description: null,
    descriptionStartsOrContains: null,
    functionalArea: 'F1',
    paramNumber: null,
    parameterNumber: '9012',
    parameterNumberStartsWith: false,
    withoutNotesDataFlag: false
  };
  const systemParametervalid = {
    paramNumber: 9012,
    functionalArea: "F1",
    lobCode: DEFAULT_LOB_VALUE,
    asOfDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("YYYY-DD-MM")
  };
  const systemParameter9047Payload = {
    description: null,
    descriptionStartsOrContains: null,
    functionalArea: "F1",
    paramNumber: null,
    parameterNumber: "9047",
    parameterNumberStartsWith: false,
    withoutNotesDataFlag: false
  };
  const getSystemParameter = systemParameter =>
    dispatch(searchSystemParameterAction(systemParameter));
  const getSystemparameterValid = systemParameter =>
    dispatch(getSystemParameterSpecificValue(systemParameter));
    const getSystemParameter9047 = systemParameter =>
    dispatch(searchSystemParameterAction9047(systemParameter));
  React.useEffect(() => {
if (props.history.location.pathname === RouteUrls.FINANCIAL_ENTITY_ADD) {
onReset();
}
    getSystemparameterValid(systemParametervalid);
    getDropdownData(dropDownCriteria);
    getBankingDropdown(bankingDropdownCriteria);
    setSystemSuccesMessages([]);
    seterrorMessages([]);

    dispatch(ResetSearchResponseData());
    dispatch(ResetPayeeResponse());
    dispatch(ResetAddressResponseData());
    validatePayeeResult = [];
    payeeSearchResult = [];
    getSystemParameter(systemParameter);
    getSystemParameter9047(systemParameter9047Payload);

    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_STATUS_TYPE,
      Dropdowns.BNKRPTCY_ID,
      Dropdowns.EFT_STAT_CODE,
      Dropdowns.BANK_ACCT_TYPE,
      Dropdowns.ADR_USG_STAT_CD,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.PAYEE_RESN_CD,
      Dropdowns.STATE_CODE
    ]);
  }, []);

  const activeValidAddress = useSelector(
    state => state.sharedState.SystemParameterData?.valueData
  );
  const systemParamaterData = useSelector(state => state.appConfigState?.systemParameterState?.data);
  React.useEffect(() => {
    if (systemParamaterData) {
      setShowPayeePaymentStatus(systemParamaterData[0]?.systemParameterDetail[0]?.valueData);
    }
  }, [systemParamaterData]);
  React.useEffect(() => {
      setSystemParamAddresscheck(
        activeValidAddress
      );
  }, [activeValidAddress]);

  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [updatedRecord, setUpdatedRecord] = React.useState(
    props.location.state
      ? props.location.state.searchSingleFinancialEntityRes
        ? props.location.state.searchSingleFinancialEntityRes
        : ''
      : ''
  );
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  // for G1 6003 system list
  const [payeeTypeDataFrmState6003, setPayeeTypeDataFrmState6003] = React.useState([]);
  // for G1 6002 system list
  const [payeeTypeDataFrmState6002, setPayeeTypeDataFrmState6002] = React.useState([]);
  const [payeeIDTypeCodeDropdown, setPayeeIDTypeCodeDropdown] = React.useState(
    []
  );
  const [frequencyDropdown, setFrequencyDropdown] = React.useState([]);
  const [EFTStatusDropdown, setEFTStatusDropdown] = React.useState([]);
  const [AccountTypeDropdown, setAccountTypeDropdown] = React.useState([]);
  const [BankruptcyTypeDropdown, setBankruptcyTypeDropdown] = React.useState(
    []
  );
  const [AddressTypeDropdown, setAddressTypeDropdown] = React.useState([]);
  const [
    SignificanceTypeDropdown,
    setSignificanceTypeDropdown
  ] = React.useState([]);
  const [ChangeReasonDropdown, setChangeReasonDropdown] = React.useState([]);
  const [GenderDropdown, setGenderDropdown] = React.useState([]);
  const [StatusCodeDropdown, setStatusCodeDropdown] = React.useState([]);
  const [CountyDropdown, setCountyDrpdown] = React.useState([]);
  const [CountryDropdown, setCountryDropdown] = React.useState([]);
  const [StateDropdown, setStateDropdown] = React.useState([]);
  const [
    descriptionDropdownValues,
    setDropdownDescriptionValues
  ] = React.useState([]);
  const [disableFields, setDisableFields] = React.useState(false);
  const [G16003, setG16003] = React.useState([]);
  const dropDownData = useSelector(
    state => state.financialEntityState.dropdownData
  );
  const payeeTypeIdDropDown = useSelector(
    state => state.financialEntityState.payeeIdTypeDropdown
  );
  const bankingDropdown = useSelector(
    state => state.financialEntityState.bankingDropdown
  );

  // ID type description
  const idTypeDescription = (_code, _idTypeArray) => {
    if (_idTypeArray && _idTypeArray.length > 0) {
      let _description = [];
      _description = _idTypeArray.filter(
        (value) => value.code === _code
      );
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };
  useEffect(() => {
    const tableData = [];
    if (
      payeeIdentifierTableData &&
      payeeIdentifierTableData.length > 0 &&
      descriptionDropdownValues &&
      descriptionDropdownValues.length > 0
    ) {
      payeeIdentifierTableData.map((row) => {
        const rowdata = row;
        tableData.push({
          ...rowdata,
          idTypeDesc: idTypeDescription(row.idType, descriptionDropdownValues)
        });
      });
    }
    setPayeeIdentifierDataForTable(tableData);
  }, [payeeIdentifierTableData, descriptionDropdownValues]);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (activeAddressData) {
        onClickAuditAddressLog(activeAddressData);
      }
      if (activeBankingData) {
        onClickAuditBankingLog(activeBankingData);
      }
      if (activeMiscellaneousData) {
        onClickAuditMiscellaneousLog(activeMiscellaneousData);
      }
    } else {
      setClose(false);
      setShowLogMiscellaneousTable(false);
      setShowLogBankingTable(false);
      setShowLogAddressTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (activeMiscellaneousData && auditButtonToggle) {
      setAuditMiscellaneousTableData([]);
      onClickAuditMiscellaneousLog(activeMiscellaneousData);
    } else {
      setAuditMiscellaneousTableData([]);
    }
  }, [activeMiscellaneousData]);

  useEffect(() => {
    if (activeBankingData && auditButtonToggle) {
      setAuditBankingTableData([]);
      onClickAuditBankingLog(activeBankingData);
    } else {
      setAuditBankingTableData([]);
    }
  }, [activeBankingData]);

  useEffect(() => {
    if (activeAddressData && auditButtonToggle) {
      setAuditAddressTableData([]);
      onClickAuditAddressLog(activeAddressData);
    } else {
      setAuditAddressTableData([]);
    }
  }, [activeAddressData]);
  // system payee ID Hyperlink functionality
  const [isHyperLinkClick, setHyperLinkClick] = useState(false);
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  React.useEffect(() => {
    // useEffect for the functionality after clicking on continue/ hyper link in pop up in system payee pop up
    // so it will fetch the data acording to selected system id in the pop up
    if (
      isHyperLinkClick && searchSingleFinancialEntityRes
    ) {
      setShowEditPage(true);
      setRedirect(0);
      setOnCreateEntityGetRecordResult('');
      dispatch(ResetResponseData());
      dispatch(ResetPayeeResponse());
      if (searchSingleFinancialEntityRes !== null) {
        const updateResult =
          searchSingleFinancialEntityRes;
        setUpdatedRecord(updateResult);
        onPayeeTypeIdDropDown(updateResult.entityType);
        setPayeeValues({
          payeePayerType:
            updateResult.entityType || DEFAULT_DD_VALUE,
          entityIDTypeCD: updateResult.entityTypeCode,
          entityID: updateResult.entityID || '',
          payeePayerName:
            updateResult.entityName &&
            !updateResult.entityName.startsWith('null')
              ? updateResult.entityName
              : '',
          voidInd: updateResult.voidInd || false,
          payeeGender:
            updateResult && updateResult.payeeGender
              ? updateResult.payeeGender
              : DEFAULT_DD_VALUE,
          sysPayeeId:
            updatedRecord !== '' && updatedRecord.sysPayeeId
              ? updatedRecord.sysPayeeId
              : null
        });
        setPayeeDob(updateResult.payeeDob);
        setRecoupmentTabValues({
          installmentvalue: updateResult.recoupInstallmentValue
            ? numeral(
              Number(
                updateResult.recoupInstallmentValue.replace(/,/g, '')
              ).toPrecision()
            ).format('0,0.00')
            : '',
          recoupmentLogDays: updateResult.recoupmentLagDays || '',
          percentagevalue: updateResult.recoupPercentageValue || '',
          frequency:
            updateResult.recoupFrequency || DEFAULT_DD_VALUE,
          maxWithholdAmount: updateResult.maxWithholdAmount
            ? numeral(
              Number(
                updateResult.maxWithholdAmount.replace(/,/g, '')
              ).toPrecision()
            ).format('0,0.00')
            : ''
        });
        setMiscellaneousTableData(updateResult.miscellaneousInfoVOList || []);
        setBankingTabTableData(updateResult.bankInfoVOList || []);
        setAddressTableData(updateResult.mailingAddressVOList || []);
        setPayeeIdentifierTableData(updateResult.payeeIdentifierVOsList || []);
        setPayeeStatusTable(updateResult.enrolmentStatusVOList || []);
        if (updateResult.voidedDate && updateResult.voidedDate !== null) {
          setDisableFields(true);
        }
        if (updateResult.noteSetVO) {
          const noteSetVO = updateResult.noteSetVO;
          const notesTable = updateResult.noteSetVO.notesList;
          setNotesInput({
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: noteSetVO.addedAuditUserID
              ? noteSetVO.addedAuditUserID
              : logInUserID,
            addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
              ? noteSetVO.addedAuditTimeStamp
              : getUTCTimeStamp(),
            versionNo: noteSetVO.versionNo,
            dbRecord: noteSetVO.dbRecord,
            sortColumn: noteSetVO.sortColumn,
            tableName: noteSetVO.tableName,
            noteSetSK: noteSetVO.noteSetSK,
            noteSourceName: noteSetVO.noteSourceName,
            notesList: notesTable,
            globalNotesList: [],
            checkAll: noteSetVO.checkAll,
            addNewLinkRender: noteSetVO.addNewLinkRender,
            filterLinkRender: noteSetVO.filterLinkRender,
            printLinkRender: noteSetVO.printLinkRender,
            completeNotesList: []
          });

          setNotesTableData(notesTable);
        }
      }
    }
  }, [props.location.state]);
  // Edit Page setting

  React.useEffect(() => {
    if (
      props.history.pathname !== '/FinancialEntityAdd' &&
      updatedRecord &&
      updatedRecord !== '' &&
      props.location.state &&
      props.location.state.searchSingleFinancialEntityRes &&
      props.history.pathname !== '/FinancialEntityAdd'
    ) {
      setShowEditPage(true);
      setRedirect(0);
      setOnCreateEntityGetRecordResult('');
      dispatch(ResetResponseData());
      dispatch(ResetPayeeResponse());
      if (props.location.state.searchSingleFinancialEntityRes !== null) {
        const updateResult =
          props.location.state.searchSingleFinancialEntityRes;
        setUpdatedRecord(updateResult);
        onPayeeTypeIdDropDown(updateResult.entityType);
        setPayeeValues({
          payeePayerType:
            updateResult.entityType || DEFAULT_DD_VALUE,
          entityIDTypeCD: updateResult.entityTypeCode,
          entityID: updateResult.entityID || '',
          payeePayerName:
            updateResult.entityName &&
            !updateResult.entityName.startsWith('null')
              ? updateResult.entityName
              : '',
          voidInd: updateResult.voidInd || false,
          payeeGender:
            updateResult && updateResult.payeeGender
              ? updateResult.payeeGender
              : DEFAULT_DD_VALUE,
          sysPayeeId:
            updatedRecord !== '' && updatedRecord.sysPayeeId
              ? updatedRecord.sysPayeeId
              : null
        });
        setPayeeDob(updateResult.payeeDob);
        setRecoupmentTabValues({
          installmentvalue: updateResult.recoupInstallmentValue
            ? numeral(
              Number(
                updateResult.recoupInstallmentValue.replace(/,/g, '')
              ).toPrecision()
            ).format('0,0.00')
            : '',
          recoupmentLogDays: updateResult.recoupmentLagDays || '',
          percentagevalue: updateResult.recoupPercentageValue || '',
          frequency:
            updateResult.recoupFrequency || DEFAULT_DD_VALUE,
          maxWithholdAmount: updateResult.maxWithholdAmount
            ? numeral(
              Number(
                updateResult.maxWithholdAmount.replace(/,/g, '')
              ).toPrecision()
            ).format('0,0.00')
            : ''
        });
        setMiscellaneousTableData(updateResult.miscellaneousInfoVOList || []);
        setBankingTabTableData(updateResult.bankInfoVOList || []);
        setAddressTableData(updateResult.mailingAddressVOList || []);
        setPayeeIdentifierTableData(updateResult.payeeIdentifierVOsList || []);
        setPayeeStatusTable(updateResult.enrolmentStatusVOList || []);
        if (updateResult.voidedDate && updateResult.voidedDate !== null) {
          setDisableFields(true);
        }
        if (updateResult.noteSetVO) {
          const noteSetVO = updateResult.noteSetVO;
          const notesTable = updateResult.noteSetVO.notesList;
          setNotesInput({
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: noteSetVO.addedAuditUserID
              ? noteSetVO.addedAuditUserID
              : logInUserID,
            addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
              ? noteSetVO.addedAuditTimeStamp
              : getUTCTimeStamp(),
            versionNo: noteSetVO.versionNo,
            dbRecord: noteSetVO.dbRecord,
            sortColumn: noteSetVO.sortColumn,
            tableName: noteSetVO.tableName,
            noteSetSK: noteSetVO.noteSetSK,
            noteSourceName: noteSetVO.noteSourceName,
            notesList: notesTable,
            globalNotesList: [],
            checkAll: noteSetVO.checkAll,
            addNewLinkRender: noteSetVO.addNewLinkRender,
            filterLinkRender: noteSetVO.filterLinkRender,
            printLinkRender: noteSetVO.printLinkRender,
            completeNotesList: []
          });

          setNotesTableData(notesTable);
        }
      }
    }
  }, [props.location.state]);
  // Payee type code dropdown initialization
  React.useEffect(() => {
    if (payeeTypeIdDropDown) {
      if (payeeTypeIdDropDown.payeeIdTypeCode) {
        setPayeeIDTypeCodeDropdown(payeeTypeIdDropDown.payeeIdTypeCode);
      }
    }
  }, [payeeTypeIdDropDown]);

  // Dropdown initialization
  const [providerDropdown, setProviderDropdown] = React.useState([]);
  const [otherDropdown, setOtherDropdown] = React.useState([]);
  const [tclDropdown, setTCLDropdown] = React.useState([]);
  const [memberDropdown, setMemberDropdown] = React.useState([]);
  const [addressTypeNonOther, setAddressTypeNonOther] = React.useState([]);
  const [addressTypeOther, setAddressTypeOther] = React.useState([]);
  const [statusDropDown, setStatusDropDown] = useState([]);
  const [statusReasonCodeDropDown, setStatusReasonCodeDropDown] = useState([]);
  React.useEffect(() => {
    if (
      bankingDropdown &&
      bankingDropdown.listObj &&
      bankingDropdown.listObj['Financial#F_BANK_ACCT_TY_CD']
    ) {
      const accountTypeList = JSON.parse(
        JSON.stringify(
          bankingDropdown.listObj['Financial#F_BANK_ACCT_TY_CD'].sort((a, b) =>
            a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
          )
        )
      );
      setAccountTypeDropdown(accountTypeList);
    }
    if (
      bankingDropdown &&
      bankingDropdown.listObj &&
      bankingDropdown.listObj['General#G_ADR_USG_TY_CD']
    ) {
      const addressTypeList = JSON.parse(
        JSON.stringify(
          bankingDropdown.listObj['General#G_ADR_USG_TY_CD'].sort((a, b) =>
            a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
          )
        )
      );
      setAddressTypeNonOther(addressTypeList);
      setAddressTypeDropdown(addressTypeList);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#6018']
    ) {
      setAddressTypeOther(dropDownData.listObj['General#6018']);
      const financialEntityResp = props.location.state?.searchSingleFinancialEntityRes;
      if (financialEntityResp && financialEntityResp?.entityType) {
        if (financialEntityResp?.entityType === AppConstants.ENTITY_TYPE_O || financialEntityResp?.entityType === AppConstants.ENTITY_TYPE_OTHER) {
        setAddressTypeDropdown(dropDownData.listObj['General#6018']);
        }
      }
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1012']
    ) {
      setUsageTypeCodeData(dropDownData.listObj['General#1012']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#1008']
    ) {
      setEFTStatusDropdown(dropDownData.listObj['Financial#1008']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1038']
    ) {
      setBankruptcyTypeDropdown(dropDownData.listObj['General#1038']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1000']
    ) {
      setStatusCodeDropdown(dropDownData.listObj['General#1000']);
    }
    if (
      showEditPage &&
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#6002']
    ) {
      setPayeeTypeDataFrmState6002(dropDownData.listObj['General#6002']);
    } else {
      if (
        dropDownData &&
        dropDownData.listObj &&
        dropDownData.listObj['General#6002']
      ) {
        setPayeeTypeDataFrmState6003(dropDownData.listObj['General#6003']);
      }
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#6003']
    ) {
      setG16003(dropDownData.listObj['General#6003']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1020']
    ) {
      setChangeReasonDropdown(dropDownData.listObj['General#1020']);
    }
    // Gender Dropdown
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1010']
    ) {
      setGenderDropdown(dropDownData.listObj['General#1010']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1041']
    ) {
      setSignificanceTypeDropdown(dropDownData.listObj['General#1041']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1019']
    ) {
      setStateDropdown(dropDownData.listObj['General#1019']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1007']
    ) {
      setCountyDrpdown(dropDownData.listObj['General#1007']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['General#1006']
    ) {
      const countryList = JSON.parse(
        JSON.stringify(
          dropDownData.listObj['General#1006'].sort((a, b) =>
            a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
          )
        )
      );
      setCountryDropdown(countryList);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#1009']
    ) {
      setFrequencyDropdown(dropDownData.listObj['Financial#1009']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#1023']
    ) {
      setTCLDropdown(dropDownData.listObj['Financial#1023']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#1024']
    ) {
      setOtherDropdown(dropDownData.listObj['Financial#1024']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#2']
    ) {
      setMemberDropdown(dropDownData.listObj['Financial#2']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#3']
    ) {
      setProviderDropdown(dropDownData.listObj['Financial#3']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Provider#1018']
    ) {
      setStatusDropDown(dropDownData.listObj['Provider#1018']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Provider#1066']
    ) {
      setStatusReasonCodeDropDown(dropDownData.listObj['Provider#1066']);
    }
    if (
      dropDownData &&
      dropDownData.listObj &&
      dropDownData.listObj['Financial#3'] &&
      dropDownData.listObj['Financial#2'] &&
      dropDownData.listObj['Financial#1023'] &&
      dropDownData.listObj['Financial#1024']
    ) {
      const mergedropdowns = [];
      dropDownData.listObj['Financial#3'].map((row) => {
        mergedropdowns.push(row);
      });
      dropDownData.listObj['Financial#2'].map((row) => {
        mergedropdowns.push(row);
      });
      dropDownData.listObj['Financial#1023'].map((row) => {
        mergedropdowns.push(row);
      });
      dropDownData.listObj['Financial#1024'].map((row) => {
        mergedropdowns.push(row);
      });
      if (mergedropdowns && mergedropdowns.length > 0) {
        setDropdownDescriptionValues(mergedropdowns);
      }
    }
    if (dropDownData && dropDownData.listObj["Financial#6023"]) {
      setPayeePaymentStatusFrmState(dropDownData.listObj["Financial#6023"]);
    }
  }, [dropDownData, bankingDropdown]);
  // Dropdown Ends ---->
  const [payeePaymentStatusValue, setPayeePaymentStatusValue] = React.useState(updatedRecord?.payeePaymentStatus || DEFAULT_DD_VALUE);
  const [payeeValues, setPayeeValues] = React.useState({
    payeePayerType:
      updatedRecord !== '' ? updatedRecord.entityType : DEFAULT_DD_VALUE,
    entityIDTypeCD:
      updatedRecord !== '' ? updatedRecord.entityTypeCode : DEFAULT_DD_VALUE,
    entityID: updatedRecord !== '' ? updatedRecord.entityID : '',
    payeePayerName:
      updatedRecord !== '' &&
      updatedRecord.entityName &&
      !updatedRecord.entityName.startsWith('null')
        ? updatedRecord.entityName
        : '',
    voidInd: updatedRecord !== '' ? updatedRecord.voidInd : false,
    payeeGender:
      updatedRecord !== '' ? updatedRecord.payeeGender : DEFAULT_DD_VALUE,
    sysPayeeId:
      updatedRecord !== '' && updatedRecord.sysPayeeId
        ? updatedRecord.sysPayeeId
        : null
  });
  const [payeeDob, setPayeeDob] = useState(null);
  const handleDob = dob => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitialFocusCheck(true);
    setPayeeDob(dob);
  };
  // Check if value exist in G1-6003
  const [IsG16003, setIsG16003] = useState(false);
  useEffect(() => {
    if (G16003 && payeeValues.payeePayerType !== DEFAULT_DD_VALUE) {
      const IsG16003 = checkValueExists(G16003, payeeValues.payeePayerType);
      setIsG16003(IsG16003);
    }
  }, [
    G16003,
    payeeValues
  ]);
  // Payee error messages and corresponding flags
  const [showPayeeError, setShowPayeeError] = React.useState({
    showPTypeErr: false,
    showPIDTypeErr: false,
    showPIDErr: false,
    showPayeeNameErr: false,
    showDOBErr: false,
    showAtleastSingleRecord: false
  });
  const [payeeErrorMsg, setPayeeErrMsg] = React.useState({
    pTypeErrMsg: '',
    pIDTypeErrMsg: '',
    pIDErrMsg: '',
    showPayeeNameErrMsg: '',
    showDOBErrMsg: '',
    showAtleastSingleRecordErrMsg: ''
  });
  // Recoupment Tab Values And Handle Change
  const [RecoupmentTabValues, setRecoupmentTabValues] = React.useState({
    installmentvalue:
      updatedRecord !== '' ? updatedRecord.recoupInstallmentValue : '',
    recoupmentLogDays:
      updatedRecord !== '' ? updatedRecord.recoupmentLagDays : '',
    percentagevalue:
      updatedRecord !== '' ? updatedRecord.recoupPercentageValue : '',
    frequency:
      updatedRecord !== ''
        ? updatedRecord.recoupFrequency
        : DEFAULT_DD_VALUE,
    maxWithholdAmount:
      updatedRecord !== '' && updatedRecord.maxWithholdAmount ? numeral(
        Number(
          updatedRecord.maxWithholdAmount.replace(/,/g, '')
        ).toPrecision()
      ).format('0,0.00') : ''
  });
  const [showRecoupmentError, setShowRecoupmentError] = React.useState({
    showInstallmentErr: false,
    showPecentValueErr: false,
    showFrequencyErr: false,
    showMaxWithholdAmtErr: false
  });
  const [recoupmentErrMsg, setRecoupmentErrMsg] = React.useState({
    installmentErrMsg: '',
    percentValueErrMsg: '',
    frequencyErrMsg: '',
    showMaxWithholdAmtErrMsg: ''
  });

  const [
    RecoupmentTabFrequencyDD,
    setrecoupmentTabFrequencyDD
  ] = React.useState([]);
  // Banking Tab Variables
  const [bankingTabTableData, setBankingTabTableData] = React.useState([]);
  const [bankingTabEFTStatusDD, setBankingTabEFTStatusDD] = React.useState([
    { code: 'Active', description: 'Active' }
  ]);
  const [bankingTabAccTypeDD, setBankingTabAccTypeDD] = React.useState([
    { code: 2, description: 'test2' }
  ]);
  const recoupmentTabHandleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === "installmentvalue") {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setRecoupmentTabValues({
            ...RecoupmentTabValues,
            [name]: event.target.value
          });
        }
      } else {
          setRecoupmentTabValues({
            ...RecoupmentTabValues,
            [name]: event.target.value
          });
      }
    } else {
    setRecoupmentTabValues({
      ...RecoupmentTabValues,
      [name]: event.target.value
    });
  }
  };
  const handlePayeeFormChanges = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitialFocusCheck(true);
    const value = event.target.value;
    setPayeeValues({ ...payeeValues, [name]: value });
    if (name === 'payeePayerType') {
      if (value === 'O' || value === 'O-Other') {
        setAddressTypeDropdown(addressTypeOther);
      } else {
        setAddressTypeDropdown(addressTypeNonOther);
      }
    }
  };
  React.useEffect(() => {}, [payeeValues.voidInd]);
  // Get Reference Data Call
  const payeeDetailsFrmState = useSelector(
    state => state.financialEntityState.payeeDetails
  );
  const payeeTypeDetailsFrmState = useSelector(
    state => state.financialEntityState.payeeTypeDetails
  );
  // Read Validate Payee Result from REDUX State
  let validatePayeeResult = useSelector(
    state => state.financialEntityState.payeeDetailsName
  );
  // Search record on row click or single record Financial Entity
  const searchSingleFinancialEntityApi = searchFinancialEntityReq =>
    dispatch(searchSingleRecordFinancialEntity(searchFinancialEntityReq));
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  // Add new Financial Entity

  const addFinancialEntityResponse = useSelector(
    state => state.financialEntityState.addResponseData
  );
  // Update Financial Entity
  const updateFinancialEntityApi = updateFinancialEntityReq =>
    dispatch(updateFinancialEntity(updateFinancialEntityReq));

  const updateFinancialEntityResponse = useSelector(
    state => state.financialEntityState.updateResponseData
  );

  const callValidatePayeeDetails = (validatePayeeOnSave = false) => {
    // payeeTab, setShowPayeeError, setPayeeErrMsg, seterrorMessages
    return validatePayeeDetails(
      payeeValues,
      payeeDob,
      payeeStatusTable,
      setShowPayeeError,
      setPayeeErrMsg,
      seterrorMessages,
      validatePayeeOnSave
    );
  };

  const callVlaidatePayeeStatusLength = () => {
    return validatePayeeStatusLength(
      payeeStatusTable,
      addressTableData,
      systemParamAddresscheck,
      errorMessages,
      seterrorMessages
    );
  };

  const callValidateAddress = () => {
    return validateAddressStatus(
      addressTableData,
      systemParamAddresscheck,
      errorMessages,
      seterrorMessages
    );
  };

  // Merge Payee Error array and Recoupment to single array.
  React.useEffect(() => {
    if (recoupmentErrorArray && recoupmentErrorArray.length > 0) {
      const arr3 = errorMessages.concat(recoupmentErrorArray);
      var errMsg = arr3.filter((item, pos) => arr3.indexOf(item) === pos);
      seterrorMessages(errMsg);
    }
  }, [recoupmentErrorArray]);
  const [redirect, setRedirect] = React.useState(0);
  // Redirect to Update screen
  useEffect(() => {
    if (redirect === 1) {
      if (
        onCreateEntityGetRecordResult &&
        onCreateEntityGetRecordResult !== '' &&
        props.history.pathname !== '/FinancialEntityUpdate'
      ) {
        setAddToUpdate(true);
        const isFormAdd = sessionStorage.setItem('isFormAdd', true);
        const searchSingleFinancialEntityRes = onCreateEntityGetRecordResult;
        props.history.push({
          pathname: '/FinancialEntityUpdate',

          state: { searchSingleFinancialEntityRes, ValuesFromFinEntity: ValuesFromFinEntity, isFormAdd }
        });
      }
    }
  }, [onCreateEntityGetRecordResult]);

  useEffect(() => {
    if (allowNavigation) {
      setSystemSuccesMessages([]);
    }
  }, [allowNavigation]);
  useEffect(() => {
    if (RecoupmentTabValues) {
      setRecoupmentTabValues(removeLeadingTrailingSpaces(RecoupmentTabValues));
    }
  }, [RecoupmentTabValues]);
  // Validate Payee on Save
  const [validatePayeeOnSave, setValidatePayee] = useState(false);
  // Validate payee from database
  const validatePayee = onSave => {
    seterrorMessages([]);
    setSystemSuccesMessages([]);
    setPayeeIdentifierTableData([]);
    const validatePayeeObj = {
      entityType: payeeValues.payeePayerType,
      entityTypeCode: payeeValues.entityIDTypeCD,
      entityID: payeeValues.entityID,
      mailingAddressVO: null
    };
    if (callValidatePayeeDetails()) {
      validatePayeeAPICall(removeLeadingTrailingSpaces(validatePayeeObj));
      if (onSave) {
        setValidatePayee(true);
      } else {
        setValidatePayee(false);
      }
    }
  };
  // Validate Payee Action Call
  const validatePayeeAPICall = validatePayeeobj => {
    dispatch(SearchPayeeIDNameAction(validatePayeeobj));
  };
  // validate Recoupment Tab
  const callValidateRecoupmentDetails = () => {
    return validateRecoupmentDetails(
      RecoupmentTabValues,
      setShowRecoupmentError,
      setRecoupmentErrMsg,
      setRecoupmentErrorArray
    );
  };
  // validate Banking Tab
  // validate Miscellaneous Tab
  // validate Address Tab
  // TabPanle Change Event
  const handleChangeTabs = (event, newValue) => {
    seterrorMessages([]);
    if (value === 0) {
      setSystemSuccesMessages([]);
      if (callValidateRecoupmentDetails()) {
        setColor({ ...color, recoupmentTabClr: 'green' });
      } else {
        setColor({ ...color, recoupmentTabClr: 'red' });
      }
    }
    setValue(newValue);
  };

  // Load Payee Details
  useEffect(() => {
    const tempErrorArray = [];
    if (payeeDetailsFrmState && payeeDetailsFrmState.systemFailue) {
      tempErrorArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempErrorArray);
    }
    if (
      payeeDetailsFrmState &&
      payeeDetailsFrmState.payeeType &&
      payeeDetailsFrmState.payeeType.length > 0
    ) {
      setPayeeTypeDataFrmState6002(payeeDetailsFrmState.payeeType);
    }
  }, [payeeDetailsFrmState]);
  // Call getPayeeTypeDetailsAPI based on seleced payeeType
  useEffect(() => {
    if (payeeValues.payeePayerType !== DEFAULT_DD_VALUE) {
      setPayeeIDTypeCodeDropdown([]);
      if (!showEditPage && !props.location.state) {
        setPayeeValues({ ...payeeValues, entityIDTypeCD: DEFAULT_DD_VALUE });
      }
      switch (payeeValues.payeePayerType) {
        case 'M':
          if (memberDropdown) {
            setPayeeIDTypeCodeDropdown(memberDropdown);
          }
          break;
        case 'O':
          if (otherDropdown) {
            setPayeeIDTypeCodeDropdown(otherDropdown);
          }
          break;
        case 'P':
          if (providerDropdown) {
            setPayeeIDTypeCodeDropdown(providerDropdown);
          }
          break;
        case 'TC':
          if (tclDropdown) {
            setPayeeIDTypeCodeDropdown(tclDropdown);
          }
          break;
        default:
          setPayeeIDTypeCodeDropdown([]);
      }
    }
    if (payeeValues.payeePayerType === DEFAULT_DD_VALUE && !showEditPage) {
      setPayeeIDTypeCodeDropdown([]);
      setPayeeValues({ ...payeeValues, entityIDTypeCD: DEFAULT_DD_VALUE });
    }
  }, [payeeValues.payeePayerType]);
  // Load Payee Type From selectd payee
  useEffect(() => {
    const tempErrorArray = [];
    if (payeeTypeDetailsFrmState && payeeTypeDetailsFrmState.systemFailure) {
      tempErrorArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempErrorArray);
    }
    if (
      payeeTypeDetailsFrmState &&
      payeeTypeDetailsFrmState.payeeIdTypeCode &&
      payeeTypeDetailsFrmState.payeeIdTypeCode.length > 0
    ) {
      setPayeeIDTypeCodeDropdown(payeeTypeDetailsFrmState.payeeIdTypeCode);
    }
  }, [payeeTypeDetailsFrmState]);
  // Setting Validate Payee Result useEffect
  const [payeeRedirectFlag, setPayeeFlag] = React.useState(false);
  const [payeeErrorArray, setPayeeErrorArray] = React.useState([]);

  // Search/Edit Redirect on Payee Validation
  const getPayeeSearchAPI = searchObj => dispatch(SearchPayeeAction(searchObj));
  const [redierctToEdit, setRedirectForPayee] = React.useState(true);
  useEffect(() => {
    if (payeeSearchResult && payeeSearchResult.systemFailue) {

    }
    if (
      payeeSearchResult &&
      !payeeSearchResult.systemFailue &&
      payeeSearchResult.length === 0
    ) {

    }
    if (
      payeeSearchResult &&
      !payeeSearchResult.systemFailue &&
      payeeSearchResult.length === 1
    ) {
      setAllowNavigation(false);
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes: payeeSearchResult[0], ValuesFromFinEntity: ValuesFromFinEntity }
      });

      setRedirectForPayee(true);
    }
    if (
      payeeSearchResult &&
      !payeeSearchResult.systemFailue &&
      payeeSearchResult.length > 1
    ) {
      setAllowNavigation(false);
      props.history.push({
        pathname: '/FinancialEntitySearch',
		tableRender: true,
        state: { payeeSearchResult, ValuesFromFinEntity: ValuesFromFinEntity }
      });

      setRedirectForPayee(false);
    }
  }, [payeeSearchResult]);

  useEffect(() => {
    if (validatePayeeResult != null) {
      const errorMessagesArray = [];
      if (validatePayeeResult && validatePayeeResult.systemFailure) {
        errorMessagesArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
        seterrorMessages(errorMessagesArray);
        setPayeeErrorArray(['Please validate the Payee.']);
      }
      if (
        validatePayeeResult &&
      !validatePayeeResult.systemFailure &&
      validatePayeeResult.enterpriseBaseVO &&
      validatePayeeResult.respcode === '01'
      ) {
        if (
          validatePayeeResult.errorMessages &&
        validatePayeeResult.errorMessages.length > 0
        ) {
          seterrorMessages(validatePayeeResult.errorMessages);
          setPayeeErrorArray(validatePayeeResult.errorMessages);
        }
        if (
          validatePayeeResult &&
        validatePayeeResult.enterpriseBaseVO &&
        validatePayeeResult.enterpriseBaseVO.payeeIdentifierVOsList
        ) {
        } else if (props && !props.state && !showEditPage) {
        }
        if (
          validatePayeeResult.enterpriseBaseVO &&
        validatePayeeResult.enterpriseBaseVO.commonEntitySK &&
        validatePayeeResult.enterpriseBaseVO.commonEntitySK !== null
        ) {
          FinancialEntityAddObj.commonEntitySK =
          validatePayeeResult.enterpriseBaseVO.commonEntitySK;
        }
        if (!showEditPage) {
          const identifierTable = [];
          const PayeeIdentifiers = {
            mainRecord: true,
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            idNumber: payeeValues.entityID,
            idType: payeeValues.entityIDTypeCD,
            beginDate: getDateInMMDDYYYYFormat(new Date()),
            altIdSk: null,
            endDate: getDateInMMDDYYYYFormat(
              new Date('9999-12-31T13:00:00.000+0000')
            )
          };
          identifierTable.push(PayeeIdentifiers);
          if (identifierTable && identifierTable.length <= 1) {
            setPayeeIdentifierTableData(identifierTable);
          }
        }
        setPayeeFlag(false);
        setPayeeErrorArray([]);
      } else if (
        validatePayeeResult &&
      !validatePayeeResult.systemFailure &&
      validatePayeeResult.respcode &&
      validatePayeeResult.respcode !== '01'
      ) {
        errorMessagesArray.push(validatePayeeResult.errorMessages);

        // Redirect if Payee already Exist
        let PayeeRedirect = false;
        validatePayeeResult.errorMessages.map(errMsg => {
          if (errMsg === 'The Payee already exists.') {
            PayeeRedirect = true;
          }
        });
        if (PayeeRedirect) {
          const loginUserDetails = JSON.parse(
            localStorage.getItem('loginState')
          );
          const tempSearchObj = {
            commonEntityTypeCode: payeeValues.payeePayerType,
            entityIDTypeCode: payeeValues.entityIDTypeCD,
            entityID: payeeValues.entityID,
            payeeNameStartsWithOrContains: '0',
            payeeName: payeeValues.payeePayerName,
            userId: loginUserDetails.logInUser,
            userLastName: loginUserDetails.loginUserName
          };
          if (payeeValues.payeePayerType === DEFAULT_DD_VALUE) {
            tempSearchObj.commonEntityTypeCode = null;
          }
          if (payeeValues.entityIDTypeCD === DEFAULT_DD_VALUE) {
            tempSearchObj.entityIDTypeCode = null;
          }
          if (payeeValues.entityID === '') {
            tempSearchObj.entityID = null;
          }
          if (payeeValues.payeePayerName === '') {
            tempSearchObj.payeeName = null;
          }
          setPayeeIdentifierTableData([]);
          openSwalDialog(tempSearchObj);
          setPayeeFlag(true);
        } else {
          setPayeeFlag(false);
        }
        if (
          validatePayeeResult.errorMessages &&
        validatePayeeResult.errorMessages.length > 0
        ) {
          if (!PayeeRedirect) {
            seterrorMessages(validatePayeeResult.errorMessages);
          }
          setPayeeErrorArray(validatePayeeResult.errorMessages);
        } else {
          setPayeeErrorArray([]);
        }
      }
      if (validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
      validatePayeeResult.enterpriseBaseVO.mailingAddressVOList) {
        setAddressTableData(validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
          validatePayeeResult.enterpriseBaseVO.mailingAddressVOList
          ? validatePayeeResult.enterpriseBaseVO.mailingAddressVOList
          : []);
      }
      if (validatePayeeResult && validatePayeeResult.enterpriseBaseVO) {
        const tableData = [];
        if (validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
            validatePayeeResult.enterpriseBaseVO.payeeIdentifierVOsList && validatePayeeResult.enterpriseBaseVO.payeeIdentifierVOsList.length > 0) {
          validatePayeeResult.enterpriseBaseVO.payeeIdentifierVOsList.map((row) => {
            const rowdata = row;
            tableData.push({
              ...rowdata,
              id: generateUUID(),
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp()
            });
          });
        } else {
          const PayeeIdentifiers = {
            mainRecord: true,
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            idNumber: payeeValues.entityID,
            idType: payeeValues.entityIDTypeCD,
            beginDate: getDateInMMDDYYYYFormat(new Date()),
            altIdSk: null,
            endDate: getDateInMMDDYYYYFormat(
              new Date('9999-12-31T13:00:00.000+0000')
            )
          };
          tableData.push(PayeeIdentifiers);
        }
        setPayeeIdentifierTableData(tableData || []);
        setPayeeDob(validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
      validatePayeeResult.enterpriseBaseVO.payeeDob);

        setPayeeValues({
          ...payeeValues,
          sysPayeeId:
      validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
      validatePayeeResult.enterpriseBaseVO.sysPayeeId
        ? validatePayeeResult.enterpriseBaseVO.sysPayeeId
        : null,
          payeeGender:
      validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
      validatePayeeResult.enterpriseBaseVO.payeeGender
        ? validatePayeeResult.enterpriseBaseVO.payeeGender
              : DEFAULT_DD_VALUE,
          payeePayerName:
      validatePayeeResult && validatePayeeResult.enterpriseBaseVO &&
      validatePayeeResult.enterpriseBaseVO.entityName && !validatePayeeResult.enterpriseBaseVO.entityName.startsWith('null')
        ? validatePayeeResult.enterpriseBaseVO.entityName
        : ''
        });
      }
    }
  }, [validatePayeeResult]);
  // Update the page on search result update
  React.useEffect(() => {
    if (showEditPage && updatedRecord) {
      const updateResult = updatedRecord;
      setPayeeValues({
        payeePayerType: updateResult.entityType,
        entityIDTypeCD: updateResult.entityTypeCode,
        entityID: updateResult.entityID,
        payeePayerName:
          updateResult.entityName && !updateResult.entityName.startsWith('null')
            ? updateResult.entityName
            : '',
        voidInd: updateResult.voidInd || false,
        payeeGender:
          updateResult && updateResult.payeeGender
            ? updateResult.payeeGender
            : DEFAULT_DD_VALUE,
        sysPayeeId:
          updatedRecord !== '' && updatedRecord.sysPayeeId
            ? updatedRecord.sysPayeeId
            : null
      });
      setPayeeDob(updateResult.payeeDob);
      setRecoupmentTabValues({
        installmentvalue: updateResult.recoupInstallmentValue
          ? numeral(
            Number(
              updateResult.recoupInstallmentValue.replace(/,/g, '')
            ).toPrecision()
          ).format('0,0.00')
          : '',
        recoupmentLogDays: updateResult.recoupmentLagDays,
        percentagevalue: updateResult.recoupPercentageValue,
        frequency: updateResult.recoupFrequency,
        maxWithholdAmount: updateResult.maxWithholdAmount
          ? numeral(
            Number(
              updateResult.maxWithholdAmount.replace(/,/g, '')
            ).toPrecision()
          ).format('0,0.00')
          : ''
      });
      setMiscellaneousTableData(updateResult.miscellaneousInfoVOList);
      setBankingTabTableData(updateResult.bankInfoVOList);
      setAddressTableData(updateResult.mailingAddressVOList);
      setPayeeIdentifierTableData(updateResult.payeeIdentifierVOsList || []);
      setPayeeStatusTable(updateResult.enrolmentStatusVOList || []);
      if (updateResult.voidedDate && updateResult.voidedDate !== null) {
        setDisableFields(true);
      }
      if (updateResult.noteSetVO) {
        const noteSetVO = updateResult.noteSetVO;
        const notesTable = updateResult.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
  }, [updatedRecord]);
  // Search Result after Update
  React.useEffect(() => {
    if (!searchSingleFinancialEntityRes) return;
    const { systemFailue } = searchSingleFinancialEntityRes;
    if (!systemFailue) {
      showEditPage && setUpdatedRecord(searchSingleFinancialEntityRes);
    } else {
      dispatch(setSearchPayeeDetails([]));
      props.history.push({
        pathname: "/FinancialEntitySearch",
        state: { searchCriteria: props.location.state?.searchCriteria }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // Update Financial Entity Response data
  React.useEffect(() => {
    const errorMessagesArray = [];
    if (showEditPage) {
      if (
        updateFinancialEntityResponse &&
        updateFinancialEntityResponse.systemFailure
      ) {
        errorMessagesArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
        seterrorMessages(errorMessagesArray);
      }
      if (
        updateFinancialEntityResponse &&
        !updateFinancialEntityResponse.systemFailure &&
        updateFinancialEntityResponse.respcode &&
        updateFinancialEntityResponse.respcode === '01'
      ) {
        if (updateFinancialEntityResponse.respdesc) {
          setFocusCheck(false);
          setAllowNavigation(false);
          setSystemSuccesMessages([updateFinancialEntityResponse.respdesc]);
        } else {
          setAllowNavigation(false);
          setFocusCheck(false);
          setSystemSuccesMessages([
            FinancialEntityConstants.SAVED_SUCCESFULLY_MSG
          ]);
        }
        searchSingleFinancialEntityApi(updatedRecord.commonEntitySK);
        setRedirect(1);
      } else if (
        updateFinancialEntityResponse &&
        !updateFinancialEntityResponse.systemFailure &&
        updateFinancialEntityResponse.respcode
      ) {
        seterrorMessages(updateFinancialEntityResponse.errorMessages);
      }
    }
  }, [updateFinancialEntityResponse]);
  // Create Financial Entity Response data
  React.useEffect(() => {
    const errorMessagesArray = [];
    if (
      addFinancialEntityResponse &&
      addFinancialEntityResponse.systemFailure
    ) {
      errorMessagesArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      addFinancialEntityResponse &&
      !addFinancialEntityResponse.systemFailure &&
      addFinancialEntityResponse.respcode &&
      addFinancialEntityResponse.respcode === '01'
    ) {
      setAllowNavigation(false);
      setSystemSuccesMessages([AppConstants.SAVE_SUCCESS]);
      setFocusCheck(false);
      if (
        addFinancialEntityResponse.enterpriseBaseVO &&
        addFinancialEntityResponse.enterpriseBaseVO.commonEntitySK
      ) {
        const commonEntitySK =
          addFinancialEntityResponse.enterpriseBaseVO.commonEntitySK;
        setSystemPayeeTableData([]); // to reset table data when comes from the add screen
        searchSingleFinancialEntityApi(commonEntitySK);
        setOnCreateEntityGetRecordResult(
          addFinancialEntityResponse.enterpriseBaseVO
        );
      }
      setRedirect(1);
    } else if (
      addFinancialEntityResponse &&
      !addFinancialEntityResponse.systemFailure &&
      addFinancialEntityResponse.respcode
    ) {
      seterrorMessages(addFinancialEntityResponse.errorMessages);
    }
  }, [addFinancialEntityResponse]);
  // Master save object and function
  const FinancialEntityAddObj = {
    auditUserID: '',
    auditTimeStamp: '',
    addedAuditUserID: '',
    addedAuditTimeStamp: '',
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    mailingAddressVO: null,
    mailingAddressVOList: [],
    deletedMailingVOList: [],
    deletedMisceInfoVOList: [],
    noteSet: null,
    deletedBankInfoVOList: [],
    voidValue: 'No',
    voidInd: false,
    voidedDate: null,
    payeePayerID: '',
    entityID: '',
    entityType: '',
    entityTypeCode: '',
    entityName: '',
    vendorNumber: null,
    commonEntitySK: '',
    commonEntity: null,
    financialInfoVrsnNo: 0,
    comnEntityVrsnNo: 0,
    commonEntTypVrsnNo: 0,
    entTypeDesc: '',
    entTypCdDesc: '',
    sysID: null,
    payeePayerType: null,
    payeePayerIDTypeCode: null,
    w9SignedDate: null,
    bankInfoVOList: [],
    miscellaneousInfoVOList: [],
    recoupmentLagDays: null,
    lob: null,
    recoupInstallmentValue: null,
    recoupPercentageValue: null,
    recoupFrequency: null,
    cmnEntityVoidInd: false,
    addressVoList: [],
    firstName: null,
    middleName: null,
    lastName: null
  };
  const masterSaveFinancialEntity = () => {
    if (errorMessages?.length) {
      return false;
    };
    seterrorMessages([]);
    dispatch(ResetPayeeResponse());
    if (!allowNavigation && showEditPage) {
      NoSaveMessage();
    } else {
      setSystemSuccesMessages([]);
      const errFlag1 = payeeRedirectFlag
        ? !payeeRedirectFlag
        : callValidatePayeeDetails(true);
      const errFlag2 = callValidateRecoupmentDetails();
      const errFlag3 = callVlaidatePayeeStatusLength();
      const errFlag4 = callValidateAddress();
      if (payeeRedirectFlag) {
        const tempSearchObj = {
          commonEntityTypeCode: payeeValues.payeePayerType,
          entityIDTypeCode: payeeValues.entityIDTypeCD,
          entityID: payeeValues.entityID,
          payeeNameStartsWithOrContains: '0',
          payeeName: payeeValues.payeePayerName
        };
        if (payeeValues.payeePayerType === DEFAULT_DD_VALUE) {
          tempSearchObj.commonEntityTypeCode = null;
        }
        if (payeeValues.entityIDTypeCD === DEFAULT_DD_VALUE) {
          tempSearchObj.entityIDTypeCode = null;
        }
        if (payeeValues.entityID === '') {
          tempSearchObj.entityID = null;
        }
        if (payeeValues.payeePayerName === '') {
          tempSearchObj.payeeName = null;
        }
        openSwalDialog(tempSearchObj);
      }
      if (
        payeeErrorArray.length > 0 &&
        typeof payeeErrorArray === 'object' &&
        !showEditPage &&
        !payeeRedirectFlag
      ) {
        seterrorMessages(payeeErrorArray);
      } else if (errorMessages.length === 0 && errFlag1 && errFlag2) {
        seterrorMessages([]);
      }
      if (showEditPage) {
        if (errFlag1 && errFlag2) {
          if (!errFlag4) {
            const tempArray = errorMessages;
            const message = `Atleast one active ${
              systemParamAddresscheck === 'B' ? 'Billing' : 'Mailing'
            } address should be present.`;
            if (!tempArray.includes(message)) {
              tempArray.push(message);
              seterrorMessages(JSON.parse(JSON.stringify(tempArray)));
            }
          } else {
            addressTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            payeeIdentifierTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            miscellaneousTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            bankingTabTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            const constUpdatedRecord = updatedRecord;
            constUpdatedRecord.auditUserID = logInUserID; // For Add and Edit/Update API call new values
            constUpdatedRecord.auditTimeStamp = getUTCTimeStamp();
            constUpdatedRecord.addedAuditUserID = constUpdatedRecord.addedAuditUserID
              ? constUpdatedRecord.addedAuditUserID
              : logInUserID;
            constUpdatedRecord.addedAuditTimeStamp = constUpdatedRecord.addedAuditTimeStamp
              ? constUpdatedRecord.addedAuditTimeStamp
              : getUTCTimeStamp();
            constUpdatedRecord.mailingAddressVOList = addressTableData;
            constUpdatedRecord.miscellaneousInfoVOList = miscellaneousTableData;
            constUpdatedRecord.bankInfoVOList = bankingTabTableData;
            constUpdatedRecord.maxWithholdAmount =
              RecoupmentTabValues.maxWithholdAmount !== ''
                ? numeral(RecoupmentTabValues.maxWithholdAmount).format(
                  '0,0.00'
                )
                : null;
            constUpdatedRecord.recoupInstallmentValue = RecoupmentTabValues.installmentvalue
              ? numeral(RecoupmentTabValues.installmentvalue).format('0,0.00')
              : null;
            constUpdatedRecord.recoupPercentageValue = RecoupmentTabValues.percentagevalue
              ? numeral(RecoupmentTabValues.percentagevalue).format('0.00')
              : null;
            constUpdatedRecord.recoupFrequency =
              RecoupmentTabValues.frequency !== DEFAULT_DD_VALUE
                ? RecoupmentTabValues.frequency
                : null;
            constUpdatedRecord.recoupmentLagDays =
              RecoupmentTabValues.recoupmentLogDays !== ''
                ? RecoupmentTabValues.recoupmentLogDays
                : null;
            constUpdatedRecord.payeeGender =
              payeeValues.payeeGender !== DEFAULT_DD_VALUE
                ? payeeValues.payeeGender
                : null;
            constUpdatedRecord.payeeDob = getDateInMMDDYYYYFormat(payeeDob);
            constUpdatedRecord.entityName = payeeValues.payeePayerName
              ? payeeValues.payeePayerName
              : null;
            constUpdatedRecord.enrolmentStatusVOList = payeeStatusTable;
            constUpdatedRecord.voidNoteInd =
              !!(updatedRecord && updatedRecord.voidInd);
            if (IsG16003) {
              constUpdatedRecord.voidInd = payeeValues.voidInd;
              constUpdatedRecord.voidValue =
                payeeValues.voidInd === 'true' || payeeValues.voidInd === true
                  ? 'Yes'
                  : 'No';
            }
            if (constUpdatedRecord.mailingAddressVO.dateFormat) {
              constUpdatedRecord.mailingAddressVO.dateFormat = null;
            }
            constUpdatedRecord.payeeIdentifierVOsList = payeeIdentifierTableData;
            constUpdatedRecord.noteSetVO = notesInput;
            constUpdatedRecord.payeePaymentStatus =
              payeePaymentStatusValue !== DEFAULT_DD_VALUE
            ? payeePaymentStatusValue
            : null;
            setSystemSuccesMessages([]);
            seterrorMessages([]);
            updateFinancialEntityApi(
              removeLeadingTrailingSpaces(constUpdatedRecord)
            );
          }
        }
        // }
      } else {
        if (errFlag1 && errFlag2 && payeeErrorArray.length === 0) {
          if (!errFlag3) {
            const tempArray = errorMessages;
            const message = FinancialEntityConstants.ATLEAST_ONE_PAYEE;
            if (!tempArray.includes(message)) {
              tempArray.push(message);
              seterrorMessages(tempArray);
            }
          }
          if (!errFlag4) {
            const tempArray = errorMessages;
            const message = `Atleast one active ${
              systemParamAddresscheck === 'B' ? 'Billing' : 'Mailing'
            } address should be present.`;
            if (!tempArray.includes(message)) {
              tempArray.push(message);
              seterrorMessages(JSON.parse(JSON.stringify(tempArray)));
            }
          } else {
            addressTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            payeeIdentifierTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            miscellaneousTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            bankingTabTableData.map(element => {
              element = removeLeadingTrailingSpaces(element);
            });
            FinancialEntityAddObj.auditUserID = logInUserID; // For Add and Edit/Update API call new values
            FinancialEntityAddObj.auditTimeStamp = getUTCTimeStamp(); // For Add and Edit/Update API call new values
            FinancialEntityAddObj.addedAuditUserID = logInUserID; //
            FinancialEntityAddObj.addedAuditTimeStamp = getUTCTimeStamp();
            FinancialEntityAddObj.mailingAddressVOList = addressTableData;
            FinancialEntityAddObj.miscellaneousInfoVOList = miscellaneousTableData;
            FinancialEntityAddObj.bankInfoVOList = bankingTabTableData;
            FinancialEntityAddObj.maxWithholdAmount =
              RecoupmentTabValues.maxWithholdAmount !== ''
                ? numeral(RecoupmentTabValues.maxWithholdAmount).format(
                  '0,0.00'
                )
                : null;
            FinancialEntityAddObj.recoupInstallmentValue =
              RecoupmentTabValues.installmentvalue !== ''
                ? numeral(RecoupmentTabValues.installmentvalue).format('0,0.00')
                : null;
            FinancialEntityAddObj.recoupPercentageValue =
              RecoupmentTabValues.percentagevalue !== ''
                ? numeral(RecoupmentTabValues.percentagevalue).format('0.00')
                : null;
            FinancialEntityAddObj.recoupFrequency =
              RecoupmentTabValues.frequency !== DEFAULT_DD_VALUE
                ? RecoupmentTabValues.frequency
                : null;
            FinancialEntityAddObj.recoupmentLagDays =
              RecoupmentTabValues.recoupmentLogDays !== ''
                ? RecoupmentTabValues.recoupmentLogDays
                : null;
            FinancialEntityAddObj.payeePayerID =
              payeeValues.entityID !== '' ? payeeValues.entityID : null;
            FinancialEntityAddObj.commonEntitySK = validatePayeeResult?.enterpriseBaseVO?.commonEntitySK;
            FinancialEntityAddObj.entityID = payeeValues.entityID
              ? payeeValues.entityID
              : null;
            FinancialEntityAddObj.entityType =
              payeeValues.payeePayerType !== DEFAULT_DD_VALUE
                ? payeeValues.payeePayerType
                : null;
            FinancialEntityAddObj.entityTypeCode =
              payeeValues.entityIDTypeCD !== DEFAULT_DD_VALUE
                ? payeeValues.entityIDTypeCD
                : null;
            FinancialEntityAddObj.entityIdTypeCode =
              payeeValues.entityIDTypeCD !== DEFAULT_DD_VALUE
                ? payeeValues.entityIDTypeCD
                : null;
            FinancialEntityAddObj.entityName = payeeValues.payeePayerName
              ? payeeValues.payeePayerName
              : null;
            FinancialEntityAddObj.sysPayeeId = payeeValues.sysPayeeId;
            FinancialEntityAddObj.payeePayerType =
              payeeValues.payeePayerType !== DEFAULT_DD_VALUE
                ? payeeValues.payeePayerType
                : null;
            FinancialEntityAddObj.noteSetVO = notesInput;
            FinancialEntityAddObj.payeeGender =
              payeeValues.payeeGender !== DEFAULT_DD_VALUE
                ? payeeValues.payeeGender
                : null;
            FinancialEntityAddObj.payeeDob = getDateInMMDDYYYYFormat(payeeDob);
            FinancialEntityAddObj.payeeIdentifierVOsList = payeeIdentifierTableData;
            FinancialEntityAddObj.enrolmentStatusVOList = payeeStatusTable;
            FinancialEntityAddObj.commonEntitySK = validatePayeeResult?.enterpriseBaseVO?.commonEntitySK;
            FinancialEntityAddObj.payeePaymentStatus =
              payeePaymentStatusValue !== DEFAULT_DD_VALUE
            ? payeePaymentStatusValue
            : null;
            // Create Financial Entity API Call
            setSystemSuccesMessages([]);
            seterrorMessages([]);
            createFinancialEntity(
              removeLeadingTrailingSpaces(FinancialEntityAddObj)
            );
          }
        } else if (payeeErrorArray.length > 0 && payeeSearchResult) {
          openSwalDialog();
        }
      }
    }
  };
  // Notes start
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  let notesDataArray = [];

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd
  // Disable Note button by parent Form validation
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    let _isIPvalid = false;
    if (payeeValues.payeePayerType === DEFAULT_DD_VALUE) {
      _isIPvalid = _isIPvalid || true;
    }
    if (payeeValues.entityIDTypeCD === DEFAULT_DD_VALUE) {
      _isIPvalid = _isIPvalid || true;
    }
    if (
      payeeValues.entityID === '' ||
      payeeValues.entityID === null ||
      payeeValues.entityID === undefined ||
      (payeeValues.entityID && !payeeValues.entityID.trim())
    ) {
      _isIPvalid = _isIPvalid || true;
    }
    return _isIPvalid;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [payeeValues]);

  const formatDecimalForAmount = name => e => {
    const value = e.target.value
      ? numeral(e.target.value).format('0,0.00')
      : '';
    setRecoupmentTabValues({
      ...RecoupmentTabValues,
      [name]: value
    });
  };
  const formatDecimalOnFocus = name => e => {
    const targetValue = e.target.value
      ? numeral(e.target.value).format('0.00')
      : '';
    setRecoupmentTabValues({
      ...RecoupmentTabValues,
      [name]: targetValue
    });
  };
  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setShowPayeeError({
      showPTypeErr: false,
      showPIDTypeErr: false,
      showPIDErr: false
    });
    setPayeeErrMsg({
      pTypeErrMsg: '',
      pIDTypeErrMsg: '',
      pIDErrMsg: ''
    });
  };
  // Dialog for redirect to Update or Search Screen
  const [openDialog, setOpenDialog] = React.useState(false);
  const confirmRedirect = tempSearchObj => {
    sessionStorage.setItem('isSwalFire', true);

    setOpenDialog(false);
    setAllowNavigation(false);
    getPayeeSearchAPI(tempSearchObj);
  };
  const openSwalDialog = tempSearchObj => {
    if (!showEditPage) {
      Swal.fire({
        customClass: {
          confirmButton: 'btn-text1 btn-transparent mr-3',
          cancelButton: 'btn1 btn-success',
          container: 'unsaved-changes-popup2'
        },
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        icon: 'warning',
        html: '<h4 >Payee already exists. Do you want to view the data?</h4 >',
        showCancelButton: true,
        confirmButtonText: 'Cancel!',
        cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
      }).then(result => {
        if (result.value) {
        } else {
          confirmRedirect(tempSearchObj);
        }
      });
    }
  };

  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleBeginDate = beginDate => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setBeginDate(getDateInMMDDYYYYFormat(beginDate));
  };

  const handleEndDate = endDate => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setEndDate(getDateInMMDDYYYYFormat(endDate));
  };

  // payee status
  const [payeeStatus, setPayeeStatus] = useState({
    statusTypeCd: DEFAULT_DD_VALUE,
    ststusRsnCd: DEFAULT_DD_VALUE
  });

  const [payeeStatusError, setPayeeStatusError] = useState({
    statusError: false,
    reasonCodeError: false,
    beginDateError: false,
    endDateError: false
  });

  const [payeeStatusErrorMsg, setPayeeStatusErrorMsg] = useState({
    statusErrorMsg: '',
    reasonCodeErrorMsg: '',
    beginDateErrorMsg: '',
    endDateErrorMsg: ''
  });
  const handlePayeeStatusChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setPayeeStatus({ ...payeeStatus, [name]: event.target.value });
  };
  const handlePayeePaymentStatusChange = event => {
    setAllowNavigation(true);
    setPayeePaymentStatusValue(event.target.value);
  };
  const [openPopUp, setOpenPopUp] = useState(false);
  const [editRowBool, setEditRowBool] = useState(false);
  const [retainEdit, setRetainEdit] = useState({});
  const handleEditRow = row => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setEditRowBool(true);
    handleOpen();
    setRetainEdit(row);
    setPayeeStatus({
      id: row.id ? row.id : null,
      enrollmentSk: row.enrollmentSk ? row.enrollmentSk : null,
      statusTypeCd: row.statusTypeCd,
      ststusRsnCd: row.ststusRsnCd
    });
    setBeginDate(row.beginDate);
    setEndDate(row.endDate);
  };
  const addClickPayeeStatus = () => {
    handleOpen();
    setEditRowBool(false);
    setPayeeStatusErrorMsg('');
    setPayeeStatusError(false);
  };
  const resetAdd = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    seterrorMessages([]);
    if (editRowBool) {
      setBeginDate(retainEdit.beginDate);
      setEndDate(retainEdit.endDate);
      setPayeeStatus({
        statusTypeCd: retainEdit.statusTypeCd,
        ststusRsnCd: retainEdit.ststusRsnCd
      });
    } else {
      setPayeeStatusErrorMsg({
        statusErrorMsg: '',
        reasonCodeErrorMsg: '',
        beginDateErrorMsg: '',
        endDateErrorMsg: ''
      });
      setPayeeStatusError({
        statusError: false,
        reasonCodeError: false,
        beginDateError: false,
        endDateError: false
      });
      setBeginDate(null);
      setEndDate(null);
      setPayeeStatus({
        statusTypeCd: DEFAULT_DD_VALUE,
        ststusRsnCd: DEFAULT_DD_VALUE
      });
    }
  };
  const handleClose = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    setFocus(false);
    setOpenPopUp(false);
    seterrorMessages([]);
  };
  const handleOpen = () => {
    if (callValidatePayeeDetails(true)) {
      setPayeeStatusErrorMsg({
        statusErrorMsg: '',
        reasonCodeErrorMsg: '',
        beginDateErrorMsg: '',
        endDateErrorMsg: ''
      });
      setPayeeStatusError({
        statusError: false,
        reasonCodeError: false,
        beginDateError: false,
        endDateError: false
      });

      setPayeeStatus({
        statusTypeCd: DEFAULT_DD_VALUE,
        ststusRsnCd: DEFAULT_DD_VALUE
      });
      setBeginDate(null);
      setEndDate(null);
      setOpenPopUp(true);
    }
  };

  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };
  let statusTabTable = [];
  const editPayeeStatus = () => {
    statusTabTable = payeeStatusTable;
    const rowIndex = payeeStatusTable.indexOf(retainEdit);
    if (
      validatePayeeStatus(
        payeeStatus,
        beginDate,
        endDate,
        setPayeeStatusError,
        setPayeeStatusErrorMsg,
        payeeStatusTable,
        setEditRowBool,
        retainEdit,
        seterrorMessages,
        errorMessages
      )
    ) {
      const rowData = statusTabTable[rowIndex];
      rowData.auditUserID = logInUserID;
      rowData.auditTimeStamp = getUTCTimeStamp();
      rowData.beginDate = getDateInMMDDYYYYFormat(beginDate);
      rowData.endDate = getDateInMMDDYYYYFormat(endDate);
      rowData.statusTypeCd = payeeStatus.statusTypeCd;
      rowData.ststusRsnCd = payeeStatus.ststusRsnCd;
      rowData.statusDesc = mapCodeDescription(
        payeeStatus.statusTypeCd,
        statusDropDown
      );
      rowData.reasonDesc = mapCodeDescription(
        payeeStatus.ststusRsnCd,
        statusReasonCodeDropDown
      );

      statusTabTable[rowIndex] = rowData;
      setFocus(false);
      setOpenPopUp(false);
      seterrorMessages([]);
    }
  };
  const addPayeeStatus = () => {
    if (
      validatePayeeStatus(
        payeeStatus,
        beginDate,
        endDate,
        setPayeeStatusError,
        setPayeeStatusErrorMsg,
        payeeStatusTable,
        setEditRowBool,
        retainEdit,
        seterrorMessages,
        errorMessages
      )
    ) {
      statusTabTable = payeeStatusTable;
      const addPayeeStatus = {
        id: generateUUID(),
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        beginDate: getDateInMMDDYYYYFormat(beginDate),
        endDate: getDateInMMDDYYYYFormat(endDate),
        statusTypeCd:
          payeeStatus.statusTypeCd === DEFAULT_DD_VALUE
            ? null
            : payeeStatus.statusTypeCd,
        ststusRsnCd:
          payeeStatus.ststusRsnCd === DEFAULT_DD_VALUE
            ? null
            : payeeStatus.ststusRsnCd,
        enrollmentSk: null,
        statusDesc: mapCodeDescription(
          payeeStatus.statusTypeCd,
          statusDropDown
        ),
        reasonDesc: mapCodeDescription(
          payeeStatus.ststusRsnCd,
          statusReasonCodeDropDown
        )
      };
      statusTabTable.push(addPayeeStatus);
      setPayeeStatusTable(statusTabTable);
      setAllowNavigation(true);
      setFocus(false);
      setOpenPopUp(false);
      seterrorMessages([]);
    }
  };

  const [payeeStatusTable, setPayeeStatusTable] = useState([]);
  useEffect(() => {
    if (
      payeeStatusTable &&
      payeeStatusTable.length > 0 &&
      statusDropDown &&
      statusReasonCodeDropDown
    ) {
      payeeStatusTable.map(rowData => {
        rowData.statusDesc = mapCodeDescription(
          rowData.statusTypeCd,
          statusDropDown
        );
        rowData.reasonDesc = mapCodeDescription(
          rowData.ststusRsnCd,
          statusReasonCodeDropDown
        );
      });
    }
  }, [payeeStatusTable, statusReasonCodeDropDown, statusDropDown]);
  // Delete Functionality
  const [payeeTableSelectedRows, setPayeeTableSelectedRows] = React.useState(
    []
  );
  const selectedPayeeStatusTableRow = selectedRows => {
    setPayeeTableSelectedRows([...selectedRows]);
  };
  const deleteSelectedRows = () => {
    let tempTableData = [...payeeStatusTable];
    if (payeeTableSelectedRows && payeeTableSelectedRows.length > 0) {
      payeeTableSelectedRows.map((selectedRowId) => {
        tempTableData = tempTableData.filter(
          tempRow => tempRow.id !== selectedRowId
        );
      });
    }
    setPayeeStatusTable(tempTableData);
  };
  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);

  const onClickAuditLog = () => {
    const tableName = 'g_pye_pyr_tb';
    const keyValue = {
      g_cmn_enty_sk: updatedRecord.commonEntitySK
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, 'auditLogData'));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(
    state => state.financialEntityState.auditDetails
  );
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);
  // address
  const [showLogAddressTable, setShowLogAddressTable] = React.useState(false);
  const auditLogAddressTableData = useSelector(
    state => state.financialEntityState.auditAddressDetails
  );
  const [auditAddressTableData, setAuditAddressTableData] = React.useState([]);

  const onClickAuditAddressLog = row => {
    const auditReq = [
      {
        tableName: 'g_adr_usg_tb',
        keyValue: {
          g_cmn_enty_sk: row.commonEntitySK,
          g_adr_usg_ty_cd: row.addressType,
          g_adr_sk: row.addressSK,
          g_adr_usg_seq_num: row.addressUsageSequenceNumber
        }
      }, {
        tableName: 'g_adr_tb',
        keyValue: {
          g_adr_sk: row.addressSK
        }
      }
    ];
    dispatch(
      getAuditLogAddressDetailsAction(auditReq, 'auditLogTableData')
    );
    setShowLogAddressTable(true);
  };
  useEffect(() => {
    if (auditLogAddressTableData) {
      setAuditAddressTableData(
        auditLogAddressTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogAddressTableData]);

  useEffect(() => {
    if (showLogAddressTable && document.getElementById('audit-Address-table')) { document.getElementById('audit-Address-table').scrollIntoView(); }
  }, [showLogAddressTable]);
  // payee status
  const [showLogPayeeStatusTable, setShowLogPayeeStatusTable] = React.useState(
    false
  );
  const auditLogPayeeStatusTableData = useSelector(
    state => state.financialEntityState.auditLogTableData
  );
  const [
    auditPayeeStatusTableData,
    setAuditPayeeStatusTableData
  ] = React.useState([]);

  useEffect(() => {
    if (auditLogPayeeStatusTableData) {
      setAuditPayeeStatusTableData(
        auditLogPayeeStatusTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogPayeeStatusTableData]);

  useEffect(() => {
    if (
      showLogPayeeStatusTable &&
      document.getElementById('audit-PayeeStatus-table')
    ) { document.getElementById('audit-PayeeStatus-table').scrollIntoView(); }
  }, [showLogPayeeStatusTable]);

  // payee identifiers
  const [
    showLogPayeeIdentifiersTable,
    setShowLogPayeeIdentifiersTable
  ] = React.useState(false);
  const auditLogPayeeIdentifiersTableData = useSelector(
    state => state.financialEntityState.auditLogTableData
  );
  const [
    auditPayeeIdentifiersTableData,
    setAuditPayeeIdentifiersTableData
  ] = React.useState([]);

  useEffect(() => {
    if (auditLogPayeeIdentifiersTableData) {
      setAuditPayeeIdentifiersTableData(
        auditLogPayeeIdentifiersTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogPayeeIdentifiersTableData]);

  useEffect(() => {
    if (
      showLogPayeeIdentifiersTable &&
      document.getElementById('audit-PayeeIdentifiers-table')
    ) { document.getElementById('audit-Payee Identifiers-table').scrollIntoView(); }
  }, [showLogPayeeIdentifiersTable]);
  // banking
  const [showLogBankingTable, setShowLogBankingTable] = React.useState(false);
  const auditLogBankingTableData = useSelector(
    state => state.financialEntityState.auditBankingDetails
  );
  const [auditBankingTableData, setAuditBankingTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);

  const onClickAuditBankingLog = row => {
    const tableName = 'g_pye_pyr_eft_setup_tb';
    const keyValue = {
      g_cmn_enty_sk: updatedRecord.commonEntitySK,
      g_pye_pyr_eft_setup_seq_num: row.payeePayerEffectiveSetupSequenceNumber
    };

    dispatch(
      getAuditLogBankingDetailsAction(tableName, keyValue, 'auditLogTableData')
    );
    setShowLogBankingTable(true);
  };
  useEffect(() => {
    if (auditLogBankingTableData) {
      setAuditBankingTableData(
        auditLogBankingTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogBankingTableData]);

  useEffect(() => {
    if (showLogBankingTable && document.getElementById('audit-Banking-table')) { document.getElementById('audit-Banking-table').scrollIntoView(); }
  }, [showLogBankingTable]);

  const [
    showLogMiscellaneousTable,
    setShowLogMiscellaneousTable
  ] = React.useState(false);
  const auditLogMiscellaneousTableData = useSelector(
    state => state.financialEntityState.auditMiscellaneousDetails
  );
  const [
    auditMiscellaneousTableData,
    setAuditMiscellaneousTableData
  ] = React.useState([]);

  const onClickAuditMiscellaneousLog = row => {
    const tableName = 'g_pye_pyr_bnkrptcy_tb';
    const keyValue = {
      g_cmn_enty_sk: updatedRecord.commonEntitySK,
      g_pye_pyr_bnkrptcy_seq_num: row.payeePayerBankruptcySequenceNumber
    };
    dispatch(
      getAuditLogMiscellaneousDetailsAction(
        tableName,
        keyValue,
        'auditLogTableData'
      )
    );
    setShowLogMiscellaneousTable(true);
  };
  useEffect(() => {
    if (auditLogMiscellaneousTableData) {
      setAuditMiscellaneousTableData(
        auditLogMiscellaneousTableData.filter(filterAuditTable)
      );
    }
  }, [auditLogMiscellaneousTableData]);

  useEffect(() => {
    if (
      showLogMiscellaneousTable &&
      document.getElementById('audit-Miscellaneous-table')
    ) { document.getElementById('audit-Miscellaneous-table').scrollIntoView(); }
  }, [showLogMiscellaneousTable]);

  // Audit Log Implementation ends
  // #endregion

  const ValuesFromFinEntity = (props.history && props.history.location && props.history.location.state && props.history.location.state.values);
  ValuesFromFinEntity ? sessionStorage.setItem('ValuesFromFinEntity', JSON.stringify(ValuesFromFinEntity)) : sessionStorage.setItem('ValuesFromFinEntity', null);
  const renderTable = sessionStorage.getItem('isFormAdd');
  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      if (window.location.pathname === '/FinancialEntityAdd') { sessionStorage.setItem('tableRender', false); } else if (swalFireData && window.location.pathname !==
        '/FinancialEntityAdd') { sessionStorage.setItem('tableRender', false); } else { { sessionStorage.setItem('tableRender', !addToUpdate); } }
      props.history.push({
        pathname: '/FinancialEntitySearch',
		tableRender: true,
        state: { ValuesFromFinEntity: ValuesFromFinEntity, tableRender: !renderTable }
      });
    }, 100);
  };

  const majorCancel = () => {
    seterrorMessages([]);
    if (window.location.pathname === '/FinancialEntityAdd') {
sessionStorage.setItem('tableRender', false);
sessionStorage.setItem('fromAddScreen', true);
} else if (swalFireData && window.location.pathname !==
      '/FinancialEntityAdd') {
sessionStorage.setItem('tableRender', false);
} else {
sessionStorage.setItem('tableRender', !addToUpdate);
sessionStorage.setItem('fromEditScreen', true);
}
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      { sessionStorage.setItem('isCancel', true); }
      props.history.push({
        pathname: '/FinancialEntitySearch',
		tableRender: true,
        state: { ValuesFromFinEntity: ValuesFromFinEntity }
      });
    }
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

        {errorMessages.length > 0 ? <ErrorMessages errorMessages={errorMessages} /> : null }
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <div className="tab-heading float-left">
            {showEditPage
              ? FinancialEntityConstants.FINANCIAL_ENTITY_HEADER_EDIT
              : FinancialEntityConstants.FINANCIALENTITYPAYEE_HEADER}
          </div>
          <div className="float-right mt-2">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-success"
              data-testid='test-major-Save'
              onClick={masterSaveFinancialEntity}
              disabled = {!userInquiryPrivileges}
            >
              <i
                className="fa fa-check"
                aria-hidden="true"
                style={{ marginRight: '2px' }}
              ></i>{' '}
              Save
            </Button>
            {showEditPage ? (
              <Button
                color="primary"
                className={
                  auditButtonToggle
                    ? 'btn btn-primary ml-1'
                    : 'btn btn-text1 btn-transparent ml-1'
                }
                onClick={() => {
                  setAuditButtonToggle(!auditButtonToggle);
                  setShowLogTable(true);
                }}
              >
                <i className="fa fa-question-circle" aria-hidden="true"></i>
                Audit Log
              </Button>
            ) : null}
            {window.location.pathname === '/FinancialEntityAdd' && (
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
            )}
            {window.location.pathname === '/FinancialEntityUpdate' && (
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <Common
            setHyperLinkClick={setHyperLinkClick}
            setSystemPayeeTableData={setSystemPayeeTableData}
            systemPayeeTableData={systemPayeeTableData}
            disableFields={disableFields}
            isHyperLinkClick={isHyperLinkClick}
            showEditPage={showEditPage}
            setUpdatedRecord={setUpdatedRecord}
            payeeValues={payeeValues}
            handlePayeeFormChanges={handlePayeeFormChanges}
            validatePayee={validatePayee}
            payeeTypeDataFrmState6002={payeeTypeDataFrmState6002 || []}
            payeeTypeDataFrmState6003={payeeTypeDataFrmState6003 || []}
            payeeIDTypeCodeDropdown={payeeIDTypeCodeDropdown || []}
            GenderDropdown={GenderDropdown || []}
            payeeDob={payeeDob}
            handleDob={handleDob}
            IsG16003={IsG16003}
            showPayeeError={showPayeeError}
            payeeErrorMsg={payeeErrorMsg}
            userInquiryPrivileges = {userInquiryPrivileges}
            dropdowns = {addDropdowns}
          />
          <div className="tab-panelbody m-0">
            <div className="tab-holder p-0">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Payee Status" className={'original-tab'} />
                  <Tab
                    label="Payee Identifiers"
                    className={'original-tab' + color.addressTabClr}
                  />
                  <Tab
                    label="Recoupment"
                    className={'original-tab' + color.recoupmentTabClr}
                  />
                  <Tab
                    label="Banking"
                    className={'original-tab' + color.bankingTabCr}
                  />
                  <Tab
                    label="Miscellaneous"
                    className={'original-tab' + color.miscellaneousTabClr}
                  />
                  <Tab
                    label="Address"
                    className={'original-tab' + color.addressTabClr}
                  />
                  <Tab label="Notes" className={'original-tab'} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div className="clearfix"></div>
                {value === 0 && (
                  <div className="tab-holder p-0">
                    <PayeeStatusTab
                      seterrorMessages={seterrorMessages}
                      IsG16003={IsG16003}
                      isPpayeePayerType={!IsG16003}
                      disableFields={disableFields}
                      handlePayeeStatusChange={handlePayeeStatusChange}
                      payeeStatus={payeeStatus}
                      statusDropDown={statusDropDown}
                      statusReasonCodeDropDown={statusReasonCodeDropDown}
                      handleBeginDate={handleBeginDate}
                      handleEndDate={handleEndDate}
                      beginDate={beginDate}
                      endDate={endDate}
                      showEditPage={showEditPage}
                      payeeStatusTable={payeeStatusTable}
                      addPayeeStatus={addPayeeStatus}
                      payeeStatusError={payeeStatusError}
                      payeeStatusErrorMsg={payeeStatusErrorMsg}
                      openPopUp={openPopUp}
                      handleClose={handleClose}
                      handleOpen={handleOpen}
                      addClickPayeeStatus={addClickPayeeStatus}
                      handleEditRow={handleEditRow}
                      editRowBool={editRowBool}
                      editPayeeStatus={editPayeeStatus}
                      resetAdd={resetAdd}
                      selectedPayeeStatusTableRow={selectedPayeeStatusTableRow}
                      deleteSelectedRows={deleteSelectedRows}
                      userInquiryPrivileges={userInquiryPrivileges}
                      setFocusCheck ={setFocusCheck}
                      initialFocusCheck={initialFocusCheck}
                      handlePayeePaymentStatusChange={handlePayeePaymentStatusChange}
                      payeePaymentStatusValue={payeePaymentStatusValue}
                      payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                      showPayeePaymentStatus={showPayeePaymentStatus}
                      dropdowns = {addDropdowns}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="clearfix"></div>
                {value === 1 && (
                  <div className="tab-holder p-0">
                    <PayeeIdentifiers
                    setFocusCheck ={setFocusCheck}
                    initialFocusCheck={initialFocusCheck}
                      tableData={payeeIdentifierDataForTable || []}
                      IsG16003={IsG16003}
                      showEditPage={showEditPage}
                      callValidatePayeeDetails={callValidatePayeeDetails}
                      setAllowNavigation={setAllowNavigation}
                      payeeIDTypeCodeDropdown={payeeIDTypeCodeDropdown || []}
                      setPayeeIdentifierTableData={setPayeeIdentifierTableData}
                      disableFields={disableFields}
                      seterrorMessages={seterrorMessages}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      dropdowns = {addDropdowns}
                      payeeValues={payeeValues}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="clearfix"></div>
                {value === 2 && (
                  <div className="tab-holder p-0">
                    <RecoupmentDetailTab
                      disableFields={disableFields}
                      setAllowNavigation={setAllowNavigation}
                      showEditPage={showEditPage}
                      RecoupmentTabValues={RecoupmentTabValues}
                      RecoupmentTabFrequencyDD={RecoupmentTabFrequencyDD}
                      recoupmentTabHandleChange={recoupmentTabHandleChange}
                      showRecoupmentError={showRecoupmentError}
                      recoupmentErrMsg={recoupmentErrMsg}
                      frequencyDropdown={frequencyDropdown || []}
                      formatDecimalForAmount={formatDecimalForAmount}
                      formatDecimalOnFocus={formatDecimalOnFocus}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      setFocusCheck={setFocusCheck}
                      dropdowns = {addDropdowns}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="clearfix"></div>
                {value === 3 && (
                  <div className="tab-holder p-0">
                    <BankingDetails
                      setFocusCheck={setFocusCheck}
                      initialFocusCheck={initialFocusCheck}
                      disableFields={disableFields}
                      callValidatePayeeDetails={callValidatePayeeDetails}
                      setAllowNavigation={setAllowNavigation}
                      showEditPage={showEditPage}
                      bankingTabTableData={bankingTabTableData}
                      setBankingTabTableData={setBankingTabTableData}
                      bankingTabEFTStatusDD={bankingTabEFTStatusDD}
                      bankingTabAccTypeDD={bankingTabAccTypeDD}
                      EFTStatusDropdown={EFTStatusDropdown || []}
                      AccountTypeDropdown={AccountTypeDropdown || []}
                      onClickAuditDialogLog={onClickAuditBankingLog}
                      uniqueKey={'payeePayerEffectiveSetupSequenceNumber'}
                      setClose={setClose}
                      close={close}
                      seterrorMessages={seterrorMessages}
                      auditRowData={auditBankingTableData}
                      setAuditRowData={setAuditBankingTableData}
                      setShowLogTable={setShowLogBankingTable}
                      setActiveBankingData={setActiveBankingData}
                      showLogBankingTable={showLogBankingTable}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      dropdowns = {addDropdowns}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={4}>
                <div className="clearfix"></div>
                {value === 4 && (
                  <div className="tab-holder p-0">
                    <MiscellaneousTab
                      setFocusCheck={setFocusCheck}
                      initialFocusCheck={initialFocusCheck}
                      BankruptcyTypeDropdown={BankruptcyTypeDropdown || []}
                      disableFields={disableFields}
                      callValidatePayeeDetails={callValidatePayeeDetails}
                      setAllowNavigation={setAllowNavigation}
                      showEditPage={showEditPage}
                      setMiscellaneousTableData={setMiscellaneousTableData}
                      miscellaneousTableData={miscellaneousTableData}
                      onClickAuditDialogLog={onClickAuditMiscellaneousLog}
                      uniqueKey={'payeePayerBankruptcySequenceNumber'}
                      setClose={setClose}
                      close={close}
                      seterrorMessages={seterrorMessages}
                      setActiveMiscellaneousData={setActiveMiscellaneousData}
                      auditRowData={auditMiscellaneousTableData}
                      setAuditRowData={setAuditMiscellaneousTableData}
                      setShowLogMiscellaneousTable={setShowLogMiscellaneousTable}
                      showLogMiscellaneousTable={showLogMiscellaneousTable}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      dropdowns = {addDropdowns}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div className="clearfix"></div>
                {value === 5 && (
                  <div className="tab-holder p-0">
                    <AddressDetailTab
                    setFocusCheck ={setFocusCheck}
                    initialFocusCheck={initialFocusCheck}
                      setActiveAddressData={setActiveAddressData}
                      disableFields={disableFields}
                      callValidatePayeeDetails={callValidatePayeeDetails}
                      setAllowNavigation={setAllowNavigation}
                      showEditPage={showEditPage}
                      setAddressTableData={setAddressTableData}
                      addressTableData={addressTableData}
                      payeeValues={payeeValues}
                      AddressTypeDropdown={AddressTypeDropdown || []}
                      SignificanceTypeDropdown={SignificanceTypeDropdown || []}
                      StatusCodeDropdown={StatusCodeDropdown || []}
                      CountyDropdown={CountyDropdown || []}
                      CountryDropdown={CountryDropdown || []}
                      StateDropdown={StateDropdown || []}
                      ChangeReasonDropdown={ChangeReasonDropdown || []}
                      onClickAuditDialogLog={onClickAuditAddressLog}
                      uniqueKey={'addressSK'}
                      setClose={setClose}
                      close={close}
                      seterrorMessages={seterrorMessages}
                      auditRowData={auditAddressTableData}
                      setAuditRowData={setAuditAddressTableData}
                      setShowLogTable={setShowLogAddressTable}
                      setShowLogAddressTable={setShowLogAddressTable}
                      showLogAddressTable={showLogAddressTable}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      dropdowns = {addDropdowns}
                    />
                  </div>
                )}

              </TabPanel>
              {
                <TabPanel value={value} index={6}>
                  {value === 6 && (
                    <div className="tab-holder p-0">
                      <Notes
                        addNotes={addNotes}
                        disableNotes={disableNotes}
                        notesTableData={notesTableData}
                        noteSetListInput={noteSetListInput}
                        setNoteSetListInput={setNoteSetListInput}
                        usageTypeCodeData={usageTypeCodeData}
                        editNoteData={editNoteData}
                        setEditNoteData={setEditNoteData}
                        disableFields={disableFields}
                        setSuccessMessages={clearSuccessErrorMsgs}
                        isNotesTable={true}
                        setFocusCheck={setFocusCheck}
                        setErrorMessages = {seterrorMessages}
                        userInquiryPrivileges = {userInquiryPrivileges}
                      />
                    </div>
                  )}
                </TabPanel>
              }
            </div>
          </div>
        </div>
        {showLogTable ? (
          <div id="audit-table">
            <AuditTableComponent
              print
              auditRowData={auditRowData}
              setAuditRowData={setAuditRowData}
              setShowLogTable={setShowLogTable}
            />{' '}
          </div>
        ) : null}
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default withRouter(
  NavHOC({
    Wrapped: FinancialEntityAddUpdate,
    action: searchSingleRecordFinancialEntity,
    url: '/FinancialEntityUpdate',
    actionKey: 'searchSingleFinancialEntityRes',
    selector: 'financialEntityState.singleSearchResults'
  })
);
