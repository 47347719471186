/**

* Â© [2020] Conduent, Inc. All rights reserved. ConduentÂ®and Conduent Agile

* StarÂ®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogContent
} from '../../../../../../SharedModules/Dialog/DialogUtilities';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../../../SharedModules/CommonUtilities/commonUtilities';
import * as errorConstants from '../ErrorCheck';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessages from '../../../../../../SharedModules/Errors/ErrorMessages';
import {
  getDropdownDataAction, getReasonCodeMapDataAction, getPayeeIdType,
  validatePayeeDetails, goToNextPageAction
} from '../../../../Store/Actions/accountReceivable/accountReceivableActions';
import { searchSystemParameterAction9047 } from '../../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import dropdownCriteria from './EntryLayout.json';
import * as AppConstants from '../../../../../../SharedModules/AppConstants';
import { resetL2KeyDataFromStore } from '../../../../../../SharedModules/store/Actions/SharedAction';
import SystemPayeeTable from '../../../../../../SharedModules/SystemIDTable/SystemPayeeIDTable';
import { Prompt } from 'react-router-dom';
import NativeDropDown from '../../../../../../SharedModules/Dropdowns/NativeDropDown';

function Original (props, { onChangeValue }) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;

  const [showTable, setShowTable] = React.useState(false);
  const initialValues = {
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    payeeId: ''
  };
  const [values, setValues] = React.useState(initialValues);
  const initialpayeeValues = {
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE
  };

  const [payeeValues, setPayeeValues] = React.useState(initialpayeeValues);
  const initialactivityReasonCodeValues = {
    activityCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE
  };

  const [activityReasonCodeValues, setActivityReasonCodeValues] = React.useState(initialactivityReasonCodeValues);

  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [isContinue, setIsContinue] = React.useState(false);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [initialReasonCodeArray, setInitialReasonCodeArray] = React.useState([]);
  const [redirect, setRedirect] = React.useState(0);
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  const dispatch = useDispatch();

  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const getReasonCodeMapData = (value) => dispatch(getReasonCodeMapDataAction(value));

  const onPayeeTypeIdDropDown = (value) => dispatch(getPayeeIdType(value));
  const onGotoNextPage = (value) => dispatch(goToNextPageAction(removeLeadingTrailingSpaces(value)));

  let dropdown = [];
  useEffect(() => {
    getDropdownData(dropdownCriteria);
    searchSystemParamForFinEntity(searchSystemParameterActionData);
  }, []);

  const errorMessagesArray = [];
  const [{
    showPayeeTypeError,
    showPayeeIdTypeCodeError, showPayeeIdError, showActivityTypeError, showReasonCodeError
  }, setShowError] = React.useState(false);
  const [{
    showPayeeTypeErrorText,
    showPayeeIdTypeCodeErrorText, showPayeeIdErrorText,
    showActivityTypeErrorText, showReasonCodeErrorText
  }, setShowErrorText] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [showPayeePaymentStatus, setShowPayeePaymentStatus] = React.useState('');
  const [payeeIDErrorMessages, setPayeeIDErrorMessages] = React.useState([]);
  dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  const reasonCodeMapdropdown = useSelector(state => state.accountReceivableState.reasonCodeMapdropdownData);
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  const systemParamaterPayload = useSelector(state => state.appConfigState?.systemParameterState?.data);
  const searchValues = useSelector(
    state => state.accountReceivableState.searchValues);
  const searchSystemParameterActionData = {
    parameterNumber: "9047", functionalArea: "F1"
  };
  const searchSystemParamForFinEntity = value => {
    dispatch(searchSystemParameterAction9047(value));
  };
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#1131']) {
        setActivityTypeData(dropdown.listObj['Financial#1131']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (systemParamaterPayload) {
      setShowPayeePaymentStatus(systemParamaterPayload[0]?.systemParameterDetail[0]?.valueData);
    }
  }, [systemParamaterPayload]);
  useEffect(() => {
    if (searchSingleFinancialEntityRes) {
      let payeeStatus = '';
      if (showPayeePaymentStatus === 'Y') {
        payeeStatus = searchSingleFinancialEntityRes.payeePaymentStatus;
      }
      if (activityReasonCodeValues.activityCode === 'GL') {
        props.history.push({
          pathname: '/FinancialAccountGrossLevel',
          state: { continueResults: { ...continueResults, grossLevelDetails: { ...continueResults.grossLevelDetails, payeeStatus } } }
        });
      }
      if (activityReasonCodeValues.activityCode === 'WL') {
        props.history.push({
          pathname: '/FinancialAccountLienLevy',
          state: { continueResults: { ...continueResults, grossLevelDetails: { ...continueResults.grossLevelDetails, payeeStatus } } }
        });
      }
    }
  }, [searchSingleFinancialEntityRes]);

  useEffect(() => {
    if (reasonCodeMapdropdown && reasonCodeMapdropdown.reasonCodesMap && reasonCodeMapdropdown.reasonCodesMap.reasonCodeList) {
      // reason code data will get set with sorted codes
      setReasonCodeData(reasonCodeMapdropdown.reasonCodesMap.reasonCodeList.sort((a, b) => (a.code - b.code)));
    }
  }, [reasonCodeMapdropdown]);

  const handleChange = name => event => {
    localStorage.setItem("globalSpinnerInd", true);
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode, index) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  useEffect(() => {
    if (payeeValues.payeeType !== '' && payeeValues.payeeTpe !== DEFAULT_DD_VALUE) {
      const payeeTypeDesc = fieldDescription(payeeTypeData, payeeValues.payeeType);
      setPayeeTypeDesc(payeeTypeDesc);
    }
  }, [payeeValues]);

  useEffect(() => {
    if (payeeValues.payeeIdTypeCode !== '' && payeeValues.payeeIdTypeCode !== DEFAULT_DD_VALUE) {
      const payeeIDTypecodeDesc = fieldDescription(payeeTypeIdCodeData, payeeValues.payeeIdTypeCode);
      setPayeeTypeIdCodeDesc(payeeIDTypecodeDesc);
    }
  }, [payeeValues]);

  const handleChangePayeeType = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setPayeeValues({ ...values, [name]: event.target.value });

    if (name === 'payeeType') {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        setShowTable(false);
        const valuetoredirect = redirect - 1;
        setRedirect(valuetoredirect);
        onPayeeTypeIdDropDown(event.target.value);
      }
      if (event.target.value === DEFAULT_DD_VALUE) {
        setPayeeTypeIdCodeData([]);
        setPayeeValues({
          payeeType: DEFAULT_DD_VALUE,
          payeeIdTypeCode: DEFAULT_DD_VALUE
        });
      }
    } else if (event.target.value === DEFAULT_DD_VALUE) {
      setPayeeValues({
        payeeType: payeeValues.payeeType,
        payeeIdTypeCode: DEFAULT_DD_VALUE
      });
    } else {
      setPayeeValues({
        payeeType: payeeValues.payeeType,
        payeeIdTypeCode: event.target.value
      });
    }
  };

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(state => state.accountReceivableState.payeeIdTypeDropdown);

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
      const error = [];
      setErrorMessages(error);
    } else if (payeeTypeIdDropDown && payeeTypeIdDropDown.systemFailure) {
      const error = [];
      error.push(AppConstants.ERR_PROCESSING_REQ);
      setErrorMessages(error);
    }
  }, [payeeTypeIdDropDown]);
  const [isMultiple, setIsMultiple] = useState(false);
  const continueResults = useSelector(state => state.accountReceivableState.goToNextPagesData);
  const multipleSys = useSelector(state => state.accountReceivableState.payoutDetails);
  const [singleRecord, setSingleRecord] = React.useState({
    payeePayerName: '',
    payeeId: '',
    systemPayeeId: ''
  });
  useEffect(() => {
    if (multipleSys && multipleSys !== undefined && multipleSys.respcode !== '02' &&
    multipleSys.enterpriseSearchresultVO && multipleSys.enterpriseSearchresultVO.searchResults) {
      const onValidateResult = multipleSys.enterpriseSearchresultVO.searchResults[0];

      if (onValidateResult.multiplePayeeDetails && onValidateResult.multiplePayeeDetails.length > 1) {
        setOpenSysPayeeTable(true);
        setSystemPayeeTableData(onValidateResult.multiplePayeeDetails);
        setIsMultiple(true);
      } else {
        if (onValidateResult) { // impo
          setSingleRecord({
            ...singleRecord,
            payeePayerName: onValidateResult.payeePayerName,
            payeeId: onValidateResult.payeePayerID,
            systemPayeeId: onValidateResult.sysID
          });
          setIsMultiple(false);
        }
      }

      setErrorMessages([]);
      setPayeeIDErrorMessages([]);
    } else if (multipleSys && multipleSys.respcode && multipleSys.respcode === '02') {
      errorMessagesArray.push(multipleSys.errorMessages);
      setErrorMessages(errorMessagesArray);
      setPayeeIDErrorMessages(multipleSys.errorMessages);
      setSingleRecord({
        ...singleRecord,
        payeePayerName: '',
        payeeId: '',
        systemPayeeId: ''
      });
    } else if (multipleSys && multipleSys.systemFailure) {
      errorMessagesArray.push('There was an error processing the request. Please retry the transaction.');
      setErrorMessages(errorMessagesArray);
    }
  }, [multipleSys]);
  useEffect(() => {
    if (continueResults) {
      if (currentSysID || singleRecord) {
        setCurrentSysID(null);
        setOpenSysPayeeTable(false);
        if (continueResults) {
          if (continueResults.payeeDetails) {
            continueResults.payeeDetails.systemPayeeId = currentSysID && currentSysID.sysID && isMultiple
              ? currentSysID.sysID : singleRecord.systemPayeeId;
            continueResults.payeeDetails.payeePayerName = currentSysID && currentSysID.payeeName && isMultiple
              ? currentSysID.payeeName : singleRecord.payeePayerName;
          }
          if (activityReasonCodeValues.activityCode === 'GL') {
            {
              props.history.push({
                pathname: '/FinancialAccountGrossLevel',
                state: {
                  continueResults,
                  values: {
                    ...values,
                    ...payeeValues,
                    ...activityReasonCodeValues,
                    reasonCodeData,
                    cPaysysID: currentSysID && currentSysID.sysID,
                    cPayeeName: currentSysID && currentSysID.payeeName,
                    sPayName: singleRecord && singleRecord.payeePayerName,
                    sPaySysId: singleRecord && singleRecord.systemPayeeId
                  }
                }
              });
            }
          }
          if (activityReasonCodeValues.activityCode === 'WL') {
            {
              props.history.push({
                pathname: '/FinancialAccountLienLevy',
                state: {
                  continueResults,
                  values: {
                    ...values,
                    ...payeeValues,
                    ...activityReasonCodeValues,
                    reasonCodeData,
                    cPaysysID: currentSysID && currentSysID.sysID,
                    cPayeeName: currentSysID && currentSysID.payeeName,
                    sPayName: singleRecord && singleRecord.payeePayerName,
                    sPaySysId: singleRecord && singleRecord.systemPayeeId
                  }
                }
              });
            }
          }
        }
      }
    }
  }, [continueResults]);

  const continueAddGrossLevel = () => {
    setIsContinue(true);
	  localStorage.setItem('isARCreated', true);
    localStorage.removeItem("globalSpinnerInd");
    var showPayeeTypeError; var showPayeeIdTypeCodeError; var showPayeeIdError;
    var showActivityTypeError; var showReasonCodeError = false;
    var showPayeeTypeErrorText; var showPayeeIdTypeCodeErrorText;
    var showActivityTypeErrorText; var showPayeeIdErrorText; var showReasonCodeErrorText = '';
    if (payeeValues.payeeType === '' || payeeValues.payeeType === DEFAULT_DD_VALUE) {
      showPayeeTypeError = true;
      showPayeeTypeErrorText = errorConstants.PAYEE_TYPE_REQUIRED;
      errorMessagesArray.push(showPayeeTypeErrorText);
    }

    if (payeeValues.payeeIdTypeCode === '' || payeeValues.payeeIdTypeCode === DEFAULT_DD_VALUE) {
      showPayeeIdTypeCodeError = true;
      showPayeeIdTypeCodeErrorText = errorConstants.PAYEE_TYPE_ID_CODE_REQUIRED;
      errorMessagesArray.push(showPayeeIdTypeCodeErrorText);
    }

    if (values.payeeId === '') {
      showPayeeIdError = true;
      showPayeeIdErrorText = errorConstants.PAYEE_ID_REQUIRED;
      errorMessagesArray.push(showPayeeIdErrorText);
    }

    if (activityReasonCodeValues.activityCode === '' || activityReasonCodeValues.activityCode === DEFAULT_DD_VALUE) {
      showActivityTypeError = true;
      showActivityTypeErrorText = errorConstants.ACTIVITY_TYPE_REQUIRED;
      errorMessagesArray.push(showActivityTypeErrorText);
    }

    if (activityReasonCodeValues.reasonCode === '' || activityReasonCodeValues.reasonCode === DEFAULT_DD_VALUE) {
      showReasonCodeError = true;
      showReasonCodeErrorText = errorConstants.REASON_CODE_REQUIRED;
      errorMessagesArray.push(showReasonCodeErrorText);
    }

    setErrorMessages(errorMessagesArray);

    setShowError({
      showActivityTypeError: showActivityTypeError,
      showPayeeTypeError: showPayeeTypeError,
      showPayeeIdTypeCodeError: showPayeeIdTypeCodeError,
      showPayeeIdError: showPayeeIdError,
      showReasonCodeError: showReasonCodeError
    });

    setShowErrorText({
      showActivityTypeErrorText: showActivityTypeErrorText,
      showPayeeIdErrorText: showPayeeIdErrorText,
      showPayeeTypeErrorText: showPayeeTypeErrorText,
      showPayeeIdTypeCodeErrorText: showPayeeIdTypeCodeErrorText,
      showReasonCodeErrorText: showReasonCodeErrorText
    });

    const nextPageValue = {
      lob: AppConstants.DEFAULT_LOB_VALUE,
      payeePayerTypeCode: payeeValues.payeeType,
      payeePayerIDTypeCode: payeeValues.payeeIdTypeCode,
      payeePayerID: values.payeeId,
      activityTypeCode: activityReasonCodeValues.activityCode,
      accountReceivableReasonCode: activityReasonCodeValues.reasonCode
    };
    if (errorMessagesArray.length === 0 && payeeIDErrorMessages.length === 0 && nextPageValue) {
      setAllowNavigation(false);

      onGotoNextPage(removeLeadingTrailingSpaces(nextPageValue));
    }
  };
  const validatePD = ()=>{
    var showPayeeTypeError; var showPayeeIdTypeCodeError; var showPayeeIdError;
    var showPayeeTypeErrorText; var showPayeeIdTypeCodeErrorText;
    var showPayeeIdErrorText;
    if (payeeValues.payeeType === '' || payeeValues.payeeType === DEFAULT_DD_VALUE) {
      showPayeeTypeError = true;
      showPayeeTypeErrorText = errorConstants.PAYEE_TYPE_REQUIRED;
      errorMessagesArray.push(showPayeeTypeErrorText);
    }

    if (payeeValues.payeeIdTypeCode === '' || payeeValues.payeeIdTypeCode === DEFAULT_DD_VALUE) {
      showPayeeIdTypeCodeError = true;
      showPayeeIdTypeCodeErrorText = errorConstants.PAYEE_TYPE_ID_CODE_REQUIRED;
      errorMessagesArray.push(showPayeeIdTypeCodeErrorText);
    }
    setErrorMessages(errorMessagesArray);

    setShowError({
      showPayeeTypeError: showPayeeTypeError,
      showPayeeIdTypeCodeError: showPayeeIdTypeCodeError,
      showPayeeIdError: showPayeeIdError
    });

    setShowErrorText({
      showPayeeIdErrorText: showPayeeIdErrorText,
      showPayeeTypeErrorText: showPayeeTypeErrorText,
      showPayeeIdTypeCodeErrorText: showPayeeIdTypeCodeErrorText
    });
    const payLoadvalues = {
      entityType: payeeValues.payeeType,
      entityIDTypeCode: payeeValues.payeeIdTypeCode,
      entityID: values.payeeId,
      lineOfBusiness: AppConstants.DEFAULT_LOB_VALUE
    };
    if (errorMessagesArray.length === 0) {
      dispatch(validatePayeeDetails(payLoadvalues));
    }
  };
  const handleChangeActivityType = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setActivityReasonCodeValues({ ...values, [name]: event.target.value });
    if (name === 'activityCode') {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        getReasonCodeMapData(event.target.value);
        setActivityReasonCodeValues({
          activityCode: event.target.value,
          reasonCode: DEFAULT_DD_VALUE
        });
      } else {
        setActivityReasonCodeValues({
          activityCode: DEFAULT_DD_VALUE,
          reasonCode: DEFAULT_DD_VALUE
        });
        setReasonCodeData(initialReasonCodeArray);
      }
    } else if (name === 'reasonCode') {
      setActivityReasonCodeValues({
        activityCode: activityReasonCodeValues.activityCode,
        reasonCode: event.target.value
      });
    } else {
      setActivityReasonCodeValues({
        activityCode: activityReasonCodeValues.activityCode,
        reasonCode: DEFAULT_DD_VALUE
      });
    }
  };
  // Payee Popup Implementation Starts
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);

  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [canCel, setCancel] = useState(false);
  const handleCloseSysPayeeTable = (e) => {
    setOpenSysPayeeTable(false);
    setCancel(true);
    setValues({
      ...values,
      payeeId: ''
    });
    setCurrentSysID(null);
  };
  const inputRefCursor = useRef(null);
  useEffect(() => {
    if (inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  }, [canCel]);
  const onRadioButtonClick = (row) => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    setOpenSysPayeeTable(false);    
  };
  // Payee popup Implementation ends
  const valuesFromARAdd = props?.history?.location?.state?.valuesFromARAdd;
  useEffect(() => {
    if (props.location.addRender && !isContinue) {
      if (valuesFromARAdd !== undefined) {
        setValues({ ...valuesFromARAdd });
        setCurrentSysID({
          ...currentSysID,
          sysID: valuesFromARAdd.cPaysysID,
          payeeName: valuesFromARAdd.cPayeeName
        });
        setSingleRecord({
          ...singleRecord,
          systemPayeeId: valuesFromARAdd.sPaySysId,
          payeePayerName: valuesFromARAdd.sPayName
        });
        getReasonCodeMapData(valuesFromARAdd.activityCode);
        onPayeeTypeIdDropDown(valuesFromARAdd.payeeType);
        setPayeeValues({
          payeeType: valuesFromARAdd.payeeType,
          payeeIdTypeCode: valuesFromARAdd.payeeIdTypeCode
        });
        setReasonCodeData(valuesFromARAdd.reasonCodeData);
        setActivityReasonCodeValues({
          activityCode: valuesFromARAdd.activityCode,
          reasonCode: valuesFromARAdd.reasonCode
        });
      };
    }
  }, [props.location.addRender]);

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      dispatch(resetL2KeyDataFromStore('accountReceivableState', 'payloadClaims'));
      props.history.push({
        pathname: searchValues?.fromPath || '/FinancialAccountInquiry',
        tableRender: false
      });
    }, 100);
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck || props.location?.focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      dispatch(resetL2KeyDataFromStore('accountReceivableState', 'payloadClaims'));
      props.history.push({
        pathname: searchValues?.fromPath || '/FinancialAccountInquiry',
        tableRender: false,
fromPath: props.location.pathname
      });
    }
  };
  return (
    <div>
      <Prompt
        when={allowNavigation}
        message={location =>
            `Are you sure you want to go to ${location.pathname}`
        }
      />

      <div className="tabs-container">
        <ErrorMessages errorMessages={errorMessages.length > 0 ? errorMessages : payeeIDErrorMessages} />
        <div className="tab-header">
          <h1 className="float-left tab-heading">
            Accounts Receivable
          </h1>

          <div className="float-right mt-2">
            {window.location.pathname === '/GrossLevelAddPayeeComponent' && (
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
            )}

          </div>
          <div className="clearfix">
          </div>
        </div>
        <form autoComplete="off">
          <div className="tab-body">
            <div className="form-wrapper">
              <NativeDropDown
                isRequired
                id="standard-payee-type"
                label="Payee Type"
                value={payeeValues.payeeType}
                onChange={handleChangePayeeType('payeeType')}
                inputProps={{ maxlength: 3 }}
                errTxt={showPayeeTypeError ? showPayeeTypeErrorText : null}
                dropdownList={payeeTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                isRequired
                id="standard-payeeid-type-code"
                label="Payee ID Type Code"
                value={payeeValues.payeeIdTypeCode}
                disabled={payeeValues.payeeType === DEFAULT_DD_VALUE}
                onChange={handleChangePayeeType('payeeIdTypeCode')}
                inputProps={{ maxlength: 3 }}
                errTxt={showPayeeIdTypeCodeError ? showPayeeIdTypeCodeErrorText : null}
                dropdownList={payeeTypeIdCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="standard-payee-id"
                  label="Payee ID"
                  inputRef={inputRefCursor
                  }
                  value={values.payeeId}
                  onChange={handleChange('payeeId')}
                  inputProps={{ maxlength: 15 }}
                  onBlur={() => validatePD()}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={showPayeeIdError ? showPayeeIdErrorText : null}
                  error={showPayeeIdError ? showPayeeIdErrorText : null}
                >
                </TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <NativeDropDown
                isRequired
                id="standard-activity-type"
                label="Activity Type"
                value={activityReasonCodeValues.activityCode}
                onChange={handleChangeActivityType('activityCode')}
                inputProps={{ maxlength: 3 }}
                errTxt={showActivityTypeError ? showActivityTypeErrorText : null}
                dropdownList={activityTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                isRequired
                id="standard-reason-code"
                label="Reason Code"
                value={activityReasonCodeValues.reasonCode}
                disabled={activityReasonCodeValues.activityCode === DEFAULT_DD_VALUE}
                onChange={handleChangeActivityType('reasonCode')}
                inputProps={{ maxlength: 3 }}
                errTxt={showReasonCodeError ? showReasonCodeErrorText : null}
                title={getLongDescription(reasonCodeData, activityReasonCodeValues.reasonCode)}
                dropdownList={reasonCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
                  />
                  </div>
          </div>
        </form>

        <div className="tab-body" style={{ overflow: 'auto' }}>
          <div className="float-right mb-2 mr-2">
            <Button variant="outlined" color="primary" className='btn btn-success'
              onClick={() => continueAddGrossLevel()}
            >
              <i className="fa fa-arrow-right ml-1"></i> Continue
            </Button>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-alert-box"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
color="primary"
            className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
          </Button>
          <Button
            onClick={cancelMinorSection}
            color="primary"
            className="btn btn-success"
            autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
          </Button>
        </DialogActions>
      </Dialog>
      {openSysPayeeTable &&
        <SystemPayeeTable
          payeeType={payeeTypeDesc}
          payeeIdTypeCode={payeeTypeIdCodeDesc}
          payeeId={values.payeeId}
          openSysPayeeTable={openSysPayeeTable}
          handleCloseSysPayeeTable={handleCloseSysPayeeTable}
          uniqueKey={'sysID'}
          onTableCellButtonClick={onRadioButtonClick}
          tableData={systemPayeeTableData}
          currentSysID={currentSysID}
          onContinue={onContinue}
        /> }
    </div>
  );
}
export default withRouter((Original));
