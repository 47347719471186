/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as ErrorConstants from "../ErrorCheck";
import GrossLevelDetailsComponent from "./GrossLevelDetailsComponent";
import GrossLevelExemptionDetailComponent from "./GrossLevelExemptionDetailComponent";
import GrossLevelHistoryTableComponent from "./GrossLevelHistory";
import ErrorMessages from "../../../../../../SharedModules/Errors/ErrorMessages";
import PaymentScheduleTable from "./paymentScheduleTable";
import * as Dropdowns from "../../../../../../SharedModules/Dropdowns/dropdowns";
import { systemListActions } from '../../../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions';
import moment from "moment";
import {
  getUTCTimeStamp,
  generateUUID,
  compareTwoDates,
  validateDateMinimumValue,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormatWithApendZero,
  getDateInMMDDYYYYFormat
} from "../../../../../../SharedModules/DateUtilities/DateUtilities";
import {
  getDropdownDataAction,
  getPayeeIdType,
  addGrossLevelAdd,
  searchFCNAccountReceivableAction,
  getDefaultRecoupmentDetails
} from "../../../../Store/Actions/accountReceivable/accountReceivableActions";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessages from "../../../../../../SharedModules/Errors/SuccessMessage";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router";
import numeral from "numeral";
import TabPanel from "../../../../../../SharedModules/TabPanel/TabPanel";
import * as AppConstants from "../../../../../../SharedModules/AppConstants";
import dropdownCriteria from "./GrossLevelAdd.json";
import {
  getMaxFrequencyParameter,
  resetL2KeyDataFromStore
} from "../../../../../../SharedModules/store/Actions/SharedAction";
import Footer from "../../../../../../SharedModules/Layout/footer";
import Notes from "../../../../../../SharedModules/Notes/Notes";
import Attachments from "../../../../../../SharedModules/Attachments/Attachments";
import * as DateUtils from "../../../../../../SharedModules/DateUtilities/DateUtilities";
import Radio from "@material-ui/core/Radio";
import { Link, Prompt } from "react-router-dom";
import { redirectSystemID } from "../../../../../../SharedModules/TabRedirections/TabRedirections";
import { searchSystemParameterAction } from "../../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions";
import {
  checkingDecimal,
  determineAmountToUse,
  getLongDescription,
  parseAmount,
  removeLeadingTrailingSpaces
} from "../../../../../../SharedModules/CommonUtilities/commonUtilities";
import NativeDropDown from "../../../../../../SharedModules/Dropdowns/NativeDropDown";

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function FinancialAccountGrossLevel (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [attchmentResponse,setAttachmentResponse] = React.useState([])
  
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    ).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setShowErrorText("");
    setShowError([]);
  };

  const [color, setColor] = React.useState("");
  let errorMessagesArray = [];
  const [rowPaymentScheduleData, setRowPaymentScheduleData] = React.useState(
    []
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [frequencyData, setFrequencyData] = React.useState([]);
  const [paymentScheduleTypeData, setPaymentScheduleTypeData] = React.useState(
    []
  );
  const [frequencyRecoupData, setFrequencyRecoupData] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [excemptData, setExcemptData] = React.useState([]);
  const [approvalData, setApprovalData] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [propsValues, setPropsValues] = React.useState({});
  const [allowNavigation, setAllowNavigation] = React.useState(true);

  const onReasonCd = (values) => dispatch(systemListActions(values));
  const reasonCdData = useSelector(state => state.appConfigState.systemListState?.payload);
  const [reasonCd, setReasonCd] = React.useState([]);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  const onGrossLevelAdd = value =>
    dispatch(
      addGrossLevelAdd(
        value,
        attachmentsTableData.length > 0 ? attachmentFile : null
      )
    );
  useEffect(() => {
    onReasonCd(Dropdowns.AR_BULK_RN9023_CD);
  }, []);

  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getMaxFrequencyParameter(systemParameter));
  const frequencyResult = useSelector(
    state => state.sharedState?.frequencyParameter
  );
  const getAddGrossLevelResult = value =>
    dispatch(searchFCNAccountReceivableAction(value));
  const userInquiryPrivileges = !global.globalIsReadOnly();

  // Attachment Section
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);

  // Attachment Section
  let dropdown = [];

  useEffect(() => {
    getDropdownData(dropdownCriteria);

    getPayeeIdFromSystemParameter({
      paramNumber: 9025,
      functionalArea: "F1",
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      asOfDate: moment(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      ).format("YYYY-DD-MM")
    });
  }, []);

  useEffect(() => {
    if (reasonCdData !== undefined && reasonCdData !== null) {
      const date = moment(new Date()).format("MM/DD/YYYY");
      const nonVoidedRsnCdsList = reasonCdData[0]?.systemListDetails?.filter(
        (code) => code.voidDate === null
			  );
			  const nonVoidedEndDtRsnCdsList = nonVoidedRsnCdsList.filter(
        (code) => compareTwoDatesGreaterThanOrEqual(date, code.endDate)
			  );
			  const nonVoidedRsnCds = nonVoidedEndDtRsnCdsList?.map(rsnCd => rsnCd.startingValue);
			  setReasonCd(nonVoidedRsnCds);
		  }
  }, [reasonCdData]);

  useEffect(() => {
    if (props.location?.state?.nextPageValue) {
      setPropsValues(props.location.state?.nextPageValue);
    }
  }, [props.location?.state?.nextPageValue]);
  const [errorMessageCopy, setErrorMessageCopy] = React.useState([]);

  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  dropdown = useSelector(state => state.accountReceivableState?.dropdownData);

  const addGrossLevelAddResponse = useSelector(
    state => state.accountReceivableState?.addData
  );
  // Search the newly added Gross Level details after successful save
  const searchGrossLevelAddResult = useSelector(
    state => state.accountReceivableState?.payload
  );

  const onGetRecoupmetDetails = values => {
    dispatch(getDefaultRecoupmentDetails(values));
  };
  const getRecoupmetDetails = useSelector(
    state => state.accountReceivableState?.fetchRecoupmetDetails
  );

  const sucessMessages = [];
  const systemErrorMessges = [];

  useEffect(() => {
    if (addGrossLevelAddResponse && !addGrossLevelAddResponse.systemFailure) {
      if (
        addGrossLevelAddResponse &&
        addGrossLevelAddResponse.respcode === "03"
      ) {
        const error = [];
        setSystemSuccesMessages([]);
        error.push(AppConstants.ERR_PROCESSING_REQ);
        seterrorMessages(error);
      }
      if (
        addGrossLevelAddResponse &&
        addGrossLevelAddResponse.respcode === "01" &&
        addGrossLevelAddResponse.respdesc !== ""
      ) {
        const success = AppConstants.SAVE_SUCCESS;
        setAllowNavigation(false);
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        sucessMessages.push(success);
        setSystemSuccesMessages(sucessMessages);
        setFocusCheck(false);
        getAddGrossLevelResult(values.fcn);
      }
      if (
        addGrossLevelAddResponse &&
        addGrossLevelAddResponse.respcode === "02" &&
        addGrossLevelAddResponse.respdesc !== ""
      ) {
        const error = addGrossLevelAddResponse.errorMessages;
        setSystemSuccesMessages([]);
        if (error && error.length > 0) {
          addGrossLevelAddResponse.errorMessages.map((option) => {
            systemErrorMessges.push(option);
          });
        }
        seterrorMessages(systemErrorMessges);
      }
    }
    if (addGrossLevelAddResponse && addGrossLevelAddResponse.systemFailure) {
      const error = [];
      setSystemSuccesMessages([]);
      error.push(AppConstants.ERR_PROCESSING_REQ);
      seterrorMessages(error);
    }
  }, [addGrossLevelAddResponse]);

  // Search the newly added Gross Level details after successful save
  useEffect(() => {
    if (
      searchGrossLevelAddResult &&
      searchGrossLevelAddResult.respcode === "02" &&
      searchGrossLevelAddResult.respdesc !== ""
    ) {
      errorMessagesArray = [];
      errorMessagesArray.push(searchGrossLevelAddResult.respdesc);
      props.showError(errorMessagesArray);
    }
    if (
      searchGrossLevelAddResult != null &&
      searchGrossLevelAddResult !== "" &&
      searchGrossLevelAddResult.respcode === undefined &&
      searchGrossLevelAddResult.systemFailure === undefined
    ) {
      const claimsReceivableData = searchGrossLevelAddResult;
      props.history.push({
        pathname: "/FinancialAccountGrossLevelUpdate",
        state: { claimsReceivableData, systemSuccessMessages, values: valuesFromARAdd },
        isFromAdd: true
      });
    }
    if (
      searchGrossLevelAddResult &&
      searchGrossLevelAddResult.systemFailure !== undefined
    ) {
      errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      searchGrossLevelAddResult &&
      searchGrossLevelAddResult.attachmentsList &&
      searchGrossLevelAddResult.attachmentsList.length > 0
    ) {
      const searchGrossLevelAttachmentList =
        searchGrossLevelAddResult.attachmentsList;
      setAttachmentsTableData(searchGrossLevelAttachmentList);
    }
    setAttachmentFile([]);
  }, [searchGrossLevelAddResult]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["General#6002"]) {
        setPayeeTypeData(dropdown.listObj["General#6002"]);
      }
      if (dropdown.listObj["Financial#1131"]) {
        setActivityTypeData(dropdown.listObj["Financial#1131"]);
      }
      if (dropdown.listObj["Financial#6012"]) {
        setExcemptData(dropdown.listObj["Financial#6012"]);
      }
      if (dropdown.listObj["Financial#6014"]) {
        setApprovalData(dropdown.listObj["Financial#6014"]);
      }
      if (dropdown.listObj["Financial#5"]) {
        setReasonCodeData(dropdown.listObj["Financial#5"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
      if (dropdown.listObj["Financial#1009"]) {
        setFrequencyData(dropdown.listObj["Financial#1009"]);
      }
      if (dropdown.listObj["Financial#6017"]) {
        setPaymentScheduleTypeData(dropdown.listObj["Financial#6017"]);
      }
      if (dropdown.listObj["Financial#6016"]) {
        setFrequencyRecoupData(dropdown.listObj["Financial#6016"]);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (excemptData && excemptData.length > 0) {
      if (excemptData.some(value => value.code === values.activityCode)) {
        setValues({
          ...values,
          exemptFromCollectionAgency: "Y",
          exemptFromInterest: "Y",
          exemptFromPenalty: "Y"
        });
      } else {
        setValues({
          ...values,
          exemptFromCollectionAgency: "N",
          exemptFromInterest: "N",
          exemptFromPenalty: "N"
        });
      }
    }
  }, [excemptData]);

  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      if (approvalData.some(value => value.code === values.activityCode)) {
        setValues({ ...values, approval: "Y" });
      } else {
        setValues({ ...values, approval: "N" });
      }
    }
  }, [approvalData]);
  const [paymentScheduleVO, setPaymentScheduleVO] = React.useState({
    installmentAmount: "",
    recoupmentAmount: "0.00"
  });
  const handleChangePaymentSchedule = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === 'installmentAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
      } else {
        setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
      }
    } else {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        [name]: event.target.value
      });
    }
  };
  const [retainPaymentSchedule, setRetainPaymentSchedule] = React.useState({});
  const [values, setValues] = React.useState({
    originalARAmount: "",
    approval: "N",
    underAppeal: "N",
    fiscalYear: "",
    categoryOfService: DEFAULT_DD_VALUE,
    collocationCode: DEFAULT_DD_VALUE,
    recoupmentTypeCode: DEFAULT_DD_VALUE,
    installmentValue: "",
    percentValue: "",
    frequency: "1",
    exemptFromPenalty: "N",
    exemptFromInterest: "N",
    exemptFromCollectionAgency: "N",
    amountDue: "",
    defaultOnPaymentSchedule: "N",
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE,
    activityCode: DEFAULT_DD_VALUE,
    systemPayeeId: "",
    payeeId: "",
    payeePayerName: "",
    fcn: "",
    adjustedARAmount: "",
    balanceAmount: "",
    relatedFTTCN: "",
    establishedDate: "",
    lastDateApplied: "",
    arAgeInDays: "",
    arLettersent: "",
    categoryOfServiceData: [],
    collocationCodeData: [],
    calculatedInterest: "",
    calculatedPenalty: "",
    feesAssesed: "",
    frequencyData: [],
    recoupmentData: [],
    payeeStatus: DEFAULT_DD_VALUE,
    originalARAmountData: 0,
    appliedAmountData: 0,
    balanceAmountData: 0,
    paymentScheduleType: DEFAULT_DD_VALUE,
    recoupmentStartDate: null,
    numberofInstallments: "",
    interestCalculation: "N",
    interestPercentage: "",
    paymentScheduleFrequency: DEFAULT_DD_VALUE,
    recoupedAmount: "0.00",
    installmentAmount: "",
    atrNumber: ''
  });

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(
    state => state.accountReceivableState?.payeeIdTypeDropdown
  );

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
    }
  }, [payeeTypeIdDropDown]);

  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [selectedRecoupmentDate, setSelectedRecoupmentDate] = React.useState(
    null
  );
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [retainSelectedDueDate, setRetainSelectedDueDate] = React.useState(
    null
  );
  const [selectedDefaultDate, setSelectedDefaultDate] = React.useState(null);
  const [paymentScheduleData, setPaymentSchedleData] = React.useState([]);
  const [grossLevel, setGrossLevel] = React.useState({});
  const [
    selectedRecoupmentStartDate,
    setSelectedRecoupmentStartDate
  ] = React.useState(null);
  const handleRecoupmentStartDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentStartDate(date);
    setPaymentSchedleData([]);
  };
  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setSelectedBeginDate(date);
  };
  const handleEndDateChange = date => {
    setFocusCheck(true);
    setSelectedEndDate(date);
  };

  const handleRecoupmentDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentDate(date);
  };

  const handleDueDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDueDate(date);
  };

  function setInputValues (grossLevelDetails, values, recoupmentsection) {
    return {
      lob: AppConstants.DEFAULT_LOB_VALUE,
      payeeType:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.payeePayerType
          : DEFAULT_DD_VALUE,
      payeeIdTypeCode:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.payeePayerIdTypeCode
          : DEFAULT_DD_VALUE,
      reasonCode:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.reasonCode
          : DEFAULT_DD_VALUE,
      activityCode:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.activityType
          : DEFAULT_DD_VALUE,
      systemPayeeId:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.systemPayeeId
          : "",
      payeeId:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.payeePayerId
          : "",
      payeePayerName:
        grossLevelDetails && grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.payeePayerName
          : "",
      fcn:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.fcnNumber
          : "",
      payeeStatus:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.payeeStatus
          : "",
      adjustedARAmount:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.adjustedARAmount
          : "",
      balanceAmount:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.balanceAmount
          : "",
      approval: recoupmentsection.approval,
      relatedFTTCN:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.relatedFTTCN
          : "",
      establishedDate:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.establishedDate
          : "",
      lastDateApplied:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.lastDateApplied
          : null,
      arAgeInDays:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.arAgeInDays
          : null,
      underAppeal: recoupmentsection.underAppeal,
      arLettersent:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.arLetterSent
          : "",
      categoryOfServiceData:
        grossLevelDetails && grossLevelDetails.accountDetails
          ? grossLevelDetails.accountDetails.categoryOfService
          : [],
      categoryOfService: values.categoryOfService,
      exemptFromCollectionAgency: recoupmentsection.exemptFromCollectionAgency,
      exemptFromInterest: recoupmentsection.exemptFromInterest,
      exemptFromPenalty: recoupmentsection.exemptFromPenalty,
      collocationCodeData:
        grossLevelDetails && grossLevelDetails.accountDetails
          ? grossLevelDetails.accountDetails.fundCode
          : [],
      collocationCode: values.collocationCode,
      recoupmentData:
        grossLevelDetails && grossLevelDetails.recoupmentDetails
          ? grossLevelDetails.recoupmentDetails.recoupmentTypeCode
          : [],
      frequencyData:
        grossLevelDetails && grossLevelDetails.recoupmentDetails
          ? grossLevelDetails.recoupmentDetails.frequency
          : [],
      recoupmentTypeCode: recoupmentsection.recoupment,
      frequency: recoupmentsection.frequency,
      amountDue: recoupmentsection.amount,
      percentValue: recoupmentsection.percent
        ? numeral(recoupmentsection.percent).format("0.00")
        : recoupmentsection.percent,
      installmentValue: recoupmentsection.installment
        ? numeral(recoupmentsection.installment).format("0,0.00")
        : recoupmentsection.installment,
      defaultOnPaymentSchedule: recoupmentsection.defaultOnPaymentSchedule,
      originalARAmountData:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.originalARAmount
          : 0,
      balanceAmountData:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.balanceAmount
          : 0,
      appliedAmountData:
        grossLevelDetails && grossLevelDetails.grossLevelDetails
          ? grossLevelDetails.grossLevelDetails.appliedAmountData
          : 0,
      fiscalYear:
        grossLevelDetails && grossLevelDetails.accountDetails
          ? grossLevelDetails.accountDetails.scoaFiscalYear
          : values.fiscalYear,
      originalARAmount: values.originalARAmount,
      paymentScheduleType: values.paymentScheduleType,
      paymentScheduleFrequency: values.paymentScheduleFrequency,
      recoupmentStartDate: values.recoupmentStartDate,
      numberofInstallments: values.numberofInstallments,
      interestCalculation: values.interestCalculation,
      interestPercentage: values.interestPercentage,
      recoupedAmount: values.recoupedAmount,
      installmentAmount: values.installmentValue
    };
  }

  const grossLevelDetails = props.location?.state?.continueResults;
  const setInput = () => {
    const percentValue = "100";
    const installmentValue = values.installmentValue;
    const recoupmenttypeCode = "B";
    const frequency = values.frequency;
    const amount = values.amountDue;
    const defaultOnPaymentSchedule = values.defaultOnPaymentSchedule;
    const underAppeal =
      grossLevelDetails && grossLevelDetails.grossLevelDetails
        ? grossLevelDetails.grossLevelDetails.underAppeal === false
          ? "N"
          : "Y"
        : "N";
    const approval =
      grossLevelDetails && grossLevelDetails.grossLevelDetails
        ? grossLevelDetails.grossLevelDetails.approval === false
          ? "N"
          : "Y"
        : "N";
    const exemptFromCollectionAgency =
      grossLevelDetails && grossLevelDetails.exemptionDetails
        ? grossLevelDetails.exemptionDetails.excemptionFromCollectionAgency ===
          true
          ? "Y"
          : "N"
        : "N";
    const exemptFromInterest =
      grossLevelDetails && grossLevelDetails.exemptionDetails
        ? grossLevelDetails.exemptionDetails.excemptionFromInterest === true
          ? "Y"
          : "N"
        : "N";
    const exemptFromPenalty =
      grossLevelDetails && grossLevelDetails.exemptionDetails
        ? grossLevelDetails.exemptionDetails.excemptionFromPenalty === true
          ? "Y"
          : "N"
        : "N";

    const recoupmentsection = {
      percent: percentValue,
      installment: installmentValue
        ? numeral(installmentValue).format("0,0.00")
        : installmentValue,
      recoupment: recoupmenttypeCode,
      frequency: frequency,
      amount: amount,
      defaultOnPaymentSchedule: defaultOnPaymentSchedule,
      underAppeal: underAppeal,
      approval: approval,
      exemptFromCollectionAgency: exemptFromCollectionAgency,
      exemptFromInterest: exemptFromInterest,
      exemptFromPenalty: exemptFromPenalty
    };
    grossLevelDetails.grossLevelDetails.establishedDate = DateUtils.getDateInMMDDYYYYFormat(
      new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      )
    );
    setValues(setInputValues(grossLevelDetails, values, recoupmentsection));
    setSelectedRecoupmentDate(
      DateUtils.getDateInMMDDYYYYFormat(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    );
  };

  useEffect(() => {
    if (values.underAppeal === "Y") {
      const percentValue = "0";
      const installmentValue = "0";
      const recoupmenttypeCode = "N";
      const frequency = DEFAULT_DD_VALUE;
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format("0,0.00")
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));
      setSelectedRecoupmentDate(null);
    }
  }, [values.underAppeal]);

  const systemParamValuetoPercentage = {
    parameterNumber: "9005",
    paramNumber: null,
    functionalArea: "F1",
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };

  const searchSystemParameterPercentage = value => {
    dispatch(searchSystemParameterAction(value));
  };

  const systemParameterPayloadPercentage = useSelector(
    state => state.appConfigState.systemParameterState?.payload
  );

  useEffect(() => {
    if (
      systemParameterPayloadPercentage &&
      systemParameterPayloadPercentage.length > 0
    ) {
      const systemParameterPercentage =
        systemParameterPayloadPercentage[0].systemParameterDetail[0].valuePCT *
        100;
      const percentValue = systemParameterPercentage;
      const installmentValue = "";
      const recoupmenttypeCode = "D";
      const frequency = "1";
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format("0,0.00")
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));
      setSelectedRecoupmentDate(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    }
  }, [systemParameterPayloadPercentage]);

  useEffect(() => {
    if (values.recoupmentTypeCode === "N") {
      setSelectedRecoupmentStartDate(null);
      setPaymentSchedleData([]);
      const percentValue = "0";
      const installmentValue = "0";
      const recoupmenttypeCode = "N";
      const frequency = DEFAULT_DD_VALUE;
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format("0,0.00")
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(
        setInputValues(
          grossLevel,
          {
            ...values,
            paymentScheduleType: DEFAULT_DD_VALUE,
            recoupmentStartDate: null,
            numberofInstallments: "",
            interestCalculation: "N",
            interestPercentage: "",
            paymentScheduleFrequency: DEFAULT_DD_VALUE
          },
          recoupmentsection
        )
      );
      setSelectedRecoupmentDate(null);
    }
    if (values.recoupmentTypeCode === "S") {
      const percentValue = "";
      const installmentValue = "";
      const recoupmenttypeCode = "S";
      const frequency = DEFAULT_DD_VALUE;
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format("0,0.00")
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(grossLevel, values, recoupmentsection));
      setSelectedRecoupmentDate(null);
    }
    if (values.recoupmentTypeCode === "A") {
      setSelectedRecoupmentStartDate(null);
      setPaymentSchedleData([]);
      const percentValue = "";
      const installmentValue =
        values.installmentValue === ""
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.installmentAmount
            ? grossLevel.recoupmentVO.installmentAmount
            : "0"
          : values.installmentValue;
      const recoupmenttypeCode = "A";
      const frequency = values.frequency === "Please Select One"
        ? grossLevel &&
        grossLevel.recoupmentVO &&
        grossLevel.recoupmentVO.frequency
          ? grossLevel.recoupmentVO.frequency
          : "1"
        : values.frequency;
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format("0,0.00")
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(
        setInputValues(
          grossLevel,
          {
            ...values,
            paymentScheduleType: DEFAULT_DD_VALUE,
            recoupmentStartDate: null,
            numberofInstallments: "",
            interestCalculation: "N",
            interestPercentage: "",
            paymentScheduleFrequency: DEFAULT_DD_VALUE
          },
          recoupmentsection
        )
      );
      setSelectedRecoupmentDate(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    }
    if (values.recoupmentTypeCode === "B") {
      setSelectedRecoupmentStartDate(null);
      setPaymentSchedleData([]);
      const percentValue =
        values.percentValue === ""
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.percentageOriginalAmount
            ? grossLevel.recoupmentVO.percentageOriginalAmount
            : "100.00"
          : values.percentValue;
      const installmentValue = "";
      const recoupmenttypeCode = "B";
      const frequency =
        values.frequency === DEFAULT_DD_VALUE
          ? grossLevel &&
            grossLevel.recoupmentVO &&
            grossLevel.recoupmentVO.frequency
            ? grossLevel.recoupmentVO.frequency
            : "1"
          : values.frequency;
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format("0,0.00")
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(
        setInputValues(
          grossLevel,
          {
            ...values,
            paymentScheduleType: DEFAULT_DD_VALUE,
            recoupmentStartDate: null,
            numberofInstallments: "",
            interestCalculation: "N",
            interestPercentage: "",
            paymentScheduleFrequency: DEFAULT_DD_VALUE
          },
          recoupmentsection
        )
      );
      setSelectedRecoupmentDate(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      );
    }
    if (values.recoupmentTypeCode === "D") {
      searchSystemParameterPercentage(systemParamValuetoPercentage);
      setValues({
        ...values,
        paymentScheduleType: DEFAULT_DD_VALUE,
        recoupmentStartDate: null,
        numberofInstallments: "",
        interestCalculation: "N",
        interestPercentage: "",
        paymentScheduleFrequency: DEFAULT_DD_VALUE
      });
      setSelectedRecoupmentStartDate(null);
      setPaymentSchedleData([]);
    }
  }, [values.recoupmentTypeCode]);
  useEffect(() => {
    if (grossLevelDetails) {
      if (grossLevelDetails.respcode === "02") {
        const message = grossLevelDetails.respdesc;
        props.history.push({
          pathname: "/GrossLevelAddPayeeComponent",
          state: { propsValues, message, values: valuesFromARAdd },
          addRender: true
        });
      }
      setGrossLevel(grossLevelDetails);
      setInput();
      onPayeeTypeIdDropDown(
        grossLevelDetails.payeeDetails
          ? grossLevelDetails.payeeDetails.payeePayerType
          : values.payeeType
      );
    }
  }, [grossLevelDetails]);

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (open) {
      document.getElementById("form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [open]);
  const [
    paymentScheduleTypeError,
    setPaymentScheduleTypeError
  ] = React.useState("");
  const [
    recoupmentStartDateError,
    setRecoupmentStartDateError
  ] = React.useState("");
  const [
    numberofInstallmentError,
    setNumberofinstallmentError
  ] = React.useState("");
  const [frequencyError, setFrequencyError] = React.useState("");
  const [interestCalError, setInstallmentCalError] = React.useState("");
  const handleClickOpenPaymentSchedule = () => {
    setFocusCheck(true);
    const errorArray = [];
    if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (!selectedRecoupmentStartDate) {
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
        const errorDateText = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
          "{current date}",
          moment(currentDate).format("MM/DD/YYYY")
        );
        setRecoupmentStartDateError(errorDateText);
        errorArray.push(errorDateText);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (errorArray.length === 0) {
      setPopUpErrorMessage([]);
      setSelectedDueDate(null);
      setSelectedDefaultDate(null);
      setOpen(true);
      setAdd(true);
      setShowError(false);
      setShowErrorText("");
      setPaymentScheduleVO({
        installmentAmount: "",
        recoupedAmount: "0.00"
      });
      setRetainPaymentSchedule({
        installmentAmount: "",
        recoupedAmount: "0.00"
      });
      setRetainSelectedDueDate(selectedDueDate);
      setRecoupmentStartDateError("");
      setPaymentScheduleTypeError("");
      seterrorMessages([]);
    } else {
      seterrorMessages(errorArray);
    }
  };

  const handleClose = () => {
    setFocusCheck(true);
    setOpen(false);
    setPaymentScheduleVO({
      installmentAmount: "",
      recoupedAmount: "0.00"
    });
    seterrorMessages([]);
  };

  const handleChangeTabs = (event, newValue) => {
    setFocusCheck(true);
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
    if (newValue === 3) {
      setTabFour(true);
    }
    const colorCondition = checkFormValidations();
    if (newValue > 0) {
      if (colorCondition) {
        if (errorMessagesArray.length > 0) {
          setColor("red");
        } else {
          setColor("green");
        }
      }
    }
  };

  const popUpErrorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showOriginalArAmmountError,
      showFiscalYearError,
      showCollocationCodeError,
      showRecoupmentTypeCodeError,
      showDueDateError,
      showAmountDueError,
      showInvalidBeginDateError,
	    showInvalidEndDateError,
      showEndDateLesserError,
      showBeginDateRequiredError,
      showEndDateRequiredError,
      showPercentError,
      showInstallementError,
      showStartDateError,
      showStartDateRequiredError,
      showDueDateGreaterError,
      showMinBeginDateError,
      showMinEndDateError,
      showMinRecoupmentDateError,
      showMinDueDateError,
      showDueDateInvalidError,
      showNegativeAmountError,
      showGreaterThanZeroAmountError,
      showOriginalArAmountPopError,
      showFrequencyError,
      showATRError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showOriginalArAmountErrorText,
      showFiscalYearErrorText,
      showCollocationCodeErrorText,
      showRecoupmentTypeCodeErrorText,
      showDueDateErrorText,
      showAmountDueErrorText,
      showInvalidBeginDateText,
	    showInvalidEndDateInvalidText,
      showEndDateLesserErrorText,
      showBeginDateRequiredErrorText,
      showEndDateRequiredErrorText,
      showPercentErrorText,
      showInstallmentErrorText,
      showStartDateErrorText,
      showStartDateRequiredErrorText,
      showDueDateGreaterErrorText,
      showMinBeginDateErrorText,
      showMinEndDateErrorText,
      showMinRecoupmentDateErrorText,
      showMinDueDateErrorText,
      showDueDateInvalidErrorText,
      showNegativeAmmountErrorText,
      showGreaterThanZeroAmountText,
      showOriginalArAmountPopErrorText,
      showFrequencyErrorText,
      showATRErrorText
    },
    setShowErrorText
  ] = React.useState("");

  const [popupErrorMessages, setPopUpErrorMessage] = React.useState([]);

  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [tabFour, setTabFour] = React.useState(false);
  const [add, setAdd] = React.useState(true);
  const [retainEdit, setRetainEdit] = React.useState({});
  const [editData, setEditData] = React.useState({});
  const [retainValues, setRetainValues] = React.useState({});

  const savePaymentSchedule = () => {
    var selectedDueDateError;
    var amountDueError = false;
    var selectedDueDateGreaterErrorTemp = false;
    var minDueDateError = false;
    var minDefaultDateError = false;
    var invalidDueDateError = false;
    var invalidDefaultDateError = false;
    var negativeAmountError = false;
    var amountGreaterThanZero = false;
    var originalArAmountRequired = false;
    var selectedDueDateErrorText;
    var amountDueErrorText = "";
    var selectedDueDateErrorTextTemp = "";
    var minDueDateErrorText = "";
    var minDefaultDateErrorText = "";
    var invalidDueDateErrorText = "";
    var invalidDefaultDateErrorText = "";
    var negativeAmountErrorText = "";
    var amountGreaterThanZeroText = "";
    var originalArAmountRequiredText = "";

    const adjustedAmount = parseAmount(values.adjustedARAmount);
    const originalAmount = parseAmount(values.originalARAmount);
    const installmentValue = parseAmount(paymentScheduleVO.installmentAmount);
    const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);

    if (!selectedDueDate) {
      selectedDueDateError = true;
      selectedDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(selectedDueDateErrorText);
    }

    if (selectedDueDate) {
      if (!moment(selectedDueDate).isValid()) {
        invalidDueDateError = true;
        invalidDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDueDateErrorText);
      }
      if (!invalidDueDateError) {
        if (validateDateMinimumValue(selectedDueDate)) {
          minDueDateError = true;
          minDueDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(minDueDateErrorText);
        }
      }
    }

    if (selectedDefaultDate) {
      if (!moment(selectedDefaultDate).isValid()) {
        invalidDefaultDateError = true;
        invalidDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDefaultDateErrorText);
      }

      if (!invalidDefaultDateError) {
        if (selectedDefaultDate) {
          if (validateDateMinimumValue(selectedDefaultDate)) {
            minDefaultDateError = true;
            minDefaultDateErrorText = AppConstants.DATE_ERROR_1964;
            popUpErrorMessagesArray.push(minDefaultDateErrorText);
          } else if (
            compareTwoDatesGreaterThanOrEqual(
              new Date(selectedDefaultDate),
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            )
          ) {
            minDefaultDateError = true;
            minDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_LESS;
            popUpErrorMessagesArray.push(minDefaultDateErrorText);
          }
        }
      }
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(new Date(selectedDueDate), new Date())
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_ERROR;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(selectedRecoupmentStartDate)
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_RECOUP;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (paymentScheduleVO.installmentAmount) {
      if (
        values.originalARAmount === "" ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        popUpErrorMessagesArray.push(originalArAmountRequiredText);
      } else {
        if (
          paymentScheduleVO.installmentAmount
            .toString()
            .replace(/,/g, "")
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
        ) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.DATA_INVALID;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        } else {
          if (
            !(
              paymentScheduleVO.installmentAmount
                .toString()
                .replace(/,/g, "")
                .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            negativeAmountError = true;
            negativeAmountErrorText = ErrorConstants.DATA_INVALID;
            popUpErrorMessagesArray.push(negativeAmountErrorText);
          }
        }
        if (+paymentScheduleVO.installmentAmount < 0) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.NEGATIVE_AMOUNT;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        }
        if (+paymentScheduleVO.installmentAmount === 0) {
          amountGreaterThanZero = true;
          amountGreaterThanZeroText = ErrorConstants.GREATER_THAN_ZERO_AMOUNT;
          popUpErrorMessagesArray.push(amountGreaterThanZeroText);
        }
        if (installmentValue > amountToUse) {
          amountDueError = true;
          amountDueErrorText =
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION;
          popUpErrorMessagesArray.push(
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION
          );
        }
      }
    } else {
      amountDueError = true;
      amountDueErrorText = ErrorConstants.AMOUNT_DUE;
      popUpErrorMessagesArray.push(amountDueErrorText);
    }
    setPopUpErrorMessage(popUpErrorMessagesArray);
    seterrorMessages(popUpErrorMessagesArray);
    setShowError({
      showDueDateError: selectedDueDateError,
      showAmountDueError: amountDueError,
      showDueDateGreaterError: selectedDueDateGreaterErrorTemp,
      showMinDueDateError: minDueDateError,
      showMinDefaultDateError: minDefaultDateError,
      showDueDateInvalidError: invalidDueDateError,
      showDefaultDateError: invalidDefaultDateError,
      showNegativeAmountError: negativeAmountError,
      showGreaterThanZeroAmountError: amountGreaterThanZero,
      showOriginalArAmountPopError: originalArAmountRequired
    });
    setShowErrorText({
      showDueDateErrorText: selectedDueDateErrorText,
      showAmountDueErrorText: amountDueErrorText,
      showDueDateGreaterErrorText: selectedDueDateErrorTextTemp,
      showMinDueDateErrorText: minDueDateErrorText,
      showMinDefaultDateErrorText: minDefaultDateErrorText,
      showDueDateInvalidErrorText: invalidDueDateErrorText,
      showDefaultDUeDateErrorText: invalidDefaultDateErrorText,
      showNegativeAmmountErrorText: negativeAmountErrorText,
      showGreaterThanZeroAmountText: amountGreaterThanZeroText,
      showOriginalArAmountPopErrorText: originalArAmountRequiredText
    });
    if (add) {
      const scheduleData = paymentScheduleData;

      if (popUpErrorMessagesArray.length === 0) {
        var value = {
          id: generateUUID(),
          scheduleSK: null,
          dueDate: selectedDueDate
            ? moment(new Date(selectedDueDate).getTime()).format("MM/DD/YYYY")
            : null,
          installmentAmount: paymentScheduleVO.installmentAmount,
          recoupedAmount: paymentScheduleVO.recoupedAmount
        };
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (option.dueDate === value.dueDate) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            scheduleData.push(removeLeadingTrailingSpaces(value));
            setPaymentSchedleData(scheduleData);
            handleClose();
          }
        } else {
          scheduleData.push(removeLeadingTrailingSpaces(value));
          setPaymentSchedleData(scheduleData);
          handleClose();
        }
      }
    } else {
      const scheduleData = paymentScheduleData;
      if (popUpErrorMessagesArray.length === 0) {
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (
              option.dueDate ===
                moment(new Date(selectedDueDate).getTime()).format(
                  "MM/DD/YYYY"
                ) &&
              option.id !== paymentScheduleVO.id
            ) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            paymentScheduleData.map(value => {
              if (value.id === paymentScheduleVO.id) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    "MM/DD/YYYY"
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
              }
              setPaymentSchedleData(paymentScheduleData);
              setOpen(false);
              setAdd(true);
              handleClose();
            });
          }
        }
      }
    }
  };

  const resetPaymentSchedule = () => {
    setPopUpErrorMessage([]);
    seterrorMessages([]);
    setShowError([]);
    setShowErrorText([]);
    setPaymentScheduleVO(retainPaymentSchedule);
    setSelectedDueDate(retainSelectedDueDate);
  };
  const calculateSchedule = () => {
    const errorArray = [];
    var originalArAmountRequired = false;
    var originalArAmountRequiredText = "";
    let paymentScheduleTypeText = "";
    let recoupmentStartDateText = "";
    let noOfInstallmentText = "";
    let frequencyText = "";
    let calPercentText = "";
    setPaymentScheduleTypeError("");
    setRecoupmentStartDateError("");
    setFrequencyError("");
    setNumberofinstallmentError("");
    setInstallmentCalError("");
    if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
      paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (
      values.originalARAmount === "" ||
      values.originalARAmount === undefined
    ) {
      originalArAmountRequired = true;
      originalArAmountRequiredText = ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorArray.push(originalArAmountRequiredText);
    }
    if (!selectedRecoupmentStartDate) {
      recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        recoupmentStartDateText = ErrorConstants.DATA_INVALID;
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
        const errorDateText = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
          "{current date}",
          moment(currentDate).format("MM/DD/YYYY")
        );
        recoupmentStartDateText = errorDateText;
        setRecoupmentStartDateError(errorDateText);
        errorArray.push(errorDateText);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          recoupmentStartDateText =
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (values.paymentScheduleFrequency === DEFAULT_DD_VALUE) {
      frequencyText = ErrorConstants.FREQUENCY;
      setFrequencyError(ErrorConstants.FREQUENCY);
      errorArray.push(ErrorConstants.FREQUENCY);
    }
    if (values.numberofInstallments === "") {
      noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
      setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
    }
    if (values.numberofInstallments !== "") {
      if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
        noOfInstallmentText =
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          ".";
        setNumberofinstallmentError(
          ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            "."
        );
        errorArray.push(
          ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            "."
        );
      }
    }
    if (
      values.interestCalculation === "Y" &&
      values.interestPercentage === ""
    ) {
      calPercentText = ErrorConstants.INTEREST_PERCENT;
      setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
      errorArray.push(ErrorConstants.INTEREST_PERCENT);
    }
    if (
      values.interestPercentage !== "" &&
      values.interestPercentage
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
      values.paymentScheduleType === "A"
    ) {
      calPercentText = ErrorConstants.DATA_INVALID;
      setInstallmentCalError(ErrorConstants.DATA_INVALID);
      errorArray.push(ErrorConstants.DATA_INVALID);
    }
    if (errorArray.length === 0) {
      const scheduleArray = [];
      const amountStep = numeral(
        Number(
          Number(values.originalARAmount.toString().replace(/,/g, "")) /
            Number(values.numberofInstallments)
        )
      ).format("0.00");
      const numberOfRows = Number(
        numeral(
          Number(values.originalARAmount.toString().replace(/,/g, "")) /
            Number(amountStep)
        ).format("0")
      );
      const dateTemp = new Date(selectedRecoupmentStartDate);
      let daysStep = 0;
      switch (values.paymentScheduleFrequency) {
        case "2":
          daysStep = 7;
          break;
        case "3":
          daysStep = 14;
          break;
        case "4":
          daysStep = 30;
          break;
      }
      let sumOfInstallmentAmount = 0;
      for (let i = 0; i < numberOfRows; i++) {
        let value;
        if (i === 0) {
          value = {
            scheduleSK: null,
            dueDate: moment(dateTemp.setDate(dateTemp.getDate())).format(
              "MM/DD/YYYY"
            ),
            installmentAmount: numeral(amountStep).format("0.00"),
            recoupedAmount: "0.00"
          };
        } else {
          value = {
            scheduleSK: null,
            dueDate: moment(
              dateTemp.setDate(dateTemp.getDate() + daysStep)
            ).format("MM/DD/YYYY"),
            installmentAmount: numeral(amountStep).format("0.00"),
            recoupedAmount: "0.00"
          };
        }
        sumOfInstallmentAmount = numeral(
          Number(numeral(sumOfInstallmentAmount).format("0.00")) +
            Number(numeral(amountStep).format("0.00"))
        ).format("0.00");
        scheduleArray.push(value);
      }
      if (
        numeral(values.originalARAmount).format("0.00") !==
        numeral(sumOfInstallmentAmount).format("0.00")
      ) {
        scheduleArray[numberOfRows - 1].installmentAmount = numeral(
          parseFloat(
            numeral(scheduleArray[numberOfRows - 1].installmentAmount).format(
              "0.00"
            )
          ) +
            (parseFloat(numeral(values.originalARAmount).format("0.00")) -
              parseFloat(numeral(sumOfInstallmentAmount).format("0.00")))
        ).format("0,0.00");
      }
      setPaymentSchedleData(scheduleArray);
      setPaymentScheduleTypeError("");
      setRecoupmentStartDateError("");
      setFrequencyError("");
      setNumberofinstallmentError("");
      setInstallmentCalError("");
      seterrorMessages([]);
    } else {
      window.scrollTo(0, 0);
      setShowError({
        showOriginalArAmountPopError: originalArAmountRequired
      });
      setShowErrorText({
        showOriginalArAmountPopErrorText: originalArAmountRequiredText
      });
      setPaymentScheduleTypeError(paymentScheduleTypeText);
      setRecoupmentStartDateError(recoupmentStartDateText);
      setFrequencyError(frequencyText);
      setNumberofinstallmentError(noOfInstallmentText);
      setInstallmentCalError(calPercentText);
      seterrorMessages(errorArray);
    }
  };
  function PaymentRowDeleteClick () {
    let temNewDialogData = [...paymentScheduleData];
    for (
      let i = 0;
      i < rowPaymentScheduleData.rowPaymentScheduleData.length;
      i++
    ) {
      temNewDialogData = temNewDialogData.filter(
        payment =>
          payment.id !== rowPaymentScheduleData.rowPaymentScheduleData[i]
      );
    }
    setPaymentSchedleData(temNewDialogData);
    setRowPaymentScheduleData([]);
  }
  const [paymentScheduleChanged, setPaymentScheduleChanged] = React.useState(
    false
  );

  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (
      name === "paymentScheduleType" &&
      values.paymentScheduleType !== event.target.value
    ) {
      setPaymentScheduleChanged(generateUUID());
      setPaymentScheduleTypeError("");
      setRecoupmentStartDateError("");
      setFrequencyError("");
      setNumberofinstallmentError("");
      setInstallmentCalError("");
      setValues({ ...values, [name]: event.target.value });
    } else if (name === "interestCalculation") {
      setValues({
        ...values,
        [name]: event.target.value,
        interestPercentage: ""
      });
    } else if (name === "originalARAmount") {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    } else if (name === "installmentValue") {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    } else if (
      name === "numberofInstallments" ||
      name === "paymentScheduleFrequency"
    ) {
      setPaymentSchedleData([]);
      setValues({ ...values, [name]: event.target.value });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  useEffect(() => {
    if (paymentScheduleChanged) {
      setPaymentSchedleData([]);
      setValues({
        ...values,
        recoupmentStartDate: null,
        numberofInstallments: "",
        interestCalculation: "N",
        interestPercentage: "",
        paymentScheduleFrequency: DEFAULT_DD_VALUE
      });
      setSelectedRecoupmentStartDate(null);
    }
  }, [paymentScheduleChanged]);
  const formatDecimalField = name => event => {
    if (event.target.value !== "") {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format("0,0.00")
      });
    }
  };

  const formatPercent = name => event => {
    if (values.percentValue) {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format("0.00")
      });
    }
  };
  const removeFormatDecimalField = () => () => {
    if (values.originalARAmount) {
      setValues({
        ...values,
        originalARAmount: numeral(values.originalARAmount).format("0.00")
      });
    }
  };
  const removeFormatDecimalFieldPercent = () => () => {
    if (values.interestPercentage) {
      setValues({
        ...values,
        interestPercentage: numeral(values.interestPercentage).format("0.00")
      });
    }
  };
  const removeFormatDecimalFieldInstallment = () => () => {
    if (values.installmentValue) {
      setValues({
        ...values,
        installmentValue: numeral(values.installmentValue).format("0.00")
      });
    }
  };
  const formatDecimalFieldInstallment = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          "0,0.00"
        )
      });
    }
  };
  const removeFormatDecimalFieldAmountDue = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          "0.00"
        )
      });
    }
  };

  var showOriginalArAmmountErrorTemp = false;
  var showApprovalErrorTemp = false;
  var showFiscalYearErrorTemp = false;
  var showATRErrorTemp = false;
  var showCollocationCodeErrorTemp = false;
  var showRecoupmentTypeCodeErrorTemp = false;
  var showInvalidBeginDateErrorTemp = false;
  var showInvalidEndDateErrorTemp;
  var showEndDateLesserErrorTemp;
  var showBeginDateRquiredErrorTemp = false;
  var showEndDateRequiredErrorTemp = false;
  var showPercentErrorTemp = false;
  var showInstallmentErrorTemp = false;
  var startDateErrorTemp = false;
  var startDateReqErrorTemp = false;
  var minimumBeginDateError = false;
  var minimumEndDateError = false;
  var minimumRecoupmentDaateError = false;
  var showOriginalArAmountErrorTextTemp = "";
  var showATRErrorTextTemp = '';
  var showApprovalErrorTextTemp = "";
  var showFiscalYearErrorTextTemp = "";
  var showCollocationCodeErrorTextTemp = "";
  var showRecoupmentTypeCodeErrorTextTemp = "";
  var showInvalidBeginDateTextTemp = "";
  var showInvalidEndDateInvalidTextTemp = "";
  var showEndDateLesserErrorTextTemp = "";
  var showBeginDateRequiredErrorTextTemp = "";
  var showEndDateRequiredErrorTextTemp = "";
  var minBeginDateTextTemp = "";
  var minEndDateTextTemp = "";
  var minRecoupmentDateTextTemp = "";
  var showPercentErrorTextTemp = "";
  var showInstallmentErrorTextTemp = "";
  var showStartDateErrorTextTemp = "";
  var startDateReqErrorTextTemp = "";
  const checkFormValidations = () => {
    var showFrequencyErrorText = "";
    var showFrequencyError = false;
    var checkFormat = false;
    const regex = /[^A-Za-z0-9]/;
    const adjustedAmount = parseAmount(values.adjustedARAmount);
    const originalAmount = parseAmount(values.originalARAmount);
    const installmentValue = parseAmount(values.installmentValue);
    const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);
    if (paymentScheduleData.length > 0 && values.recoupmentTypeCode === "S") {
      let summation = 0;
      paymentScheduleData.map(value => {
        summation =
          summation + Number(numeral(value.installmentAmount).format("0.00"));
      });
      const summationAmount = parseAmount(summation);
      if (summationAmount !== amountToUse) {
        errorMessagesArray.push(ErrorConstants.SUM_AMOUNT);
      }
    }
    if (
      values.originalARAmount === "" ||
      values.originalARAmount === undefined
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp =
        ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
    }
    if (
      values.originalARAmount &&
      values.originalARAmount
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp = ErrorConstants.DATA_INVALID;
      checkFormat = true;
    }

    if (values?.atrNumber && regex.test(values?.atrNumber)) {
      showATRErrorTemp = true;
      showATRErrorTextTemp = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`;
      errorMessagesArray.push(showATRErrorTextTemp);
    }

    if (values.fiscalYear === "" || values.fiscalYear === undefined) {
      showFiscalYearErrorTemp = true;
      showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
      errorMessagesArray.push(showFiscalYearErrorTextTemp);
    }

    if (
      values.collocationCode === "" ||
      values.collocationCode === DEFAULT_DD_VALUE ||
      values.collocationCode === undefined
    ) {
      showCollocationCodeErrorTemp = true;
      showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
      errorMessagesArray.push(showCollocationCodeErrorTextTemp);
    }

    if (values.underAppeal === "N") {
      if (
        values.recoupmentTypeCode === "" ||
        values.recoupmentTypeCode === DEFAULT_DD_VALUE ||
        values.recoupmentTypeCode === undefined
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPMENT_TYPE_CODE_REQUIRED;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }
    if(values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || 
      values.recoupmentTypeCode ===AppConstants.RECOUPMENTTYPECODE_DOLLARAMT){
      if(values.frequency ===undefined ||
      values.frequency === DEFAULT_DD_VALUE 
      ){
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }

    let pushFlag = false;
    if (reasonCd.includes(values.reasonCode)) {
	  if (!selectedBeginDate) {
        showBeginDateRquiredErrorTemp = true;
        showBeginDateRequiredErrorTextTemp = ErrorConstants.REASON_CODE_SETTLEMENT_ERROR;
        pushFlag = true;
      }
	  if (!selectedEndDate) {
        showEndDateRequiredErrorTemp = true;
        showEndDateRequiredErrorTextTemp = ErrorConstants.REASON_CODE_SETTLEMENT_ERROR;
        pushFlag = true;
      }
	  if (pushFlag) {
        errorMessagesArray.push(ErrorConstants.REASON_CODE_SETTLEMENT_ERROR);
	  }
    }
    if (selectedBeginDate) {
      if (!moment(selectedBeginDate).isValid()) {
        showInvalidBeginDateErrorTemp = true;
        showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidBeginDateTextTemp);
      }
      if (!showInvalidBeginDateErrorTemp) {
        if (validateDateMinimumValue(selectedBeginDate)) {
          minimumBeginDateError = true;
          minBeginDateTextTemp = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(minBeginDateTextTemp);
        }
      }
    }

    if (selectedEndDate) {
      if (!moment(selectedEndDate).isValid()) {
        showInvalidEndDateErrorTemp = true;
        showInvalidEndDateInvalidTextTemp = ErrorConstants.END_DATE_INVALID;
        errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
      }
      if (!showInvalidEndDateErrorTemp) {
        if (validateDateMinimumValue(selectedEndDate)) {
          minimumEndDateError = true;
          minEndDateTextTemp = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(minEndDateTextTemp);
        }
      }
    }

    if (selectedBeginDate && selectedEndDate) {
      var beginDate = new Date(selectedBeginDate);
      var endDate = new Date(selectedEndDate);
      if (!compareTwoDates(beginDate, endDate)) {
        if (
          moment(selectedBeginDate).isValid() &&
          moment(selectedEndDate).isValid() &&
          !validateDateMinimumValue(selectedEndDate) &&
          !validateDateMinimumValue(selectedBeginDate)
        ) {
          showEndDateLesserErrorTemp = true;
          showEndDateLesserErrorTextTemp = ErrorConstants.END_DATE_ERROR;
          errorMessagesArray.push(showEndDateLesserErrorTextTemp);
        }
      }
    }

    if (reasonCd.includes(values.reasonCode)) {
      if (selectedBeginDate) {
        if (
          !moment(selectedBeginDate).isValid() &&
          !showInvalidBeginDateErrorTemp
        ) {
          showInvalidBeginDateErrorTemp = true;
          showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidBeginDateTextTemp);
        }
      }

      if (selectedEndDate) {
        if (
          !moment(selectedEndDate).isValid() &&
          !showInvalidEndDateErrorTemp
        ) {
          showInvalidEndDateErrorTemp = true;
          showInvalidEndDateInvalidTextTemp = ErrorConstants.END_DATE_INVALID;
          errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
        }
      }
    }

    if (
      values.percentValue &&
      values.percentValue
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.DATA_INVALID;
      checkFormat = true;
    }

    if (values.percentValue && values.percentValue > 100) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp =
        ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (
      values.recoupmentTypeCode !== "N" &&
      values.percentValue &&
      values.percentValue === 0
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (values.percentValue && values.percentValue < 0) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }

    if (values.installmentValue && parseFloat(values.installmentValue) < 0) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
    } else if (installmentValue > amountToUse) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp =
        ErrorConstants.INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
    }
    if (
      values.installmentValue &&
      values.installmentValue
        .toString()
        .replace(/,/g, "")
        .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
    ) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.DATA_INVALID;
      checkFormat = true;
    }
    if (values.recoupmentTypeCode === "D") {
      const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
        ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }
    if (values.recoupmentTypeCode === "B") {
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        const showErrorMessage = ErrorConstants.PERCENTAGE_START_DATE_REQUIRED;
        errorMessagesArray.push(showErrorMessage);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (values.installmentValue !== null && values.installmentValue !== "") {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
        errorMessagesArray.push(showInstallmentErrorTextTemp);
        if (values.percentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode === "A") {
      if (!values.installmentValue && !selectedRecoupmentDate) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        const showErrorMessage = ErrorConstants.INSTALLMENT_START_DATE_REQUIRED;

        errorMessagesArray.push(showErrorMessage);
      } else {
        if (!values.installmentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        } else if (parseInt(values.installmentValue) === 0) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (values.percentValue !== null && values.percentValue !== "") {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
        errorMessagesArray.push(showPercentErrorTextTemp);
        if (values.installmentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === "N") {
      if (
        parseInt(values.installmentValue) > 0 ||
        parseInt(values.installmentValue) < 0 ||
        parseInt(values.percentValue) > 0 ||
        parseInt(values.percentValue) < 0
      ) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0);
        if (values.percentValue !== "") {
          if (
            (values.percentValue !== "0" && values.percentValue !== "0.00") ||
            parseInt(values.percentValue) > 0 ||
            parseInt(values.percentValue) < 0
          ) {
            showPercentErrorTemp = true;
            showPercentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
        if (values.installmentValue !== "") {
          if (
            (values.installmentValue !== "0" &&
              values.installmentValue !== "0.00") ||
            parseInt(values.installmentValue) > 0 ||
            parseInt(values.installmentValue) < 0
          ) {
            showInstallmentErrorTemp = true;
            showInstallmentErrorTextTemp =
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
          }
        }
      }

      if (selectedRecoupmentDate) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }

      if (
        values.frequency &&
        values.frequency !== DEFAULT_DD_VALUE
      ) {
        showFrequencyError = true;
        showFrequencyErrorText = ErrorConstants.FREQUENCY_MUST_BE_BLANK;
        errorMessagesArray.push(showFrequencyErrorText);
      }
    }

    if (selectedRecoupmentDate) {
      var startDate = new Date(selectedRecoupmentDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.DATA_INVALID;
        checkFormat = true;
      } else if (
        validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        minimumRecoupmentDaateError = true;
        minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(minRecoupmentDateTextTemp);
      } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
          "{current date}",
          moment(currentDate).format("MM/DD/YYYY")
        );
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          errorMessagesArray.push(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
        }
      }
    }

    if (checkFormat) {
      errorMessagesArray.push(ErrorConstants.DATA_INVALID);
      checkFormat = false;
    }

    setShowError({
      showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
      showApprovalError: showApprovalErrorTemp,
      showFiscalYearError: showFiscalYearErrorTemp,
      showATRError: showATRErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp,
      showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
      showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
      showInvalidEndDateError: showInvalidEndDateErrorTemp,
      showEndDateLesserError: showEndDateLesserErrorTemp,
      showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
      showEndDateRequiredError: showEndDateRequiredErrorTemp,
      showPercentError: showPercentErrorTemp,
      showInstallementError: showInstallmentErrorTemp,
      showStartDateError: startDateErrorTemp,
      showStartDateRequiredError: startDateReqErrorTemp,
      showMinBeginDateError: minimumBeginDateError,
      showMinEndDateError: minimumEndDateError,
      showMinRecoupmentDateError: minimumRecoupmentDaateError,
      showFrequencyError: showFrequencyError
    });
    setShowErrorText({
      showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
      showApprovalErrorText: showApprovalErrorTextTemp,
      showATRErrorText: showATRErrorTextTemp,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
      showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
      showInvalidBeginDateText: showInvalidBeginDateTextTemp,
      showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
      showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
      showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
      showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
      showPercentErrorText: showPercentErrorTextTemp,
      showInstallmentErrorText: showInstallmentErrorTextTemp,
      showStartDateErrorText: showStartDateErrorTextTemp,
      showStartDateRequiredErrorText: startDateReqErrorTextTemp,
      showMinBeginDateErrorText: minBeginDateTextTemp,
      showMinEndDateErrorText: minEndDateTextTemp,
      showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
      showFrequencyErrorText: showFrequencyErrorText
    });
    seterrorMessages(errorMessagesArray);
    setErrorMessageCopy(errorMessagesArray);
    return true;
  };

  const saveGrossLevelAdd = () => {
    let estDate = values.establishedDate
      ? values.establishedDate
      : grossLevel.grossLevelDetails.establishedDate;
    estDate = new Date(estDate);
    const year = estDate.getFullYear();
    let date = estDate.getDate();
    let month = estDate.getMonth() + 1;
    if (date < 10) {
      date = "0" + date;
    }
    if (month < 10) {
      month = "0" + month;
    }

    const estDateFormatted = `${month}/${date}/${year}`;
    checkFormValidations(); if (values.recoupmentTypeCode === 'S') {
      const errorArray = [];
      var originalArAmountRequired = false;
      var originalArAmountRequiredText = "";
      let paymentScheduleTypeText = "";
      let recoupmentStartDateText = "";
      let noOfInstallmentText = "";
      let frequencyText = "";
      let calPercentText = "";
      if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
        paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
        errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
        setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      }
      if (
        values.originalARAmount === "" ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorArray.push(originalArAmountRequiredText);
      }
      if (!selectedRecoupmentStartDate) {
        recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
        setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
        errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
      }

      if (selectedRecoupmentStartDate) {
        var startDate = new Date(selectedRecoupmentStartDate);
        estDate = new Date(values.establishedDate);
        var currentDate = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        );

        if (!moment(startDate).isValid()) {
          recoupmentStartDateText = ErrorConstants.DATA_INVALID;
          setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
          errorArray.push(ErrorConstants.DATA_INVALID);
        } else if (
          validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
        ) {
          recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
          setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
          errorArray.push(minRecoupmentDateTextTemp);
        } else if (compareTwoDatesGreaterThanOrEqual(startDate, currentDate)) {
          const errorDateText = ErrorConstants.START_DATE_LESS_CURRENT_DATE_ERROR.replace(
            "{current date}",
            moment(currentDate).format("MM/DD/YYYY")
          );
          recoupmentStartDateText = errorDateText;
          setRecoupmentStartDateError(errorDateText);
          errorArray.push(errorDateText);
        }
        if (
          moment(startDate).isValid() &&
          !validateDateMinimumValue(moment(startDate).format("MM/DD/YYYY"))
        ) {
          if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
            recoupmentStartDateText =
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
            setRecoupmentStartDateError(
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
            );
            errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
          }
        }
      }
      if (
        values.paymentScheduleFrequency === DEFAULT_DD_VALUE &&
        values.paymentScheduleType === "A"
      ) {
        frequencyText = ErrorConstants.FREQUENCY;
        setFrequencyError(ErrorConstants.FREQUENCY);
        errorArray.push(ErrorConstants.FREQUENCY);
      }
      if (
        values.numberofInstallments === "" &&
        values.paymentScheduleType === "A"
      ) {
        noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
        setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
        errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      }
      if (
        values.numberofInstallments !== "" &&
        values.paymentScheduleType === "A"
      ) {
        if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
          noOfInstallmentText =
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            ".";
          setNumberofinstallmentError(
            ErrorConstants.INSTALLMENT_MORE +
              frequencyResult.valueNum.toString() +
              "."
          );
          errorArray.push(
            ErrorConstants.INSTALLMENT_MORE +
              frequencyResult.valueNum.toString() +
              "."
          );
        }
      }
      if (
        values.interestCalculation === "Y" &&
        values.interestPercentage === "" &&
        values.paymentScheduleType === "A"
      ) {
        if (!errorMessagesArray.includes(ErrorConstants.INTEREST_PERCENT)) {
          calPercentText = ErrorConstants.INTEREST_PERCENT;
          setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
          errorArray.push(ErrorConstants.INTEREST_PERCENT);
        }
      }
      if (
        values.interestPercentage !== "" &&
        values.interestPercentage
          .toString()
          .replace(/,/g, "")
          .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
        values.paymentScheduleType === "A"
      ) {
        calPercentText = ErrorConstants.DATA_INVALID;
        setInstallmentCalError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      }
      if (errorArray.length === 0) {
        setPaymentScheduleTypeError("");
        setRecoupmentStartDateError("");
        setFrequencyError("");
        setNumberofinstallmentError("");
        setInstallmentCalError("");
      } else {
        setPaymentScheduleTypeError(paymentScheduleTypeText);
        setRecoupmentStartDateError(recoupmentStartDateText);
        setFrequencyError(frequencyText);
        setNumberofinstallmentError(noOfInstallmentText);
        setInstallmentCalError(calPercentText);
        if (errorArray.length !== 0) {
          errorArray.map(value => {
            errorMessagesArray.push(value);
          });
        }
      }
    }
    const paymentScheduleTemp = paymentScheduleData;
    paymentScheduleTemp.map(value => {
      value.auditUserID = logInUserID;
      value.auditTimeStamp = getUTCTimeStamp();
      value.addedAuditUserID = logInUserID;
      value.addedAuditTimeStamp = getUTCTimeStamp();
      value.installmentAmount = numeral(value.installmentAmount).format("0.00");
      value.recoupedAmount = numeral(value.recoupedAmount).format("0.00");
    });
    const value = {
      adjustedArAmt: AppConstants.DEFAULT_ADJUSTED_AR_AMT,
      versionNoForFinancialDetail: 0,
      versionNo: 0,
      fcnMediaCode: null,
      fcnSequenceNo: grossLevel
        ? grossLevel.grossLevelDetails.fcnNumber.slice(
          grossLevel.grossLevelDetails.fcnNumber.length - 5,
          grossLevel.grossLevelDetails.fcnNumber.length
        )
        : 0,
      receivableFCN: grossLevel ? grossLevel.grossLevelDetails.fcnNumber : 0,
      atrNumber: values?.atrNumber?.toUpperCase(),
      payeePayerID: grossLevel ? grossLevel.payeeDetails.payeePayerId : 0,
      payeePayerIDTypeCode: grossLevel
        ? grossLevel.payeeDetails.payeePayerIdTypeCode
        : "",
      payeePayerTypeCode: grossLevel
        ? grossLevel.payeeDetails.payeePayerType
        : "",
      provBillSysId:
        grossLevel && grossLevel.payeeDetails.billProvSysId
          ? grossLevel.payeeDetails.billProvSysId
          : "",
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      payeePayerIdFromDB: grossLevel
        ? grossLevel.payeeDetails.systemPayeeId
        : "",
      withholdingVO: {
        thirdPartyID: null,
        thirdPartyIDFromDB: "56421551",
        thirdPartyType: "P",
        thirdPartyIDType: null
      },
      lob: AppConstants.DEFAULT_LOB_VALUE,
      activityTypeCode: grossLevel ? grossLevel.payeeDetails.activityType : "",
      originalAmount: values.originalARAmount,
      balanceAmount: "0.00",
      establishedDate: estDateFormatted,
      establishedDateStr: estDateFormatted,
      accountReceivableReasonCode: grossLevel
        ? grossLevel.payeeDetails.reasonCode
        : "",
      memberID: null,
      memberIDTypeCode: null,
      approvalIndicator: values.approval === "Y" ? 1 : 0,
      lastAppliedDate: grossLevel
        ? grossLevel.grossLevelDetails.lastDateApplied
        : null,
      advancePaymentReceivableVO: {
        manualCheckNumber: "0"
      },
      manualCheckDateStr: null,
      holdLetterIndicator: false,
      receivablePurgeIndicator: false,
      calculatedInterest: grossLevel
        ? grossLevel.grossLevelDetails.calculatedInterest
        : 0.0,
      calculatedPenalty: grossLevel
        ? grossLevel.grossLevelDetails.calculatedPenalty
        : 0.0,
      calculatedFees: grossLevel
        ? grossLevel.grossLevelDetails.calculatedFees
        : 0.0,
      agingCode: grossLevel ? grossLevel.grossLevelDetails.arLetterSent.split('-')[0] : null,
      recoupmentVO: {
        recoupmentTypeCode: values.recoupmentTypeCode
          ? values.recoupmentTypeCode === DEFAULT_DD_VALUE
            ? null
            : values.recoupmentTypeCode
          : null,
        installmentAmount: values.installmentValue,
        percentageOriginalAmount: values.percentValue,
        startDateStr: selectedRecoupmentDate
          ? moment(new Date(selectedRecoupmentDate).getTime()).format(
            "MM/DD/YYYY"
          )
          : null,
        endDate: null,
        frequency: values.frequency
          ? values.frequency === DEFAULT_DD_VALUE
            ? null
            : values.frequency
          : null,
        releaseDateStr: null,
        returnDateStr: null
      },
      accountVO: {
        scoaFiscalYear: values.fiscalYear,
        cos: values.categoryOfService
          ? values.categoryOfService === DEFAULT_DD_VALUE
            ? null
            : values.categoryOfService
          : null,
        fundCode: values.collocationCode
          ? values.collocationCode === DEFAULT_DD_VALUE
            ? null
            : values.collocationCode
          : null
      },
      exemptionsVO: {
        interestExemptionsInd: values.exemptFromInterest === "Y" ? 1 : 0,
        penaltyExemptionsInd: values.exemptFromPenalty === "Y" ? 1 : 0,
        collectionAgencyInd: values.exemptFromCollectionAgency === "Y" ? 1 : 0
      },
      grossLevelReceivableVO: {
        appealIndicator: values.underAppeal === "Y" ? 1 : 0,
        beginDateStr:
          selectedBeginDate && (reasonCd.includes(values.reasonCode))
            ? moment(new Date(selectedBeginDate).getTime()).format("MM/DD/YYYY")
            : null,
        endDateStr:
          selectedEndDate && (reasonCd.includes(values.reasonCode))
            ? moment(new Date(selectedEndDate).getTime()).format("MM/DD/YYYY")
            : null
      },
      schedulePaymentList: paymentScheduleTemp,
      relatedFTTCN: grossLevel.grossLevelDetails.relatedFTTCN,
      voidIndicator: 0,
      adjustmentVO: {
        adjustmentActivityType: null,
        interest: null,
        penalty: null,
        fee: null,
        adjustmentReasonCode: null,
        establishedDate: null,
        balanceAmount: null,
        originalAmount: null
      },
      transferVO: {
        payeePayerID: null,
        payeePayerIDTypeCode: null,
        payeePayerTypeCode: null,
        balanceAmount: null,
        establishedDate: null,
        payeePayerIdFromDB: null,
        transferReasonCode: null,
        agingCode: null
      },
      noteSetVO: notesInput,
      paymentScheduleType:
        values.paymentScheduleType === DEFAULT_DD_VALUE
          ? null
          : values.paymentScheduleType,
      recoupmentStartDate: selectedRecoupmentStartDate
        ? moment(selectedRecoupmentStartDate).format("MM/DD/YYYY")
        : null,
      numberofInstallments: values.numberofInstallments
        ? values.numberofInstallments
        : null,
      interestCalculation: values.interestCalculation,
      interestPercentage:
        values.interestPercentage !== ""
          ? numeral(values.interestPercentage).format("0.00")
          : null,
      paymentScheduleFrequency:
        values.paymentScheduleFrequency !== DEFAULT_DD_VALUE
          ? values.paymentScheduleFrequency
          : null
    };
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map(value => {
        const attachmentVO = {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: getUTCTimeStamp(),
          versionNo: 0,
          dbRecord: true,
          sortColumn: null,
          attachmentSK: null,
          attachmentPageID: null,
          finalPath: null,
          detachInd: false,
          historyIndicator: false,
          cascadeKey: null,
          attachmentIndicator: null,
          dateAdded: getDateInMMDDYYYYFormatWithApendZero(
            new Date(
              new Date().toLocaleString("en-US", {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          ),
          fileUrl: null,
          showDeleteForSearched: false,
          rowIndex: 0,
          addedBy: logInUserID,
          description: value && value ? value.description : null,
          edmsStoredDate: null,
          anEDMSPageId: value? value.anEDMSPageId : null,
          fileName: attachmentsTableData && value ? value.fileName : null,
          file1: null,
          edmsWrkUnitLevel: null,
          edmsDocType: null
        };
        attachmentVOList.push(attachmentVO);
      });
    }
    value.attachmentVO = null;
    value.attachmentsList = attachmentVOList;
    value.payeeStatus = values.payeeStatus !== DEFAULT_DD_VALUE
      ? values.payeeStatus
      : null;

    if (errorMessagesArray.length === 0) {
      onGrossLevelAdd(value);
    }
  };

  const editRow = data => () => {
    seterrorMessages([]);
    setAdd(false);
    setEditData(data);
    setRetainEdit(data);
    setOpen(true);
    setSelectedDueDate(data.dueDate);
    setPaymentScheduleVO(data);
    setRetainPaymentSchedule(data);
    setRetainSelectedDueDate(data.dueDate);
    setShowError(false);
    setShowErrorText(false);
  };

  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO
    ) {
      const amount = "";
      const defaultOnPaymentSchedule = "N";
      const underAppeal = values.underAppeal;
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        installment: getRecoupmetDetails.recoupmentVO.installmentAmount
          ? numeral(getRecoupmetDetails.recoupmentVO.installmentAmount).format(
            "0,0.00"
          )
          : getRecoupmetDetails.recoupmentVO.installmentAmount,
        recoupment: getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        frequency: getRecoupmetDetails.recoupmentVO.frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        underAppeal: underAppeal,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      if (
        recoupmentsection.frequency !== values.frequency ||
         recoupmentsection.recoupment !== values.recoupmentTypeCode ||
         Number(recoupmentsection.installment) !== Number(values.installmentValue) ||
         Number(recoupmentsection.percent) !== Number(values.percentValue) ||
         getRecoupmetDetails.recoupmentVO.startDateStr !== moment(selectedRecoupmentDate).format('MM/DD/YYYY')
      ) { setFocusCheck(true); }
      setValues(setInputValues(grossLevel, values, recoupmentsection));
      setSelectedRecoupmentDate(getRecoupmetDetails.recoupmentVO.startDateStr);
      seterrorMessages([]);
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);

  const handleDefaultRecoupment = () => {
    setAllowNavigation(true);
    let estDate = values.establishedDate
      ? values.establishedDate
      : grossLevel.grossLevelDetails.establishedDate;
    estDate = new Date(estDate);
    const estDateFormatted = getDateInMMDDYYYYFormat(estDate);

    onGetRecoupmetDetails({
      adjustedArAmt: 0,
      versionNoForFinancialDetail: 0,
      versionNo: 0,
      fcnMediaCode: null,
      fcnSequenceNo: grossLevel
        ? grossLevel.grossLevelDetails.fcnNumber.slice(
          grossLevel.grossLevelDetails.fcnNumber.length - 5,
          grossLevel.grossLevelDetails.fcnNumber.length
        )
        : 0,
      receivableFCN: grossLevel ? grossLevel.grossLevelDetails.fcnNumber : 0,
      payeePayerID: grossLevel ? grossLevel.payeeDetails.payeePayerId : 0,
      payeePayerIDTypeCode: grossLevel
        ? grossLevel.payeeDetails.payeePayerIdTypeCode
        : "",
      payeePayerTypeCode: grossLevel
        ? grossLevel.payeeDetails.payeePayerType
        : "",
      provBillSysId:
        grossLevel && grossLevel.payeeDetails.billProvSysId
          ? grossLevel.payeeDetails.billProvSysId
          : "",
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      payeePayerIdFromDB: grossLevel
        ? grossLevel.payeeDetails.systemPayeeId
        : "",
      lob: AppConstants.DEFAULT_LOB_VALUE,
      activityTypeCode: grossLevel ? grossLevel.payeeDetails.activityType : "",
      originalAmount: values.originalARAmount,
      balanceAmount: "0.00",
      establishedDate: estDateFormatted,
      establishedDateStr: estDateFormatted,
      accountReceivableReasonCode: grossLevel
        ? grossLevel.payeeDetails.reasonCode
        : "",
      memberID: null,
      memberIDTypeCode: null,
      approvalIndicator: values.approval === "Y" ? 1 : 0,
      lastAppliedDate: grossLevel
        ? grossLevel.grossLevelDetails.lastDateApplied
        : null,
      advancePaymentReceivableVO: {
        manualCheckNumber: "0"
      },
      manualCheckDateStr: null,
      holdLetterIndicator: false,
      receivablePurgeIndicator: false,
      calculatedInterest: grossLevel
        ? grossLevel.grossLevelDetails.calculatedInterest
        : 0.0,
      calculatedPenalty: grossLevel
        ? grossLevel.grossLevelDetails.calculatedPenalty
        : 0.0,
      calculatedFees: grossLevel
        ? grossLevel.grossLevelDetails.calculatedFees
        : 0.0,
      agingCode: grossLevel ? grossLevel.grossLevelDetails.arAgeInDays : null,
      recoupmentVO: {
        recoupmentTypeCode: values.recoupmentTypeCode
          ? values.recoupmentTypeCode === DEFAULT_DD_VALUE
            ? null
            : values.recoupmentTypeCode
          : null,
        installmentAmount: values.installmentValue,
        percentageOriginalAmount: values.percentValue,
        startDateStr: selectedRecoupmentDate
          ? moment(new Date(selectedRecoupmentDate).getTime()).format(
            "MM/DD/YYYY"
          )
          : null,
        endDate: null,
        frequency: values.frequency
          ? values.frequency === DEFAULT_DD_VALUE
            ? null
            : values.frequency
          : null,
        releaseDateStr: null,
        returnDateStr: null
      },
      accountVO: {
        scoaFiscalYear: values.fiscalYear,
        cos: values.categoryOfService
          ? values.categoryOfService === DEFAULT_DD_VALUE
            ? null
            : values.categoryOfService
          : null,
        fundCode: values.collocationCode
          ? values.collocationCode === DEFAULT_DD_VALUE
            ? null
            : values.collocationCode
          : null
      },
      exemptionsVO: {
        interestExemptionsInd: values.exemptFromInterest === "Y" ? 1 : 0,
        penaltyExemptionsInd: values.exemptFromPenalty === "Y" ? 1 : 0,
        collectionAgencyInd: values.exemptFromCollectionAgency === "Y" ? 1 : 0
      },
      grossLevelReceivableVO: {
        appealIndicator: values.underAppeal === "Y" ? 1 : 0,
        beginDateStr:
          selectedBeginDate && (reasonCd.includes(values.reasonCode))
            ? moment(new Date(selectedBeginDate).getTime()).format("MM/DD/YYYY")
            : null,
        endDateStr:
          selectedEndDate && (reasonCd.includes(values.reasonCode))
            ? moment(new Date(selectedEndDate).getTime()).format("MM/DD/YYYY")
            : null
      },
      schedulePaymentList: null,
      relatedFTTCN: grossLevel.grossLevelDetails.relatedFTTCN,
      voidIndicator: 0,
      adjustmentVO: {
        adjustmentActivityType: null,
        interest: null,
        penalty: null,
        fee: null,
        adjustmentReasonCode: null,
        establishedDate: null,
        balanceAmount: null,
        originalAmount: null
      },
      transferVO: {
        payeePayerID: null,
        payeePayerIDTypeCode: null,
        payeePayerTypeCode: null,
        balanceAmount: null,
        establishedDate: null,
        payeePayerIdFromDB: null,
        transferReasonCode: null,
        agingCode: null
      },
      noteSetVO: notesInput,
      paymentScheduleType:
        values.paymentScheduleType === DEFAULT_DD_VALUE
          ? null
          : values.paymentScheduleType,
      recoupmentStartDate: selectedRecoupmentStartDate
        ? moment(selectedRecoupmentStartDate).format("MM/DD/YYYY")
        : null,
      numberofInstallments: values.numberofInstallments
        ? values.numberofInstallments
        : null,
      interestCalculation: values.interestCalculation,
      interestPercentage:
        values.interestPercentage !== ""
          ? numeral(values.interestPercentage).format("0.00")
          : null,
      paymentScheduleFrequency:
        values.paymentScheduleFrequency !== DEFAULT_DD_VALUE
          ? values.paymentScheduleFrequency
          : null
    });
    setRetainValues(values);
  };

  const rowDeletePaymentSchedule = data => {
    setRowPaymentScheduleData({
      ...rowPaymentScheduleData,
      rowPaymentScheduleData: data
    });
  };
  const valuesFromARAdd = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setDialogOpen(false);
    setFocusCheck(false);
	dispatch(resetL2KeyDataFromStore('accountReceivableState', 'payload'));
    props.history.push({
      pathname: '/GrossLevelAddPayeeComponent',
      state: { valuesFromARAdd: valuesFromARAdd },
      addRender: true,
      focusCheck: true
    });
  };

  const majorCancel = () => {
    seterrorMessages([]);
    setAllowNavigation(false);
    setTimeout(() => {
      if (focusCheck) {
        setDialogOpen(true);
        setAllowNavigation(false);
      } else {
		dispatch(resetL2KeyDataFromStore('accountReceivableState', 'payload'));
        setDialogOpen(false);
        props.history.push({
          pathname: '/GrossLevelAddPayeeComponent',
          state: { valuesFromARAdd: valuesFromARAdd },
          addRender: true,
          focusCheck: true
        });
      }
    }, 100);
  };

  return (
    <div>
      <Prompt
        when={allowNavigation}
        message={location =>
            `Are you sure you want to go to ${location.pathname}`
        }
      />

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Add Gross Level</h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveGrossLevelAdd()}
			  data-testid="test-save"
            >
              {" "}
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button title="Cancel" data-testid="test_MajorCancel" onClick={majorCancel}>Cancel</Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                id="standard-AccountPayeeType"
                label="Payee Type"
                value={values.payeeType}
                onChange={handleChange("payeeType")}
                inputProps={{ maxlength: 3 }}
                disabled={true}
                dropdownList={payeeTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                id="standard-AccountTypeCode"
                label="Payee ID Type Code"
                value={values.payeeIdTypeCode}
                onChange={handleChange("payeeIdTypeCode")}
                inputProps={{ maxlength: 3 }}
                disabled={true}
                dropdownList={payeeTypeIdCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
				          data-testid='payee_Id'
                  label="Payee ID"
                  disabled={true}
                  value={values.payeeId}
                  onChange={handleChange("payeeId")}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form  input-md">
                <TextField
                  disabled={true}
                  fullWidth
                  id="standard-AccountPayeeName"
                  label="Payee Name "
                  placeholder=""
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                ></TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form  input-md has-link">
                <TextField
                  disabeld={true}
                  fullWidth
                  id="standard-AccountSystemPayeeID"
                  label="System Payee ID"
                  placeholder=""
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values.systemPayeeId)} target='_blank'>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                  disabled={true}
                ></TextField>
              </div>
              <NativeDropDown
                disabled={true}
                id="standard-AccountActivityType"
                label="Activity Type"
                value={values.activityCode}
                onChange={handleChange("activityCode")}
                inputProps={{ maxlength: 3 }}
                dropdownList={activityTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                disabled={true}
                id="standard-AccountReasonCode"
                label="Reason Code"
                value={values.reasonCode}
                onChange={handleChange("reasonCode")}
                inputProps={{ maxlength: 3 }}
                title={getLongDescription(reasonCodeData, values.reasonCode)}
                dropdownList={reasonCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
            </div>
          </form>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Gross Level Detail"
                  />
                  <Tab
                    label="Interest and Penalty"
                  />
                  <Tab
                    label="Payment Schedule"
                    data-testid="payment_schedule_tab_testid"
                  />
                  <Tab
                    label="History"
                    style={{ marginLeft: "-7px" }}
                  />
                  <Tab
                    label="Notes"
                    style={{ marginLeft: "-6px" }}
                  />
                  <Tab
                    label="Attachment"
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <>
                    <div className="tab-holder my-3 p-0">
                      <GrossLevelDetailsComponent
                        payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                        values={values}
                        handleChange={handleChange}
                        formatDecimalField={formatDecimalField}
                        formatPercent={formatPercent}
                        removeFormatDecimalField={removeFormatDecimalField}
                        removeFormatDecimalFieldInstallment={
                          removeFormatDecimalFieldInstallment
                        }
                        frequencyData={frequencyData}
                        errors={[
                          showOriginalArAmmountError,
                          showFiscalYearError,
                          showCollocationCodeError,
                          showRecoupmentTypeCodeError,
                          showInvalidBeginDateError,
                          showInvalidEndDateError,
                          showEndDateLesserError,
                          showBeginDateRequiredError,
                          showEndDateRequiredError,
                          showPercentError,
                          showInstallementError,
                          showStartDateError,
                          showStartDateRequiredError,
                          showMinBeginDateError,
                          showMinEndDateError,
                          showMinRecoupmentDateError,
                          showFrequencyError,
                          showATRError
                        ]}
                        errorText={[
                          showOriginalArAmountErrorText,
                          showFiscalYearErrorText,
                          showCollocationCodeErrorText,
                          showRecoupmentTypeCodeErrorText,
                          showInvalidBeginDateText,
                          showInvalidEndDateInvalidText,
                          showEndDateLesserErrorText,
                          showBeginDateRequiredErrorText,
                          showEndDateRequiredErrorText,
                          showPercentErrorText,
                          showInstallmentErrorText,
                          showStartDateErrorText,
                          showStartDateRequiredErrorText,
                          showMinBeginDateErrorText,
                          showMinEndDateErrorText,
                          showMinRecoupmentDateErrorText,
                          showFrequencyErrorText,
                          showATRErrorText
                        ]}
                        selectedBeginDate={selectedBeginDate}
                        handleBeginDateChange={handleBeginDateChange}
                        selectedEndDate={selectedEndDate}
                        handleEndDateChange={handleEndDateChange}
                        selectedRecoupmentDate={selectedRecoupmentDate}
                        handleRecoupmentDateChange={handleRecoupmentDateChange}
                        defaultRecoupment={handleDefaultRecoupment}
                      ></GrossLevelDetailsComponent>
                    </div>
                  </>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <GrossLevelExemptionDetailComponent
                      values={values}
                      handleChange={handleChange}
                      exempt={excemptData.some(
                        value => value.code === values.activityCode
                      )}
                    ></GrossLevelExemptionDetailComponent>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={2}>
                {value === 2 && (
                  <>
                    <div className="tab-holder">
                      <form autoComplete="off">
                        <div className="form-wrapper">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NativeDropDown
                              className='min-md'
                              id="paymentSchedule"
                              label="Payment Schedule"
                              value={values.paymentScheduleType}
                              onChange={handleChange("paymentScheduleType")}
                              inputProps={{ maxlength: 3, 'data-testid': "payment_schedule_testid" }}
                              disabled={values.recoupmentTypeCode !== "S"}
                              isRequired={values.recoupmentTypeCode === "S"}
                              errTxt={
                                paymentScheduleTypeError !== ""
                                  ? paymentScheduleTypeError
                                  : null
                              }
                              dropdownList={paymentScheduleTypeData.map(
                                (option, index) => (
                                  <option key={index} value={option.code}>
                                    {option.description}
                                  </option>
                                )
                              )}
                            />
                            <div className="mui-custom-form with-date">
                              <KeyboardDatePicker
                                fullWidth
                                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                id="GrossLevelDetailBegindate-picker-dialog"
                                label="Recoupment Start Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                minDate={"01/01/1964"}
                                value={selectedRecoupmentStartDate}
                                onChange={handleRecoupmentStartDateChange}
                                inputProps={{ "data-testid": "recoupment_start_date_testid" }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                required={values.recoupmentTypeCode === "S"}
                                disabled={values.recoupmentTypeCode !== "S"}
                                helperText={
                                  recoupmentStartDateError !== ""
                                    ? recoupmentStartDateError
                                    : null
                                }
                                error={
                                  recoupmentStartDateError !== ""
                                    ? recoupmentStartDateError
                                    : null
                                }
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                          <div className="mui-custom-form input-md override-m-2">
                            <TextField
                              fullWidth
                              id="installments"
                              label="Number of Installments"
                              placeholder=""
                              inputProps={{ maxlength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={values.numberofInstallments}
                              onChange={handleChange("numberofInstallments")}
                              disabled={
                                values.recoupmentTypeCode !== "S" ||
                                values.paymentScheduleType !== "A"
                              }
                              required={values.paymentScheduleType === "A"}
                              helperText={
                                numberofInstallmentError !== ""
                                  ? numberofInstallmentError
                                  : null
                              }
                              error={
                                numberofInstallmentError !== ""
                                  ? numberofInstallmentError
                                  : null
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/,
                                  ""
                                );
                              }}
                            ></TextField>
                          </div>
                          <NativeDropDown
                            className='min-md override-m-2'
                            id="frequency"
                            label="Frequency"
                            value={values.paymentScheduleFrequency}
                            onChange={handleChange(
                              "paymentScheduleFrequency"
                            )}
                            inputProps={{ maxlength: 3 }}
                            disabled={
                              values.recoupmentTypeCode !== "S" ||
                                values.paymentScheduleType !== "A"
                            }
                            isRequired={values.paymentScheduleType === "A"}
                            errTxt={
                              frequencyError !== "" ? frequencyError : null
                            }
                            dropdownList={frequencyRecoupData.map((option, index) => (
                              <option key={index} value={option.code}>
                                {option.description}
                              </option>
                            ))}
                          />
                        </div>
                        <div className="form-wrapper">
                          <div
                            className="mui-custom-form min-md"
                            style={{ lineHeight: "100%" }}
                          >
                            <label className="MuiFormLabel-root small-label">
                              Interest Calculation
                            </label>
                            <div className="sub-radio no-margin">
                              <Radio
                                value="Y"
                                id="GroosLevelDetailsapprovalIdYes"
                                checked={values.interestCalculation === "Y"}
                                onChange={handleChange("interestCalculation")}
                                disabled={
                                  values.recoupmentTypeCode !== "S" ||
                                  values.paymentScheduleType !== "A"
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="GroosLevelDetailsapprovalIdYes"
                              >
                                Yes
                              </label>
                              <Radio
                                value="N"
                                id="GroosLevelDetailsapprovalIdNo"
                                checked={values.interestCalculation === "N"}
                                onChange={handleChange("interestCalculation")}
                                className="ml-2"
                                disabled={
                                  values.recoupmentTypeCode !== "S" ||
                                  values.paymentScheduleType !== "A"
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="GroosLevelDetailsapprovalIdNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              fullWidth
                              id="interestPercentage"
                              label="Interest Percentage"
                              placeholder=""
                              inputProps={{ maxlength: 14 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              value={values.interestPercentage}
                              onBlur={formatDecimalField("interestPercentage")}
                              onFocus={removeFormatDecimalFieldPercent(
                                "interestPercentage"
                              )}
                              onChange={handleChange("interestPercentage")}
                              onKeyDown={evt =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              disabled={
                                values.recoupmentTypeCode !== "S" ||
                                values.paymentScheduleType !== "A" ||
                                values.interestCalculation === "N"
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                  ""
                                );
                              }}
                              required={values.interestCalculation === "Y"}
                              helperText={
                                interestCalError !== ""
                                  ? interestCalError
                                  : null
                              }
                              error={
                                interestCalError !== ""
                                  ? interestCalError
                                  : null
                              }
                            ></TextField>
                          </div>
                        </div>
                      </form>
                      <div className="float-right mt-3">
                        <Button
                          className="btn-text-main-add btn-success"
                          disabled={
                            values.recoupmentTypeCode !== "S" ||
                            values.paymentScheduleType !== "A"
                          }
                          title={"Calculate"}
                          onClick={calculateSchedule}
                        >
                          Calculate
                        </Button>
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={PaymentRowDeleteClick}
                          disabled={
                            values.recoupmentTypeCode !== "S" ||
                            values.paymentScheduleType === "A" ||
                            open
                          }
                          title={"Delete"}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpenPaymentSchedule}
                          data-testid="payment_schedule_add_btn"
                          disabled={
                            values.recoupmentTypeCode !== "S" ||
                            values.paymentScheduleType === "A"
                          }
                          title={"Add Payment Schedule"}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="tab-holder my-3 p-0">
                      {values.paymentScheduleType === "M" ? (
                        <PaymentScheduleTable
                          tableData={paymentScheduleData}
                          onRowClick={editRow}
                          rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                          isSearch={false}
                        />
                      ) : (
                        <PaymentScheduleTable
                          tableData={paymentScheduleData}
                          onRowClick={editRow}
                          rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                          isSearch={true}
                        />
                      )}
                    </div>
                  </>
                )}
                {open ? (
                  <div className="tabs-container" id="form_pop_up">
                    <div className="tab-header  mini-tab-header">
                      <div className="tab-heading float-left">
                        {add ? "Add Payment Schedule" : "Edit Payment Schedule"}
                      </div>
                      <div className="float-right mt-1">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-success ml-1"
                          onClick={savePaymentSchedule}
                          disabled={values.paymentScheduleType === "A"}
                          title={add ? "Add" : "Update"}
                          data-testid="payment_schedule_save_btn"
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>
                          {add ? "Add" : "Update"}
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="bt-reset btn-transparent ml-1"
                          onClick={resetPaymentSchedule}
                          disabled={values.paymentScheduleType === "A"}
                          title={'Reset'}
                        >
                          <i className="fa fa-undo" aria-hidden="true"></i>
                          Reset
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-primary btn btn-outlined ml-1"
                          onClick={handleClose}
                          title={'Cancel'}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    <div className="form-border my-3">
                      <form autoComplete="off">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className="form-wrapper">
                            <div className="mui-custom-form with-date override-width-20">
                              <KeyboardDatePicker
                                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                required
                                fullWidth
                                id="dueDate"
                                label="Due Date"
                                inputProps={{ "data-testid": "payment_schedule_due_date" }}
                                minDate={
                                  new Date(
                                    new Date().toLocaleString("en-US", {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                initialFocusedDate={
                                  new Date(
                                    new Date().toLocaleString("en-US", {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                value={selectedDueDate}
                                onChange={handleDueDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                disabled={values.paymentScheduleType === "A"}
                                helperText={
                                  showDueDateError
                                    ? showDueDateErrorText
                                    : null || showDueDateGreaterError
                                      ? showDueDateGreaterErrorText
                                      : null || showMinDueDateError
                                        ? showMinDueDateErrorText
                                        : null || showDueDateInvalidError
                                          ? showDueDateInvalidErrorText
                                          : null
                                }
                                error={
                                  showDueDateError
                                    ? showDueDateErrorText
                                    : null || showDueDateGreaterError
                                      ? showDueDateGreaterErrorText
                                      : null || showMinDueDateError
                                        ? showMinDueDateErrorText
                                        : null || showDueDateInvalidError
                                          ? showDueDateInvalidErrorText
                                          : null
                                }
                              />
                            </div>
                            <div className="mui-custom-form input-md override-width-20">
                              <TextField
                                id="installmentAmount"
                                fullWidth
                                label="Installment Amount"
                                required
                                value={paymentScheduleVO.installmentAmount}
                                inputProps={{ maxlength: 14, "data-testid": "payment_schedule_due_amount" }}
                                onChange={handleChangePaymentSchedule(
                                  "installmentAmount"
                                )}
                                onBlur={formatDecimalFieldInstallment(
                                  "installmentAmount"
                                )}
                                onFocus={removeFormatDecimalFieldAmountDue(
                                  "installmentAmount"
                                )}
                                disabled={values.paymentScheduleType === "A"}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  )
                                }}
                                helperText={
                                  showAmountDueError
                                    ? showAmountDueErrorText
                                    : null || showNegativeAmountError
                                      ? showNegativeAmmountErrorText
                                      : null || showGreaterThanZeroAmountError
                                        ? showGreaterThanZeroAmountText
                                        : null || showOriginalArAmountPopError
                                          ? showOriginalArAmountPopErrorText
                                          : null
                                }
                                error={
                                  showAmountDueError
                                    ? showAmountDueErrorText
                                    : null || showNegativeAmountError
                                      ? showNegativeAmmountErrorText
                                      : null || showGreaterThanZeroAmountError
                                        ? showGreaterThanZeroAmountText
                                        : null || showOriginalArAmountPopError
                                          ? showOriginalArAmountPopErrorText
                                          : null
                                }
                                onInput={e => {
                                  e.target.value = e.target.value.replace(
                                    /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                    ""
                                  );
                                }}
                              ></TextField>
                            </div>
                            <div className="mui-custom-form input-md override-width-20">
                              <TextField
                                id="recoupedAmount"
                                fullWidth
                                label="Recouped Amount"
                                required
                                disabled
                                inputProps={{ maxlength: 14 }}
                                value={paymentScheduleVO.recoupedAmount}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  )
                                }}
                              ></TextField>
                            </div>
                          </div>
                        </MuiPickersUtilsProvider>
                      </form>
                    </div>
                  </div>
                ) : null}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {value === 3 && (
                  <>
                    <div className="tab-holder my-3 p-0">
                      <GrossLevelHistoryTableComponent values={values} />
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={4}>
                {value === 4 && (
                  <div className="tab-holder p-0">
                    <Notes
                      addNotes={addNotes}
                      setFocusCheck={setFocusCheck}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={5}
              >
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={userInquiryPrivileges}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={seterrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    setFocusCheck={setFocusCheck}
                    values={values}
                    setValues={setValues}
                    attchmentResponse={attchmentResponse}
                    setAttachmentResponse={setAttachmentResponse}
                  />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              data-testid="minorCancel"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(FinancialAccountGrossLevel);
